import { useState } from 'react';
import { Button, Tab, Grid, Box, Paper, Typography } from '@mui/material';
import { SeleccionDeMedioDePago } from 'components/matriculas/seleccionDeMedioDePago';
import { PagoBrick } from 'components/matriculas/pagoBrick';
import { PagoQR } from 'components/matriculas/pagoQR';
import { PagoCompletadoTitEspecial } from './pagoCompletadoTitEspecial';
import { PagoPendiente } from 'components/matriculas/pagoPendiente';


export const DerechoDeInscripcionTituloEspecial = ({ datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, ocultarContenido, setOcultarContenido, bloquearTabs, aprobacionDocumentacion, setAprobacionDocumentacion, resultadoPagoTasa, estadoOperacion, obtenerResultadoDePago, setEstadoOperacion, setResult, montoDeTasa, idLegajoTemporal, medioDePago, setMedioDePago }) => {
    // const [medioDePago, setMedioDePago] = useState("0");


  return (
    <Grid>
        {
            estadoOperacion === 1 ? 
            <PagoPendiente obtenerResultadoDePago={obtenerResultadoDePago} setEstadoOperacion={setEstadoOperacion}/>
            :
            (!resultadoPagoTasa ? 
                (() => {
                    switch(medioDePago) {
                        case "0":
                            return <SeleccionDeMedioDePago setMedioDePago={setMedioDePago} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} bloquearBtn={bloquearBtn} setValue={setValue} resultadoPagoTasa={resultadoPagoTasa}/>
                        case "1":
                            return <PagoBrick setResult={setResult} montoDeTasa={montoDeTasa} setMedioDePago={setMedioDePago}/>
                        case "2":
                            return <PagoQR setMedioDePago={setMedioDePago} setResult={setResult}/>
                    }
                })()
                : 
                <PagoCompletadoTitEspecial idLegajoTemporal={idLegajoTemporal} datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearTabs={bloquearTabs} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setValue} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} aprobacionDocumentacion={aprobacionDocumentacion} setAprobacionDocumentacion={setAprobacionDocumentacion} resultadoPagoTasa={resultadoPagoTasa}/>
            )
        }
    </Grid>
  )
}
