import axios from 'axios';

import { 
	HEADERS_TOKEN,
	TITULOS_PROFESIONAL
} from 'constants/routes/endpoints';



export const getCatalogoTitulosProfesional = (legajoProf) => {

   if(legajoProf === undefined){
	legajoProf = localStorage.idLegajo;
   }
	return new Promise((resolve, reject) => {
		return axios({
			url: TITULOS_PROFESIONAL + "/" + legajoProf,
			method: 'GET',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
			if (err.response.status === 401 || err.response.status === 500){
				window.location.href = `/login`;
			}
			});
	});
};

