import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { postLoginExterno } from 'app/features/Usuarios/usuariosAPI';
import { useDispatch } from 'react-redux';
import { TIPOS_TRAMITE, TIPOS_USUARIO }  from 'constants/constants';
import useQuery from 'customHooks/useQuery';
import { createSession } from 'utils/sessionManager';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const LoginAltaTitulo = () => {
    localStorage.setItem('token', "");
    localStorage.removeItem('usuario');
    const params = useParams();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles();

    let query = useQuery();
    const [mensajePantalla, setMensajePantalla] = useState("cargando...");

    useEffect(() => {

        const usrData = { usuario: encodeURIComponent(query.get("usuario")), password: encodeURIComponent(query.get("pass")), idLegajoTemporal: 0, email: "", tpoTramite : 8 };
        postLoginExterno(usrData)
            .then((value) => {

                localStorage.clear();
            
                if (value.data.resultado) {

                    const session = {
                        ...value.data,
                        userType: TIPOS_USUARIO.PROFESIONAL_ALTA_TITULO,
                        tpoTramite: TIPOS_TRAMITE.ALTA_TITULO,
                        user: query.get("usuario"),
                        pass: query.get("pass")
                    }
                    createSession(session);

                    // localStorage.setItem('token', value.data.token);
                    // localStorage.setItem('idLegajoTemporal',  value.data.idLegajoTemporal);
                    // localStorage.setItem('usuario', '');
                    // localStorage.setItem('usuarioValidarDatos', query.get("usuario"));
                    // localStorage.setItem('passValidarDatos', query.get("pass"));
                    // localStorage.setItem('idLegajo', value.data.legajoProfesional);
                    // localStorage.setItem('email', value.data.email);
                    // localStorage.setItem('userType', TIPOS_USUARIO.PROFESIONAL_ALTA_TITULO);
                    // localStorage.setItem('usuario', value.data.usuarioInterno);

                    window.location.href = `/altatitulo`;
                } else {
                    setMensajePantalla("Usuario y/o contraseña incorrectos");
                }
            });
    }, []);

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle1">
                {mensajePantalla}
            </Typography>
        </Paper>
    )
}

export default LoginAltaTitulo;
