import React, { useState, Fragment } from 'react';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import { Grid, Card, CardContent, CardActions, Button, Link, TextField, Typography } from '@mui/material';
import {
    recuperarPasswordUsuario
} from 'app/features/Usuarios/usuariosAPI';
// Styles
import 'scss/components/header.scss';
import CircularProgress from '@mui/material/CircularProgress';
const RecuperarPassword = () => {
    localStorage.setItem('token', "");
    const { handleSubmit } = useForm();


    const [mostrarLoader, setMostrarLoader] = useState({ display: 'none' });
    const [mostrarContenido, setMostrarContenido] = useState({ display: '' });
    const [email, setEmail] = useState();
    const [errorEmail, setErrorEmail] = useState();
    const [textoErrorEmail, setTextoErrorEmail] = useState();

    const InputChange = (e) => {
        setEmail(e.target.value);
    }
    const onSubmit = data => {
        
        setMostrarLoader({ display: ''});
        setMostrarContenido({ display: 'none'});
        const result = recuperarPasswordUsuario({ email: email, tpoTramite: 1 });
        result.then((value) => {
          
            if (!value.data.resultado) {

                if (value.data.codigoError === 0) {

                    setTextoErrorEmail("Intentelo nuevamente mas tarde.");
                }
                if (value.data.codigoError === 1) {

                    setTextoErrorEmail("El email ya se encuentra registrado");
                }
                if (value.data.codigoError === 2) {
                    setTextoErrorEmail("El email aún no se encuentra verificado");
                }
                if (value.data.codigoError === 3) {

                    setTextoErrorEmail("El email no se encuentra registrado");
                }
                if (value.data.codigoError === 4) {
                    setTextoErrorEmail("El token es invalido. Por favor solicite uno nuevo.");
                }
                setErrorEmail("error");
            } else {
                Swal.fire({
                    title: 'Correcto',
                    html: 'Por favor revise su correo:<b>' + email + '</b> <br> para poder cambiar la contraseña.',
                    icon: 'success',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        window.location.href = `/login`
                    }
                });

                //   this.state.mensaje_resultado = "El corre <strong>"+ match.params.email +"  </strong>  fue verificado correctamente";
            }
            setMostrarLoader({ display: 'none'});
            setMostrarContenido({ display: ''});
        });
    }



    return (

        <Fragment>
            <Grid
                   
                   align='center'
                    direction="column"
            >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    md={6}
                    item
                >

                    <Card>
                        <CardContent >
                            <Typography sx={{ mb: 1 }} variant="h6">Recuperar de Contraseña</Typography>

                            <Typography variant="display1">A continuación ingrese el email con el que se registro:</Typography>
                            <Grid sx={{ mb: 3, mt: 2 }} item md={12} xs={12} >
                                <TextField label="Email"
                                    value={email}
                                    error={errorEmail}
                                    helperText={textoErrorEmail}
                                    onChange={(value) => InputChange(value)}
                                    required
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    inputProps={{ sx: {fontWeight: 'light'}}}
                                    InputLabelProps={{ sx: { fontWeight: 'normal', color: 'gray' }}}
                                />
                            </Grid>

                            <CardActions
                                sx={{
                                    justifyContent: "center",
                                }}>

                                <Button variant="contained"  style={mostrarContenido} type="submit" color="primary" >
                                    Recuperar
                                </Button>
                                <Button style={mostrarLoader}  variant="contained"  color="primary" >
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "white"
                                        }}
                                    />
                                </Button>

                            </CardActions>
                            <Grid item sx={{ mt: 1 }} md={12} xs={12} className="centrar_contenido"  >
                                <Grid className="centrar_contenido">
                                    Para iniciar sesión hacer
                                    <Link sx={{ ml: 1 }} href="/Login">
                                        Click Aqui
                                    </Link>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
            </form>
        </Grid>
        </Fragment >

    );

};

export default RecuperarPassword;
