export const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};


export const HEADERS_TOKEN = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.token
};

export const HEADERS_GOOGLE = {
    "x-rapidapi-host": "google-maps-geocoding.p.rapidapi.com",
    "x-rapidapi-key": "AIzaSyBb5RiBio4TouN2IjNLz-LVW4BbIZH0eE8"
};

let location = window.location;

console.log(location.hostname);
export const HOST =  location.hostname === 'localhost' ? process.env.REACT_APP_API_URL_LOCAL : location.hostname === 'calidadonline2' ? process.env.REACT_APP_API_URL_CALIDAD : location.hostname === 'www.cpbaonline.com.ar' ? process.env.REACT_APP_API_URL_PRODUCCION : 'No se encontro HOST'

export const MP_VARIABLE = process.env.REACT_APP_MP_VARIABLE

console.log(HOST);

const MS_CONTROLLER = {
    USUARIO: "usuario",
    CATALOGO: "catalogo",
    PROFESIONAL: "Profesionales",
    COMUNICACION: "Comunicacion",
    LEYENDAS:"Leyendas"
};

const ENDPOINT = {
    AGREGAR_USUARIO: "registro",
    LOGIN_USUARIO: "login",
    LOGIN_EXTERNO: "loginexterno",
    RECUPERAR_PASSWORD: "RecuperarPassword",
    CONFIRMAR_EMAIL: "ConfirmarEmail",
    GENERAR_TOKEN: "ConfirmacionEmail",
    CAMBIAR_PASSWORD: "CambiarPassword",
    TIPO_DOCUMENTO: "TipoDocumento",
    ESTADO_CIVIL: "EstadoCivil",
    PROFESIONAL: "Profesional",
    TIPO_DOMICILIO: "TipoDomicilio",
    TITULOS: "Titulos",
    TITULOS_COMPLETOS: "TitulosCompletos",
    TITULOS_ESPECIALES: "TitulosEspeciales",
    UNIVERSIDADES: "Universidades",
    PARENTESCOS: "Parentescos",
    NACIONALIDADES:"Nacionalidades",
    LEGAJO_INSCRIPTO:"LegajoInscrito",
    LOGIN_ACTUALIZACION:"LoginActualizacion",
    VALIDA_CUIT:"ValidaCuit",
    VALIDAR_EMAIL:"ValidaEmailUnico",
    VALIDAR_EMAIL_ACTUALIZACION:"ValidaEmailUnicoActualizacion",
    CAMBIAR_ESTADO: "CambiarEstadoPendienteEnRevision",
    PARTIDOS:"Partidos",
    TIPO_CALLE:"TiposDeCalles",
    TIPOS_OBRAS_SOCIALES:"TiposObrasSociales",
    TITULOS_PROFESIONAL:"TitulosDeUnProfesional",
    LOCALIDADES:"Localidades",
    PAGO_TASA:"PagoMatricula",
    DERECHO_INSCRIPCION:"DerechoInscripcion",
    MONTO_TASA:"MontoDeTasa",
    GENERAR_OPERACION:"GenerarOperacion",
    OBTENER_OPERACION: "ObtenerOperacion",
    OBTENER_NUMERO_OPERACION: "ObtenerNumeroOperacion",
    OBTENER_ESTADO_OPERACION: "ObtenerEstadoOperacion",
    GRABAR_OPERACION: "grabarProfIntermediaTasa",
    GENERAR_OPERACION_ALTA_TITULO: "grabarOperacionMpBricksAltaTitulo",
    GENERAR_QR: "generarQr",
    ELIMINAR_QR: "eliminarQr",
    OBTENER_QR: "obtenerQr",
    OBTENER_ESTADO_OPERACION_QR: "estadoOperacionQr",
    VERIFICAR_PAGO: "verificarPago",
    OBTENER_PROF_POR_DOCUMENTO: "obtenerProfConDni",
    OBTENER_LISTA_TRAMITES: "obtenerTramites",
    CREAR_NUEVO_TRAMITE: "NuevoTramite",
    OBTENER_TOKEN_BAJA: "obtenerTokenTramite",
    VERIFICAR_PERFIL_USUARIO: "verificarPerfilDeUsuario",
    OBTENER_ID_PERFIL_USUARIO: "ObtenerIdPerfildeUsuario",
    VERIFICAR_SI_TIENE_TRAMITE_FALLECIDO: "verificarSiTieneTramiteFallecido",
    INSERTAR_FALLECIMIENTO : "InsertarFallecimiento",
    NOTIFICAR_VERIFICACION_ACTA_DEFUNCION: "NotificarVerificacionActaDefuncion",
    OBTENER_GERENCIA: "ObtenerGerencia",
    OBTENER_PROF_CON_TITULO:"ObtenerProfConTitulo"
};

export const AGREGAR_USUARIOS = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.AGREGAR_USUARIO}`;
export const LOGIN_USUARIO = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.LOGIN_USUARIO}`;
export const LOGIN_EXTERNO = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.LOGIN_EXTERNO}`;
export const RECUPERAR_PASSWORD = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.RECUPERAR_PASSWORD}`;
export const CONFIRMAR_EMAIL = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.CONFIRMAR_EMAIL}`;
export const GENERAR_TOKEN = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.GENERAR_TOKEN}`;
export const CAMBIAR_PASSWORD = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.CAMBIAR_PASSWORD}`;
export const PAGO_TASA = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.PAGO_TASA}`;
export const DERECHO_INSCRIPCION = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.DERECHO_INSCRIPCION}`;
export const MONTO_TASA = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.MONTO_TASA}`;
export const TIPO_DOCUMENTO = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.TIPO_DOCUMENTO}`;
export const ESTADO_CIVIL = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.ESTADO_CIVIL}`;
export const MATRICULAS = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/`;
export const TIPO_DOMICILIO = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.TIPO_DOMICILIO}`;
export const TITULOS = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.TITULOS}`;
export const TITULOS_COMPLETOS = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.TITULOS_COMPLETOS}`;
export const TITULOS_ESPECIALES = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.TITULOS_ESPECIALES}`;
export const UNIVERSIDADES = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.UNIVERSIDADES}`;
export const PARENTESCOS = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.PARENTESCOS}`;
export const COMUNICACION = `${HOST}/${MS_CONTROLLER.COMUNICACION}`;
export const NACIONALIDADES = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.NACIONALIDADES}`;
export const LEGAJO_INSCRIPTO = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.LEGAJO_INSCRIPTO}`;
export const FOTO_PROFESIONAL = `${HOST}/${MS_CONTROLLER.PROFESIONAL}`;
export const LOGIN_ACTUALIZACION = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.LOGIN_ACTUALIZACION}`;
export const VALIDAR_CUIT = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.VALIDA_CUIT}`;
export const VALIDAR_EMAIL = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.VALIDAR_EMAIL}`;
export const VALIDAR_EMAIL_ACTUALIZACION = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.VALIDAR_EMAIL_ACTUALIZACION}`;
export const CAMBIAR_ESTADO = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.CAMBIAR_ESTADO}`;
export const GENERAR_OPERACION = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.GENERAR_OPERACION}`;
export const OBTENER_NUMERO_OPERACION = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.OBTENER_NUMERO_OPERACION}`;
export const OBTENER_ESTADO_OPERACION = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.OBTENER_ESTADO_OPERACION}`;
export const OBTENER_OPERACION = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.OBTENER_OPERACION}`;
export const GRABAR_OPERACION = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.GRABAR_OPERACION}`;
export const GENERAR_OPERACION_ALTA_TITULO = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.GENERAR_OPERACION_ALTA_TITULO}`;
export const GENERAR_QR = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.GENERAR_QR}`;
export const ELIMINAR_QR = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.ELIMINAR_QR}`;
export const OBTENER_QR = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.OBTENER_QR}`;
export const OBTENER_LISTA_TRAMITES = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.OBTENER_LISTA_TRAMITES}`;
export const VERIFICAR_PERFIL_USUARIO = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.VERIFICAR_PERFIL_USUARIO}`;
export const OBTENER_ID_PERFIL_USUARIO = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.OBTENER_ID_PERFIL_USUARIO}`;
export const VERIFICAR_SI_TIENE_TRAMITE_FALLECIDO = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.VERIFICAR_SI_TIENE_TRAMITE_FALLECIDO}`;
export const OBTENER_PROF_POR_DOCUMENTO = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.OBTENER_PROF_POR_DOCUMENTO}`;
export const INSERTAR_FALLECIMIENTO = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.INSERTAR_FALLECIMIENTO}`;
export const NOTIFICAR_VERIFICACION_ACTA_DEFUNCION = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.NOTIFICAR_VERIFICACION_ACTA_DEFUNCION}`;
export const OBTENER_GERENCIA = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.OBTENER_GERENCIA}`;
export const OBTENER_PROF_CON_TITULO = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.OBTENER_PROF_CON_TITULO}`;
export const CREAR_NUEVO_TRAMITE = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.CREAR_NUEVO_TRAMITE}`;
export const OBTENER_TOKEN_BAJA = `${HOST}/${MS_CONTROLLER.USUARIO}/${ENDPOINT.OBTENER_TOKEN_BAJA}`;
export const OBTENER_ESTADO_OPERACION_QR = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.OBTENER_ESTADO_OPERACION_QR}`;
export const VERIFICAR_PAGO = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.VERIFICAR_PAGO}`;
export const PARTIDOS = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.PARTIDOS}`;
export const TIPOS_OBRAS_SOCIALES = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.TIPOS_OBRAS_SOCIALES}`;
export const TIPO_CALLE = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.TIPO_CALLE}`;
export const LEYENDAS = `${HOST}/${MS_CONTROLLER.LEYENDAS}/`;
export const LOCALIDADES = `${HOST}/${MS_CONTROLLER.CATALOGO}/${ENDPOINT.LOCALIDADES}`;
export const TITULOS_PROFESIONAL = `${HOST}/${MS_CONTROLLER.PROFESIONAL}/${ENDPOINT.TITULOS_PROFESIONAL}`;