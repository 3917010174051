import axios from 'axios';

import {
	HEADERS_TOKEN,
	COMUNICACION
} from 'constants/routes/endpoints';
import { getRefreshSessionURL } from 'utils/sessionManager';



export const getComunicaciones = (idLegajoTemporal) => {

	return new Promise((resolve, reject) => {
		return axios({
			url: COMUNICACION + '/' + idLegajoTemporal,
			method: 'GET',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401) {
					const redirectURL = getRefreshSessionURL();
					window.location.href = redirectURL;
				}
			});
	});
};

export const guardarComunicacion = (datos) => {
	return new Promise((resolve, reject) => {
		return axios({
			url: COMUNICACION,
			method: 'POST',
			headers: HEADERS_TOKEN,
			data: datos,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {

				if (err.response.status === 401) {
					const redirectURL = getRefreshSessionURL();
					window.location.href = redirectURL;
				}
			});
	});
};