
import { Box, Button, Modal, Paper, Slider, Typography } from "@mui/material";
import { setFotoNueva } from "app/features/FotoProfesional/editarDatosProfesionalSlice";
import { useRef } from "react";
import { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
};

function ModalNuevaFoto({ open, onClose, nuevaFoto }) {

    const avatarEditorRef = useRef(null);
    const [zoom, setZoom] = useState(0);
    const dispatch = useDispatch();

    function handleAceptar() {
        const img = avatarEditorRef.current?.getImageScaledToCanvas().toDataURL();
        // const rect = avatarEditorRef.current?.getCroppingRect();
        dispatch(setFotoNueva(img));
        onClose();
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            onClose={onClose}
            style={modalStyle}
            open={open}
            closeAfterTransition>
            <Paper className="paper-nueva-foto">
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"} variant='subtitle1'>Puede ajustar la imagen</Typography>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </Box>
                <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box>
                        <AvatarEditor
                            ref={avatarEditorRef}
                            image={nuevaFoto} width={250}
                            height={250}
                            border={20}
                            color={[0, 0, 0, 0.5]} // RGBA
                            scale={1 + zoom}
                            rotate={0}></AvatarEditor>
                        <Slider onChange={(_, value) => setZoom(value / 100)} defaultValue={20} aria-label="Default" valueLabelDisplay="auto" />
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={handleAceptar} variant='contained' color={'secondary'}>Aceptar</Button>
                </Box>
            </Paper>
        </Modal>);
}

export default ModalNuevaFoto;