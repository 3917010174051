import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Button, Select, Grid, TextField, MenuItem, InputLabel, Typography, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { obtenerListaTramites, obtenerProfesionalPorDocumento, verificarSiTieneTramiteFallecido } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';
import CircularProgress from '@mui/material/CircularProgress';
import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import Swal from 'sweetalert2';
import { TIPOS_USUARIO } from 'constants/constants';
import { ModalProfesionales } from './ModalProfesionales';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },
    button: {
        display: "flex!important",
        justifyContent: "flex-end!important"
    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    color_rojo: {
        color: 'red'
    },
    option: {
        fontWeight: 'bold',
        color: 'black'
    },
}));

export const DatosProfesional = ({datos, bloquearBtn, setDatos, setValue, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, idEstado, setMatriculas, setCantidadTramites, tpoUser}) => {

    const { handleSubmit } = useForm();
    const [nombre , setNombre] = useState('');
    const [apellido , setApellido] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [profBuscados, setProfBuscados] = useState('')
    const tipoUsuario = Number(localStorage.getItem('userType'));

    const inputHandleChange = (evt) => {
        const { name, value } = evt.target;
        const newData = { ...datos };
    
        if (name === "TipoDocumento" || name === "NroDocumento") {
            newData.bajaFallecimiento[0][name] = value;
            newData.bajaFallecimiento[0].codParentesco = ''
            setDatos(newData);
        }
    }

    const mascaraDocumento = (evt) => {
        var codigo = evt.which || evt.keyCode;
    
        if (codigo !== 8) {
            let documento = datos.bajaFallecimiento[0].NroDocumento;
    
            // Eliminar puntos y caracteres no numéricos
            documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "");
    
            // Formatear el documento
            let documentoNuevo = documento.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    
            const newData = { ...datos };
            newData.bajaFallecimiento[0].NroDocumento = documentoNuevo;
            setDatos(newData);
        }
    }

    const obtenerCantidadTramites = async() => {
        const resp = await obtenerListaTramites();
        if (resp !== null) {
            setCantidadTramites(resp.length);
        }
    }

    const onSubmit = async() => {
        if(datos.bajaFallecimiento[0].NroDocumento === null) {
            Swal.fire({
                title: 'Atención', html: 'Debe ingresar un número de documento', icon: 'warning',showCloseButton: true, 
                showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return;
        }
        setLoaderBtnGuardar(true);
        setBloquearBtn(true);
        const nroDocString = datos.bajaFallecimiento[0].NroDocumento.replace(/\./g, '');
        const nroDoc = parseInt(nroDocString, 10);
        const resp = await obtenerProfesionalPorDocumento(nroDoc);
        if ( resp !== null  && resp.resultado !== false) {
            setErrorMsg('');
            setProfBuscados(resp.$values);
            setShowModal(true);

        } else if (resp.resultado === false) {
            setErrorMsg(resp.message)
        }
        
        setLoaderBtnGuardar(false);
        setBloquearBtn(false);
    }

    const onContinue = () => {
        if(datos.bajaFallecimiento[0].LegProf === '0' || datos.bajaFallecimiento[0].LegProf === 0) {
            Swal.fire({
                title: 'Atención', html: 'Debe seleccionar el profesional y determinar parentesco/ relación', icon: 'warning',showCloseButton: true, 
                showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return;
        }
        if(datos.bajaFallecimiento[0].codParentesco === '') {
            Swal.fire({
                title: 'Atención', html: 'Debe agregar la relacion de parentesco', icon: 'warning',showCloseButton: true, 
                showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return;
        } 
        setLoaderBtnGuardar(true);
        setBloquearBtn(true);
        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 4 }).then((value) => {
            setMatriculas(value.data);
            setLoaderBtnGuardar(false);
            setBloquearBtn(false);
            if (value.data.resultado) {
                (tipoUsuario === TIPOS_USUARIO.USUARIO_EXTERNO) ? setValue("2") : setValue("2");
                Swal.fire({ 
                    title: 'Datos guardados correctamente', icon: 'success',showCloseButton: true, 
                    showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                })
            } else {
                Swal.fire({
                    title: 'Atención', html: value.data.mensaje !== '' && value.data.mensaje !== null ? value.data.mensaje : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                })
                return false;
            }
        });
    }
        
    const dispatch = useDispatch();
    const clases = useStyles();
    const TipoDocumentos = useSelector(tipoDocumentoList);

    useEffect(() => {
        if (TipoDocumentos.length === 0) {
            dispatch(getTipoDocumentoAsync());
        }
    }, [])


    useEffect(() => {
        if( Number(localStorage.userType !== 1)){
            obtenerCantidadTramites();
        }
    }, [])
    

  return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >* <b>Ingrese Numero de DNI.</b></Typography>
            <span sx={{ mb: 2 }}></span>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <TextField label="Número"
                        name='NroDocumento'
                        onChange={inputHandleChange}
                        onKeyUp={mascaraDocumento}
                        value={datos.bajaFallecimiento[0].NroDocumento}
                        inputProps={{ maxLength: 10, style: {fontWeight: 'bold'}}}
                        required
                        disabled={bloquearBtn}
                        variant={bloquearBtn === true ? "filled" : "outlined"}
                        fullWidth
                        type="text"
                        InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                    />
                </Grid>

                <Grid item md={4} xs={12} mt={1}>
                    <Button variant="contained" color="primary" onClick={onSubmit} disabled={bloquearBtn}>Seleccionar profesional</Button>
                </Grid>
                {
                    errorMsg !== '' &&
                    <Grid item md={12} xs={12}>
                        <Typography className={clases.color_rojo} sx={{ mt: 2 }} >{errorMsg}</Typography>
                    </Grid>
                }
                { (datos.bajaFallecimiento[0].Nombre !== '0' && datos.bajaFallecimiento[0].Apellido !== '0') &&
                    <Grid container spacing={2}  sx={{ mt: 3 }} >
                        <Grid item md={4} xs={12}>
                            <TextField label="Nombre"
                                name='nombre'
                                value={datos.bajaFallecimiento[0].Nombre}
                                disabled={true}
                                variant="filled"
                                fullWidth
                                type="text"
                            />
                        </Grid> 
                        <Grid item md={4} xs={12}>
                            <TextField label="Apellido"
                                name='apellido'
                                value={datos.bajaFallecimiento[0].Apellido}
                                disabled={true}
                                variant="filled"
                                fullWidth
                                type="text"
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField label="Legajo"
                                name='legajo'
                                value={datos.bajaFallecimiento[0].LegProf}
                                disabled={true}
                                variant="filled"
                                fullWidth
                                type="text"
                            />
                        </Grid>
                        {
                            datos.bajaFallecimiento[0].TitulosFallecido.map(prof => (
                                <>
                                    <Grid item md={4} xs={12}>
                                        <TextField label="Titulo"
                                            name='titulo'
                                            value={prof.descrip}
                                            disabled={true}
                                            variant="filled"
                                            fullWidth
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <TextField label="Tomo"
                                            name='tomo'
                                            value={prof.tomo}
                                            disabled={true}
                                            variant="filled"
                                            fullWidth
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <TextField label="Folio"
                                            name='folio'
                                            value={prof.folio}
                                            disabled={true}
                                            variant="filled"
                                            fullWidth
                                            type="text"
                                        />
                                    </Grid>
                                </>
                            ))
                        }
                        { tpoUser !== TIPOS_USUARIO.USUARIO_INTERNO &&
                            <Grid item md={4} xs={12}>
                                <TextField label="Vínculo del solicitante con el profesional"
                                    name='parentesco'
                                    value={datos.bajaFallecimiento[0].codParentesco}
                                    disabled={true}
                                    variant="filled"
                                    fullWidth
                                    type="text"
                                    />
                            </Grid>
                        }   
                    </Grid> 
                }
                {
                    showModal && <ModalProfesionales datos={datos} setDatos={setDatos} profBuscados={profBuscados} setShowModal={setShowModal} showModal={showModal} tpoUser={tpoUser}/>
                }
                <Grid item md={12} xs={6} display='flex' justifyContent='flex-end'>
                    <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" color="secondary" onClick={onContinue} >
                        {loaderBtnGuardar === true ? <CircularProgress /> : ''}Continuar
                    </Button>
                </Grid>
            </Grid>
    </form>
  )
}

