import { Button, Checkbox, CircularProgress, FormControlLabel, TextField, Grid } from "@mui/material";
import { marcarDocumentacionComoEnviada } from "app/features/Matriculas/matriculasApi";
import moment from "moment";
import { useState } from "react";


function AprobacionAreaExterna({ setAprobacionDocumentacion, fechaAprobacionDocumentacion, esPrecarga, idLegajoTemporal, tipoUsuario, aprobacionDocumentacion, setFechaAprobacionDocumentacion, nroResolucionAprobacionDocumentacion, setNroResolucionAprobacionDocumentacion }) {
    const [guardando, setGuardando] = useState(false);
    const [guardado, setGuardado] = useState(aprobacionDocumentacion.documentacionEnviadaParaRevision);
    const [enviadoParaRevision, setEnviadoParaRevision] = useState(aprobacionDocumentacion.documentacionEnviadaParaRevision);

    if (tipoUsuario !== "1") return null;
    async function marcarDocumentacionEnviadaParaRevision() {
        try {
            setGuardando(true);
            const response = await marcarDocumentacionComoEnviada(idLegajoTemporal); 
            setGuardado(true);
            setAprobacionDocumentacion(a => ({ ...a, documentacionEnviadaParaRevision: true, fechaEnvioDocumentacion: response.data.result.fechaEnvioDocumentacion }));
        } catch (error) {
            
        }
        setGuardando(false);
    }

    return <>
        <FormControlLabel
            style={{ marginBottom: "15px" }}
            disabled={guardado}
            onChange={() => setEnviadoParaRevision(!enviadoParaRevision)}
            required
            control={<Checkbox checked={enviadoParaRevision} />}
            label="Enviado para revision"
        />
        {
            guardado &&
            <Grid display={"flex"} direction={"row"} >
                <TextField label="N° RMD"
                    name='nroResolucionAprobacionDocumentacion'
                    onChange={(e) => setNroResolucionAprobacionDocumentacion(e.target.value)}
                    inputProps={{ maxLength: 10 }}
                    value={nroResolucionAprobacionDocumentacion}
                    required
                    disabled={!esPrecarga}
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                    fullWidth
                    type="string"
                    style={{ marginRight: "10px" }} />
                <TextField label="Fecha aprobaci&oacute;n"
                    name='fechaAprobacion'
                    onChange={(e) => setFechaAprobacionDocumentacion(e.target.value)}
                    value={fechaAprobacionDocumentacion}
                    required
                    disabled={!esPrecarga}
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{                    
                        max: moment().format('YYYY-MM-DD')  
                    }}
                    variant="outlined"
                    fullWidth
                    type="date"
                    style={{ marginBottom: "15px" }} />
            </Grid>
        }
        {
            !guardado && <Button
            onClick={marcarDocumentacionEnviadaParaRevision}
            disabled={!enviadoParaRevision}
            sx={{ height: "40px" }}
            variant="contained"
            color="secondary">{guardando === true ? <CircularProgress /> : ''} Guardar</Button>
        }
    </>
}


export default AprobacionAreaExterna;