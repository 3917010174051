
import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { getProfesionalValidarMailActualizacion } from "app/features/Profesionales/profesionalesAPI";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { estadoCivilList } from "app/features/EstadoCivil/estadoCilvilSlice";

const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
};

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },

    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },

    button: {
        display: "flex!important",
        justifyContent: "flex-end!important"
    },
    color_texto_aclaracion: {
        //  color: 'gray',
        fontSize: 1
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        bomdhadow: 24,
        p: 4,
        '@media (max-width:900px)': {
            top: '92%',
        }
    },
    modales: {
        overflow: 'scroll',

    },
    ocultarBtnModal: {
        '@media (max-width:900px)': {
            display: 'none',
        },
        '@media (min-width:900px)': {
            display: '',
        }
    },
    ocultarBtnModalCelular: {
        '@media (max-width:900px)': {
            display: '',
        },
        '@media (min-width:900px)': {
            display: 'none',
        }
    },
    color_rojo: {
        color: 'red'
    }
}));

function ModalEditarDatosContacto({ open, onClose, onDatosChange, obrasSociales, bloquearBtn, email, estadoCivil, obraSocial, tpoObraSocial }) {

    const [errorEmailLabel, setErrorEmailLabel] = useState(false);
    const EstadoCivilList = useSelector(estadoCivilList);
    const [errorEmailRepetido, setErrorEmailRepetido] = useState({ display: "none" });
    const [errorEmail, setErrorEmail] = useState({ display: "none" });
    const [errorObraSocial, setErrorObraSocial] = useState({ display: "none" });
    const [datosModalCuitEmail, setDatosModalCuitEmail] = useState({
        Email: '',
        EmailRepetido: '',
        EstadoCivil: 0,
        TpoOsocial: 0,
        ObraSocial: ''
    });
    const [autoCompleteOS, setAutoCompleteOS] = useState(null);


    const clases = useStyles();

    useEffect(() => {
        if (open) {
            
            setDatosModalCuitEmail({
                Email: email,
                EmailRepetido: email,
                EstadoCivil: estadoCivil,
                TpoOsocial: tpoObraSocial,
                ObraSocial: obraSocial
            })

            setAutoCompleteOS({ 
                label: obraSocial, 
                value: isNaN(tpoObraSocial) === false ? tpoObraSocial : 0 
            });

            setErrorEmailLabel(false);
            setErrorEmailRepetido({ display: "none" });
            setErrorEmail({ display: "none" });
            setErrorObraSocial({ display: "none" });
            
        }
    }, [open]);

    function handleAceptar() {
        if (datosModalCuitEmail.Email === '') {
            setErrorEmailLabel(true)
            setErrorEmailRepetido({ display: '' })
            return false;
        }
        if (datosModalCuitEmail.EmailRepetido === '') {
            setErrorEmailLabel(true)
            setErrorEmailRepetido({ display: '' })
            return false;
        }

        if (datosModalCuitEmail.EmailRepetido !== datosModalCuitEmail.Email) {
            setErrorEmailLabel(true)
            setErrorEmailRepetido({ display: '' })
            return false;
        }

        if (datosModalCuitEmail.ObraSocial === '') {
            setErrorObraSocial({ display: '' })
            return false;
        }

        getProfesionalValidarMailActualizacion(datosModalCuitEmail.Email, localStorage.idLegajo).then((value) => {
            if (!value.data.response) {
                setErrorEmail({ display: '' })
                return false;
            } else {
                setErrorEmail({ display: 'none' })
                onDatosChange(datosModalCuitEmail);
                onClose();
            }
        });
    }

    

    const inputHandleChange = (evt) => {

        if (evt.target.name === "EmailRepetido" && datosModalCuitEmail.Email !== evt.target.value) {
            setErrorEmailRepetido({ display: '' })
            //setBloquearBtn(true);
        } else if (evt.target.name === "EmailRepetido" && datosModalCuitEmail.Email === evt.target.value) {
            setErrorEmailRepetido({ display: 'none' })
            //setBloquearBtn(false);
        } else if (evt.target.name === "Email" && datosModalCuitEmail.EmailRepetido === evt.target.value) {
            setErrorEmailRepetido({ display: 'none' })
            //setBloquearBtn(false);
        } else if (evt.target.name === "Email" && datosModalCuitEmail.EmailRepetido !== evt.target.value && datosModalCuitEmail.EmailRepetido !== "") {
            setErrorEmailRepetido({ display: '' })
            // setBloquearBtn(true);
        }


        if (evt.target.name === "Email" && evt.target.value === "") {
            setErrorEmailLabel(true)
        } else if (evt.target.name === "Email" && evt.target.value !== "") {
            setErrorEmailLabel(false)
        }

        setDatosModalCuitEmail({
            ...datosModalCuitEmail,
            [evt.target.name]: evt.target.value
        })
    }

    const ingresaObraSocial = (event, value) => {
        
        if (value !== null) {

            setDatosModalCuitEmail({
                ...datosModalCuitEmail,
                ObraSocial: value.label,
                TpoOsocial: Number(value.value)
            });
            setAutoCompleteOS({ 
                label: value.label, 
                value: Number(value.value) 
            });
        } else {
            setDatosModalCuitEmail({
                ...datosModalCuitEmail,
                ObraSocial: '',
                TpoOsocial: 0
            });

            setAutoCompleteOS({ 
                label: '', 
                value: 0
            });
        }
    }
    let obraSocialObjeto = [];
    let obraSocialNombre = "";
    obrasSociales.map((obraSocial, index) => {
        if (obraSocialNombre.toUpperCase() !== obraSocial.sigla.trim().toUpperCase())
            obraSocialObjeto.push({ label: obraSocial.sigla.trim(), value: obraSocial.tpoOSocial })

        obraSocialNombre = obraSocial.sigla.trim();
    })

    let ObrasSocialesOrdenadas = [];

    if (obraSocialObjeto.length > 0) {

        obraSocialNombre = "";

        obraSocialObjeto.map((obraSocial, index) => {
            if (obraSocialNombre.toUpperCase() !== obraSocial.label.trim().toUpperCase()) {

                let agregarObraSocial = 0;
                ObrasSocialesOrdenadas.map(obraSocialRepetida => {
                    if (obraSocialRepetida.label.trim().toUpperCase() === obraSocial.label.trim().toUpperCase())
                        agregarObraSocial = 1;

                })
                if (agregarObraSocial === 0)
                    ObrasSocialesOrdenadas.push({ key: index, label: obraSocial.label.trim().toUpperCase(), value: obraSocial.value })
            }


            obraSocialNombre = obraSocial.label.trim();


        })

    }
    console.log(ObrasSocialesOrdenadas);
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            onClose={onClose}
            style={modalStyle}
            open={open}
            closeAfterTransition>
            <Paper sx={{ minWidth: "350px", width: "45vw", padding: "20px" }}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"} variant='subtitle1'>Modificar datos</Typography>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </Box>
                <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Grid container spacing={2}>

                        <Grid item md={6} xs={12}>
                            <TextField label="Email"
                                name='Email'
                                error={errorEmailLabel}
                                variant={"outlined"}

                                InputProps={{ style: { fontSize: 20 } }}
                                InputLabelProps={{ style: { fontSize: 23 } }}
                                onChange={inputHandleChange}
                                value={datosModalCuitEmail.Email}
                                required
                                fullWidth
                                type="text"
                            />
                            <Typography className={clases.color_rojo} style={errorEmail} >El Email ingresado ya se encuentra registrado</Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField label="Repetir Email"
                                name='EmailRepetido'
                                variant={"outlined"}

                                InputProps={{ style: { fontSize: 20 } }}
                                InputLabelProps={{ style: { fontSize: 23 } }}
                                onChange={inputHandleChange}
                                value={datosModalCuitEmail.EmailRepetido}
                                required
                                fullWidth
                                type="text"
                            />
                            <Typography className={clases.color_rojo} style={errorEmailRepetido} >Los Emails ingresados no coinciden</Typography>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="labelEstadoCivil">Estado Civil *</InputLabel>
                                <Select
                                    labelId="labelEstadoCivil"
                                    name='EstadoCivil'
                                    onChange={inputHandleChange}
                                    required
                                    value={datosModalCuitEmail.EstadoCivil}
                                    label="Estado Civil"
                                >
                                    {
                                        EstadoCivilList.map((eC, index) =>
                                            <MenuItem key={index} value={eC.estadoCivil}>{eC.descripcion}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                name='TpoOsocial'
                                onChange={ingresaObraSocial}
                                options={ObrasSocialesOrdenadas}
                                disabled={bloquearBtn}
                                noOptionsText={'No se encontraron Obras Sociales'}
                                value={autoCompleteOS}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <TextField {...params} variant={bloquearBtn === true ? "filled" : "outlined"} required label="Obra Social" />}
                            />
                            <Typography className={clases.color_rojo} style={errorObraSocial} >Debe ingresar una obra social</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "2vh" }}>
                    <Button onClick={handleAceptar} variant='contained' color={'secondary'}>Confirmar</Button>
                </Box>
            </Paper>
        </Modal>);
}

export default ModalEditarDatosContacto;