import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCatalogoTitulos } from 'app/features/Titulos/titulosAPI';
const initialState = {
  List: [],
  status: 'idle',
};


export const getTitulosAsync = createAsyncThunk(
  'Titulos/fetchTitulos',
  async data => {
    const response = await getCatalogoTitulos(data);
    // console.log({response});
    return response.data;
  }
);


export const titulosSlice = createSlice({
  name: 'titulos',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTitulosAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTitulosAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.List = action.payload;
      });
  },
});



export const titulosList = (state) => state.titulosReducer.List;
export const titulosStatus = state => state.titulosReducer.status;

export default titulosSlice.reducer;
