import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { postLoginExterno } from 'app/features/Usuarios/usuariosAPI';
import { TIPOS_TRAMITE, TIPOS_USUARIO }  from 'constants/constants';
import { createSession } from 'utils/sessionManager';
import useQuery from 'customHooks/useQuery';
import { crearNuevoTramite, obtenerListaTramites, obtenerTokenBajaFallecimiento } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const LoginInternoBajaFallecimiento = () => {
    localStorage.setItem('token', "");
    localStorage.removeItem('usuario');
    const classes = useStyles();

    let query = useQuery();
    const [mensajePantalla, setMensajePantalla] = useState("cargando...");

    useEffect(() => {
        const usrData = { usuario: encodeURIComponent(query.get("usuario")), password: encodeURIComponent(query.get("pass")), idLegajoTemporal: 0, email: "", tpoTramite : 99 };
        postLoginExterno(usrData)
            .then((value) => {
                localStorage.clear();
                if (value.data.resultado) {
                    const session = {
                        ...value.data,
                        userType: TIPOS_USUARIO.USUARIO_INTERNO,
                        tpoTramite: TIPOS_TRAMITE.BAJA_FALLECIMIENTO,
                        validacionTitulo: true,
                        user: query.get("usuario"),
                        pass: query.get("pass")
                    }
                    createSession(session);
                    obtenerListaTramites().then(async(resp) => {
                        if (!resp.hasOwnProperty('resultado')) {
                            const jsonDatos = JSON.parse(resp[0].jsonDatos);
                            localStorage.setItem('usuario', `${jsonDatos.Apellido} ${jsonDatos.Nombre}`);
                            if (resp.length === 1 && resp[0].idEstado === 1) {
                                const response = await obtenerTokenBajaFallecimiento(resp[0].idLegajoTemporal)
                                if(response !== false){
                                    localStorage.setItem('idLegajoTemporal', resp[0].idLegajoTemporal);
                                    localStorage.setItem('token', response.token);
                                    window.location.href = '/bajaFallecimiento';
                                }
                            } else {
                                window.location.href = `/menuBajaFallecimiento`;
                            }
                        }
                        else{
                            const respo = await crearNuevoTramite({ email: localStorage.email, password: '', tpoTramite: 4, dni:'', tpo_usuario: 12});
                            const data = await obtenerTokenBajaFallecimiento(respo);
                            if(respo !== false){
                                
                                localStorage.setItem('idLegajoTemporal', respo);
                                localStorage.setItem('token', data.token);
                                // localStorage.setItem('userType', TIPOS_USUARIO.USUARIO_INTERNO);
                                window.location.href = '/bajaFallecimiento';
                            }
                        }
                    }); 
                } else {
                    setMensajePantalla("Usuario y/o contraseña incorrectos");
                }
            });
    }, []);

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle1">
                {mensajePantalla}
            </Typography>
        </Paper>
    )
}

export default LoginInternoBajaFallecimiento;
