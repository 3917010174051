import EditIcon from '@mui/icons-material/Edit';
import { Box, Fab } from "@mui/material";

function MiniaturaFotoNueva({src, onClickEditar, disableEdit}) {

    return (
        <Box display={"flex"} position={"relative"} sx={{height: "100px", width: "100px"}}>
            <img style={{height: "100px", width: "100px"}} src={src} alt='Nueva foto del profesional' />
            { !disableEdit &&
                <Fab  size="small"  style={{position: "absolute", bottom: "-10px", right: "-10px"}} color="secondary" aria-label="edit" onClick={onClickEditar}>
                    <EditIcon />
                </Fab>
            }
        </Box>
    );
}

export default MiniaturaFotoNueva;