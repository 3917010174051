import axios from 'axios';
import { FOTO_PROFESIONAL } from 'constants/routes/endpoints';

export const getfoto = async (legajo, token) => {
    try {
        const response =  await axios({
            url: `${FOTO_PROFESIONAL}/${legajo}/foto`,
            method: 'GET',
            responseType: "arraybuffer"
        }).then(response => Buffer.from(response.data, 'binary').toString('base64'));
        return "data:image/png;base64," + response;
    } catch (err) {
        if (err.response.status === 401 || err.response.status === 500) {
            window.location.href = `/login`;
        }
    }
};