import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { useEffect, useState } from 'react';
import { Button, Card, CardContent, FormGroup, FormControlLabel, Checkbox, Typography, Modal, Grid, Fab, FormControl,RadioGroup, Radio } from '@mui/material';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from '@mui/styles';
import { useForm } from "react-hook-form";
import { cambiarAlturaBox } from '../funciones/funcionesGlobales';
import { guardarDatosMatriculas, validarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import CloseIcon from '@mui/icons-material/Close';
import PdfPreCarga from 'components/DDJJ/pdfPreCarga';
import Pdf from 'components/altaTitulosEspeciales/pdfAltaTituloEspecial';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { EMPTY_FILE, PDF_FILE, DEL_FILE } from 'constants/icons';
import { getLeyendasAsync } from 'app/features/Leyendas/leyendasSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ESTADOS_TRAMITE, TIPOS_USUARIO, ADJUNTOS_BASE } from 'constants/constants';
import AprobacionAreaExterna from 'components/AprobacionAreaExterna/AprobacionAreaExterna';
import ModalTerminosyCondiciones from 'components/common/ModalTerminosyCondiciones';


//BUSQUEDA PARA EL PDF

import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { nacionalidadesList, getNacionalidadesAsync } from 'app/features/Nacionalidades/nacionalidadesSlice';
import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';

// import { borderRadius } from '@mui/system';
import FilePreview from 'components/common/FilePreview';
import {descargarFile} from 'utils/archivosManager';
import { calcularEdad } from 'utils/utils';
import { getMatriculasAsync } from 'app/features/Matriculas/matriculasSlice';

const useStyles = makeStyles(theme => ({
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    pIcon: {
        textAlign: 'center'
    },
    pFileName: {
        textAlign: 'center',
        marginTop: '0',
        fontWeight: 'bold',
        size: '12px'
    },
    imgIcon: {
        width: '40px',
        height: '40px',
        margin: '0px 0px 0px 0px',
        cursor: 'pointer'
    },
    imgIconCargado: {
        width: '40px',
        height: '40px',
        margin: '10px 30px  0px',
        cursor: 'pointer'
    },
    imgIconTexto: {
        width: '40px',
        height: '22px'
    },
    delIcon: {
        background: 'red',
        width: '25px',
        height: '28px',
        borderRadius: '50%',
        position: 'relative',
        cursor: 'pointer',
        marginTop: "10px"
    },

    modalWindow: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'rgb(255, 255, 255)',
        border: '2px solid #000',
        padding: '15px',
    }
}));

export const ArchivosAdjuntosTituloEspecial = ({ idLegajoTemporal, datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, aprobacionDocumentacion, setAprobacionDocumentacion,  setMatriculas, resultadoPagoTasa }) => {
    const [fileUpdType, setFileUpdType] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [fechaAprobacionDocumentacion, setFechaAprobacionDocumentacion] = useState(aprobacionDocumentacion?.fechaAprobacionDocumentacion);
    const [nroResolucionAprobacionDocumentacion, setNroResolucionAprobacionDocumentacion] = useState(aprobacionDocumentacion?.nroResolucionAprobacionDocumentacion)

    const dispatch = useDispatch();
    //pasar a la vista del pdf
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const EstadoCivilList = useSelector(estadoCivilList);
    const NacionalidadesList = useSelector(nacionalidadesList);
    const ObrasSociales = useSelector(obrasSocialesList);


    const [leyendas, setLeyendas] = useState([]);
    const [anchoPantalla, setAnchoPantalla] = useState(0);
    const [mostrarAnalitico, setMostrarAnalitico] = useState(0);
    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }

    const adjuntosDefault = [
        ADJUNTOS_BASE.DNI.id,
        ADJUNTOS_BASE.RES_MINISTERIO_EDUCACION.id,
        ADJUNTOS_BASE.PLAN_ESTUDIOS.id,
        ADJUNTOS_BASE.ANALITICO.id,
        ADJUNTOS_BASE.TITULO.id
    ]

    window.addEventListener("resize", handleResize);
    useEffect(() => {
        if (ObrasSociales.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getObrasSocialesAsync());
        }

        if (localStorage.userType !== "10" && localStorage.validacionTitulo !== "1") {
            setMostrarAnalitico(1);
        }

        if (datos.Observado && localStorage.userType === "1")
            setBloqueBtnConfirmarInscripcion(true);

        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);

        //llamo a la funcion del Slice
        dispatch(getLeyendasAsync("Inscripcion_Profesionales")).then((value) => {
            setLeyendas(value.payload.results)
        });

        if (localStorage.userType === "0") {
             Swal.fire({
                 title: 'Atención',
                 html: 'Verifique que los documentos a adjuntar se visualicen correctamente',
                 icon: 'warning',
                 showCloseButton: true,
                 showCancelButton: false,
                 confirmButtonText:
                     'Aceptar',
                 cancelButtonText:
                     'Cancelar',
                 allowOutsideClick: true
             })
        }

        if (EstadoCivilList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getEstadoCivilAsync());
        }

        if (TipoDocumentos.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getTipoDocumentoAsync());
        }

        if (NacionalidadesList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getNacionalidadesAsync());
        }

    }, [])

    let blobDDJJ = {};
    let urlDDJJ = "";

    const [open, setOpen] = useState(false);
    const [openConfirmarTerminos, setOpenConfirmacionTerminos] = useState(false);

    //metodo para subir archivos
    const changeHandler = (evt) => {

        if (evt.target.files.length >= 1) {
            const tipo = evt.target.files[0].type;
            const size = ((evt.target.files[0].size / 1000) / 1000).toFixed(4);

            if (tipo !== 'application/pdf' && tipo !== 'image/jpeg' && tipo !== 'image/png') {
                Swal.fire({
                    title: 'Atención',
                    html: 'El tipo de archivo no es correcto, se admite unicamente: pdf, jpg, jpeg o png.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else if (Number(size) > 5) {
                Swal.fire({
                    title: 'Atención',
                    html: 'El tamaño del archivo  no debe superar 5MB.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else {
                const upload = datos.adjuntos;
                upload.filter(c => c.fileType === fileUpdType)[0].fileName = evt.target.files[0].name;
                upload.filter(c => c.fileType === fileUpdType)[0].extension = tipo;
                upload.filter(c => c.fileType === fileUpdType)[0].file = evt.target.value;
                upload.filter(c => c.fileType === fileUpdType)[0].size = parseFloat(size);
                upload.filter(c => c.fileType === fileUpdType)[0].saved = false;

                const reader = new FileReader();

                reader.onload = () => {

                    if (tipo !== 'application/pdf')
                        upload.filter(c => c.fileType === fileUpdType)[0].url = reader.result;
                    else
                        upload.filter(c => c.fileType === fileUpdType)[0].url = PDF_FILE;

                    upload.filter(c => c.fileType === fileUpdType)[0].file = reader.result;
                    setDatos({
                        ...datos,
                        adjuntos: upload
                    });
                }
                reader.readAsDataURL(evt.target.files[0]);
            }
        }
    }

    const { handleSubmit, formState: { errors } } = useForm();


    const [perteneceOtroConsejo, setPerteneceOtroConsejo] = useState(0);

    const onSubmit = data => {
        let erroresMostrar = "";

        if (datos.titulos.length > 2) {
            Swal.fire({
                title: 'Atención',
                html: 'Solo puede ingresar hasta <b> dos titulos</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        let errorDdjjEnfermedades = 0;
        let cantidadAdjuntosFaltantes = 0;
        let nombreAdjuntosFaltantes = "";

        if (datos.PerteneceOtroConsejo === null && idEstado !== 1 && idEstado !== 3 && idEstado !== 8) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe indicar si se encuentra matriculado en otro Consejo Profesional',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);

            return false;
        }

        datos.adjuntos.map((adjunto, index) => {
            //ERRORES MOSTRAR MODAL ADJUNTOS
            if ((adjunto.file === null || adjunto.file === '') 
                && adjunto.id !== ADJUNTOS_BASE.SOLICITUD_REGISTRO.id
                && adjunto.id !== ADJUNTOS_BASE.DECLARACION_JURADA.id) {

                if (adjunto.id !== ADJUNTOS_BASE.CERT_LIBRE_SANCION.id && adjunto.id !== ADJUNTOS_BASE.DECLARACION_JURADA_ENFERMEDADES.id ) {
                    cantidadAdjuntosFaltantes++;

                    if (nombreAdjuntosFaltantes === "") {
                        nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                    else {
                        nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                } else if (adjunto.id === ADJUNTOS_BASE.CERT_LIBRE_SANCION.id 
                    && idEstado !== 1 && idEstado !== 3 && idEstado !== 8 && datos.PerteneceOtroConsejo === 0) {
                    cantidadAdjuntosFaltantes++;

                    if (nombreAdjuntosFaltantes === "") {
                        nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                    else {
                        nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                }
            }
            if (calcularEdad(datos.Nacimiento) >= 50 && (idEstado !== 1 && idEstado !== 3 && idEstado !== 8) && (adjunto.file === null || adjunto.file === '') && (adjunto.id === ADJUNTOS_BASE.DECLARACION_JURADA_ENFERMEDADES.id))
                errorDdjjEnfermedades = 1;
        })
        
        if (cantidadAdjuntosFaltantes > 0 && cantidadAdjuntosFaltantes <= 2) {
            if (erroresMostrar !== "") {
                erroresMostrar += ", ";
            }
            erroresMostrar += nombreAdjuntosFaltantes;
        } else if (cantidadAdjuntosFaltantes > 0) {
            if (erroresMostrar !== "") {
                erroresMostrar += ", ";
            }
            erroresMostrar += "<b>Adjuntos</b>";
        }

        if (errorDdjjEnfermedades === 1) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe cargar la <b>Declaración jurada de enfermedades y patologías preexistentes</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }


        if (!Array.isArray(datos.titulos) || datos.titulos.length === 0) {
            if (erroresMostrar !== "") {
                erroresMostrar += ",";
            }
            erroresMostrar += "<b>Titulos</b>";
        }

        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }
        
        if (idEstado !== ESTADOS_TRAMITE.DRAFT && idEstado !== ESTADOS_TRAMITE.EN_REVISION && idEstado !== ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION) {
            dispatch(getMatriculasAsync(({ idLegajo: localStorage.idLegajoTemporal, token: localStorage.token })))
            .then(({payload}) => {
                setMatriculas(payload);
                if(payload.result.exentoTasa){  
                    if(resultadoPagoTasa){        
                        setValue("4");
                    }else{                        
                        setOcultarTabs({ display: 'none' });
                        setOcultarContenido(true);
                        cambiarAlturaBox('0px');
                    }
                } else {
                    setValue("4");
                }
            })
        } else {
            setOcultarTabs({ display: 'none' });
            setOcultarContenido(true);
            cambiarAlturaBox('0px');
        }
    };

    const guardarDatosInscripcion = () => {

        const size = ((blobDDJJ.size / 1000) / 1000).toFixed(4);

        const reader = new FileReader();
        const upload = datos.adjuntos;

        let nombreDDJJ = ADJUNTOS_BASE.DECLARACION_JURADA.fileType;
        let idArchivo = ADJUNTOS_BASE.DECLARACION_JURADA.id;

        if (idEstado === 1 || idEstado === 8){
            nombreDDJJ = ADJUNTOS_BASE.SOLICITUD_REGISTRO.fileType;
            idArchivo = ADJUNTOS_BASE.SOLICITUD_REGISTRO.id;
        }


        if (upload.filter(c => c.fileType === nombreDDJJ).length === 0) {
            reader.readAsDataURL(blobDDJJ)
            reader.onload = () => {
                datos.adjuntos.push({ id: idArchivo ,fileType: nombreDDJJ, fileName: nombreDDJJ, extension: 'application/pdf', file: reader.result, size: parseFloat(size), url: PDF_FILE, saved: false });
            }
        } else {

            reader.readAsDataURL(blobDDJJ)
            reader.onload = () => {
                upload.filter(c => c.fileType === nombreDDJJ)[0].fileName = nombreDDJJ;
                upload.filter(c => c.fileType === nombreDDJJ)[0].extension = 'application/pdf';
                upload.filter(c => c.fileType === nombreDDJJ)[0].size = parseFloat(size);
                upload.filter(c => c.fileType === nombreDDJJ)[0].saved = false;
                upload.filter(c => c.fileType === nombreDDJJ)[0].file = reader.result;
            }

        }


        setOpenConfirmacionTerminos(true);
        setBotonCancelarTerminos(false);
    }

    const guardarInscripcion = () => {
        setLoaderBtnGuardar(true)
        if (localStorage.userType != TIPOS_USUARIO.SECRETARIO_TECNICO) {

            Swal.fire({
                title: '',
                html: 'Comienza descarga de archivo...',
                icon: 'info',
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false
            });
        }

        localStorage.userType === "0" ? setBloquearBtn(true) : setBloqueBtnConfirmarInscripcion(true)

        if (localStorage.userType === "0") {

            setDatos({
                ...datos,
                Observado: false
            });
        } else {
            let titulosError = '';
            datos.titulos.map(tit => {
                if (tit.codTitulo === 0 || tit.codUniversidad === 0) {
                    if (titulosError === '')
                        titulosError = tit.nombreTitulo;
                    else
                        titulosError += ',' + tit.nombreTitulo;


                }
            });

            if (titulosError !== '') {
                Swal.fire({
                    title: 'Atención',
                    html: 'Debe seleccionar <b>la Universidad</b> y/o el nombre del <b>Titulo</b>',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                setLoaderBtnGuardar(false)
                localStorage.userType === "0" ? setBloquearBtn(false) : setBloqueBtnConfirmarInscripcion(false)
                return false;
            }

        }
        datos.Observado = false;

        setBotonCancelarTerminos(true);

        switch (idEstado) {
            case 1:
                idEstado = 3;
                break;
            case 3:
                idEstado = 4;
                break;
            case 4:
                idEstado = 11;
                break;
            case 2:
                idEstado = 7;
                break;
            case 8:
                if (localStorage.userType === "1")
                    idEstado = 9;
                else
                    idEstado = 8;

                break;
            case 8 && localStorage.userType === "0":
                idEstado = 8;
                break;
            case 6:
                idEstado = 6;
                break;
            case 9:
                idEstado = 10;
                break;
        }

        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, accion: localStorage.userType == TIPOS_USUARIO.SECRETARIO_TECNICO ? "CONFIRMAR_PRECARGA" : null, tpo_tramite: 10, fechaAprobacionDocumentacion, nroResolucionAprobacionDocumentacion}).then((value) => {
            if (localStorage.userType != TIPOS_USUARIO.SECRETARIO_TECNICO) {
                const estadoArchivoMapping = {
                    [ESTADOS_TRAMITE.DRAFT]: 'DDJJ.pdf',
                    [ESTADOS_TRAMITE.PENDIENTE]: 'DDJJ.pdf',
                    [ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION]: 'DDJJ.pdf',
                    [ESTADOS_TRAMITE.EN_REVISION]: 'DDJJ.pdf',
                    [ESTADOS_TRAMITE.PENDIENTE_REVISION_FINAL_TTEE]: `${ADJUNTOS_BASE.SOLICITUD_REGISTRO.fileType}.pdf` ,
                    [ESTADOS_TRAMITE.FINALIZADA]: `${ADJUNTOS_BASE.SOLICITUD_REGISTRO.fileType}.pdf`,
                };
                descargarFile(blobDDJJ, estadoArchivoMapping[idEstado] || 'Descarga.pdf');
            }

            
            setLoaderBtnGuardar(false)

            if (value.data.resultado) {
                
                switch (idEstado) {

                    case 3:
                        setIdEstado(3);
                        break;
                    case 4:
                        setIdEstado(4);
                        break;
                    case 2:
                        setIdEstado(2);
                        break;
                    case 6:
                        setIdEstado(6);
                    case 7:
                        setIdEstado(7);
                        break;
                    case 8 && localStorage.userType === "1":
                        setIdEstado(9);
                        break;
                    case 8 && localStorage.userType === "0":
                        setIdEstado(8);
                        break;
                    case 9:
                        setIdEstado(10);
                        break;
                }

                setOcultarContenido(false);
                cambiarAlturaBox('45px');
                setOcultarTabs({ display: '' });
                setBloquearBtn(true);
                if (localStorage.userType === "1") {

                    setBloqueBtnConfirmarInscripcion(true);
                    Swal.fire({
                        title: '',
                        html: 'La solicitud fue enviada correctamente al profesional',
                        icon: 'success',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonText:
                            'Aceptar',
                        cancelButtonText:
                            'Cancelar',
                        allowOutsideClick: true
                    });

                } 
            } else {
                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                localStorage.userType === "0" ? setBloquearBtn(false) : setBloqueBtnConfirmarInscripcion(false)
                return false;
            }

        });
    }

    const [bloqueBtnConfirmarInscripcion, setBloqueBtnConfirmarInscripcion] = useState(false);


    //ENVIA LA INSCRIPCION PARA GUARDAR EN LAS TABLAS DE MATRICULAS
    const enviarInscripcion = () => {

        setBloqueBtnConfirmarInscripcion(true);
        setLoaderBtnGuardar(true);
        let errorDomicilio = 0;
        datos.domicilios.map(dm => {
            if (dm.codLocalidad === "-1") {
                errorDomicilio = 1;
            }
        })

        if (errorDomicilio === 1) {
            setLoaderBtnGuardar(false);
            setBloqueBtnConfirmarInscripcion(false);
            Swal.fire({
                title: 'Atención',
                html: 'Existen domicilios que tienen ingresada la localidad <b> Otros</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        validarDatosMatriculas().then(value => {
            setLoaderBtnGuardar(false);

            if (value.data == '' || value.data.result) {
                setIdEstado(7);
                Swal.fire({
                    title: '',
                    html: 'La solicitud de inscripción se inició correctamente.',
                    icon: 'success',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
            } else {
                setBloqueBtnConfirmarInscripcion(false);

                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
        });


    }

    const deleteFile = file => {
        const arrFiles = datos.adjuntos;
        arrFiles.filter(c => c.fileType === file)[0].fileName = '';
        arrFiles.filter(c => c.fileType === file)[0].extension = '';
        arrFiles.filter(c => c.fileType === file)[0].file = null;
        arrFiles.filter(c => c.fileType === file)[0].size = 0;
        arrFiles.filter(c => c.fileType === file)[0].url = EMPTY_FILE;
        arrFiles.filter(c => c.fileType === file)[0].saved = false;
        setDatos({
            ...datos,
            adjuntos: arrFiles
        });

    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = ev => {
        if (bloquearBtn !== true) {
            setFileUpdType(ev.target.name);
            hiddenFileInput.current.click();
        }

    };

    const popitup = (adjunto) => {

        setSelectedFile(adjunto);
        setOpenPreview(true);
    }

    const handleClose = () => setOpenPreview(false);

    const clases = useStyles();
    const handleOpen = () => {

        setOpen(true);
    }

    ///BORRA LOS ADJUNTOS QUE DECIDIO AGREGAR EXTRA
    const eliminarArchivoAdjuntoExtra = (nombreArchivo) => {

        const arrFiles = datos.adjuntos;
        setDatos({
            ...datos,
            adjuntos: arrFiles.filter(c => c.fileType !== nombreArchivo)
        });
    };


    const agregarAdjunto = (fileType, id) => {
        let sumarTitulo = 0;
        let detectaNumeros = /\d+/;

        for (let i = 0; i < datos.adjuntos.length; i++) {
            //SI EL ADJUNTO ES IGUAL EN EL NOMBRE PASA SIN IMPORTAR EL NUMERO
            if (fileType.replace(/[0-9]/g, '').trim() === datos.adjuntos[i].fileType.replace(/[0-9]/g, '').trim()) {

                //ACA INGRESA SI EL NOMBRE ES EXACTAMENTE IGUAL
                if (fileType === datos.adjuntos[i].fileType && sumarTitulo === 0) {
                    sumarTitulo++;
                } else if (detectaNumeros.test(datos.adjuntos[i].fileType) && detectaNumeros.test(fileType)) {
                    sumarTitulo++;
                } else if (detectaNumeros.test(datos.adjuntos[i].fileType) && !detectaNumeros.test(fileType)) {//SI EN EL NOMBRE NO DETECTA NUMEROS
                    sumarTitulo++;
                }
            }
        };

        const ordenarAdjuntos = (adjuntos) => {    
            const adjuntosDNIs =  adjuntos.filter(a => a.id === ADJUNTOS_BASE.DNI.id );
            const adjuntosMinisterio =  adjuntos.filter(a => a.id === ADJUNTOS_BASE.RES_MINISTERIO_EDUCACION.id);
            const adjuntosTitulo = adjuntos.filter(a => a.id === ADJUNTOS_BASE.TITULO.id);
            const adjuntosPlan = adjuntos.filter(a => a.id === ADJUNTOS_BASE.PLAN_ESTUDIOS.id);
            const adjuntosDetalle = adjuntos.filter(a => a.id ===  ADJUNTOS_BASE.DETALLE_INCUMBENCIAS.id);
            const adjuntosAnalitico = adjuntos.filter(a => a.id === ADJUNTOS_BASE.ANALITICO.id);
            const adjuntosLibreSancion = adjuntos.filter(a => a.id === ADJUNTOS_BASE.CERT_LIBRE_SANCION.id);
            const adjuntosDDJJ = adjuntos.filter(a => a.id === ADJUNTOS_BASE.DECLARACION_JURADA.id);
            const adjuntosSolicitud = adjuntos.filter(a => a.id ===  ADJUNTOS_BASE.SOLICITUD_REGISTRO.id);
            const adjuntosDDJJSalud = adjuntos.filter(a => a.id === ADJUNTOS_BASE.DECLARACION_JURADA_ENFERMEDADES.id);


            return [
                ...adjuntosDNIs,
                ...adjuntosMinisterio,
                ...adjuntosTitulo,
                ...adjuntosPlan,
                ...adjuntosDetalle,
                ...adjuntosAnalitico,
                ...adjuntosDDJJSalud,
                ...adjuntosLibreSancion,
                ...adjuntosDDJJ,
                ...adjuntosSolicitud
            ];
        }

        datos.adjuntos.push({id: id,  fileType: fileType + ' ' + sumarTitulo, fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: false })
        
        setDatos({
            ...datos,
            adjuntos: ordenarAdjuntos(datos.adjuntos)
        })
    }


    const [botonCancelarTerminos, setBotonCancelarTerminos] = useState(false);
    const [agregarAdjuntoArchivo, setAgregarAdjuntoArchivo] = useState('');
    const inputHandleChange = (e) => {
        setAgregarAdjuntoArchivo(e.target.value);
    }
    const [ocultarContenido, setOcultarContenido] = useState(false);

    const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
    const cancelarIncripcionPDF = () => {
        setOcultarContenido(false);
        cambiarAlturaBox('45px');
        setOcultarTabs({ display: '' });
    }

    

    function contarNumeros(str) {
        const regex = /\d+/g; // expresión regular que busca todas las secuencias de dígitos
        const coincidencias = str.match(regex); // encuentra todas las coincidencias en la cadena
        return coincidencias ? coincidencias.length : 0; // devuelve la cantidad de coincidencias encontradas o cero si no se encontraron
    }

    const validadEliminarAdjuntoExtra = (adjunto, validacion) => {

        let adjuntoEncontro = 0;
        let posicion = 0;
        let regex = /\d+/;

        for (let i = 0; i < datos.adjuntos.length; i++) {
            if (!regex.test(adjunto))
                break;
            if (adjunto.replace(/[0-9]/g, '').trim() === datos.adjuntos[i].fileType.replace(/[0-9]/g, '').trim()) {

                if (adjunto.search('-') !== -1) {
                    
                    if (adjunto === datos.adjuntos[i].fileType && adjuntoEncontro === 0) {
                       
                        adjuntoEncontro++;
                        break;
                    } else if (regex.test(adjunto)) {
                        adjuntoEncontro++;

                    } else if (adjunto === datos.adjuntos[i].fileType) {
                        adjuntoEncontro++;
                        break;
                    }
                } else {
                    adjuntoEncontro++;
                }
            }

        }
        //AGREGO VALIDACION
        return validacion === 0 ? adjuntoEncontro <= 1 ? false : true : adjuntoEncontro <= 1 ? true : false;
    }

    const continuarGuardadoAdjuntos = () => {

        setLoaderBtnGuardar(true)
        setBloquearBtn(true);
        setBotonCancelarTerminos(true);
        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 10 }).then((value) => {
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);
            setOpenConfirmacionTerminos(false);
            if (value.data.resultado) {
                const response = value.data.result;
                let mensajeAlerta = 'Sr/a profesional, si presiona aceptar su solicitud será enviada al Consejo Profesional';
                if(response.idEstado === ESTADOS_TRAMITE.DRAFT || response.idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION){
                    mensajeAlerta = 'El Consejo Profesional le comunicará lo resuelto por correo electrónico. En caso afirmativo quedará habilitado para dar inicio a la etapa 2.';
                }

                    localStorage.setItem('idLegajoTemporal', value.data.result.idLegajoTemporal);
                Swal.fire({
                    title: 'Atención',
                    html: mensajeAlerta,
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                }).then((result) => {

                    if (result.value) {
                        guardarInscripcion()

                    }
                });

            } else {
                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }

        });
    }
    const validarModificacionAdjunto = tipoAdjunto => {
        if (tipoAdjunto !== "Certificado de libre sanción disciplinaria de otros consejos profesionales" && (idEstado === 1 || idEstado === 3 || idEstado === 8)) {

            return true;
        }else if (tipoAdjunto === "Certificado de libre sanción disciplinaria de otros consejos profesionales" && (idEstado !== 1 && idEstado !== 3 && idEstado !== 8)) {
            return true;
        }else if (tipoAdjunto === "Declaración jurada de enfermedades y patologías preexistentes" && (idEstado === 4 || idEstado === 9)) {
            return true;
        } 
        return false;
    }

    function downloadPDF(pdf, nombrePdf) {
        const linkSource = `${pdf}`;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = nombrePdf;
        downloadLink.click();
    }

    const esPrecarga = (idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION || idEstado === ESTADOS_TRAMITE.EN_REVISION);
    const esValidacionFinal = !esPrecarga;

    return (
        <Grid>
        {ocultarContenido === false ?
            <form onSubmit={handleSubmit(onSubmit)}>

                <input type="file" name="uploadFile" onChange={changeHandler} style={{ display: 'none' }} ref={hiddenFileInput} />
                <Typography style={{ fontSize: 14 }} >* <b>Para adjuntar los documentos deberá hacer clic en el ícono <img src={EMPTY_FILE} className={clases.imgIconTexto} />
                    . Si desea adjuntar un documento complementario –vgr. reverso de DNI, reverso de
                    título, etc.- deberá hacer clic en el botón “+”.</b> </Typography>


                <Grid container spacing={2} sx={{ mt: 0.01 }}  >

                    {datos.adjuntos && datos.adjuntos.map((adj, index) => {
                        let mostrarAdjuntoDDJJ = 1;

                        if (adj.id == ADJUNTOS_BASE.SOLICITUD_REGISTRO.id && idEstado === 1) 
                            mostrarAdjuntoDDJJ = 0;
                        
                        if (adj.id == ADJUNTOS_BASE.DECLARACION_JURADA.id && idEstado === 4)
                            mostrarAdjuntoDDJJ = 0;

                        if (adj.id == ADJUNTOS_BASE.CERT_LIBRE_SANCION.id && (idEstado === 1 || idEstado === 3 || idEstado === 8))
                            mostrarAdjuntoDDJJ = 0;

                        if (adj.id == ADJUNTOS_BASE.DETALLE_INCUMBENCIAS.id)
                            mostrarAdjuntoDDJJ = 0;

                        if ((localStorage.userType !== '1') && (!(idEstado === 1 || idEstado === 3 || idEstado === 8)) && (adjuntosDefault.indexOf(adj.id) >= 0 ))
                            mostrarAdjuntoDDJJ = 0;

                        if ((calcularEdad(datos.Nacimiento) < 50 || (idEstado === 1 || idEstado === 3 || idEstado === 8) ) && adj.id === ADJUNTOS_BASE.DECLARACION_JURADA_ENFERMEDADES.id )
                            mostrarAdjuntoDDJJ = 0;

                        //(adjunto.fileType.replace(/\d/g, "") === "Resolución del Resolución Ministerio de Educación de la Nación" || adjunto.fileType.replace(/\d/g, "") ==='DNI' || adjunto.fileType.search('-') !== -1)
                        //MUESTRA LA IMAGEN CARGADA   
                        if ((idEstado !== 2 && localStorage.userType !== '1') ||
                            ((idEstado === 2 || idEstado === 6 && datos.Observado === false) && adj.file !== null && adj.file !== '' && localStorage.userType === "0") ||
                            (localStorage.userType === "1" && adj.file !== null && adj.file !== ''))
                            if ((adj.file !== null && adj.file !== '' && localStorage.userType === "1") || localStorage.userType === "0")
                                if (mostrarAdjuntoDDJJ === 1)
                                    return (
                                        <Grid item xs={6} md={4} key={index} >
                                            <Grid align='center' direction="column" container key={index}>
                                                <Grid item key={index}>
                                                <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', fontSize: adj.fileType.length < 30 ? 17 : 15, margin: 0 }} title={adj.fileType }>
                                                            <b> {adj.fileType}  {adj.id === ADJUNTOS_BASE.RES_MINISTERIO_EDUCACION.id && <br />}
                                                            </b>
                                                        </p>

                                                    {
                                                        (adj.id === ADJUNTOS_BASE.DNI.id  || adj.id === ADJUNTOS_BASE.TITULO.id) &&

                                                        <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', fontSize: 13, margin: 0 }}
                                                            title={adj.fileType}>
                                                            <b>Anverso y reverso</b>
                                                        </p>
                                                    }

                                                    {adj.id === ADJUNTOS_BASE.CERT_LIBRE_SANCION.id &&
                                                        <Grid item>
                                                            {adj.fileType.replace(/[^0-9]/g, "").length === 0 &&
                                                                <Grid item md={12}>
                                                                    <Typography style={{ fontSize: 12 }}><b>¿Se encuentra matriculado en otro Consejo Profesional?</b> </Typography>
                                                                </Grid>
                                                            }
                                                            <Grid item md={12} xs={12}>

                                                                <Grid container>
                                                                    {adj.fileType.replace(/[^0-9]/g, "").length === 0 &&
                                                                        <Grid item md={5}>
                                                                            <FormControl>
                                                                                <RadioGroup
                                                                                    row
                                                                                    name="position"
                                                                                    value={datos.PerteneceOtroConsejo}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        value={0}

                                                                                        onChange={e => setDatos({
                                                                                            ...datos,
                                                                                            PerteneceOtroConsejo: Number(e.target.value)
                                                                                        })}
                                                                                        disabled={bloquearBtn}
                                                                                        control={<Radio size="small" />}
                                                                                        label="Si"
                                                                                        labelPlacement="start"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        value={1}
                                                                                        disabled={bloquearBtn}
                                                                                        onChange={e => setDatos({
                                                                                            ...datos,
                                                                                            PerteneceOtroConsejo: Number(e.target.value)
                                                                                        })}
                                                                                        control={<Radio size="small" />}
                                                                                        label="No"

                                                                                        labelPlacement="start"
                                                                                    />

                                                                                </RadioGroup>
                                                                            </FormControl>

                                                                        </Grid>
                                                                    }
                                                                    <Grid item md={adj.fileType.replace(/[^0-9]/g, "").length === 0 ? 5 : 12}>
                                                                        {/* IMAGEN ELIMINAR ADJUNTO CARGADO */ }
                                                                        {adj.file === null || adj.file === '' ?
                                                                            <img src={adj.url} className={clases.imgIcon} alt='Subir archivo' name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                                                            :

                                                                            <img src={adj.url} className={clases.imgIconCargado} alt='empty file' title='Ver archivo' onClick={() => popitup(adj)} />
                                                                        }
                                                                        {(localStorage.userType !== "1" && bloquearBtn !== true && adj.file !== null && adj.file !== '') &&

                                                                              <img src={DEL_FILE} className={clases.delIcon} alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                                                        }

                                                                        <p className={clases.pFileName} style={{ marginBottom: 0, textAlign: "left" }} >{adj.fileName.length >= 15 ? adj.fileName.substring(0, 11) + '...' : adj.fileName}</p>

                                                                        <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', marginTop: 0 }} title={adj.fileType}>

                                                                            {((localStorage.userType !== "1" && bloquearBtn !== true) && adj.fileType.replace(/[^0-9]/g, "").length > 0) && <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>}

                                                                            {

                                                                                ((localStorage.userType !== "1" && bloquearBtn !== true) &&
                                                                                    adj.fileType.replace(/[^0-9]/g, "").length === 0
                                                                                ) &&

                                                                                <Fab title={"Agregar " + adj.fileType} color="secondary" size="small" variant="contained" aria-label="edit" onClick={() => agregarAdjunto(adj.fileType, adj.id)}><AddIcon />  </Fab>
                                                                            }

                                                                            {adj.extension === "application/pdf" &&
                                                                                <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                                            }
                                                                        </p>
                                                                    </Grid>
                                                                </Grid>



                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {adj.id  !== ADJUNTOS_BASE.CERT_LIBRE_SANCION.id &&
                                                        <Grid item >

                                                            {adj.file !== null && adj.file !== '' ?
                                                                <img src={adj.url} className={clases.imgIcon} alt='empty file' title='Ver archivo' onClick={() => popitup(adj)} />
                                                                :
                                                                <img src={adj.url} className={clases.imgIcon} alt='Subir archivo' name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                                            }

                                                            {(localStorage.userType !== "1" && bloquearBtn !== true && adj.file !== null && adj.file !== '' && (adj.id !== ADJUNTOS_BASE.DECLARACION_JURADA.id || adj.fileType !== ADJUNTOS_BASE.SOLICITUD_REGISTRO.id) ) &&
                                                                validarModificacionAdjunto(adj.fileType)  &&
                                                                <img src={DEL_FILE} className={clases.delIcon}
                                                                    alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                                            }


                                                            <p className={clases.pFileName} style={{ marginBottom: 0 }} >{adj.fileName.length >= 15 ?
                                                                adj.fileName.substring(0, 11) + '...' : adj.fileName}</p>
                                                            <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', marginTop: 0, marginBottom: 0 }} title={adj.fileType}>

                                                                    {((localStorage.userType !== "1" && bloquearBtn !== true) && validadEliminarAdjuntoExtra(adj.fileType, 0) && validarModificacionAdjunto(adj.fileType))
                                                                        && <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>
                                                                        }
                                                                {
                                                                    ((localStorage.userType !== "1" && bloquearBtn !== true && adj.id !== ADJUNTOS_BASE.DECLARACION_JURADA.id  && adj.id !== ADJUNTOS_BASE.SOLICITUD_REGISTRO.id ) &&
                                                                        validadEliminarAdjuntoExtra(adj.fileType, 1) && validarModificacionAdjunto(adj.fileType)
                                                                    ) &&
                                                                    <Fab title={"Agregar " + adj.fileType} color="secondary" size="small" variant="contained" aria-label="edit" onClick={() => agregarAdjunto(adj.fileType, adj.id)}><AddIcon />  </Fab>
                                                                }

                                                                {(adj.extension === "application/pdf" || adj.extension === "image/jpeg" || adj.extension === "image/png" || adj.extension === "image/tiff") &&
                                                                    <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                                }
                                                                </p>
                                                            </Grid>
                                                        }
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    )

                    })}
                </Grid>

                <Grid container>
                    <Grid item xs={12}> </Grid>
                    <Grid item md={6} xs={6} sx={{ mt: 3, alignItems: "flex-end" }} container>

                        <Button sx={{ height: "40px" }} startIcon={<ArrowBackIosIcon />} onClick={() => setValue("2")} variant="contained" color="error" >
                            Atras
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <Grid sx={{ mt: 3 }} container justifyContent="flex-end">

                            {(localStorage.userType !== "1") &&
                                <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary">
                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                </Button>
                            }
                            {
                                localStorage.userType === "1" &&
                                <Grid display={"flex"} direction={"column"}>
                                        {/* <Button style={{ marginRight: 7 }} startIcon={<ConstructionIcon />} disabled={idEstado === 1 ? true : false} variant="contained" onClick={revisarInscripcion} color="info">
                                            Revisar Inscripción
                                        </Button> */}

                                        {/* || (idEstado === 6 && datos.Observado === false) */}

                                        {(!datos.Observado && !resultadoPagoTasa) && <AprobacionAreaExterna
                                            setAprobacionDocumentacion={setAprobacionDocumentacion}
                                            esPrecarga={esPrecarga}
                                            fechaAprobacionDocumentacion={fechaAprobacionDocumentacion}
                                            setFechaAprobacionDocumentacion={setFechaAprobacionDocumentacion}
                                            nroResolucionAprobacionDocumentacion={nroResolucionAprobacionDocumentacion}
                                            setNroResolucionAprobacionDocumentacion={setNroResolucionAprobacionDocumentacion}
                                            idLegajoTemporal={idLegajoTemporal}
                                            tipoUsuario={localStorage.userType}
                                            aprobacionDocumentacion={aprobacionDocumentacion} />
                                        }

                                        {
                                            esPrecarga && aprobacionDocumentacion.documentacionEnviadaParaRevision &&
                                            <Button
                                                startIcon={<AddIcon />}
                                                variant="contained"
                                                disabled={fechaAprobacionDocumentacion !== null && fechaAprobacionDocumentacion !== '' &&
                                                nroResolucionAprobacionDocumentacion !== null && nroResolucionAprobacionDocumentacion !== '' && (
                                                    idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION ||
                                                    idEstado === ESTADOS_TRAMITE.EN_REVISION ||
                                                    idEstado === ESTADOS_TRAMITE.PRE_CARGA_FINALIZADA ||
                                                    idEstado === ESTADOS_TRAMITE.PENDIENTE)
                                                    && bloqueBtnConfirmarInscripcion === false ? false : true}
                                                onClick={guardarInscripcion}
                                                color="secondary">
                                                {/* {loaderBtnGuardar === true ? <CircularProgress /> : ''} {'Continuar Inscripcion'} */}
                                                {loaderBtnGuardar === true ? <CircularProgress /> : ''} {'Continuar Inscripcion'}
                                            </Button>
                                        }
                                        {
                                            esValidacionFinal &&
                                            <Grid>
                                                {
                                                (!resultadoPagoTasa && datos.exentoTasa ) ?
                                                <Button
                                                    startIcon={<AddIcon />}
                                                    variant="contained"
                                                    disabled={(
                                                        (idEstado === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && datos.Observado === false) ||
                                                        idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION ||
                                                        idEstado === ESTADOS_TRAMITE.EN_REVISION ||
                                                        // idEstado === ESTADOS_TRAMITE.VALIDA ||
                                                        idEstado === ESTADOS_TRAMITE.PRE_CARGA_FINALIZADA ||
                                                        idEstado === ESTADOS_TRAMITE.PENDIENTE ||
                                                        idEstado === ESTADOS_TRAMITE.PENDIENTE_REVISION_FINAL_TTEE)
                                                        && bloqueBtnConfirmarInscripcion === false ? false : true}
                                                    onClick={enviarInscripcion}
                                                    color="secondary">
                                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''} {'Confirmar inscripción'}
                                                </Button>
                                                 :
                                                 <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary">
                                                     {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                                 </Button>
                                                } 
                                            </Grid>

                                        }

                                    </Grid>
                                // <Grid>
                                //     <Button startIcon={<AddIcon />} variant="contained" disabled={(idEstado === 8 || idEstado === 3 || idEstado === 10 || idEstado === 2) && bloqueBtnConfirmarInscripcion === false ? false : true} onClick={() => (idEstado === 8 || idEstado === 3) ? guardarInscripcion() : enviarInscripcion()} color="secondary">
                                //         {loaderBtnGuardar === true ? <CircularProgress /> : ''} {(idEstado === 8 || idEstado === 3) ? 'Continuar Inscripcion' : 'Confirmar inscripción'}
                                //     </Button>
                                // </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </form >
            : <Grid>

                <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >* Verifique que los datos ingresados sean los correctos y luego haga click en el botón aceptar para enviar su solicitud de inscripción.</Typography>
                <PDFViewer style={{
                    width: '100%',
                    height: '65vh'
                }} showToolbar={false}>
                    {idEstado === 1 || idEstado === 8 ?
                        <PdfPreCarga datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} muestraDireccion={false} muestraDatosPersonales={false} esAlta={true}/>
                        :
                        <Pdf datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} />
                    }
                </PDFViewer>

                {idEstado === 1 || idEstado === 8 ?
                    <PDFDownloadLink document={<PdfPreCarga datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas}  muestraDireccion={false} muestraDatosPersonales={false} esAlta={true}/> } fileName= {ADJUNTOS_BASE.SOLICITUD_REGISTRO.fileType +".pdf"}>
                        {({ blob, url, loading, error }) => {

                            blobDDJJ = blob;
                            urlDDJJ = url;

                        }

                        }
                    </PDFDownloadLink>
                    :
                    <PDFDownloadLink document={<Pdf datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} />} fileName="DDJJ.pdf">
                        {({ blob, url, loading, error }) => {

                            blobDDJJ = blob;
                            urlDDJJ = url;

                        }

                        }
                    </PDFDownloadLink>
                }
                <Grid container>

                    <Grid item md={6} sx={{ mt: 3 }} container>
                        <Button startIcon={<HighlightOffIcon />} onClick={() => cancelarIncripcionPDF()} variant="contained" color="error" >
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item md={6}>
                        <Grid sx={{ mt: 3 }} container justifyContent="flex-end">

                            <Button startIcon={<CheckIcon />} disabled={bloquearBtn} variant="contained" onClick={() => guardarDatosInscripcion()} color="secondary">
                                {loaderBtnGuardar === true ? <CircularProgress /> : ''}  Aceptar
                            </Button>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
        <FilePreview file={selectedFile} onClose={handleClose} opened={openPreview} />


        <ModalTerminosyCondiciones
                onClose={() => setOpenConfirmacionTerminos(false)}
                open={openConfirmarTerminos}
                onSuccess={continuarGuardadoAdjuntos}
            />
    </Grid >
    );
}
