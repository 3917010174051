import Swal from 'sweetalert2';


export function descargarFile(blob, name) {
    try {
        const urlNueva = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = urlNueva;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();

        Swal.close();

        Swal.fire({
            title: '',
            html: 'Archivo Descargado.',
            icon: 'success',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false
        });
    } catch (error) {
        console.error("Ocurrió un error al descargar el archivo:", error);

        Swal.close();

        Swal.fire({
            title: '',
            html: 'Ocurrió un error al descargar el archivo.',
            icon: 'error',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false
        });
    }
}