import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { postLoginActualizacionDatos } from 'app/features/Usuarios/usuariosAPI';
import { TIPOS_TRAMITE, TIPOS_USUARIO }  from 'constants/constants';
import { createSession } from 'utils/sessionManager';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const LoginEditarDatos = () => {
    localStorage.setItem('token', "");
    const params = useParams();
    const { pathname } = useLocation();
    const classes = useStyles();

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();


    useEffect(() => {

        //  console.log(params.id)


        const usrData = { usuario: encodeURIComponent(query.get("usuario")), password: encodeURIComponent(query.get("pass")), id: query.get("id"), tpoTramite: 8 };
        postLoginActualizacionDatos(usrData)
            .then((value) => {
                localStorage.clear();
                
                if (value.data.resultado) {

                    const session = {
                        ...value.data,
                        userType: TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS,
                        tpoTramite: TIPOS_TRAMITE.MODIFICACION,
                        user: query.get("usuario"),
                        pass: query.get("pass")
                    }
                    createSession(session);



                    window.location.href = `/editardatospersonales`;
                }
            });
    }, []);

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle1">
                cargando...
            </Typography>
        </Paper>
    )
}

export default LoginEditarDatos;
