import React, { useState, Fragment } from 'react';
import Swal from 'sweetalert2'
import { Grid, Card, CardContent, CardActions, Button, Link, TextField, Typography } from '@mui/material';
import { useForm } from "react-hook-form";
import {
    generarTokenUsuario
} from 'app/features/Usuarios/usuariosAPI';
import { useParams } from 'react-router-dom';
// Styles
import 'scss/components/header.scss';


const GenerarToken = () => {
    const { handleSubmit, formState: { errors } } = useForm();
    const [email, setEmail] = useState();
    const [errorEmail, setErrorEmail] = useState();
    const [textoErrorEmail, setTextoErrorEmail] = useState();
    localStorage.setItem('token', "");
    const InputChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const params = useParams();

    const onSubmit = () => {

        const result = generarTokenUsuario({ email: email, tpoTramite: params.tpoTramite });
        result.then((value) => {

            if (!value.data.resultado) {

                if (value.data.codigoError === 0) {

                    setTextoErrorEmail("Intentelo nuevamente mas tarde.");
                }
                if (value.data.codigoError === 1) {

                    setTextoErrorEmail("El email ya se encuentra registrado");
                }
                if (value.data.codigoError === 2) {
                    setTextoErrorEmail("El email aún no se encuentra verificado");
                }
                if (value.data.codigoError === 3) {

                    setTextoErrorEmail("El email no se encuentra registrado");
                }
                if (value.data.codigoError === 4) {

                    setTextoErrorEmail("La autorización es invalida. Por favor solicite uno nueva.");
                }
                setErrorEmail("error");
            } else {
                Swal.fire({
                    title: 'Correcto',
                    html: 'Por favor revise su correo:<b>' + email + '</b> <br> para confirmar su email.',
                    icon: 'success',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        params.tpoTramite === '1' ? window.location.href = `/Login` : window.location.href = `/loginBajaFallecimiento` 
                    }
                });

                //   this.state.mensaje_resultado = "El corre <strong>"+ match.params.email +"  </strong>  fue verificado correctamente";
            }
        });
    }

    return (

        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid

                    md={6}

                    container direction="column"
                    className="centrar_contenido"
                >

                    <Card>
                        <CardContent >
                            <Typography align='center' sx={{ mb: 1 }} variant="h6">Generar autorización</Typography>

                            <Typography variant="display1">A continuación ingrese el email con el que se registro:</Typography>
                            <Grid sx={{ mb: 3, mt: 2 }} item md={12} xs={12} >
                                <TextField label="Email"
                                    value={email}
                                    error={errorEmail}
                                    helperText={textoErrorEmail}
                                    onChange={(value) => InputChangeEmail(value)}
                                    required
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                />
                            </Grid>

                            <CardActions
                                sx={{
                                    justifyContent: "center",
                                }}>

                                <Button variant="contained" type="submit" color="primary" >
                                    Generar
                                </Button>


                            </CardActions>
                            <Grid item sx={{ mt: 1 }} md={12} xs={12} className="centrar_contenido"  >
                                <Grid className="centrar_contenido">
                                    Para iniciar sesión hacer

                                    {params.tpoTramite === '1' ?
                                        <Link sx={{ ml: 1 }} href="/Login">
                                            Click Aqui
                                        </Link>
                                        :
                                        <Link sx={{ ml: 1 }} href="/logininscripciontitulosespeciales">
                                            Click Aqui
                                        </Link>
                                    }

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
            </form>
        </Fragment>

    );

};

export default GenerarToken;
