import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import { TIPOS_TRAMITE, TIPOS_USUARIO } from 'constants/constants';
import { obtenerTokenBajaFallecimiento, obtnerIdPerfilUsuario } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';

const useStyles = makeStyles((theme) => ({
    userText: {
        fontSize: '15px',
    }
}));

const Header = () => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const tipoUsuario = Number(localStorage.userType);
    const tipoTramite = Number(localStorage.tpoTramite);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const {pathname} = useLocation();

    const cerrarSesion = () => {
        
        if (pathname === '/inscripciontitulosespeciales' )
            window.location.href = `/logininscripciontitulosespeciales`;
        else if (pathname === '/bajaFallecimiento' || pathname === '/menuBajaFallecimiento' || pathname === '/perfilUsuario'){
            window.location.href = `/loginBajaFallecimiento`;
        }
        else {
            window.location.href = `/login`;
        }
    }

    const datosPersonales = async() => {
        const resp = await obtnerIdPerfilUsuario(localStorage.email)
        const data = await obtenerTokenBajaFallecimiento(resp);
        if(resp !== false){
            localStorage.setItem('idLegajoTemporal', resp);
            localStorage.setItem('token', data.token);
            window.location.href = `/perfilUsuario`;
        }

    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton className={classes.userText}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
                    <p className={classes.userText}>{localStorage.usuario}</p>
            </IconButton>
            <Menu
                sx={{ mt: '35px' }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={cerrarSesion}>Cerrar Sesion</MenuItem>
                {
                    // ((tipoUsuario  === '4' || tipoUsuario === '99') || (tipoUsuario === 11) && (pathname === '/bajaFallecimiento' || pathname === '/menuBajaFallecimiento') ) &&  
                    // ( (tipoTramite === TIPOS_TRAMITE.BAJA_FALLECIMIENTO) && (pathname === '/bajaFallecimiento' || pathname === '/menuBajaFallecimiento')) &&  
                    ((pathname === '/bajaFallecimiento' || pathname === '/menuBajaFallecimiento') && (tipoUsuario === TIPOS_USUARIO.USUARIO_EXTERNO )) &&
                    <MenuItem onClick={datosPersonales}>Datos Personales</MenuItem>
                }
            </Menu>
        </div>
    );
};

export default Header;