import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Card, CardContent, FormGroup, FormControlLabel, Checkbox, Typography, Box, Modal, Grid, Fab, FormControl, InputLabel, Select, MenuItem, RadioGroup, Radio } from '@mui/material';
import { PDFViewer, PDFDownloadLink, Document } from '@react-pdf/renderer';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { cambiarAlturaBox } from '../funciones/funcionesGlobales';
import { makeStyles } from '@mui/styles';
import { useForm } from "react-hook-form";
import { guardarDatosMatriculas, validarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { getProfesionalValidarCuit, getProfesionalValidarMailActualizacion } from 'app/features/Profesionales/profesionalesAPI';
import Pdf from 'components/editarDatosPersonales/pdfEditarDatos';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { EMPTY_FILE, PDF_FILE, DEL_FILE } from 'constants/icons';
import { leyendasList, getLeyendasAsync } from 'app/features/Leyendas/leyendasSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ModalTerminosyCondiciones from 'components/common/ModalTerminosyCondiciones';


//BUSQUEDA PARA EL PDF

import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { nacionalidadesList, getNacionalidadesAsync } from 'app/features/Nacionalidades/nacionalidadesSlice';
import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';
import { TIPOS_USUARIO } from 'constants/constants';


// import { borderRadius } from '@mui/system';
import FilePreview from 'components/common/FilePreview';
import { nuevoAdjunto } from 'utils/adjuntosLogic';
import { descargarFile } from 'utils/archivosManager';
import { format, parseISO } from 'date-fns'
import { calcularEdad } from 'utils/utils';

const useStyles = makeStyles(theme => ({

    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    pIcon: {
        textAlign: 'center'
    },
    pFileName: {
        textAlign: 'center',
        marginTop: '0',
        fontWeight: 'bold',
        size: '12px'
    },
    imgIcon: {
        width: '40px',
        height: '40px',
        margin: '0px 80px  0px',
        cursor: 'pointer'
    },
    imgIconCargado: {
        width: '40px',
        height: '40px',
        margin: '10px 30px  0px',
        cursor: 'pointer'
    },
    imgIconTexto: {
        width: '40px',
        height: '22px'
    },
    delIcon: {
        background: 'red',
        width: '25px',
        borderRadius: '50%',
        position: 'relative',
        right: '-70px',
        cursor: 'pointer'
    },
    modalWindow: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'rgb(255, 255, 255)',
        border: '2px solid #000',
        padding: '15px',
    }
}));

export const ArchivosAdjuntos = ({ datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, setLegajoTemporal }) => {
    const [fileUpdType, setFileUpdType] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const editarFotoSlice = useSelector(state => state.editarDatosProfesionalReducer);
    const matriculasListSlice = useSelector(state => state.matriculasReducer.List);

    const dispatch = useDispatch();
    //pasar a la vista del pdf
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const EstadoCivilList = useSelector(estadoCivilList);
    const NacionalidadesList = useSelector(nacionalidadesList);
    const ObrasSociales = useSelector(obrasSocialesList);


    const [leyendas, setLeyendas] = useState([]);
    const [anchoPantalla, setAnchoPantalla] = useState(0);

    function generarNombreDescarga() {
        return `DDJJ-${datos.Nombre} ${datos.Apellido}-${datos.LegProf}-${format(parseISO(matriculasListSlice.result.fehaInscripcion), 'dd-MM-yyyy')}`;
    }

    function generarAdjuntoFotoPerfil() {
        if (editarFotoSlice.deseaCambiarFoto) {
            let adjuntoDeFoto = datos.adjuntos.find(a => a.fileType === "FotoPerfil");
            if (adjuntoDeFoto) {
                adjuntoDeFoto.file = editarFotoSlice.fotoNueva;
            } else {
                adjuntoDeFoto = nuevoAdjunto("FotoPerfil", "image/jpeg", editarFotoSlice.fotoNueva);
                datos.adjuntos.push(adjuntoDeFoto);
            }
            let adjuntoDeFotoFull = datos.adjuntos.find(a => a.fileType === "FotoPerfilFull");
            if (adjuntoDeFotoFull) {
                adjuntoDeFotoFull.file = editarFotoSlice.fotoNuevaFullSize;
            } else {
                adjuntoDeFotoFull = nuevoAdjunto("FotoPerfilFull", "image/jpeg", editarFotoSlice.fotoNuevaFullSize);
                datos.adjuntos.push(adjuntoDeFotoFull);
            }
        }
        datos.cambiaFoto = editarFotoSlice.deseaCambiarFoto;
        datos.cambiaDNI = editarFotoSlice.cambiaDNI;
        setDatos({
            ...datos
        });
    }

    useEffect(() => {
        generarAdjuntoFotoPerfil();
    }, []);

    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }

    window.addEventListener("resize", handleResize);
    useEffect(() => {
        if (ObrasSociales.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getObrasSocialesAsync());
        }

        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);

        //llamo a la funcion del Slice
        dispatch(getLeyendasAsync("Inscripcion_Profesionales")).then((value) => {
            setLeyendas(value.payload.results)
        });


        if (EstadoCivilList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getEstadoCivilAsync());
        }

        if (TipoDocumentos.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getTipoDocumentoAsync());
        }

        if (localStorage.userType == TIPOS_USUARIO.PROFESIONAL_EDITAR_DATOS) {
            Swal.fire({
                title: 'Atención',
                html: 'Verifique que los documentos a adjuntar se visualicen correctamente',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
        }


        if (NacionalidadesList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getNacionalidadesAsync());
        }


    }, [])

    let blobDDJJ = {};
    let urlDDJJ = "";

    const [open, setOpen] = useState(false);
    const [openConfirmarTerminos, setOpenConfirmacionTerminos] = useState(false);




    //metodo para subir archivos
    const changeHandler = (evt) => {

        if (evt.target.files.length >= 1) {
            const tipo = evt.target.files[0].type;
            const size = ((evt.target.files[0].size / 1000) / 1000).toFixed(4);

            if (tipo !== 'application/pdf' && tipo !== 'image/jpeg' && tipo !== 'image/png') {
                Swal.fire({
                    title: 'Atención',
                    html: 'El tipo de archivo no es correcto, se admite unicamente: pdf, jpg, jpeg o png.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else if ((Number(size) > 5)) {
                var textoError = 'Sr/a profesional el tamaño de la imagen no debe superar(';
                Swal.fire({
                    title: 'Atención',
                    html: 'El tamaño del archivo  no debe superar 5MB.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else {
                const upload = datos.adjuntos;
                upload.filter(c => c.fileType === fileUpdType)[0].fileName = evt.target.files[0].name;
                upload.filter(c => c.fileType === fileUpdType)[0].extension = tipo;
                upload.filter(c => c.fileType === fileUpdType)[0].file = evt.target.value;
                upload.filter(c => c.fileType === fileUpdType)[0].size = parseFloat(size);
                upload.filter(c => c.fileType === fileUpdType)[0].saved = false;

                const reader = new FileReader();

                reader.onload = () => {

                    if (tipo !== 'application/pdf')
                        upload.filter(c => c.fileType === fileUpdType)[0].url = reader.result;
                    else
                        upload.filter(c => c.fileType === fileUpdType)[0].url = PDF_FILE;

                    upload.filter(c => c.fileType === fileUpdType)[0].file = reader.result;
                    setDatos({
                        ...datos,
                        adjuntos: upload
                    });
                }
                reader.readAsDataURL(evt.target.files[0]);
            }
        }
    }

    const { handleSubmit, formState: { errors } } = useForm();

    const [perteneceOtroConsejo, setPerteneceOtroConsejo] = useState(0);



    const onSubmit = data => {

        let erroresMostrar = "";

        if (datos.CuitCuil === ""
            || datos.Email === "") {
            erroresMostrar = "<b>Datos Personales</b>";
        }


        let mostrarErrorDomicilio = 0;
        datos.domicilios.map((domicilio, index) => {

            console.log(isNaN(domicilio.caracteristica) || isNaN(domicilio.celular))
            if (domicilio.calle === "" && mostrarErrorDomicilio === 0 && (isNaN(domicilio.caracteristica) || isNaN(domicilio.celular))) {
                if (erroresMostrar !== "") {
                    erroresMostrar += ", ";
                }
                erroresMostrar += "<b>Domicilios y Teléfono</b>";
                mostrarErrorDomicilio = 1;
            }

        })

        let cantidadAdjuntosFaltantes = 0;
        let nombreAdjuntosFaltantes = "";
        datos.adjuntos.map((adjunto, index) => {
            console.log(adjunto.file)
            if (adjunto.file === null || adjunto.file === '') {

                cantidadAdjuntosFaltantes++;

                if (nombreAdjuntosFaltantes === "") {
                    nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }
                else {
                    nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }

            }

        })

        if (erroresMostrar !== "") {
            erroresMostrar += ", ";
        }


        if (cantidadAdjuntosFaltantes <= 2) {
            erroresMostrar += nombreAdjuntosFaltantes;
        } else {
            erroresMostrar += "<b>Adjuntos</b>";
        }


        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }

        let documento = datos.NroDocumento;

        documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "");

        let cuit = datos.CuitCuil;

        cuit = cuit.substring(3);
        cuit = cuit.substring(0, 8);

        if (datos.CuitCuil.length !== 13 || cuit !== documento) {
            Swal.fire({
                title: 'Atención',
                html: 'El CUIT-CUIL ingresado no es valido',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }
        if (datos.Email === "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe ingresar un Email',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }

        if (datos.EmailRepetido === '') {
            Swal.fire({
                title: 'Atención',
                html: 'Debe Repetir el Email',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }
        if (datos.EmailRepetido !== datos.Email) {
            Swal.fire({
                title: 'Atención',
                html: 'El los Emails ingresados no coinciden',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }



        getProfesionalValidarCuit(datos.CuitCuil.replace(/-/gi, '')).then((value) => {
            if (!value.data.response) {
                Swal.fire({
                    title: 'Atención',
                    html: 'El CUIT-CUIL ingresado es invalido',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                return false;
            } else {
                setOpenConfirmacionTerminos(true);
            }
        });

    };

    const guardarDatosInscripcion = () => {

        const size = ((blobDDJJ.size / 1000) / 1000).toFixed(4);

        const reader = new FileReader();
        const upload = datos.adjuntos;


        if (upload.filter(c => c.fileType === 'Declaracion Jurada').length === 0) {
            reader.readAsDataURL(blobDDJJ)
            reader.onload = () => {
                datos.adjuntos.push({ fileType: 'Declaracion Jurada', fileName: 'DDJJ', extension: 'application/pdf', file: reader.result, size: parseFloat(size), url: PDF_FILE, saved: false });
            }
        } else {

            reader.readAsDataURL(blobDDJJ)
            reader.onload = () => {
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].fileName = 'DDJJ';
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].extension = 'application/pdf';
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].size = parseFloat(size);
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].saved = false;
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].file = reader.result;
            }
        }

        Swal.fire({
            title: 'Atención',
            html: 'Si presiona aceptar su solicitud será enviada al Consejo Profesional',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText:
                'Aceptar',
            cancelButtonText:
                'Cancelar',
            allowOutsideClick: true
        }).then((result) => {

            if (result.value) {


                setLoaderBtnGuardar(true);
                if (localStorage.userType != TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS) {

                    Swal.fire({
                        title: '',
                        html: 'Comienza descarga de archivo...',
                        icon: 'info',
                        showCloseButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false
                    });
                }
                setBloquearBtn(true);
                setDatos({
                    ...datos,
                    Observado: false
                });

                if (editarFotoSlice.deseaCambiarFoto) {
                    let adjuntoDeFoto = datos.adjuntos.find(a => a.fileType === "FotoPerfil");
                    if (adjuntoDeFoto) {
                        adjuntoDeFoto.file = editarFotoSlice.fotoNueva;
                    } else {
                        adjuntoDeFoto = nuevoAdjunto("FotoPerfil", "image/jpeg", editarFotoSlice.fotoNueva);
                        datos.adjuntos.push(adjuntoDeFoto);
                    }

                    let adjuntoDeFotoFull = datos.adjuntos.find(a => a.fileType === "FotoPerfilFull");
                    if (adjuntoDeFotoFull) {
                        adjuntoDeFotoFull.file = editarFotoSlice.fotoNuevaFullSize;
                    } else {
                        adjuntoDeFotoFull = nuevoAdjunto("FotoPerfilFull", "image/jpeg", editarFotoSlice.fotoNuevaFullSize);
                        datos.adjuntos.push(adjuntoDeFotoFull);
                    }
                }
                datos.cambiaFoto = editarFotoSlice.deseaCambiarFoto;
                datos.cambiaDNI = editarFotoSlice.cambiaDNI;

                datos.Observado = false;

                guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado === 1 || idEstado === 2 ? 2 : 6, tpo_tramite: 6 }).then((value) => {
                    setIdEstado(2);
                    setLoaderBtnGuardar(false);
                    if (localStorage.userType != TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS) {
                        descargarFile(blobDDJJ, 'DDJJ.pdf');
                    }
                    setBloquearBtn(false);
                    if (value.data.resultado) {
                        setOcultarContenido(false);
                        cambiarAlturaBox('45px');
                        setOcultarTabs({ display: '' });
                        setBloquearBtn(true);
                        Swal.fire({
                            title: '',
                            html: 'La solicitud fue enviada.',
                            icon: 'success',
                            showCloseButton: true,
                            showCancelButton: false,
                            confirmButtonText:
                                'Aceptar',
                            cancelButtonText:
                                'Cancelar',
                            allowOutsideClick: true
                        });
                    } else {
                        Swal.fire({
                            title: 'Atención',
                            html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                            icon: 'warning',
                            showCloseButton: true,
                            showCancelButton: false,
                            confirmButtonText:
                                'Aceptar',
                            cancelButtonText:
                                'Cancelar',
                            allowOutsideClick: true
                        })
                        return false;
                    }

                });


            }
        });
    }

    const [bloqueBtnConfirmarInscripcion, setBloqueBtnConfirmarInscripcion] = useState(false);
    const enviarInscripcion = () => {
        setBloqueBtnConfirmarInscripcion(true);
        setLoaderBtnGuardar(true);
        let errorDomicilio = 0;
        datos.domicilios.map(dm => {
            if (dm.codLocalidad === "-1") {
                errorDomicilio = 1;
            }
        })

        if (errorDomicilio === 1) {
            setLoaderBtnGuardar(false);
            setBloqueBtnConfirmarInscripcion(false);
            Swal.fire({
                title: 'Atención',
                html: 'Existen domicilios que tienen ingresada la localidad <b> Otros</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        validarDatosMatriculas().then(value => {
            setLoaderBtnGuardar(false);
            if (value.data.result) {
                setIdEstado(7);
                Swal.fire({
                    title: '',
                    html: 'La solicitud de modificación de datos se inició correctamente.',
                    icon: 'success',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
            } else {
                setBloqueBtnConfirmarInscripcion(false);
                Swal.fire({
                    title: 'Atención',
                    html: value?.data?.mensaje !== '' && value?.data?.mensaje !== null && value?.data?.mensaje?.indexOf('illegal character among') < 0 ? value?.data?.mensaje : value?.data?.mensaje?.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }

        });


    }

    const deleteFile = file => {
        const arrFiles = datos.adjuntos;
        arrFiles.filter(c => c.fileType === file)[0].fileName = '';
        arrFiles.filter(c => c.fileType === file)[0].extension = '';
        arrFiles.filter(c => c.fileType === file)[0].file = null;
        arrFiles.filter(c => c.fileType === file)[0].size = 0;
        arrFiles.filter(c => c.fileType === file)[0].url = EMPTY_FILE;
        arrFiles.filter(c => c.fileType === file)[0].saved = false;
        setDatos({
            ...datos,
            adjuntos: arrFiles
        });

    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = ev => {
        if (bloquearBtn !== true) {
            setFileUpdType(ev.target.name);
            hiddenFileInput.current.click();
        }

    };

    const popitup = (adjunto) => {

        setSelectedFile(adjunto);
        setOpenPreview(true);
    }

    const handleClose = () => setOpenPreview(false);

    const clases = useStyles();

    const eliminarArchivoAdjuntoExtra = (nombreArchivo) => {

        const arrFiles = datos.adjuntos;
        setDatos({
            ...datos,
            adjuntos: arrFiles.filter(c => c.fileType !== nombreArchivo)
        });
    };

    let sumarTitulo = 0;

    const [ocultarContenido, setOcultarContenido] = useState(false);

    const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
    const cancelarIncripcionPDF = () => {
        setOcultarContenido(false);
        cambiarAlturaBox('45px');
        setOcultarTabs({ display: '' });
    }

    const guardarDatosEdicionTemporal = () => {
        setLoaderBtnGuardar(true)
        setBloquearBtn(true);

        let erroresMostrar = "";
        let cantidadAdjuntosFaltantes = 0;
        let nombreAdjuntosFaltantes = "";

        if (datos.EmailRepetido !== datos.Email) {
            Swal.fire({
                title: 'Atención',
                html: 'Los Emails ingresados no coinciden',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);
            return false;
        }

        if (datos.CuitCuil === '' || datos.Email === '') {
            Swal.fire({
                title: 'Atención',
                html: 'El <b>Email</b>  y <b> CUIT-CUIL</b> son obligatorios',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            setLoaderBtnGuardar(false);
            setBloquearBtn(false);
            return false;
        }


        if (datos.EmailRepetido === '') {
            Swal.fire({
                title: 'Atención',
                html: 'Debe Repetir el Email',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);
            return false;
        }

        datos.adjuntos.map((adjunto, index) => {

            if ((adjunto.file === null || adjunto.file === '') && adjunto.fileType.replace(/\d/g, "").trim() != "DNI - Reverso" && adjunto.fileType !== 'Declaracion Jurada' && adjunto.fileType !== 'SOLICITUD DE MODIFICACIÓN DE DATOS ') {

                cantidadAdjuntosFaltantes++;

                if (nombreAdjuntosFaltantes === "") {
                    nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }
                else {
                    nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }

            }
        })

        if (cantidadAdjuntosFaltantes <= 2) {
            erroresMostrar += nombreAdjuntosFaltantes;
        } else {
            erroresMostrar += "<b>Adjuntos</b>";
        }

        let mostrarErrorDomicilio = 0;
        datos.domicilios.map((domicilio, index) => {
            if ((domicilio.calle === "" || domicilio.caracteristica === null || domicilio.caracteristica === "" || domicilio.celular === "" || (isNaN(domicilio.caracteristica) || isNaN(domicilio.celular))) && mostrarErrorDomicilio === 0) {
                if (erroresMostrar !== "") {
                    erroresMostrar += ", ";
                }
                erroresMostrar += "<b>Domicilios y/o Teléfono</b>";
                mostrarErrorDomicilio = 1;
            }

        })

        let erroresFamiliares = 0;
        let errorOS = 0;
        let errorSexo = 0;
        let errorFN = 0;
        let errorNombre = 0;
        let errorApellido = 0;
        let errorTD = 0;
        let errorND = 0;
        let errorEdad = 0;
        let errorIncapacitado = 0;
        let errorCargo = 0;
        let errorFamiliarEdad = 0;
        let errorNacionalidad = 0;
        let errorNomParentesco = 0;
        let grupoFamiliarCompletar = "";
        if (Array.isArray(datos.familiares)) {

            datos.familiares.filter(item => item.estado !== 0).map(fam => {
                // console.log(domicilio);
                if (((fam.tpoOsocial === 0 || fam.tpoOsocial === null) && errorOS === 0) ||
                    (fam.sexo === null && errorSexo == 0) ||
                    ((fam.edad === "" || fam.edad === null) && errorEdad === 0) ||
                    ((fam.incapacitado === "" || fam.incapacitado === null) && errorIncapacitado === 0) ||
                    ((fam.nombre === "" || fam.nombre === null) && errorNombre === 0) ||
                    ((fam.apellido === "" || fam.apellido === null) && errorApellido === 0) ||
                    ((fam.numeroDocumento === "" || fam.numeroDocumento === null && errorND == 0)) ||
                    ((fam.cargo === "" || fam.cargo === null && errorCargo == 0)) ||
                    ((fam.fechaNacimiento === "" || fam.fechaNacimiento === null) && errorFN == 0) ||
                    ((fam.nombreParentesco === "" || fam.nombreParentesco === null) && errorNomParentesco == 0) ||
                    ((fam.codNacionalidad === 3 || fam.codNacionalidad === 0 || fam.codNacionalidad === null) && errorNacionalidad == 0) ||
                    ((fam.tipoDocumento === "" || fam.tipoDocumento === null) && errorTD == 0)) {

                    if (fam.incapacitado === "" || fam.incapacitado === null) {
                        errorIncapacitado = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Incapacitado";
                        else
                            grupoFamiliarCompletar += " ,Incapacitado";

                    }
                    if (fam.codNacionalidad === 3 || fam.codNacionalidad === 0 || fam.codNacionalidad === null) {
                        errorNacionalidad = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Nacionalidad";
                        else
                            grupoFamiliarCompletar += " ,Nacionalidad";

                    }

                    if (fam.nombreParentesco === "" || fam.nombreParentesco === null) {
                        errorNomParentesco = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Parentesco";
                        else
                            grupoFamiliarCompletar += " ,Parentesco";

                    }

                    if (fam.numeroDocumento === "" || fam.numeroDocumento === null) {
                        errorND = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Número Documento";
                        else
                            grupoFamiliarCompletar += " ,Número Documento";

                    }
                    if (fam.nombre === "" || fam.nombre === null) {
                        errorNombre = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Nombre";
                        else
                            grupoFamiliarCompletar += " ,Nombre";

                    }

                    if (fam.apellido === "" || fam.apellido === null) {
                        errorApellido = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Apellido";
                        else
                            grupoFamiliarCompletar += " ,Apellido";

                    }

                    if (fam.cargo === "" || fam.cargo === null) {
                        errorCargo = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(A Cargo";
                        else
                            grupoFamiliarCompletar += " ,A Cargo";

                    }
                    if (fam.edad === "" || fam.edad === null) {
                        errorEdad = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Edad";
                        else
                            grupoFamiliarCompletar += " ,Edad";

                    }

                    if (fam.tpoOsocial === 0 || fam.tpoOsocial === null) {
                        errorOS = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Obra Social";
                        else
                            grupoFamiliarCompletar += " ,Obra Social";

                    }

                    if (fam.sexo === null) {
                        errorSexo = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Sexo";
                        else
                            grupoFamiliarCompletar += " ,Sexo ";
                    }
                    if (fam.fechaNacimiento === "" || fam.fechaNacimiento === null) {
                        errorFN = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Fecha Nacimiento";
                        else
                            grupoFamiliarCompletar += " ,Fecha Nacimiento";
                    }
                    if (fam.tipoDocumento === "" || fam.tipoDocumento === null) {
                        errorTD = 1;
                        if (erroresFamiliares === 0)
                            grupoFamiliarCompletar = "(Tipo Documento";
                        else
                            grupoFamiliarCompletar += " ,Tipo Documento";
                    }


                    erroresFamiliares += 1;
                }

                if (calcularEdad(fam.fechaNacimiento) > 110) {
                    errorFamiliarEdad = 1;
                }

            })

            if (errorFamiliarEdad === 1) {
                Swal.fire({
                    title: 'Atención',
                    html: 'La <b>Fecha de Nacimiento</b> de un integrante del grupo familiar primario es incorrecta.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }

            if (erroresFamiliares >= 1) {
                if (erroresMostrar !== "") {
                    erroresMostrar += ",";
                }
                erroresMostrar += "<b>Familiares" + grupoFamiliarCompletar + ")</b>";
            }
        }

        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);
            return false;

        }

        getProfesionalValidarMailActualizacion(datos.Email, localStorage.idLegajo).then((value) => {

            if (!value.data.response) {

                Swal.fire({
                    title: 'Atención',
                    html: 'El <b>Email</b> ingresado ya se encuentra registrado',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                setLoaderBtnGuardar(false)
                setBloquearBtn(false);
                return false;
            } else {
                guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 6 }).then((value) => {
                    setLoaderBtnGuardar(false)
                    setBloquearBtn(false);
                    if (value.data.resultado) {
                        setOcultarTabs({ display: 'none' });
                        setOcultarContenido(true);
                        cambiarAlturaBox('0px');
                        setLegajoTemporal(value.data.result.idLegajoTemporal)
                        localStorage.setItem('idLegajoTemporal', value.data.result.idLegajoTemporal);
                    } else {
                        Swal.fire({
                            title: 'Atención',
                            html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                            icon: 'warning',
                            showCloseButton: true,
                            showCancelButton: false,
                            confirmButtonText:
                                'Aceptar',
                            cancelButtonText:
                                'Cancelar',
                            allowOutsideClick: true
                        })
                        return false;
                    }
                });
            }
        })

    }

    const continuarGuardadoAdjuntos = () => {
        setOpenConfirmacionTerminos(false);
        guardarDatosInscripcion();
    }
    function downloadPDF(pdf, nombrePdf) {
        const linkSource = `${pdf}`;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = nombrePdf;
        downloadLink.click();
    }

    datos.adjuntos = datos.adjuntos.filter(adj => adj.fileType !== "FotoPerfilFull");

    if (localStorage.userType != TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS) {
        datos.adjuntos = datos.adjuntos.filter(adj => adj.fileType !== "FotoPerfil");
    }

    return (
        <div>

            {ocultarContenido === false ?
                <form onSubmit={handleSubmit(onSubmit)}>

                    <input type="file" name="uploadFile" onChange={changeHandler} style={{ display: 'none' }} ref={hiddenFileInput} />

                    <Typography style={{ fontSize: 14 }} >
                        *<b>Para adjuntar su documento deberá hacer clic en el ícono
                            <img alt={"Icono adjuntos"} src={EMPTY_FILE} className={clases.imgIconTexto} />.
                        </b>
                    </Typography>



                    <Grid container spacing={2} style={{marginTop: "1vh"}}>

                        {datos.adjuntos && datos.adjuntos.filter(adj => (idEstado !== 2 && adj.fileType !== "Declaracion Jurada" && localStorage.userType != TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS) || ((idEstado === 2 || idEstado === 6 && datos.Observado === false) && adj.file !== null && adj.file !== '') || (localStorage.userType == TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS && adj.file !== null && adj.file !== '')).map((adj, index) => {

                            return (
                                <Grid item key={index} style={{ marginLeft: 0 }}>
                                    <Grid container key={index}>
                                        <Grid item key={index}>
                                            <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', fontSize: 17, margin: 0 }} title={adj.fileType}>
                                                <b> {adj.fileType === "Declaracion Jurada" ? "SOLICITUD DE MODIFICACIÓN DE DATOS" : adj.fileType} </b>
                                            </p>

                                            {(localStorage.userType != TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS && bloquearBtn !== true && adj.fileType !== "Declaracion Jurada" && adj.file !== null && adj.file !== '') &&
                                                <img src={DEL_FILE} className={clases.delIcon}
                                                    alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                            }

                                            {adj.file !== null && adj.file !== '' ?

                                                <img src={adj.url} style={{ marginLeft: adj.fileType === "Declaracion Jurada" ? 160 : 18 }} className={clases.imgIcon} alt='empty file'
                                                    title='Ver archivo' onClick={() => popitup(adj)} />

                                                :
                                                <img src={adj.url} style={{
                                                    marginLeft: 97
                                                }}
                                                    className={clases.imgIcon} alt='Subir archivo'
                                                    name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                            }

                                            <p className={clases.pFileName} style={{ marginBottom: 0 }} > {adj.fileName.length >= 15 ?
                                                adj.fileName.substring(0, 11) + '...' : adj.fileName}</p>
                                            <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', marginTop: 0, marginBottom: 0 }} title={adj.fileType}>

                                                {((localStorage.userType != TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS && bloquearBtn !== true) && adj.fileType.replace(/[^0-9]/g, "").length > 0) && <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>}
                                                

                                                {adj.extension === "application/pdf" && (adj.fileType !== "Declaracion Jurada" || localStorage.userType != TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS ) &&
                                                    <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                }
                                                {adj.extension === "application/pdf" && (adj.fileType === "Declaracion Jurada" && localStorage.userType == TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS) &&
                                                    <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, generarNombreDescarga())}><FileDownloadIcon />  </Fab>

                                                }
                                                {adj.fileType === "FotoPerfil" &&
                                                    <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                }
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            )
                        })}
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}> </Grid>
                        <Grid item md={6} xs={6} sx={{ mt: 3 }} container>

                            <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue("2")} variant="contained" color="error" >
                                Atras
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                                {
                                    localStorage.userType == TIPOS_USUARIO.PROFESIONAL_EDITAR_DATOS &&
                                    <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" onClick={guardarDatosEdicionTemporal} color="secondary">
                                        {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                    </Button>
                                }


                                {
                                    localStorage.userType == TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS &&
                                    <Grid>
                                        <Button startIcon={<AddIcon />} variant="contained" disabled={(idEstado === 1 || idEstado === 2 || (idEstado === 6 && datos.Observado === false)) ? false : true} onClick={enviarInscripcion} color="secondary">
                                            {loaderBtnGuardar === true ? <CircularProgress /> : ''} Confirmar Modificacion de Datos
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form >
                : <Grid>

                    <Typography style={{ fontSize: 14 }} sx={{ mb: 1 }} ><b>* Verifique que los datos  ingresados sean los correctos y luego haga click en el boton aceptar para enviar su modificación de datos</b></Typography>
                    <PDFViewer style={{
                        width: '100%',
                        height: '65vh'
                    }} showToolbar={false}>

                        <Pdf datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} />

                    </PDFViewer>
                    <PDFDownloadLink document={<Pdf datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} />} fileName="DDJJ.pdf">
                        {({ blob, url, loading, error }) => {

                            blobDDJJ = blob;
                            urlDDJJ = url;

                        }

                        }
                    </PDFDownloadLink>
                    <Grid container>

                        <Grid item md={6} sx={{ mt: 3 }} container>
                            <Button startIcon={<HighlightOffIcon />} onClick={() => cancelarIncripcionPDF()} variant="contained" color="error" >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">

                                <Button startIcon={<CheckIcon />} disabled={bloquearBtn} variant="contained" onClick={() => {
                                    setOpenConfirmacionTerminos(true)
                                }} color="secondary">
                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''}  Aceptar
                                </Button>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <FilePreview file={selectedFile} onClose={handleClose} opened={openPreview} />

          
            <ModalTerminosyCondiciones
                onClose={() => setOpenConfirmacionTerminos(false)}
                open={openConfirmarTerminos}
                onSuccess={continuarGuardadoAdjuntos}
            />
        </div >
    );
}

