import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Card, CardContent, FormGroup, FormControlLabel, Checkbox, Typography, Box, Modal, Grid, Fab, FormControl, InputLabel, Select, MenuItem, RadioGroup, Radio } from '@mui/material';
import { PDFViewer, PDFDownloadLink, Document } from '@react-pdf/renderer';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { cambiarAlturaBox } from '../funciones/funcionesGlobales';
import { makeStyles } from '@mui/styles';
import { useForm } from "react-hook-form";
import { guardarDatosMatriculas, validarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { getProfesionalValidarCuit } from 'app/features/Profesionales/profesionalesAPI';
import Pdf from 'components/matriculas/pdf';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { EMPTY_FILE, PDF_FILE, DEL_FILE } from 'constants/icons';
import { getLeyendasAsync } from 'app/features/Leyendas/leyendasSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TIPOS_USUARIO } from 'constants/constants';
import {descargarFile} from 'utils/archivosManager';
import ModalTerminosyCondiciones from 'components/common/ModalTerminosyCondiciones';



//BUSQUEDA PARA EL PDF

import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { nacionalidadesList, getNacionalidadesAsync } from 'app/features/Nacionalidades/nacionalidadesSlice';
import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';


// import { borderRadius } from '@mui/system';
import FilePreview from 'components/common/FilePreview';

const useStyles = makeStyles(theme => ({
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    pIcon: {
        textAlign: 'center'
    },
    pFileName: {
        textAlign: 'center',
        marginTop: '0',
        fontWeight: 'bold',
        size: '12px'
    },
    imgIcon: {
        width: '40px',
        height: '40px',
        cursor: 'pointer'
    },
    imgIconCargado: {
        width: '40px',
        height: '40px',
        cursor: 'pointer'
    },
    imgIconTexto: {
        width: '40px',
        height: '22px'
    },
    delIcon: {
        width: '25px',
        height: '28px',
        position: 'relative',
        cursor: 'pointer',
        marginTop: "10px"
    },
    delIconOpcionales: {
        background: 'red',
        width: '25px',
        borderRadius: '50%',
        position: 'relative',
        right: '3%',
        cursor: 'pointer'
    },
    modalWindow: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'rgb(255, 255, 255)',
        border: '2px solid #000',
        padding: '15px',
    }
}));

export const ArchivosAdjuntos = ({ datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, ocultarContenido, setOcultarContenido, ValorTab, resultadoPagoTasa, setMatriculas}) => {
    const [fileUpdType, setFileUpdType] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});


    const dispatch = useDispatch();
    //pasar a la vista del pdf
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const EstadoCivilList = useSelector(estadoCivilList);
    const NacionalidadesList = useSelector(nacionalidadesList);
    const ObrasSociales = useSelector(obrasSocialesList);
    const [botonConfirmarTerminos, setBotonConfirmarTerminos] = useState(true);
    const [botonCancelarTerminos, setBotonCancelarTerminos] = useState(false);

    const [datosAdjuntosGuardar, setDatosAdjuntosGuardar] = useState({});
    const [leyendas, setLeyendas] = useState([]);
    const [anchoPantalla, setAnchoPantalla] = useState(0);
    const [mostrarAnalitico, setMostrarAnalitico] = useState(0);
    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }
    
    window.addEventListener("resize", handleResize);
    useEffect(() => {
        if (ObrasSociales.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getObrasSocialesAsync());
        }

        if (localStorage.userType !== "10" && localStorage.validacionTitulo !== "1") {
            setMostrarAnalitico(1);
        }

        if (localStorage.userType === "10" || localStorage.userType === "0") {
            Swal.fire({
                title: 'Atención',
                html: 'Verifique que los documentos a adjuntar se visualicen correctamente',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
        }


        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);

        //llamo a la funcion del Slice
        dispatch(getLeyendasAsync("Inscripcion_Profesionales")).then((value) => {
            setLeyendas(value.payload.results)
        });


        if (EstadoCivilList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getEstadoCivilAsync());
        }

        if (TipoDocumentos.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getTipoDocumentoAsync());
        }

        if (NacionalidadesList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getNacionalidadesAsync());
        }

    }, [])

    let blobDDJJ = {};
    let urlDDJJ = "";

    const [open, setOpen] = useState(false);
    const [openConfirmarTerminos, setOpenConfirmacionTerminos] = useState(false);




    //metodo para subir archivos
    const changeHandler = (evt) => {
        
        
       

        if (evt.target.files.length >= 1) {
            const tipo = evt.target.files[0].type;
            const size = ((evt.target.files[0].size / 1000) / 1000).toFixed(4);

            if (tipo !== 'application/pdf' && tipo !== 'image/jpeg' && tipo !== 'image/png') {
                Swal.fire({
                    title: 'Atención',
                    html: 'El tipo de archivo no es correcto, se admite unicamente: pdf, jpg, jpeg o png.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else if ((Number(size) > 5)) {
                var textoError = 'Sr/a profesional el tamaño de la imagen no debe superar(';
                Swal.fire({
                    title: 'Atención',
                    html: 'El tamaño del archivo  no debe superar 5MB.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else {
                const upload = datos.adjuntos;
                upload.filter(c => c.fileType === fileUpdType)[0].fileName = evt.target.files[0].name;
                upload.filter(c => c.fileType === fileUpdType)[0].extension = tipo;
                upload.filter(c => c.fileType === fileUpdType)[0].file = evt.target.value;
                upload.filter(c => c.fileType === fileUpdType)[0].size = parseFloat(size);
                upload.filter(c => c.fileType === fileUpdType)[0].saved = false;

                const reader = new FileReader();

                reader.onload = () => {

                    if (tipo !== 'application/pdf')
                        upload.filter(c => c.fileType === fileUpdType)[0].url = reader.result;
                    else
                        upload.filter(c => c.fileType === fileUpdType)[0].url = PDF_FILE;

                    upload.filter(c => c.fileType === fileUpdType)[0].file = reader.result;
                    setDatos({
                        ...datos,
                        adjuntos: upload
                    });
                }
                reader.readAsDataURL(evt.target.files[0]);
               
            }
        }
    }



    const { handleSubmit, formState: { errors } } = useForm();


    const [perteneceOtroConsejo, setPerteneceOtroConsejo] = useState(0);

    const onSubmit = data => {

        

        let erroresMostrar = "";
        let errorDatosPersonales = "";

        if (datos.Apellido === "" || datos.CuitCuil === ""
            || datos.Email === "" || datos.EstadoCivil === "" || datos.ObraSocial === ""
            || datos.Nacimiento === "" || datos.Nombre === ""
            || datos.NroDocumento === "" || datos.TipoDocumento === "") {


            if (datos.Apellido === "") {
                errorDatosPersonales = "<b> Apellido</b>";
            }

            if (datos.CuitCuil === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b> Cuit - Cuil</b>";
                else
                    errorDatosPersonales += ", <b> Cuit - Cuil</b>";
            }

            if (datos.Email === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Email</b>";
                else
                    errorDatosPersonales += ", <b>Email</b>";
            }

            if (datos.EstadoCivil === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Estado Civil</b>";
                else
                    errorDatosPersonales += ", <b>Estado Civil</b>";
            }

            if (datos.ObraSocial === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Obra Social</b>";
                else
                    errorDatosPersonales += ", <b>Obra Social</b>";
            }

            if (datos.Nacimiento === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Fecha de Nacimiento</b>";
                else
                    errorDatosPersonales += ", <b>Fecha de Nacimiento</b>";
            }

            if (datos.Nombre === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Nombre</b>";
                else
                    errorDatosPersonales += ", <b>Nombre</b>";
            }

            if (datos.NroDocumento === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Número de Documento</b>";
                else
                    errorDatosPersonales += ", <b>Número de Documento</b>";
            }

            if (datos.TipoDocumento === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Tipo de Documento</b>";
                else
                    errorDatosPersonales += ", <b>Tipo Documento</b>";
            }

        }

        if (errorDatosPersonales !== "")
            erroresMostrar = "<b>Datos Personales</b> (" + errorDatosPersonales + ")";

        if (datos.PerteneceOtroConsejo === null) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe indicar si se encuentra matriculado en otro Consejo Profesional',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }


        if (calcularEdad(datos.Nacimiento) > 100) {
            Swal.fire({
                title: 'Atención',
                html: 'La <b>Fecha de Nacimiento</b> ingresada es incorrecta',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        let errorFamiliarEdad = 0;
        if (Array.isArray(datos.familiares)) {
            datos.familiares.map(fam => {
                if (calcularEdad(fam.fechaNacimiento) > 110) {
                    errorFamiliarEdad = 1;
                }

            })
        }


        if (errorFamiliarEdad === 1) {
            Swal.fire({
                title: 'Atención',
                html: 'La <b>Fecha de Nacimiento</b> de un integrante del grupo familiar primario es incorrecta.',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }
        let mostrarErrorDomicilio = 0;
        datos.domicilios.map((domicilio, index) => {

            if ((domicilio.calle === "" || domicilio.caracteristica === null || domicilio.caracteristica.length > 5 || domicilio.caracteristicaFijo.length > 5 || (isNaN(domicilio.caracteristica) || isNaN(domicilio.celular))) && mostrarErrorDomicilio === 0) {
                if (erroresMostrar !== "") {
                    erroresMostrar += ", ";
                }
                erroresMostrar += "<b>Domicilios</b>";
                mostrarErrorDomicilio = 1;
            }

        })

        let mostrarErrorAdjuntos = 0;

        let errorDdjjEnfermedades = 0;
        let cantidadAdjuntosFaltantes = 0;
        let nombreAdjuntosFaltantes = "";
        datos.adjuntos.map((adjunto, index) => {

            if ((adjunto.file === null || adjunto.file === '') && mostrarErrorAdjuntos === 0
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Certificado de libre sanción disciplinaria de otros consejos profesionales"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Declaración jurada de enfermedades y patologías preexistentes (para mayores de  años)"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Declaración jurada de enfermedades y patologías preexistentes"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Pago Inscripción"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Declaracion Jurada"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Analítico" || (datos.PerteneceOtroConsejo === 0 && adjunto.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales" && (adjunto.file === null || adjunto.file === '') && mostrarErrorAdjuntos === 0)) {

                cantidadAdjuntosFaltantes++;

                if (nombreAdjuntosFaltantes === "") {
                    nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }
                else {
                    nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }

            }


            if (calcularEdad(datos.Nacimiento) >= 50 && (adjunto.file === null || adjunto.file === '') && (adjunto.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes (para mayores de  años)" || adjunto.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes"))
                errorDdjjEnfermedades = 1;


        })

        if (cantidadAdjuntosFaltantes <= 2) {
            if (erroresMostrar !== "") {
                erroresMostrar += ", ";
            }
            erroresMostrar += nombreAdjuntosFaltantes;
        } else {
            if (erroresMostrar !== "") {
                erroresMostrar += ", ";
            }
            erroresMostrar += "<b>Adjuntos</b>";
        }

        if (errorDdjjEnfermedades === 1) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe cargar   la <b>Declaración jurada de enfermedades y patologías preexistentes</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }


        if (!Array.isArray(datos.titulos) || datos.titulos.length === 0) {
            if (erroresMostrar !== "") {
                erroresMostrar += ",";
            }
            erroresMostrar += "<b>Titulos</b>";
        }

        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }
        if (datos.NroDocumento.length !== 10) {
            Swal.fire({
                title: 'Atención',
                html: 'El Numero de Documento ingresado no es valido',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        let documento = datos.NroDocumento;

        documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "");

        let cuit = datos.CuitCuil;

        cuit = cuit.substring(3);
        cuit = cuit.substring(0, 8);

        if (datos.CuitCuil.length !== 13 || cuit !== documento) {
            Swal.fire({
                title: 'Atención',
                html: 'El CUIT-CUIL ingresado no es valido',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        getProfesionalValidarCuit(datos.CuitCuil.replace(/-/gi, '')).then((value) => {
            if (!value.data.response) {
                Swal.fire({
                    title: 'Atención',
                    html: 'El CUIT-CUIL ingresado es invalido',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                return false;
            } else {
                setLoaderBtnGuardar(true)
                setBloquearBtn(true);
                // console.log(datos);
                guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: localStorage.userType === "10" ? 8 : 1 }).then((value) => {
                    setMatriculas(value.data);
                        setLoaderBtnGuardar(false)
                        setBloquearBtn(false);
                        if (value.data.resultado) {
                            console.dir(value);
                            if(value.data.result.exentoTasa) {
                                if(resultadoPagoTasa){
                                    ValorTab("6")
                                }else{
                                    setOcultarTabs({ display: 'none' });
                                    setOcultarContenido(true);
                                }
                            } else{
                                ValorTab("6")
                            }
                            cambiarAlturaBox('0px');
                        } else {
                            Swal.fire({
                                title: 'Atención',
                                html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                                icon: 'warning',
                                showCloseButton: true,
                                showCancelButton: false,
                                confirmButtonText:
                                    'Aceptar',
                                cancelButtonText:
                                    'Cancelar',
                                allowOutsideClick: true
                            })
                            return false;
                        }
                });
            }
        });
    };

    const guardarDatosAltaTituloTemporal = () => {
        

        setLoaderBtnGuardar(true)
        setBloquearBtn(true);

        let mostrarErrorAdjuntos = 0;
        let erroresMostrar = "";
        let errorDdjjEnfermedades = 0;
        let cantidadAdjuntosFaltantes = 0;
        let nombreAdjuntosFaltantes = "";
        if (datos.PerteneceOtroConsejo === null) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe indicar si se encuentra matriculado en otro Consejo Profesional',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);

            return false;
        }
        datos.adjuntos.map((adjunto, index) => {

            if (adjunto.fileName === "" && mostrarErrorAdjuntos === 0
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Certificado de libre sanción disciplinaria de otros consejos profesionales"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Declaración jurada de enfermedades y patologías preexistentes (para mayores de  años)"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Declaración jurada de enfermedades y patologías preexistentes"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Pago Inscripción"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Analítico" || (datos.PerteneceOtroConsejo === 0 && adjunto.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales" && adjunto.fileName === "" && mostrarErrorAdjuntos === 0)) {

                cantidadAdjuntosFaltantes++;

                if (nombreAdjuntosFaltantes === "") {
                    nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }
                else {
                    nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }
            }

            if (calcularEdad(datos.Nacimiento) >= 50 && adjunto.fileName === "" && (adjunto.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes (para mayores de  años)" || adjunto.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes"))
                errorDdjjEnfermedades = 1;


        })


        if (cantidadAdjuntosFaltantes <= 2) {
            erroresMostrar += nombreAdjuntosFaltantes;
        } else {
            erroresMostrar += "<b>Adjuntos</b>";
        }

        if (!Array.isArray(datos.titulos) || datos.titulos.length === 0) {
            if (erroresMostrar !== "") {
                erroresMostrar += ",";
            }
            erroresMostrar += "<b>Titulos</b>";
        }
        if (errorDdjjEnfermedades === 1) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe cargar   la <b>Declaración jurada de enfermedades y patologías preexistentes</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);
            return false;
        }

        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);
            return false;

        }

        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 8 }).then((value) => {
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);


            if (value.data.resultado) {
                setOcultarTabs({ display: 'none' });
                setOcultarContenido(true);
                cambiarAlturaBox('0px');
                if (localStorage.userType === '10') {
                    localStorage.setItem('idLegajoTemporal', value.data.result.idLegajoTemporal);
                }
            } else {
                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;


            }

        });
    }

    const guardarDatosInscripcion = () => {

        // console.log(blobDDJJ);
        
        // return false;
        const size = ((blobDDJJ.size / 1000) / 1000).toFixed(4);

        const reader = new FileReader();
        const upload = datos.adjuntos;


        if (upload.filter(c => c.fileType === 'Declaracion Jurada').length === 0) {
            reader.readAsDataURL(blobDDJJ)
            // console.log(blob, reader);
            //upload.filter(c => c.fileType === 'Declaracion Jurada')[0].file = reader.result;
            reader.onload = () => {
                datos.adjuntos.push({ fileType: 'Declaracion Jurada', fileName: 'DDJJ', extension: 'application/pdf', file: reader.result, size: parseFloat(size), url: PDF_FILE, saved: false });
            }
        } else {

            reader.readAsDataURL(blobDDJJ)
            // console.log(blob, reader);
            //
            reader.onload = () => {
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].fileName = 'DDJJ';
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].extension = 'application/pdf';
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].size = parseFloat(size);
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].saved = false;
                upload.filter(c => c.fileType === 'Declaracion Jurada')[0].file = reader.result;
            }
        }

        setOpenConfirmacionTerminos(true);
        setBotonConfirmarTerminos(true);
        setBotonCancelarTerminos(false);
    }

    const guardarInscripcion = () => {
        setLoaderBtnGuardar(true);
        if (localStorage.userType != TIPOS_USUARIO.SECRETARIO_TECNICO) {

            Swal.fire({
                title: '',
                html: 'Comienza descarga de archivo...',
                icon: 'info',
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false
            });
        }

        setBloquearBtn(true);
        setDatos({
            ...datos,
            Observado: false
        });

        datos.Observado = false;


        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado === 1 || idEstado === 2 ? 2 : 6, tpo_tramite: localStorage.userType === "10" ? 8 : 1 }).then((value) => {
            setIdEstado(2);
            if (localStorage.userType != TIPOS_USUARIO.SECRETARIO_TECNICO) {
                descargarFile(blobDDJJ, 'DDJJ.pdf');
            }
            setLoaderBtnGuardar(false);
            setBloquearBtn(false);

            //if (localStorage.userType === "10") {
            setOpenConfirmacionTerminos(false);
            if (value.data.resultado) {
                setOcultarContenido(false);
                cambiarAlturaBox('45px');
                setOcultarTabs({ display: '' });
                setBloquearBtn(true);
                Swal.fire({
                    title: '',
                    html: localStorage.userType !== "10" ? 'Sr/a profesional, su solicitud fue enviada' : 'Su solicitud fue enviada',
                    icon: 'success',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
            } else {

                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }

        });
    }


    const [bloqueBtnConfirmarInscripcion, setBloqueBtnConfirmarInscripcion] = useState(false);
    const enviarInscripcion = () => {
        

        setBloqueBtnConfirmarInscripcion(true);
        setLoaderBtnGuardar(true);
        let errorDomicilio = 0;
        datos.domicilios.map(dm => {
            if (dm.codLocalidad === "-1") {
                errorDomicilio = 1;
            }
        })

        if (errorDomicilio === 1) {
            setLoaderBtnGuardar(false);
            setBloqueBtnConfirmarInscripcion(false);
            Swal.fire({
                title: 'Atención',
                html: 'Existen domicilios que tienen ingresada la localidad <b> Otros</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        validarDatosMatriculas().then(value => {
            setLoaderBtnGuardar(false);

            if (value.data.result) {
                setIdEstado(7);
                Swal.fire({
                    title: '',
                    html: 'La solicitud de inscripción se inició correctamente.',
                    icon: 'success',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
            } else {
                setBloqueBtnConfirmarInscripcion(false);

                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }

        });


    }

    const deleteFile = file => {
        
        const arrFiles = datos.adjuntos;
        arrFiles.filter(c => c.fileType === file)[0].fileName = '';
        arrFiles.filter(c => c.fileType === file)[0].extension = '';
        arrFiles.filter(c => c.fileType === file)[0].file = null;
        arrFiles.filter(c => c.fileType === file)[0].size = 0;
        arrFiles.filter(c => c.fileType === file)[0].url = EMPTY_FILE;
        arrFiles.filter(c => c.fileType === file)[0].saved = false;
        setDatos({
            ...datos,
            adjuntos: arrFiles
        });

    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = ev => {
        if (bloquearBtn !== true) {
            setFileUpdType(ev.target.name);
            hiddenFileInput.current.click();
        }

    };

    const popitup = (adjunto) => {
        // console.log(adjunto)
        setSelectedFile(adjunto);
        setOpenPreview(true);
    }
    const handleClose = () => setOpenPreview(false);

    const clases = useStyles();
    const handleOpen = () => {

        setOpen(true);
    }

    const eliminarArchivoAdjuntoExtra = (nombreArchivo) => {

        const arrFiles = datos.adjuntos;
        setDatos({
            ...datos,
            adjuntos: arrFiles.filter(c => c.fileType !== nombreArchivo)
        });
    };

    let sumarTitulo = 0;
    const agregarAdjunto = (data) => {

        

        datos.adjuntos.map((tit) => {
            if (tit.fileType.replace(/\d/g, "").trim() === data.replace(/\d/g, "").trim()) {
                sumarTitulo++;
            }
        });

        datos.adjuntos.push({ fileType: data + ' ' + sumarTitulo, fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: false });

        //datosAdjuntosGuardar.push({ fileType: data + ' ' + sumarTitulo, fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: false });

        // return false;
        let adjuntosOrdenados = 0;
        let adjuntosArray = [];
        let titulosTotales = 0;
        let dniTotales = 0;
        let fotoTotales = 0;
        let analiticoTotales = 0;
        let libreSancion = 0;
        let declaracionJuradaEnfermedadesTotales = 0;
        let pagoTotales = 0;

        datos.adjuntos.map((adj, index) => {

            if (adj.fileType.search('-') >= 0)
                titulosTotales++;

            if (adj.fileType.indexOf('DNI') >= 0)
                dniTotales++;

            if (adj.fileType.indexOf('Foto') >= 0)
                fotoTotales++;

            if (adj.fileType.indexOf('Pago') >= 0)
                pagoTotales++;

            if (adj.fileType.indexOf('Analítico') >= 0)
                analiticoTotales++;

            if (adj.fileType.indexOf('enfermedades') >= 0)
                declaracionJuradaEnfermedadesTotales++;

            if (adj.fileType.indexOf('sanción') >= 0)
                libreSancion++;

        });

        for (let i = 0; i < datos.adjuntos.length;) {
            datos.adjuntos.map((adj, index) => {

                if (
                    (adjuntosOrdenados >= 0 && adjuntosOrdenados < dniTotales && adj.fileType.indexOf('DNI') >= 0) ||
                    (adjuntosOrdenados >= dniTotales && adjuntosOrdenados < (dniTotales + fotoTotales) && adj.fileType.indexOf('Foto') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales) && adjuntosOrdenados < (dniTotales + fotoTotales + pagoTotales) && adj.fileType.indexOf('Pago') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + pagoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales) && adj.fileType.search('-') >= 0) ||
                    (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion) && adj.fileType.indexOf('sanción') >= 0) ||
                    (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales) && adj.fileType.indexOf('enfermedades') >= 0) ||
                    (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales) && adj.fileType.indexOf('Analítico') >= 0) ||
                    (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales + 1) && adj.fileName === "DDJJ") && datos.adjuntos.length > i) {


                    adjuntosArray.push(adj);
                    adjuntosOrdenados++;
                    i++
                }

            });
        }


        setDatos({
            ...datos,
            adjuntos: adjuntosArray
        })

    
    }

    

    // const [ocultarContenido, setOcultarContenido] = useState(false);

    const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
    const cancelarIncripcionPDF = () => {
        setOcultarContenido(false);
        cambiarAlturaBox('45px');
        setOcultarTabs({ display: '' });
    }

    const calcularEdad = (fecha) => {
        let hoy = new Date();
        let cumpleanos = new Date(fecha);
        let edad = hoy.getFullYear() - cumpleanos.getFullYear();
        let m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }

    const continuarGuardadoAdjuntos = () => {
        

        setLoaderBtnGuardar(true)
        setBloquearBtn(true);

        if (localStorage.userType === "0") {

            let erroresMostrar = "";

            let mostrarErrorAdjuntos = 0;

            let errorDdjjEnfermedades = 0;
            let cantidadAdjuntosFaltantes = 0;
            let nombreAdjuntosFaltantes = "";
            datos.adjuntos.map((adjunto, index) => {

                if ((adjunto.file === null || adjunto.file === '') && mostrarErrorAdjuntos === 0
                    && adjunto.fileType.replace(/\d/g, "").trim() !== "Certificado de libre sanción disciplinaria de otros consejos profesionales"
                    && adjunto.fileType.replace(/\d/g, "").trim() !== "Declaración jurada de enfermedades y patologías preexistentes (para mayores de  años)"
                    && adjunto.fileType.replace(/\d/g, "").trim() !== "Declaración jurada de enfermedades y patologías preexistentes"
                    && adjunto.fileType.replace(/\d/g, "").trim() !== "Pago Inscripción"
                    && adjunto.fileType.replace(/\d/g, "").trim() !== "Declaracion Jurada"
                    && adjunto.fileType.replace(/\d/g, "").trim() !== "Analítico" || (datos.PerteneceOtroConsejo === 0 && adjunto.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales" && (adjunto.file === null || adjunto.file === '') && mostrarErrorAdjuntos === 0)) {

                    cantidadAdjuntosFaltantes++;

                    if (nombreAdjuntosFaltantes === "") {
                        nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                    else {
                        nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }


                    // if (erroresMostrar !== "") {
                    //     erroresMostrar += ", ";
                    // }


                    // erroresMostrar += "<b>Adjuntos</b>";
                    //  mostrarErrorAdjuntos = 1;
                }


                if (calcularEdad(datos.Nacimiento) >= 50 && (adjunto.file === null || adjunto.file === '') && (adjunto.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes (para mayores de  años)" || adjunto.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes"))
                    errorDdjjEnfermedades = 1;


            })

            if (cantidadAdjuntosFaltantes <= 2) {
                if (erroresMostrar !== "") {
                    erroresMostrar += ", ";
                }
                erroresMostrar += nombreAdjuntosFaltantes;
            } else {
                if (erroresMostrar !== "") {
                    erroresMostrar += ", ";
                }
                erroresMostrar += "<b>Adjuntos</b>";
            }

            if (errorDdjjEnfermedades === 1) {
                Swal.fire({
                    title: 'Atención',
                    html: 'Debe cargar   la <b>Declaración jurada de enfermedades y patologías preexistentes</b>',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                setLoaderBtnGuardar(false)
                setBloquearBtn(false);
                return false;
            }

            if (erroresMostrar !== "") {
                Swal.fire({
                    title: 'Atención',
                    html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                setLoaderBtnGuardar(false)
                setBloquearBtn(false);
                return false;

            }

        }

        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: localStorage.userType === "10" ? 8 : 1 }).then((value) => {
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);
            setOpenConfirmacionTerminos(false);

            if (value.data.resultado) {

                if (localStorage.userType === '10') {
                    localStorage.setItem('idLegajoTemporal', value.data.result.idLegajoTemporal);
                }
                
                Swal.fire({
                    title: 'Atención',
                    html: localStorage.userType !== "10" ? 'Sr/a profesional, si presiona aceptar su solicitud será enviada al Consejo Profesional' : 'Si presiona aceptar su solicitud será enviada al Consejo Profesional',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                }).then((result) => {

                    if (result.value) {

                        guardarInscripcion()

                    }
                });

            } else {
                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }

        });
    }

    function downloadPDF(pdf, nombrePdf) {
        const linkSource = `${pdf}`;
        const downloadLink = document.createElement("a");
        let extension = "";
        if  (pdf.includes("data:application/pdf") && !nombrePdf.includes(".pdf")) 
            extension = ".pdf"

        downloadLink.href = linkSource;
        downloadLink.download = nombrePdf + extension;
        downloadLink.click();
    }

    return (
        <Grid>
            {ocultarContenido === false ?
                <form onSubmit={handleSubmit(onSubmit)}>

                    <input type="file" name="uploadFile" onChange={changeHandler} style={{ display: 'none' }} ref={hiddenFileInput} />
                    <Typography style={{ fontSize: 14 }} >* <b>Para adjuntar los documentos deberá hacer clic en el ícono <img src={EMPTY_FILE} className={clases.imgIconTexto} />
                        . Si desea adjuntar un documento complementario –vgr. reverso de DNI, reverso de
                        título, etc.- deberá hacer clic en el botón “+”.</b> </Typography>

                    <Grid container spacing={2}>

                        {datos.adjuntos && datos.adjuntos.filter(adjunto => adjunto.fileType.replace(/\d/g, "").trim() === "DNI" || adjunto.fileType.replace(/\d/g, "").trim() === "Foto" || adjunto.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes" || adjunto.fileType.search('-') !== -1).map((adj, index) => {
                            if ((calcularEdad(datos.Nacimiento) >= 50 && adj.fileType.replace(/\d/g, "") === "Declaración jurada de enfermedades y patologías preexistentes") || adj.fileType.replace(/\d/g, "") !== "Declaración jurada de enfermedades y patologías preexistentes")
                                //(adjunto.fileType.replace(/\d/g, "") === "Foto" || adjunto.fileType.replace(/\d/g, "") ==='DNI' || adjunto.fileType.search('-') !== -1)

                                return (
                                    <Grid item xs={6} md={2} key={index} >
                                        <Grid container align='center' direction="column" key={index}>
                                            <Grid item key={index}>
                                                <p className={clases.pIcon} style={{ fontSize: adj.fileType.length < 30 ? 17 : 13, margin: 0 }} title={adj.fileType}>
                                                    <b> {adj.fileType}  {adj.fileType.replace(/\d/g, "") === "Declaración jurada de enfermedades y patologías preexistentes" && <br />}
                                                        {adj.fileType.replace(/\d/g, "") === "Declaración jurada de enfermedades y patologías preexistentes" &&
                                                            "Si usted es mayor a  50 años"}</b>
                                                </p>

                                                {
                                                    (adj.fileType.replace(/\d/g, "").trim() === "DNI" || adj.fileType.search('-') !== -1) ?

                                                        <p className={clases.pIcon} style={{ fontSize: 13, margin: 0 }}
                                                            title={adj.fileType}>
                                                            <b>Anverso y reverso</b>
                                                        </p>
                                                        :

                                                        <br />
                                                }

                                                { ///EN CASO DE NO ESTAR EL ADJUNTO MUESTRO LA IMAGEN PARA SUBIR EL ADJUNTO
                                                    adj.file !== null && adj.file !== '' ?
                                                        <img src={adj.url} className={clases.imgIcon} alt='empty file'
                                                            title='Ver archivo' onClick={() => popitup(adj)} />
                                                        :
                                                        <img src={adj.url}
                                                            className={clases.imgIcon} alt='Subir archivo' name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                                }
                                                {(localStorage.userType !== "1" && bloquearBtn !== true && adj.file !== null && adj.file !== '') &&
                                                    <img src={DEL_FILE} className={clases.delIcon}
                                                        alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                                }
                                                <p className={clases.pFileName} style={{ marginBottom: 0 }} >{adj.fileName.length >= 19 ?
                                                    adj.fileName.substring(0, 14) + '...' : adj.fileName}</p>
                                                <p className={clases.pIcon} style={{ marginTop: 0, marginBottom: 0 }} title={adj.fileType}>

                                                    {((localStorage.userType !== "1" && bloquearBtn !== true) && adj.fileType.replace(/[^0-9]/g, "").length > 0) && adj.fileType !== "Declaración jurada de enfermedades y patologías preexistentes (para mayores de 50 años)" &&
                                                        <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>
                                                    }
                                                    {
                                                        ((localStorage.userType !== "1" && bloquearBtn !== true) &&
                                                            adj.fileType.replace(/[^0-9]/g, "").length === 0
                                                        ) &&
                                                        <Fab title={"Agregar " + adj.fileType} color="secondary" size="small" variant="contained" aria-label="edit" onClick={() => agregarAdjunto(adj.fileType)}><AddIcon />  </Fab>
                                                    }

                                                    {(adj.extension === "application/pdf" || adj.extension === "image/jpeg" || adj.extension === "image/png" || adj.extension === "image/tiff") &&
                                                        <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                    }
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                )
                        })}
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        {datos.adjuntos && datos.adjuntos.filter(adjunto => adjunto.fileType !== "Pago Inscripción" && adjunto.fileType.replace(/\d/g, "").trim() !== "DNI" && adjunto.fileType.replace(/\d/g, "").trim() !== "Foto" && adjunto.fileType.replace(/\d/g, "").trim() !== "Declaración jurada de enfermedades y patologías preexistentes" && adjunto.fileType.search('-') === -1).map((adj, index) => {
                            if ((idEstado !== 2   && adj.fileType !== "Declaracion Jurada" && localStorage.userType !== '1') ||
                                ((idEstado === 2 || idEstado === 6 && datos.Observado === false) && adj.file !== null && adj.file !== '' && (localStorage.userType === "10" || localStorage.userType === "0" )) ||
                                (localStorage.userType === "1" && adj.file !== null && adj.file !== '') )
                                return (
                                    <Grid item xs={6} md={4} key={index}>
                                        <Grid align='center' direction="column" container key={index}>
                                            {adj.fileType.replace(/\d/g, "").trim() !== "Certificado de libre sanción disciplinaria de otros consejos profesionales" &&
                                                <p className={clases.pIcon} style={{ fontSize: adj.fileType.length < 30 ? 17 : 14, marginTop: 10, marginBottom: 0 }}
                                                    title={adj.fileType}><b> {adj.fileType}</b>

                                                </p>
                                            }
                                            {adj.fileType.replace(/\d/g, "") === "Analítico" ?
                                                <Typography style={{ fontSize: '11px', marginLeft: 15 }}><b>En caso de tener promedio de 7 o más puntos y <br /> encontrarse dentro del año de expedición de su título.</b></Typography>
                                                : ''}
                                            {adj.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales" ?

                                                <Grid item md={12} key={index}>
                                                    <Grid container>
                                                        {(adj.fileType.replace(/[^0-9]/g, "").length === 0 && (localStorage.userType !== "10" && localStorage.validacionTitulo !== undefined)) &&
                                                            <Grid item md={12}>
                                                                <Typography style={{ fontSize: 12 }} sx={{ mt: "9px" }} ><b>¿Se encuentra matriculado en otro Consejo Profesional</b> </Typography>
                                                            </Grid>
                                                        }
                                                        {(localStorage.userType !== "10" && localStorage.validacionTitulo === undefined) &&
                                                            <Grid item md={12}>
                                                                <Typography sx={{ mt: localStorage.userType !== "10" && localStorage.validacionTitulo === undefined ? "9px" : 0 }} style={{ fontSize: 13 }} >
                                                                    <b>Certificado de libre sanción disciplinaria  de otros  consejos  profesionales {adj.fileType.replace(/[^0-9]/g, "") > 0 && adj.fileType.replace(/[^0-9]/g, "").trim()}</b>
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        {(adj.fileType.replace(/[^0-9]/g, "").length === 0 && localStorage.userType !== "10" && localStorage.validacionTitulo === undefined) &&
                                                            <Grid item md={12}>
                                                                <Typography style={{ fontSize: 12 }}><b>¿Se encuentra matriculado en otro Consejo Profesional?</b> </Typography>
                                                            </Grid>
                                                        }

                                                        {/* AQUI PASA EN EL CASO DE SER UN ALTA DE TITULOS*/}
                                                        {(localStorage.userType === "10" || localStorage.validacionTitulo !== undefined) &&
                                                            <Grid item md={12}>
                                                                <Grid container>
                                                                    <Grid item md={adj.fileType.replace(/[^0-9]/g, "").length === 0 ? 12 : 12} xs={12}>
                                                                        <Typography sx={{ mt: "14px" }} style={{ fontSize: 13 }} ><b>Certificado de libre sanción disciplinaria en otros Consejos</b>  {adj.fileType.replace(/[^0-9]/g, "") > 0 && adj.fileType.replace(/[^0-9]/g, "").trim()} </Typography>
                                                                    </Grid>
                                                                    {adj.fileType.replace(/[^0-9]/g, "").length === 0 &&
                                                                        <Grid item md={12} xs={12}>
                                                                            <Typography style={{ fontSize: 12 }} ><b>¿Se encuentra matriculado en otro/s Consejo/s con el/los título/s que desea inscribir?</b> </Typography>
                                                                        </Grid>
                                                                    }

                                                                </Grid>
                                                            </Grid>
                                                        }

                                                        <Grid item md={12} xs={12}>

                                                            <Grid container>
                                                                {adj.fileType.replace(/[^0-9]/g, "").length === 0 &&
                                                                    <Grid item md={6}>
                                                                        <FormControl>
                                                                            <RadioGroup
                                                                                row
                                                                                name="position"
                                                                                value={datos.PerteneceOtroConsejo}
                                                                            >
                                                                                <FormControlLabel
                                                                                    value={0}

                                                                                    onChange={e => setDatos({
                                                                                        ...datos,
                                                                                        PerteneceOtroConsejo: Number(e.target.value)
                                                                                    })}
                                                                                    disabled={bloquearBtn}
                                                                                    control={<Radio size="small" />}
                                                                                    label="Si"
                                                                                    labelPlacement="start"
                                                                                />
                                                                                <FormControlLabel
                                                                                    value={1}
                                                                                    disabled={bloquearBtn}
                                                                                    onChange={e => setDatos({
                                                                                        ...datos,
                                                                                        PerteneceOtroConsejo: Number(e.target.value)
                                                                                    })}
                                                                                    control={<Radio size="small" />}
                                                                                    label="No"

                                                                                    labelPlacement="start"
                                                                                />

                                                                            </RadioGroup>
                                                                        </FormControl>

                                                                    </Grid>
                                                                }

                                                                <Grid item md={adj.fileType.replace(/[^0-9]/g, "").length === 0 ? 5 : 12}>

                                                                    {
                                                                        adj.file !== null && adj.file !== '' ?
                                                                            <img src={adj.url}  className={clases.imgIconCargado} alt='empty file' title='Ver archivo' onClick={() => popitup(adj)} />
                                                                            :
                                                                            <img src={adj.url} className={clases.imgIcon} alt='Subir archivo' name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                                                    }

                                                                    {(localStorage.userType !== "1" && bloquearBtn !== true && adj.file !== null && adj.file !== '') &&

                                                                        <img src={DEL_FILE} className={clases.delIcon} alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                                                    }

                                                                    <p className={clases.pFileName} style={{ marginBottom: 0, textAlign: "left" }} >{adj.fileName.length >= 15 ? adj.fileName.substring(0, 11) + '...' : adj.fileName}</p>

                                                                    <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', marginTop: 0 }} title={adj.fileType}>

                                                                        {((localStorage.userType !== "1" && bloquearBtn !== true) && adj.fileType.replace(/[^0-9]/g, "").length > 0) && adj.fileType !== "Declaración jurada de enfermedades y patologías preexistentes (para mayores de 50 años)" &&
                                                                            <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>
                                                                        }

                                                                        {

                                                                            ((localStorage.userType !== "1" && bloquearBtn !== true) &&
                                                                                adj.fileType.replace(/[^0-9]/g, "").length === 0
                                                                            ) &&

                                                                            <Fab title={"Agregar " + adj.fileType} color="secondary" size="small" variant="contained" aria-label="edit" onClick={() => agregarAdjunto(adj.fileType)}><AddIcon />  </Fab>
                                                                        }

                                                                        {adj.extension === "application/pdf" &&
                                                                            <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                                        }
                                                                    </p>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                :

                                                <Grid item>

                                                    {
                                                        adj.file !== null && adj.file !== '' ?
                                                            <img src={adj.url} style={{ marginLeft: 50 }} className={clases.imgIconCargado} alt='empty file' title='Ver archivo' onClick={() => popitup(adj)} />
                                                            :
                                                            <img src={adj.url} className={clases.imgIcon} alt='Subir archivo' name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                                    }
                                                    {(localStorage.userType !== "1" && bloquearBtn !== true && adj.fileType !== "Declaracion Jurada" && adj.file !== null && adj.file !== '') &&

                                                        <img src={DEL_FILE} className={clases.delIcon} alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                                    }
                                                    <p className={clases.pFileName} style={{ whiteSpace: 'pre-wrap', marginBottom: 0 }} >{adj.fileName === "DDJJ" ? "Solicitud de \n inscripción en la matrícula" : adj.fileName.length >= 15 ? adj.fileName.substring(0, 11) + '...' : adj.fileName}</p>

                                                    <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', fontSize: 13, marginTop: 0 }} title={adj.fileType}>

                                                        {((localStorage.userType !== "1" && bloquearBtn !== true) && adj.fileType.replace(/[^0-9]/g, "").length > 0) && adj.fileType !== "Declaración jurada de enfermedades y patologías preexistentes (para mayores de 50 años)" && <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>}
                                                        {
                                                            ((localStorage.userType !== "1" && bloquearBtn !== true && adj.fileType !== "Declaracion Jurada") &&
                                                                adj.fileType.replace(/[^0-9]/g, "").length === 0
                                                            ) &&
                                                            <Fab title={"Agregar " + adj.fileType} color="secondary" size="small" variant="contained" aria-label="edit" onClick={() => agregarAdjunto(adj.fileType)}><AddIcon />  </Fab>
                                                        }

                                                        {(adj.extension === "application/pdf" || adj.extension === "image/jpeg" || adj.extension === "image/png" || adj.extension === "image/tiff") &&
                                                            <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                        }

                                                    </p>

                                                </Grid>}
                                        </Grid>
                                    </Grid>


                                )
                        }
                        )}
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}> </Grid>
                        <Grid item md={6} xs={6} sx={{ mt: 3 }} container>

                            <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue(localStorage.validacionTitulo !== undefined || localStorage.userType === "10" ? "2" : "4")} variant="contained" color="error" >
                                Atras
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                                {
                                    localStorage.userType === "10" &&
                                    <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" onClick={guardarDatosAltaTituloTemporal} color="secondary">
                                        {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                    </Button>
                                }
                                {(localStorage.userType !== "1" && localStorage.userType !== "10") &&
                                    <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary">
                                        {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                    </Button>
                                }
                                {
                                    (localStorage.userType === "1") &&
                                    <Grid>
                                       {

                                        (!datos.exentoTasa)  ?
                                            <Grid>
                                                <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary">
                                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                                </Button>
                                            </Grid>
                                            :
                                            <Grid>
                                                <Button startIcon={<AddIcon />} variant="contained" disabled={(idEstado === 1 || idEstado === 2 || (idEstado === 6 && datos.Observado === false)) && bloqueBtnConfirmarInscripcion === false ? false : true} onClick={enviarInscripcion} color="secondary">
                                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''} Confirmar inscripción
                                                </Button>
                                            </Grid>
                                       } 

                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form >
                : <Grid>

                    <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >* Verifique que los datos ingresados sean los correctos y luego haga click en el botón aceptar para enviar su solicitud de inscripción.</Typography>
                    <PDFViewer style={{
                        width: '100%',
                        height: '65vh'
                    }}  showToolbar={false}>

                        <Pdf datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} />

                    </PDFViewer>
                    <PDFDownloadLink document={<Pdf datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} />} fileName="DDJJ.pdf">
                        {({ blob, url, loading, error }) => {

                            blobDDJJ = blob;
                            urlDDJJ = url;

                        }

                        }
                    </PDFDownloadLink>
                    <Grid container>

                        <Grid item md={6} sx={{ mt: 3 }} container>
                            <Button startIcon={<HighlightOffIcon />} onClick={() => cancelarIncripcionPDF()} variant="contained" color="error" >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">

                                <Button startIcon={<CheckIcon />} disabled={bloquearBtn} variant="contained" onClick={() => guardarDatosInscripcion()} color="secondary">
                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''}  Aceptar
                                </Button>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
                <FilePreview file={selectedFile} onClose={handleClose} opened={openPreview} />


            <ModalTerminosyCondiciones
                onClose={() => setOpenConfirmacionTerminos(false)}
                open={openConfirmarTerminos}
                onSuccess={continuarGuardadoAdjuntos}
            />
        </Grid>

    );
}

