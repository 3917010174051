import axios from 'axios';

import { 
	HEADERS_TOKEN,
	LEYENDAS
} from 'constants/routes/endpoints';



export const getLeyendas = (codLeyendaDdjj) => {
	
	return new Promise((resolve, reject) => {
		return axios({
			url: LEYENDAS+codLeyendaDdjj,
			method: 'GET',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {

				
				return resolve({ data: json.data });
			})
			.catch(err => {
				
				if (err.response.status === 401 || err.response.status === 500){
					window.location.href = `/login`;
				}
			});
	});
};

