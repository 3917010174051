import React, { useState, Fragment, useEffect } from 'react';
import { Grid, Card, CardContent, Link, AlertTitle, Alert, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
    confirmarEmailUsuario
} from 'app/features/Usuarios/usuariosAPI';
// Styles
import 'scss/components/header.scss';


const ConfirmacionCorreoTitulosEspeciales = () => {


    const params = useParams();
    const [mensajeResultado, setMensajeResultado] = useState();
    const [iconoResultado, setIconoResultado] = useState();
    localStorage.setItem('token', "");


    useEffect(() => {
        const result = confirmarEmailUsuario({ email: params.email, token: params.token, tpoTramite : 9 });
        result.then((value) => {

            if (!value.datas.resultado) {

                if (value.datas.codigoError === 0) {
                    setMensajeResultado("Intentelo nuevamente mas tarde.");
                }
                if (value.datas.codigoError === 1) {
                    setMensajeResultado("El email ya se encuentra registrado");
                }
                if (value.datas.codigoError === 2) {

                    setMensajeResultado("El email aún no se encuentra verificado");
                }
                if (value.datas.codigoError === 3) {

                    setMensajeResultado("El email no se encuentra registrado");
                }
                if (value.datas.codigoError === 4) {

                    setMensajeResultado("La autorización es invalida. Por favor solicite uno nueva.");

                }

                setIconoResultado("error");

            } else {
                setIconoResultado("success");
                setMensajeResultado("El  correo   fue verificado correctamente:" + params.email);
            }
        });

    }, [])

    return (

        <Fragment>
            <Grid
                md={10}
                container direction="column"
                className="centrar_contenido"
            >

                <Card>
                    <CardContent align='center'>

                        <Alert severity={iconoResultado} align='center'>
                            <AlertTitle> {mensajeResultado} </AlertTitle>

                        </Alert>

                        {iconoResultado !== "error" ?

                            <Grid item sx={{ mt: 2 }} md={12} xs={12} className="centrar_contenido"  >
                              
                                Para realizar la solicitud de inscripción haga
                                <Link sx={{ ml: 1, mr:1 }} href="/logininscripciontitulosespeciales">
                                    Click Aqui 
                                </Link>
                                 e ingrese nuevamente su correo electrónico y contraseña.
                                
                            </Grid> :
                            <Grid item sx={{ mt: 2 }} md={12} xs={12} className="centrar_contenido"  >

                                Para solicitar una nueva autorización hacer
                                <Link sx={{ ml: 1 }} href="/GenerarToken">
                                    Click Aqui
                                </Link>

                            </Grid>}
                    </CardContent>
                </Card>

            </Grid>
        </Fragment>

    );

};

export default ConfirmacionCorreoTitulosEspeciales;
