import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Button, Card, CardContent, Fab, Grid, Modal, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import SchoolIcon from '@mui/icons-material/School';
import { useForm } from "react-hook-form";
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector, useDispatch } from 'react-redux';
import { titulosList, getTitulosAsync } from 'app/features/Titulos/titulosSlice';
import { universidadesList, getUniversidadesAsync } from 'app/features/Universidades/universidadesSlice';
import { EMPTY_FILE } from 'constants/icons';
import { ADJUNTOS_BASE } from 'constants/constants';

const useStyles = makeStyles(theme => ({
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    centrarContenido: {
        display: 'flex',
        justifyContent: 'center'
    },
    backGroundBox: {
        backgroundColor: '#00b347!important'
    },
    deleteFab: {
        backgroundColor: 'red!important'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    },
    modales: {
        overflow: 'scroll',

    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    color_rojo: {
        color: 'red'
    },
    mapContainer: {
        height: '100vh'
    },
    mapElements: {
        height: '100%'
    }
}));

export const Titulos = ({ datos, bloquearBtn, setDatos, setValue, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, idEstado, titulosProfesional, obtenerEstadoOperacion, obtenerOperacion, setMatriculas }) => {
    const clases = useStyles();
    const dispatch = useDispatch();
    const [datosTitulo, setDatosTitulo] = useState({
        id: 0,
        nombreTitulo: '',
        codTitulo: 0,
        codUniversidad: 0,
        nombreUniversidad: '',
        fechaUltimaMateria: '',
        entregoTitulo: true,
        fechaEmisionTitulo: '',
        promedio: '',
    });

    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const Titulos = useSelector(titulosList);
    const Universidades = useSelector(universidadesList);
    const [titulosSelect, setTitulosSelect] = useState({ label: '', value: 0 });
    const [universidadesSelect, setUniversidadesSelect] = useState({ label: '', value: 0 });
    const [editarRegistro, setEditarRegistro] = useState(0);
    const [mostrarGuardar, setMostrarGuardar] = useState({ display: "none" });
    const [editarRegistroIndex, setEditarRegistroIndex] = useState(0);
    const [cargarTitulos, setCargarTitulos] = useState(0);
    const [titulosAgregarAutocomplete, setTitulosAgregarAutocomplete] = useState([]);
    const [modificoTitulo, setModificoTitulo] = useState(false);

    const handleOpen = (index) => {
        setErrorFecha({ display: "none" })

        //si pasa por aca es que esta editando
        if (!isNaN(index)) {

            titulosObjeto = [];
       
            Titulos.map((titulo) => {
                let agregarTitulo = 0;


                titulosAgregados.map(tituloAgregado => {
                    if (titulo.descripcion.trim() === tituloAgregado.nombreTitulo)
                        agregarTitulo = 1;

                })

                if (localStorage.userType === "10" && agregarTitulo === 0) {
                    titulosProfesional.map(profTit => {
                        if (titulo.codTitulo === profTit.codTitulo)
                        agregarTitulo = 1;

                    })
                }

                if (agregarTitulo === 0 || titulo.descripcion.trim() === titulosAgregados[index].nombreTitulo)
                    titulosObjeto.push({ "label": titulo.descripcion.trim(), "value": titulo.codTitulo })

            })
            setTitulosAgregarAutocomplete(titulosObjeto);
            setDatosTitulo({
                nombreTitulo: titulosAgregados[index].nombreTitulo,
                codTitulo: titulosAgregados[index].codTitulo,
                codUniversidad: titulosAgregados[index].codUniversidad,
                nombreUniversidad: titulosAgregados[index].nombreUniversidad,
                fechaUltimaMateria: titulosAgregados[index].fechaUltimaMateria,
                entregoTitulo: true,
                fechaEmisionTitulo: titulosAgregados[index].fechaEmisionTitulo,
                promedio: titulosAgregados[index].promedio

            })
            setEditarRegistroIndex(index);
            setEditarRegistro(1);
            setUniversidadesSelect({
                label: titulosAgregados[index].nombreUniversidad,
                value: titulosAgregados[index].codUniversidad
            });
            setTitulosSelect({
                label: titulosAgregados[index].nombreTitulo,
                value: titulosAgregados[index].codTitulo
            });


        } else {
            titulosObjeto = [];
            Titulos.map((titulo, index) => {
                let agregarTitulo = 0;
                titulosAgregados.map((tituloAgregado, index) => {
                    if (titulo.descripcion.trim() === tituloAgregado.nombreTitulo)
                        agregarTitulo = 1;

                })
                
                if (localStorage.userType === "10" && agregarTitulo === 0) {
                    titulosProfesional.map(profTit => {
                        if (titulo.codTitulo === profTit.codTitulo)
                        agregarTitulo = 1;

                    })
                }
                if (agregarTitulo === 0)
                    titulosObjeto.push({ "label": titulo.descripcion.trim(), "value": titulo.codTitulo })

            })

            setTitulosAgregarAutocomplete(titulosObjeto);

            setDatosTitulo({
                id: 0,
                nombreTitulo: '',
                codTitulo: 0,
                codUniversidad: 0,
                nombreUniversidad: '',
                entregoTitulo: true,
                fechaEmisionTitulo: '',
                fechaUltimaMateria: '',
            })
            setUniversidadesSelect({
                label: '', value: 0
            });
            setTitulosSelect({
                label: '', value: 0
            });
            setEditarRegistro(0);
        }
        //  alert(index);
        setOpen(true);
    }


    let universidadesObjeto = [];
    let titulosObjeto = [];
    Universidades.map((universidad, index) =>

        universidadesObjeto.push({ "label": universidad.nombre.trim(), "value": universidad.codUniversidad })
    )

    if (cargarTitulos === 1) {
        Titulos.map((titulo, index) =>

            titulosObjeto.push({ "label": titulo.descripcion.trim(), "value": titulo.codTitulo })
        )
        setTitulosAgregarAutocomplete(titulosObjeto);
        setCargarTitulos(0)
    }



    const statusCheck = (checked) => {

        setDatosTitulo({
            ...datosTitulo,
            entregoTitulo: checked
        });
    }

    const ingresaUniversidad = (event, value) => {

        // console.log(value);
        if (value !== null) {
            setDatosTitulo({
                ...datosTitulo,
                codUniversidad: value.value,
                nombreUniversidad: value.label
            });
        } else {
            setDatosTitulo({
                ...datosTitulo,
                codUniversidad: 0,
                nombreUniversidad: ''
            });
        }

    }



    const ingresaTitulo = (evt, value) => {
        const numbers = /\d/;
        // if (numbers.test(evt.target.value)) {
        //     return false;
        // }
        if (value !== null) {
            setDatosTitulo({
                ...datosTitulo,
                codTitulo: value.value,
                nombreTitulo: value.label
            });
        } else {
            setDatosTitulo({
                ...datosTitulo,
                codTitulo: 0,
                nombreTitulo: ''
            });
        }
        setModificoTitulo(true);
        setBtnGuardarTituloEstado(false);
    }

    const [btnGuardarTituloEstado, setBtnGuardarTituloEstado] = useState(false)
    const [errorFechas, setErrorFecha] = useState({ display: "none" });
    const [errorFechasTexto, setErrorFechaText] = useState('');
    const inputHandleChange = (evt) => {

        const { name, value } = evt.target;

        if (evt.target.name === "fechaUltimaMateria" || evt.target.name === "fechaEmisionTitulo") {

            var hoy = new Date();
            var fechaFormulario = new Date(evt.target.value);
            let anioEmision = parseInt(value.substring(0,4));


            // Comparamos solo las fechas => no las horas!!
            hoy.setHours(0, 0, 0, 0);  // Lo iniciamos a 00:00 horas

            if (hoy <= fechaFormulario) {


                var mes = (hoy.getMonth() + 1);
                if ((hoy.getMonth() + 1) < 10) {
                    mes = "0" + (hoy.getMonth() + 1)
                }
                //hoy.getFullYear() + "-" + mes + "-" +  hoy.getDate()
                setDatosTitulo({
                    ...datosTitulo,
                    [evt.target.name]: ''
                });
                setErrorFechaText("La fecha ingresada no debe ser posterior a la fecha actual");
                setErrorFecha({ display: "" })
                return false;
            } else if (evt.target.name === "fechaUltimaMateria") {
                if (datosTitulo.fechaEmisionTitulo !== "") {
                    if (new Date(evt.target.value) > new Date(datosTitulo.fechaEmisionTitulo)) {
                        setErrorFechaText("La fecha última materia no puede  ser posterior a la fecha  emisión de título");
                        setErrorFecha({ display: "" })

                        setDatosTitulo({
                            ...datosTitulo,
                            [evt.target.name]: ''
                        });
                        return false;
                    }
                }
            // } else if ( anioEmision < 2013 ) {

            //     setErrorFechaText("La fecha emisión de título no puede ser anterior a la fecha 2013");
            //     setErrorFecha({ display: "" })

            //     setDatosTitulo({
            //         ...datosTitulo,
            //         [evt.target.name]: ''
            //     });
            //     return false;

            } else if (datosTitulo.fechaUltimaMateria !== "") {

                    if (new Date(evt.target.value) < new Date(datosTitulo.fechaUltimaMateria)) {
                        setErrorFechaText("La fecha última materia no puede  ser posterior a la fecha  emisión de título");
                        setErrorFecha({ display: "" })
                        setBtnGuardarTituloEstado(true)
                        setDatosTitulo({
                            ...datosTitulo,
                            [evt.target.name]: evt.target.value
                        });
                        return false;
                    }
                
            } 

        }

        if (name === 'promedio') {

            let newValue = value.replace('.', ',');
            
            const regex = /^[0-9]*[.,]?[0-9]*$/;
            
            if (!regex.test(newValue)) {
                setDatosTitulo(prevState => ({
                    ...prevState,
                    [name]: ''
                }));
                return;
            }

            const parts = newValue.split(',');
            if (parts.length > 1) {
                parts[1] = parts[1].slice(0, 2);
                newValue = parts.join(',');
            }
            
            const numericValue = parseFloat(newValue);
            if (numericValue < 1 || numericValue > 10) {
                return; 
            }

            if (numericValue === 10 && newValue.includes(',')) {
                return;
            }
    
            setDatosTitulo(prevState => ({
                ...prevState,
                [name]: newValue
            }));

            return
        }
       
        setErrorFecha({ display: "none" })
        setDatosTitulo({
            ...datosTitulo,
            [evt.target.name]: evt.target.value
        });

        setBtnGuardarTituloEstado(false)
    }


    const validacionAnio = (string) => {

        var info = string.split('-');
        return info[0];
    }

    const [anchoPantalla, setAnchoPantalla] = useState(0);

    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }

    window.addEventListener("resize", handleResize);

    useEffect(() => {
        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);
        if (Titulos.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getTitulosAsync());
        }
        if (Universidades.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getUniversidadesAsync());
        }

        setCargarTitulos(1);
    }, [])

    const [titulosAgregados, setTitulosAgregados] = useState([]);
    const [titulosGuardados, setTitulosGuardados] = useState(0);
    if (titulosGuardados === 0) {


        if (Array.isArray(datos.titulos)) {

            setTitulosAgregados(datos.titulos);
            if (datos.titulos.length > 0) {
                setMostrarGuardar({ display: "" });
            }
        }
        setTitulosGuardados(1);
    }

    const eliminarAdjuntoTitulo = (id, nombreTitulo) => {
        return datos.adjuntos.filter(adj => adj.id !== id && adj.fileType.replace(/\d/g, "").trim() !== "Título - " + nombreTitulo.replace(/\d/g, "").trim());
    }

    const eliminarTitulo = (index) => {


        Swal.fire({
            title: 'Atención',
            html: '¿Esta seguro que desea eliminar este título?',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText:
                'Aceptar',
            cancelButtonText:
                'Cancelar',
            allowOutsideClick: true
        }).then((result) => {

            if (result.value) {

                if (titulosAgregados.length === 1) {
                    setTitulosAgregados([])
                    // setMostrarGuardar({display:"none"});
                } else {
                    setTitulosAgregados(titulosAgregados.filter(item => item.id !== index))
                }
                let arrFiles = [];
                let agregado = 0;

                agregado = 0;
                datos.adjuntos.map((adj) => {
                    if (adj.fileType.replace(/\d/g, "").trim() === "DNI" || adj.fileType.replace(/\d/g, "").trim() === "Foto" || adj.fileType.replace(/\d/g, "").trim() === "Certificado"
                        || adj.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales" || adj.fileType.replace(/\d/g, "").trim() === "Declaracion Jurada"
                        || adj.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes"
                        || adj.fileType.replace(/\d/g, "").trim() === "Analítico" || adj.fileType.replace(/\d/g, "").trim() === "Pago Inscripción") {
                        arrFiles.push({ fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file, size: adj.size, url: adj.url });
                    } else {

                        titulosAgregados.filter(item => item.id !== index).map((tit) => {
                            if ('Título - ' + tit.nombreTitulo.trim() === adj.fileType.replace(/\d/g, "").trim()) {
                                agregado = 1;
                                arrFiles.push({ fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file, size: adj.size, url: adj.url });
                            }
                        })
                    }

                })

                let adjuntosOrdenados = 0;
                let adjuntosArray = [];
                let titulosTotales = 0;
                let dniTotales = 0;
                let fotoTotales = 0;
                let analiticoTotales = 0;
                let libreSancion = 0;
                let declaracionJuradaEnfermedadesTotales = 0;
                let pagoTotales = 0;
                //console.log(arrFiles)
                arrFiles.map((adj, index) => {

                    if (adj.fileType.search('-') >= 0)
                        titulosTotales++;

                    if (adj.fileType.indexOf('DNI') >= 0)
                        dniTotales++;

                    if (adj.fileType.indexOf('Foto') >= 0)
                        fotoTotales++;

                    if (adj.fileType.indexOf('Pago') >= 0)
                        pagoTotales++;

                    if (adj.fileType.indexOf('Analítico') >= 0)
                        analiticoTotales++;

                    if (adj.fileType.indexOf('enfermedades') >= 0)
                        declaracionJuradaEnfermedadesTotales++;

                    if (adj.fileType.indexOf('sanción') >= 0)
                        libreSancion++;

                });
                //titulosTotales = titulosTotales - 1;
                for (let i = 0; i < arrFiles.length;) {
                    arrFiles.map((adj, index) => {

                        if (
                            (adjuntosOrdenados >= 0 && adjuntosOrdenados < dniTotales && adj.fileType.replace(/\d/g, "") === "DNI") ||
                            (adjuntosOrdenados >= dniTotales && adjuntosOrdenados < (dniTotales + fotoTotales) && adj.fileType.replace(/\d/g, "") === "Foto") ||
                            (adjuntosOrdenados >= (dniTotales + fotoTotales) && adjuntosOrdenados < (dniTotales + fotoTotales + pagoTotales) && adj.fileType.replace(/\d/g, "") === "Pago Inscripción") ||
                            (adjuntosOrdenados >= (dniTotales + fotoTotales + pagoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales) && adj.fileType.search('-') >= 0) ||
                            (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion) && adj.fileType.indexOf('sanción') >= 0) ||
                            (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales) && adj.fileType.indexOf('enfermedades') >= 0) ||
                            (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales) && adj.fileType.indexOf('Analítico') >= 0) ||
                            (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales + 1) && adj.fileName === "DDJJ") && arrFiles.length > i) {
                            adjuntosArray.push(adj);
                            adjuntosOrdenados++;
                            i++
                        }

                    });
                }


                setDatos({
                    ...datos,
                    titulos: titulosAgregados.filter(item => item.id !== index),
                    adjuntos: arrFiles
                })

            }
        });
    }

    const agregarTituloArray = () => {

        let tituloAnterior = "";
        let tituloNuevo = "";
        //let tituloNuevo = "";

        if (editarRegistro === 1) {
            tituloAnterior = titulosAgregados[editarRegistroIndex].nombreTitulo;
            tituloNuevo = datosTitulo.nombreTitulo;

            if (modificoTitulo) {
                datos.adjuntos = eliminarAdjuntoTitulo(ADJUNTOS_BASE.TITULO.id, tituloAnterior);
                datos.adjuntos.push({ id: ADJUNTOS_BASE.TITULO.id, fileType: 'Título - ' + datosTitulo.nombreTitulo.trim(), fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE })
            }
            
            titulosAgregados[editarRegistroIndex].codUniversidad = datosTitulo.codUniversidad;
            titulosAgregados[editarRegistroIndex].fechaUltimaMateria = datosTitulo.fechaUltimaMateria;
            titulosAgregados[editarRegistroIndex].nombreTitulo = datosTitulo.nombreTitulo;
            titulosAgregados[editarRegistroIndex].codTitulo = datosTitulo.codTitulo;
            titulosAgregados[editarRegistroIndex].nombreUniversidad = datosTitulo.nombreUniversidad;
            titulosAgregados[editarRegistroIndex].entregoTitulo = true;
            titulosAgregados[editarRegistroIndex].fechaEmisionTitulo = datosTitulo.fechaEmisionTitulo;
            titulosAgregados[editarRegistroIndex].promedio = datosTitulo.promedio;

            setTitulosAgregados(titulosAgregados)
            setDatos({
                ...datos,
                titulos: titulosAgregados
            })

        } else {

            datos.adjuntos.push({ fileType: 'Título - ' + datosTitulo.nombreTitulo.trim(), fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE })

            const listaTitulos = [...titulosAgregados, {
                id: 1 + (Math.random() * (999999 - 1)),
                codUniversidad: datosTitulo.codUniversidad,
                fechaEmisionTitulo: datosTitulo.fechaEmisionTitulo,
                nombreTitulo: datosTitulo.nombreTitulo,
                codTitulo: datosTitulo.codTitulo,
                nombreUniversidad: datosTitulo.nombreUniversidad,
                entregoTitulo: true,
                fechaUltimaMateria: datosTitulo.fechaUltimaMateria,
                promedio: datosTitulo.promedio

            }]

            datos.titulos.push({
                id: 1 + (Math.random() * (999999 - 1)),
                codUniversidad: datosTitulo.codUniversidad,
                fechaEmisionTitulo: datosTitulo.fechaEmisionTitulo,
                nombreTitulo: datosTitulo.nombreTitulo,
                codTitulo: datosTitulo.codTitulo,
                nombreUniversidad: datosTitulo.nombreUniversidad,
                entregoTitulo: true,
                fechaUltimaMateria: datosTitulo.fechaUltimaMateria,
                promedio: datosTitulo.promedio
            })

            setTitulosAgregados(listaTitulos)
        }

        actualizarAdjuntos(tituloAnterior, tituloNuevo);
        setMostrarGuardar({ display: "" });
        setOpen(false);
    }

    const [agregarTituloGuardados, setAgregarTituloGuardados] = useState(0);

    const actualizarAdjuntos = (tituloAnterior, tituloNuevo) => {
        let adjuntosOrdenados = 0;
        let adjuntosArray = [];
        let titulosTotales = 0;
        let dniTotales = 0;
        let fotoTotales = 0;
        let analiticoTotales = 0;
        let libreSancion = 0;
        let declaracionJuradaEnfermedadesTotales = 0;
        let pagoTotales = 0;

        datos.adjuntos.map((adj, index) => {

            if (adj.fileType.search('-') >= 0)
                titulosTotales++;

            if (adj.fileType.indexOf('DNI') >= 0)
                dniTotales++;

            if (adj.fileType.indexOf('Foto') >= 0)
                fotoTotales++;

            if (adj.fileType.indexOf('Pago') >= 0)
                pagoTotales++;

            if (adj.fileType.indexOf('Analítico') >= 0)
                analiticoTotales++;

            if (adj.fileType.indexOf('enfermedades') >= 0)
                declaracionJuradaEnfermedadesTotales++;

            if (adj.fileType.indexOf('sanción') >= 0)
                libreSancion++;

        });

        for (let i = 0; i < datos.adjuntos.length;) {
            datos.adjuntos.map((adj, index) => {

                if (
                    (adjuntosOrdenados >= 0 && adjuntosOrdenados < dniTotales && adj.fileType.indexOf('DNI') >= 0) ||
                    (adjuntosOrdenados >= dniTotales && adjuntosOrdenados < (dniTotales + fotoTotales) && adj.fileType.indexOf('Foto') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales) && adjuntosOrdenados < (dniTotales + fotoTotales + pagoTotales) && adj.fileType.indexOf('Pago') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + pagoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales) && adj.fileType.search('-') >= 0) ||
                    (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion) && adj.fileType.indexOf('sanción') >= 0) ||
                    (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales) && adj.fileType.indexOf('enfermedades') >= 0) ||
                    (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales) && adj.fileType.indexOf('Analítico') >= 0) ||
                    (adjuntosOrdenados >= (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales + fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales + 1) && adj.fileName === "DDJJ") && datos.adjuntos.length > i) {


                    if (tituloAnterior !== "" && adj.fileType.replace(/\d/g, "") === "Título - " + tituloAnterior) {
                        adj = { fileType: "Título - " + tituloNuevo, fileName: adj.fileName, extension: adj.extension, file: adj.adj, size: adj.size, url: adj.url, saved: true }
                    }


                    adjuntosArray.push(adj);
                    adjuntosOrdenados++;
                    i++
                }

            });

        }

        setDatos({
            ...datos,
            adjuntos: adjuntosArray
        })

    }

    const guardarContinuar = () => {
        setDatos({
            ...datos,
            titulos: titulosAgregados
        })
        setLoaderBtnGuardar(true)
        setBloquearBtn(true);

        // let adjuntosTemporales = [];
        // datos.adjuntos.map((adj, i) => {
        //     adjuntosTemporales.push({
        //         url: adj.url,
        //         file: adj.file,
        //         fileType: adj.fileType,
        //         fileName: adj.fileName
        //     })
        // })

        // datos.adjuntos.map((adj, i) => {
        //     datos.adjuntos.filter(c => c.fileType === adj.fileType)[0].file = '';
        //     datos.adjuntos.filter(c => c.fileType === adj.fileType)[0].url = '';
        // })


        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: localStorage.userType === "10" ? 8 : 1 }).then((value) => {
            setMatriculas(value.data);
            if(localStorage.userType !== "10") {
                    setLoaderBtnGuardar(false)
                    setBloquearBtn(false);
                    if (value.data.resultado) {
                            setValue("5"); 
                    } else {
                        Swal.fire({
                            title: 'Atención',
                            html: value.data.mensaje !== '' && value.data.mensaje !== null ? value.data.mensaje : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                            icon: 'warning',
                            showCloseButton: true,
                            showCancelButton: false,
                            confirmButtonText:
                            'Aceptar',
                            cancelButtonText:
                            'Cancelar',
                            allowOutsideClick: true
                        })
                        return false;
                    }
            }else {
                localStorage.setItem('idLegajoTemporal', value.data.result.idLegajoTemporal);
                    obtenerEstadoOperacion().then(() => {
                            obtenerOperacion()
                            setLoaderBtnGuardar(false)
                            setBloquearBtn(false);
                            setValue("3");
                    })
            }


        });
    }


    const convertDateFormat = string => {

        var info = string.split('-');
        
        if(info[2] + '/' + info[1] + '/' + info[0] === "01/01/0001"){
            return "";
        }

            return info[2] + '/' + info[1] + '/' + info[0];
        
    }
    const convertirFechaTituloProfesional = string => {

        string = string.slice(0, string.search('T'));
        var info = string.split('-');
        return info[2] + '/' + info[1] + '/' + info[0];
    }

    const [openAutocompleteUniversidad, setOpenAutocompleteUniversidad] = useState(false);
    const [openAutocompleteTitulos, setOpenAutocompleteTitulos] = useState(false);
    const { handleSubmit, formState: { errors } } = useForm();
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    return (
        <Fragment>
            <Typography sx={{ mb: 1 }} >* <b>Para cargar su/s título/s deberá hacer clic en el botón
                “+”. Si desea cargar más de un título deberá hacer clic nuevamente en el botón “+”.</b>.</Typography>
            <Grid container spacing={2} >
              
                {titulosAgregados.map((tituloAgregado, index) =>
                    <Grid item md={6} key={index}>
                        <Card variant='custom' sx={{ boxShadow: 5 }}>
                            <CardContent>
                                <Grid container>
                                    <Grid item md={12} className={clases.centrarContenido}>
                                        <h3 style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}><SchoolIcon />  {tituloAgregado.nombreTitulo}</h3>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        Universidad: <b>{tituloAgregado.nombreUniversidad}</b>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        Promedio: <b>{tituloAgregado.promedio}</b>
                                    </Grid>

                                    <Grid item md={8} sx={{ mb: 2 }}>
                                        Fec. Última Materia : <b>{tituloAgregado.fechaUltimaMateria !== null && convertDateFormat(tituloAgregado.fechaUltimaMateria)}</b>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        Fec. Emisión de Título: <b>{tituloAgregado.fechaEmisionTitulo !== null && convertDateFormat(tituloAgregado.fechaEmisionTitulo)}</b>
                                    </Grid>

                                    {(localStorage.userType !== "1" && bloquearBtn !== true) &&
                                        <Grid item md={12} className={clases.floatRight}>
                                            <Fab className={clases.deleteFab} sx={{ mr: 2, color: "white" }} aria-label="edit" onClick={() => eliminarTitulo(tituloAgregado.id)}>
                                                <DeleteIcon />
                                            </Fab>
                                            <Fab color="secondary" aria-label="edit" onClick={() => handleOpen(index)}>
                                                <EditIcon />
                                            </Fab>
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {(localStorage.userType !== "1" && bloquearBtn !== true) &&
                    <Grid item md={12} className={clases.floatRight}>
                        <Fab color="secondary" aria-label="edit" onClick={handleOpen}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                }
            </Grid>

            <Modal
                open={open}
                className={clases.modales}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className={clases.modal}>
                    <CardContent>
                        <Grid container style={{ marginBottom: 15 }}>

                            <Grid item container justifyContent="flex-end" >
                                <Typography sx={{}} ></Typography>

                                <CloseIcon style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                            </Grid>
                        </Grid>
                        <form onSubmit={handleSubmit(agregarTituloArray)}>
                            <Grid container spacing={2}>
                                <Grid item md={8} xs={12}>

                                    <Autocomplete
                                        open={openAutocompleteTitulos}
                                        name='titulo'
                                        onClose={() => setOpenAutocompleteTitulos(false)}
                                        onChange={ingresaTitulo}
                                        options={titulosAgregarAutocomplete}
                                        noOptionsText={'No se encontraron Títulos'}
                                        defaultValue={titulosSelect}
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(params) => <TextField {...params} onClick={() => setOpenAutocompleteTitulos(true)} fullWidth required label="Titulo" />}
                                    />
                                    <Typography sx={{ mb: 1, ml: 1, fontSize: anchoPantalla < 700 ? 12 : 16 }} ><b>Si la denominación de su título no coincide con los detallados, contáctese con el Sector Matrículas  al 0221 – 441 4100. </b></Typography>

                                </Grid>
                                                            
                                <Grid item md={4} xs={12}>
                                    <TextField 
                                        label="Promedio"
                                        name='promedio'
                                        variant="outlined"
                                        value={datosTitulo.promedio}
                                        onChange={inputHandleChange}
                                        required
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item md={12} xs={12}>


                                    <Autocomplete
                                        open={openAutocompleteUniversidad}

                                        onClose={() => setOpenAutocompleteUniversidad(false)}
                                        name='universidad'
                                        onChange={ingresaUniversidad}
                                        options={universidadesObjeto}
                                        noOptionsText={'No se encontraron Universidades'}
                                        defaultValue={universidadesSelect}
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(params) => <TextField {...params} onClick={() => setOpenAutocompleteUniversidad(true)} fullWidth required label="Universidad" />}
                                    />
                                    <Typography sx={{ mb: 1, ml: 1, fontSize: anchoPantalla < 700 ? 12 : 16 }} ><b>Si no se encuentra la universidad, contáctese con el Sector Matrículas al 0221 – 441 4100.</b></Typography>

                                </Grid>

                                

                                <Grid item md={6} xs={12}>
                                    <TextField label="Fec. Emisión de Título"
                                        name='fechaEmisionTitulo'
                                        onChange={inputHandleChange}
                                        required
                                        value={datosTitulo.fechaEmisionTitulo}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextField label="Fec. Última Materia"
                                        name='fechaUltimaMateria'
                                        onChange={inputHandleChange}
                                        required
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={datosTitulo.fechaUltimaMateria}
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Typography className={clases.color_rojo} style={errorFechas} >{errorFechasTexto}</Typography>
                                </Grid>
                                <Grid item md={12} className={clases.floatRight}>
                                    <Button variant="contained" color="info" disabled={btnGuardarTituloEstado} type="submit" startIcon={<CheckIcon />}>
                                        Confirmar
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Modal>
            <Grid container>
                <Grid item md={12}> </Grid>
                <Grid item md={6} xs={6} sx={{ mt: 3 }} container>
                   
                        <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue(localStorage.userType === "10" || localStorage.validacionTitulo === "1" ? "1" :"3")} variant="contained" type="submit" color="error" >
                            Atras
                        </Button>
                   
                </Grid>
                <Grid item md={6} xs={6}>
                    <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                        <Button disabled={bloquearBtn} style={mostrarGuardar} endIcon={<ArrowForwardIosIcon />} onClick={() => guardarContinuar()} variant="contained" type="submit" color="secondary" >

                            {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                        </Button>
                        {/* <Button endIcon={<ArrowForwardIosIcon />} onClick={() => setValue("4")} variant="contained" type="submit" color="info" >
                            Continuar
                        </Button> */}
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}
