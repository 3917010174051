import axios from 'axios';
import { 
	HEADERS_TOKEN,
	TIPO_CALLE
} from 'constants/routes/endpoints';

export const getTipoCalle = () => {

	return new Promise((resolve, reject) => {
		return axios({
			url: TIPO_CALLE,
			method: 'GET',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 500){
					window.location.href = `/login`;
				}
			});
	});
};