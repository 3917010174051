import { useState } from 'react';
import { Button, Tab, Grid, Box, Paper, Typography } from '@mui/material';
import { SeleccionDeMedioDePago } from './seleccionDeMedioDePago';
import { PagoBrick } from './pagoBrick';
import { PagoQR } from './pagoQR';
import { PagoCompletado } from './pagoCompletado';
import { PagoPendiente } from './pagoPendiente';

export const DerechoDeInscripcion = ({ datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, ocultarContenido, setOcultarContenido, resultadoPagoTasa, estadoOperacion, obtenerResultadoDePago, setEstadoOperacion, setResult, montoDeTasa, setMedioDePago, medioDePago}) => {

    return (
    <Grid>
        {
            estadoOperacion === 1 ? 
             <PagoPendiente obtenerResultadoDePago={obtenerResultadoDePago} setEstadoOperacion={setEstadoOperacion}/>
            :
            (!resultadoPagoTasa ? 
                (() => {
                    switch(medioDePago) {
                        case "0":
                            return <SeleccionDeMedioDePago setMedioDePago={setMedioDePago} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} bloquearBtn={bloquearBtn} setValue={setValue}/>
                        case "1":
                            return <PagoBrick setResult={setResult} montoDeTasa={montoDeTasa} setMedioDePago={setMedioDePago} />
                        case "2":
                            return <PagoQR setMedioDePago={setMedioDePago} setResult={setResult}/>
                    }
                })()
                : 
                <PagoCompletado datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setValue} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} resultadoPagoTasa={resultadoPagoTasa}  estadoOperacion={estadoOperacion} />
            )
         }
    </Grid>
  )
}
