import React, { useEffect, useState } from 'react';
import { Button, Grid, Card, CardContent, Typography, Box, FormControl, Fab, CircularProgress, FormLabel, Paper } from '@mui/material';
import Swal from 'sweetalert2';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getProfesionalValidarCuit } from 'app/features/Profesionales/profesionalesAPI';
import EditIcon from '@mui/icons-material/Edit';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import { tipoDocumentoList } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { fetchFotoProfesionalAsync, setCambiaDNI, setCambiaFoto, setFotoNuevaFullSize } from 'app/features/FotoProfesional/editarDatosProfesionalSlice';
import UploadIcon from '@mui/icons-material/Upload';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ModalNuevaFoto from './ModalNuevaFoto';
import MiniaturaFotoNueva from './MiniaturaFotoNueva';
import { ESTADOS_TRAMITE, TIPOS_USUARIO } from 'constants/constants';
import ModalEditarCuit from './ModalEditarCuit';
import { nuevoAdjunto } from 'utils/adjuntosLogic';
import { isNullOrEmpty } from 'utils/utils';

const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: 'green'
    }
});

function DatosPersonales({
    datos,
    legajoProfesional,
    bloquearBtn,
    ObrasSociales,
    setDatos,
    setValue,
    loaderBtnGuardar,
    setLoaderBtnGuardar,
    setBloquearBtn,
    idEstado,
    setLegajoTemporal }) {
    const dispatch = useDispatch();
    const TiposDocumento = useSelector(tipoDocumentoList);
    const editarFotoSlice = useSelector(state => state.editarDatosProfesionalReducer);

    const [openNuevaFoto, setOpenNuevaFoto] = React.useState(false);
    const hiddenFileInput = React.useRef(null);

    const changeHandler = (evt) => {

        if (evt.target.files.length >= 1) {
            const tipo = evt.target.files[0].type;
            const size = ((evt.target.files[0].size / 1000) / 1000).toFixed(4);

            if (tipo !== 'image/jpeg' && tipo !== 'image/png') {
                Swal.fire({
                    title: 'Atención',
                    html: 'El tipo de archivo no es correcto, se admite unicamente: jpg, jpeg o png.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else if ((Number(size) > 5)) {
                Swal.fire({
                    title: 'Atención',
                    html: 'El tamaño del archivo  no debe superar 5MB.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else {
                const foto = evt.target.files[0];

                const reader = new FileReader();

                reader.onload = () => {
                    dispatch(setFotoNuevaFullSize(reader.result));
                    setOpenNuevaFoto(true);
                }
                reader.readAsDataURL(foto);
            }
        }
    }

    const handlecambiaDocumento = (
        event,
        newcambiaDocumento,
    ) => {
        dispatch(setCambiaDNI(newcambiaDocumento));
    };

    const handlecambiaFoto = (
        event,
        newcambiaFoto,
    ) => {
        dispatch(setCambiaFoto(newcambiaFoto));
    };

    useEffect(() => {

        let legajo = localStorage.idLegajo;

        if (localStorage.userType !== TIPOS_USUARIO.PROFESIONAL_EDITAR_DATOS.toString()) {
            legajo = legajoProfesional;
        }

        dispatch(fetchFotoProfesionalAsync(legajo));
    }, [legajoProfesional, dispatch]);



    let obraSocialObjeto = [];
    let obraSocialNombre = "";
    ObrasSociales.map((obraSocial, index) => {
        if (obraSocialNombre.toUpperCase() !== obraSocial.sigla.trim().toUpperCase())
            obraSocialObjeto.push({ label: obraSocial.sigla.trim(), value: obraSocial.tpoOSocial })

        obraSocialNombre = obraSocial.sigla.trim();
    })


    const EstadoCivilList = useSelector(estadoCivilList);
    const validarDomicilios = () => {
        let errorDomicilio = 0;
        datos.domicilios.map((domicilio, index) => {

            if (domicilio.calle === "" || domicilio.provincia === "" || domicilio.provincia === null || domicilio.localidad === "" || domicilio.localidad === null) {
                errorDomicilio = 1;

            }

        })


        if (errorDomicilio === 1) {
            Swal.fire({
                title: 'Atención',
                html: 'Los domicilios tienen campos sin completar',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        } else {
            return true;
        }

    }


    const [datosModalCuitEmail, setDatosModalCuitEmail] = useState({
        CuitCuil: '',
        Email: '',
        EmailRepetido: '',
        EstadoCivil: 0,
        TpoOsocial: 0,
        ObraSocial: ''
    });

    const isEditionDisabled = () => {
        const observado = datos.Observado;
        const isProfesional = localStorage.userType == TIPOS_USUARIO.PROFESIONAL_EDITAR_DATOS;
        return !isProfesional || (idEstado !== ESTADOS_TRAMITE.DRAFT && !observado);
    }

    const onSubmit = accion => {
        if (isNullOrEmpty(editarFotoSlice.cambiaDNI)) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe responder si desea cambiar su DNI',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }

        if (isNullOrEmpty(editarFotoSlice.deseaCambiarFoto)) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe responder si desea cambiar su Foto',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }

        if (editarFotoSlice.deseaCambiarFoto && isNullOrEmpty(editarFotoSlice.fotoNueva)) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe cargar una nueva foto',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }


        if (datos.CuitCuil === '' || datos.Email === '') {
            Swal.fire({
                title: 'Atención',
                html: 'El <b> CUIT-CUIL</b> es obligatorio',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }


        getProfesionalValidarCuit(datos.CuitCuil.replace(/-/gi, '')).then((value) => {

            if (!value.data.response) {

                Swal.fire({
                    title: 'Atención',
                    html: 'El <b>CUIT-CUIL</b> ingresado no es valido',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });

                return false;
            } else {
                setLoaderBtnGuardar(true)
                setBloquearBtn(true);

                if (editarFotoSlice.deseaCambiarFoto) {
                    let adjuntoDeFoto = datos.adjuntos.find(a => a.fileType === "FotoPerfil");
                    if (adjuntoDeFoto) {
                        adjuntoDeFoto.file = editarFotoSlice.fotoNueva;
                    } else {
                        adjuntoDeFoto = nuevoAdjunto("FotoPerfil", "image/jpeg", editarFotoSlice.fotoNueva);
                        datos.adjuntos.push(adjuntoDeFoto);
                    }

                    let adjuntoDeFotoFull = datos.adjuntos.find(a => a.fileType === "FotoPerfilFull");
                    if (adjuntoDeFotoFull) {
                        adjuntoDeFotoFull.file = editarFotoSlice.fotoNuevaFullSize;
                    } else {
                        adjuntoDeFotoFull = nuevoAdjunto("FotoPerfilFull", "image/jpeg", editarFotoSlice.fotoNuevaFullSize);
                        datos.adjuntos.push(adjuntoDeFotoFull);
                    }
                }
                datos.cambiaFoto = editarFotoSlice.deseaCambiarFoto;
                datos.cambiaDNI = editarFotoSlice.cambiaDNI;

                guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 6 }).then(value => {
                    setLoaderBtnGuardar(false);
                    setBloquearBtn(false);
                    if (value.data.resultado) {
                        setValue("1");
                        setLegajoTemporal(value.data.result.idLegajoTemporal)
                        localStorage.setItem('idLegajoTemporal', value.data.result.idLegajoTemporal);
                    } else {
                        Swal.fire({
                            title: 'Atención',
                            html: 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                            icon: 'warning',
                            showCloseButton: true,
                            showCancelButton: false,
                            confirmButtonText:
                                'Aceptar',
                            cancelButtonText:
                                'Cancelar',
                            allowOutsideClick: true
                        })
                        return false;
                    }


                });


            }
        });

    }

    const [modalEditarDatosPersonales, setModalEditarDatosPersonales] = useState(false);
    const handleClose = () => setModalEditarDatosPersonales(false);

    function mostrarAlertaTipoArchivos() {
        Swal.fire({
            title: 'Atención',
            html: `
            Solo se permiten archivos .jpg, .jpeg y .png
            `,
            showCloseButton: true,
            confirmButtonText: 'Aceptar',
            allowOutsideClick: true,
            showCancelButton: true,
            cancelButtonText:
                'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                hiddenFileInput.current.click();
            }
        });;
    }

    useEffect(() => {
        if (EstadoCivilList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getEstadoCivilAsync());
        }
    }, [])

    const tipoDocumentoPersona = TiposDocumento.find(td => td.tipoDocumento === datos.TipoDocumento);
    
    return (
        <Grid>
            <Typography sx={{ mb: 1 }} >* <b>Datos registrados. Para realizar la modificación de los mismos deberá hacer click en  <EditIcon />.</b></Typography>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12} >
                    <Card variant='custom' sx={{ boxShadow: 5 }}>
                        <CardContent>
                            <Grid container >

                                <Grid item md={3} xs={12} sx={{ mb: 2 }} >
                                    Tipo de Documento: <b> {tipoDocumentoPersona.descripcion}</b>
                                </Grid>
                                <Grid item md={3} xs={12} sx={{ mb: 2 }} >
                                    N° Documento: <b> {datos.NroDocumento}</b>
                                </Grid>
                                <Grid className='editar-cuit-container' xs={12} item>
                                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyItems={"center"}>
                                        <p>CUIT - CUIL: <b> {datos.CuitCuil}</b></p>
                                        {!isEditionDisabled() && <Fab
                                            style={{ marginLeft: "10px" }}
                                            size="small"
                                            color="secondary"
                                            aria-label="edit"
                                            onClick={() => {
                                                setModalEditarDatosPersonales(true)
                                                setDatosModalCuitEmail({
                                                    ...datosModalCuitEmail,
                                                    CuitCuil: datos.CuitCuil,
                                                    Email: datos.Email,
                                                    EmailRepetido: datos.EmailRepetido,
                                                    ObraSocial: datos.ObraSocial,
                                                    TpoOsocial: datos.TpoOsocial,
                                                    EstadoCivil: datos.EstadoCivil
                                                })
                                            }}>
                                            <EditIcon />
                                        </Fab>}
                                    </Box>
                                    <Box display={"flex"} flexDirection={"column"}>
                                        <FormControl>
                                            <Grid>
                                                <FormLabel required={true}>
                                                    ¿Desea actualizar su tipo y número de documento?
                                                </FormLabel>
                                                <br />
                                                <Typography variant='caption'>
                                                    En caso afirmativo debera adjuntarlo en la solapa "adjuntos"
                                                </Typography>
                                            </Grid>
                                            <ToggleButtonGroup
                                                value={editarFotoSlice.cambiaDNI}
                                                exclusive
                                                disabled={isEditionDisabled()}
                                                sx={{ background: "white", width: "79px" }}
                                                color="primary"
                                                onChange={handlecambiaDocumento}
                                                aria-label="text cambiaDocumento"
                                            >
                                                <ToggleButton
                                                    sx={{ width: "40px", height: "30px" }}
                                                    value={true}
                                                    aria-label="left aligned">
                                                    SI
                                                </ToggleButton>
                                                <ToggleButton
                                                    sx={{ width: "40px", height: "30px" }}
                                                    value={false}
                                                    aria-label="centered">
                                                    NO
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Paper sx={{ marginTop: "20px" }}>
                        <Box className="modificar-foto-container">
                            <Box padding={"20px"} display={"flex"} flexDirection={"column"}>
                                <Typography fontWeight={"bold"}>Foto actual</Typography>
                                {
                                    !editarFotoSlice.fotoActual &&
                                    <Typography variant={"caption"}>No tiene foto</Typography>
                                }
                                {
                                    editarFotoSlice.fotoActual &&
                                    <img

                                        alt={"Foto actual del profesional"}
                                        style={{ height: "350px", objectFit: "contain" }}
                                        src={editarFotoSlice?.fotoActual} />
                                }

                            </Box>
                            <Box padding={"20px"} display={"flex"} flexDirection={"column"} sx={{}} flex={1} >
                                <FormControl>
                                    <Grid>
                                        <FormLabel required={true}>
                                            ¿Desea actualizar su Foto?
                                        </FormLabel>
                                        <br />
                                        <Typography variant='caption'>
                                            En caso afirmativo debera adjuntarla
                                        </Typography>
                                    </Grid>
                                    <ToggleButtonGroup
                                        value={editarFotoSlice.deseaCambiarFoto}
                                        exclusive
                                        disabled={isEditionDisabled()}
                                        sx={{ background: "white", width: "79px" }}
                                        color="primary"
                                        onChange={handlecambiaFoto}
                                        aria-label="text cambiaDocumento"
                                    >
                                        <ToggleButton
                                            sx={{ width: "40px", height: "30px" }}
                                            value={true}
                                            aria-label="left aligned">
                                            SI
                                        </ToggleButton>
                                        <ToggleButton
                                            sx={{ width: "40px", height: "30px" }}
                                            value={false}
                                            aria-label="centered">
                                            NO
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </FormControl>
                                {editarFotoSlice.deseaCambiarFoto && editarFotoSlice.fotoNueva && <Box sx={{ marginTop: "20px" }}>
                                    <MiniaturaFotoNueva
                                        disableEdit={isEditionDisabled()}
                                        src={editarFotoSlice.fotoNueva}
                                        onClickEditar={() => setOpenNuevaFoto(true)} />
                                </Box>}
                                <Box
                                    className="btn-modificar-foto-container"
                                    justifyContent={!isEditionDisabled() && editarFotoSlice.deseaCambiarFoto ? "space-between" : "flex-end"} >
                                    {!isEditionDisabled() && editarFotoSlice.deseaCambiarFoto && 
                                        <Button
                                            sx={{ minWidth: '150px', width: '15vw', height: '40px' }}
                                            variant='contained'
                                            color='secondary'
                                            onClick={() =>mostrarAlertaTipoArchivos()}
                                        >
                                            Subir foto
                                        </Button>
                                    }
                                    <Button
                                        sx={{ height: "40px" }}
                                        disabled={bloquearBtn}
                                        endIcon={<ArrowForwardIosIcon />}
                                        onClick={onSubmit}
                                        variant="contained" color="secondary" >

                                        {loaderBtnGuardar === true ? <CircularProgress /> : ''}Continuar
                                    </Button>
                                </Box>

                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <input type="file" name="uploadFotoEditar" onChange={changeHandler} style={{ display: 'none' }} ref={hiddenFileInput} accept=".jpg, .jpeg, .png"/>


            <ModalNuevaFoto
                nuevaFoto={editarFotoSlice.fotoNuevaFullSize}
                open={openNuevaFoto}
                onClose={() => setOpenNuevaFoto(false)}
            />

            <ModalEditarCuit
                open={modalEditarDatosPersonales}
                onClose={handleClose}
                cuit={datos.CuitCuil}
                onCuitChange={(nuevoCuit) => setDatos({ ...datos, CuitCuil: nuevoCuit })}
            />

        </Grid>
    )
}

export default DatosPersonales;