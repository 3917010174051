//Layouts
import PrincipalLayout from 'layouts/principal';

//Pages
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import Matriculas from 'pages/Matriculas';
import Registros from 'pages/Registro';
import Login from 'pages/Login';
import ConfirmacionCorreo from 'pages/ConfirmacionCorreo';
import ConfirmacionCorreoTitulosEspeciales from 'pages/ConfirmacionCorreoTitulosEspeciales';
import RecuperacionPassword from 'pages/RecuperacionPassword';
import RecuperarPassword from 'pages/RecuperarPassword';
import GenerarToken from 'pages/GenerarToken';
import TramitesPendientes from 'pages/TramitesPendientes';
import LoginExterno from 'pages/LoginExterno';
import LoginEditarDatos from 'pages/LoginEditarDatos';
import EditarDatosPersonales from 'pages/EditarDatosPersonales';
import LoginActualizacionDatos from 'pages/LoginActualizacionDatos';
import LoginAltaTitulo from 'pages/LoginAltaTitulo';
import AltaTitulo from 'pages/AltaTitulo';
import BajaPorFallecimiento from 'pages/BajaPorFallecimiento';
import AltaTituloEspecial from 'pages/AltaTituloEspecial';
import LoginValidacionTitulo from 'pages/LoginValidacionTitulo';
import LoginInscipcionTitulosEspeciales from 'pages/LoginInscipcionTitulosEspeciales';
import InscipcionTitulosEspeciales from 'pages/InscipcionTitulosEspeciales';
import RegistroAltaTitulosEspeciales from 'pages/RegistroAltaTitulosEspeciales';
import LoginValidacionTitulosEspeciales from 'pages/LoginValidacionTitulosEspeciales';
import RecuperarPasswordTituloEspecial from 'pages/RecuperarPasswordTituloEspecial';
import RecuperacionPasswordTituloEspecial from 'pages/RecuperacionPasswordTituloEspecial';
import LoginAltaTituloEspecial from 'pages/LoginAltaTituloEspecial';
import LoginValidacionAltaTituloEspecial from 'pages/LoginValidacionAltaTituloEspecial';
import RegistroBajaFallecimiento from 'pages/RegistroBajaFallecimiento';
import LoginBajaFallecimiento from 'pages/LoginBajaFallecimiento';
import ConfirmacionCorreoBajaFallecimiento from 'pages/ConfirmacionCorreoBajaFallecimiento';
import { MenuBajaFallecimiento } from 'pages/MenuBajaFallecimiento';
import LoginValidacionBajaFallecimiento from 'pages/LoginValidacionBajaFallecimiento';
import LoginInternoBajaFallecimiento from 'pages/LoginInternoBajaFallecimiento';
//Classes
class ROUT {
    constructor(path, display, layout, page, classNames) {
        this.PATH = path;
        this.DISPLAY = display;
        this.LAYOUT = layout;
        this.PAGE = page;
        this.CLASS_NAME = classNames;
    }
}

//constantes internas
const HOME = new ROUT('/', 'HOME', PrincipalLayout, Login, 'login');
const MATRICULAS = new ROUT('/matriculas', 'MATRICULAS', PrincipalLayout, Matriculas, 'matriculas');
const REGISTRO = new ROUT('/registro', 'REGISTRO', PrincipalLayout, Registros, 'registro');
const LOGIN = new ROUT('/login', 'LOGIN', PrincipalLayout, Login, 'login');
const LOGIN_EXTERNO = new ROUT('/loginexterno', 'LOGIN_EXTERNO', PrincipalLayout, LoginExterno, 'loginexterno');
const LOGIN_EDITAR_DATOS = new ROUT('/logineditardatos', 'LOGIN_EDITAR_DATOS', PrincipalLayout, LoginEditarDatos, 'logineditardatos');
const EDITAR_DATOS_PERSONALES = new ROUT('/editardatospersonales', 'LOGIN_EDITAR_DATOS', PrincipalLayout, EditarDatosPersonales, 'editardatospersonales');
const CONFIRMACIONCORREO = new ROUT('/confirmacioncorreo/:token/:email', 'CONFIRMAREMAIL', PrincipalLayout, ConfirmacionCorreo, 'confirmacioncorreo');
const CONFIRMACIONCORREOBAJAFALLECIMIENTO = new ROUT('/confirmacionCorreoBajaFallecimiento/:token/:email', 'CONFIRMAREMAILBAJAFALLECIMIENTO', PrincipalLayout, ConfirmacionCorreoBajaFallecimiento, 'confirmacionCorreoBajaFallecimiento');
const CONFIRMACIONEMAILTITULOESPECIAL = new ROUT('/confirmacionCorreotitulosespeciales/:token/:email', 'CONFIRMAREMAILTITULOESPECIAL', PrincipalLayout, ConfirmacionCorreoTitulosEspeciales, 'ConfirmacionCorreoTitulosEspeciales');
const RECUPERACIONPASSWORD = new ROUT('/recuperacionpassword/:token/:email', 'RECUPERACIONPASSWORD', PrincipalLayout, RecuperacionPassword, 'recuperacionpassword');
const RECUPERARPASSWORD = new ROUT('/recuperarpassword', 'RECUPERARPASSWORD', PrincipalLayout, RecuperarPassword, 'recuperarpassword');
const GENERARTOKEN = new ROUT('/generartoken/:tpoTramite', 'GENERARTOKEN', PrincipalLayout, GenerarToken, 'generartoken');
const TRAMITESPENDIENTES = new ROUT('/tramitespendientes', 'TRAMITESPENDIENTES', PrincipalLayout, TramitesPendientes, 'TramitesPendientes');
const LOGINACTUALIZACIONDATOS = new ROUT('/loginactualizaciondatos', 'LOGINACTUALIZACIONDATOS', PrincipalLayout, LoginActualizacionDatos, 'LoginActualizacionDatos');
const LOGINALTATITULO = new ROUT('/loginaltatitulo', 'LOGINALTATITULO', PrincipalLayout, LoginAltaTitulo, 'LoginAltaTitulo');
const LOGINALTATITULOESPECIAL = new ROUT('/loginaltatituloespecial', 'LOGINALTATITULOESPECIAL', PrincipalLayout, LoginAltaTituloEspecial, 'LoginAltaTituloEspecial');
const LOGINVALIDACIONALTATITULOSESPECIALES = new ROUT('/LoginValidacionAltaTituloEspecial', 'LOGINALTATITULOESPECIAL', PrincipalLayout, LoginValidacionAltaTituloEspecial, 'LoginValidacionAltaTituloEspecial');
const ALTA_TITULO = new ROUT('/altatitulo', 'ALTA_TITULO', PrincipalLayout, AltaTitulo, 'AltaTitulo');
const ALTA_TITULO_ESPECIAL = new ROUT('/altatituloespecial', 'ALTA_TITULO_ESPECIAL', PrincipalLayout, AltaTituloEspecial, 'AltaTituloEspecial');
const LOGININSCRIPCIONTITULOSESPECIALES = new ROUT('/logininscripciontitulosespeciales', 'LOGININSCRIPCIONTITULOSESPECIALES', PrincipalLayout, LoginInscipcionTitulosEspeciales, 'LoginInscipcionTitulosEspeciales');
const LOGIN_VALIDACION_TITULO = new ROUT('/loginvalidaciontitulo', 'LOGIN_VALIDACION_TITULO', PrincipalLayout, LoginValidacionTitulo, 'LoginValidacionTitulo');
const LOGIN_VALIDACION_BAJA_FALLECIMIENTO = new ROUT('/loginValidacionBajaFallecimiento', 'LOGIN_VALIDACION_BAJA_FALLECIMIENTO', PrincipalLayout, LoginValidacionBajaFallecimiento, 'LoginValidacionBajaFallecimiento');
const LOGIN_USUARIO_INTERNO_BAJA_FALLECIMIENTO = new ROUT('/loginInternoBajaFallecimiento', 'LOGIN_USUARIO_INTERNO_BAJA_FALLECIMIENTO', PrincipalLayout, LoginInternoBajaFallecimiento, 'LoginInternoBajaFallecimiento');
const INSCIPCIONTITULOSESPECIALES = new ROUT('/inscripciontitulosespeciales', 'INSCIPCIONTITULOSESPECIALES', PrincipalLayout, InscipcionTitulosEspeciales, 'InscipcionTitulosEspeciales');
const REGISTROALTATITULOSESPECIALES = new ROUT('/registroaltatitulosespeciales', 'REGISTROALTATITULOSESPECIALES', PrincipalLayout, RegistroAltaTitulosEspeciales, 'RegistroAltaTitulosEspeciales');
const LOGINVALIDACIONTITULOSESPECIALES = new ROUT('/loginvalidaciontitulosEspeciales', 'LOGINVALIDACIONTITULOSESPECIALES', PrincipalLayout, LoginValidacionTitulosEspeciales, 'LoginValidacionTitulosEspeciales');
const LOGIN_BAJA_FALLECIMIENTO = new ROUT('/loginBajaFallecimiento', 'LOGIN_BAJA_FALLECIMIENTO', PrincipalLayout, LoginBajaFallecimiento, 'LoginBajaFallecimiento');
const REGISTRO_BAJA_FALLECIMIENTO  = new ROUT('/registroBajaFallecimiento', 'REGISTRO_BAJA_FALLECIMIENTO', PrincipalLayout, RegistroBajaFallecimiento, 'RegistroBajaFallecimiento');
const BAJA_FALLECIMIENTO = new ROUT('/bajaFallecimiento', 'BAJA_FALLECIMIENTO', PrincipalLayout, BajaPorFallecimiento, 'BajaPorFallecimiento');
const PERFIL_USUARIO = new ROUT('/perfilUsuario', 'PERFIL_USUARIO', PrincipalLayout, BajaPorFallecimiento, 'BajaPorFallecimiento');
const MENU_BAJA_FALLECIMIENTO = new ROUT('/menuBajaFallecimiento', 'MENU_BAJA_FALLECIMIENTO', PrincipalLayout, MenuBajaFallecimiento, 'MenuBajaFallecimiento');
const RECUPERARPASSWORDTITULOESPECIAL = new ROUT('/recuperarpasswordtituloespecial', 'RECUPERARPASSWORDTITULOESPECIAL', PrincipalLayout, RecuperarPasswordTituloEspecial, 'RecuperarPasswordTituloEspecial');
const RECUPERACIONPASSWORDTITULOESPECIAL = new ROUT('/recuperacionpasswordtituloEspecial/:token/:email', 'RECUPERACIONPASSWORDTITULOESPECIAL', PrincipalLayout, RecuperacionPasswordTituloEspecial, 'RecuperacionPasswordTituloEspecial');
const NOT_FOUND = new ROUT('*', 'No Disponible', PrincipalLayout, NotFound, 'not-found');

//Exports
export const ROUTES = Object.freeze({
    HOME,
    MATRICULAS,
    LOGIN,
    LOGIN_EXTERNO,
    REGISTRO,
    ALTA_TITULO,
    CONFIRMACIONCORREO,
    RECUPERARPASSWORD,
    LOGIN_EDITAR_DATOS,
    RECUPERACIONPASSWORD,
    GENERARTOKEN,
    EDITAR_DATOS_PERSONALES,
    TRAMITESPENDIENTES,
    LOGIN_VALIDACION_TITULO,
    LOGINALTATITULO,
    LOGINACTUALIZACIONDATOS,
    LOGININSCRIPCIONTITULOSESPECIALES,
    INSCIPCIONTITULOSESPECIALES,
    REGISTROALTATITULOSESPECIALES,
    RECUPERARPASSWORDTITULOESPECIAL,
    CONFIRMACIONEMAILTITULOESPECIAL,
    RECUPERACIONPASSWORDTITULOESPECIAL,
    LOGINVALIDACIONTITULOSESPECIALES,
    LOGINALTATITULOESPECIAL,
    LOGINVALIDACIONALTATITULOSESPECIALES,
    ALTA_TITULO_ESPECIAL,
    LOGIN_BAJA_FALLECIMIENTO,
    REGISTRO_BAJA_FALLECIMIENTO,
    MENU_BAJA_FALLECIMIENTO,
    BAJA_FALLECIMIENTO,
    LOGIN_VALIDACION_BAJA_FALLECIMIENTO,
    CONFIRMACIONCORREOBAJAFALLECIMIENTO,
    PERFIL_USUARIO,
    LOGIN_USUARIO_INTERNO_BAJA_FALLECIMIENTO,
    NOT_FOUND,
    LIST: [
        HOME,
        MATRICULAS,
        LOGIN,
        LOGIN_EXTERNO,
        REGISTRO,
        ALTA_TITULO,
        LOGIN_EDITAR_DATOS,
        RECUPERARPASSWORD,
        EDITAR_DATOS_PERSONALES,
        RECUPERACIONPASSWORD,
        GENERARTOKEN,
        LOGINACTUALIZACIONDATOS,
        CONFIRMACIONEMAILTITULOESPECIAL,
        CONFIRMACIONCORREO,
        LOGIN_VALIDACION_TITULO,
        LOGINALTATITULO,
        LOGINVALIDACIONALTATITULOSESPECIALES,
        TRAMITESPENDIENTES,
        LOGININSCRIPCIONTITULOSESPECIALES,
        REGISTROALTATITULOSESPECIALES,
        RECUPERARPASSWORDTITULOESPECIAL,
        RECUPERACIONPASSWORDTITULOESPECIAL,
        INSCIPCIONTITULOSESPECIALES,
        LOGINVALIDACIONTITULOSESPECIALES,
        LOGINALTATITULOESPECIAL,
        ALTA_TITULO_ESPECIAL,
        LOGIN_BAJA_FALLECIMIENTO,
        REGISTRO_BAJA_FALLECIMIENTO,
        MENU_BAJA_FALLECIMIENTO,
        BAJA_FALLECIMIENTO,
        LOGIN_VALIDACION_BAJA_FALLECIMIENTO,
        CONFIRMACIONCORREOBAJAFALLECIMIENTO,
        PERFIL_USUARIO,
        LOGIN_USUARIO_INTERNO_BAJA_FALLECIMIENTO,
        NOT_FOUND //Dejar siempre en ultimo lugar!!!
        
    ]
})