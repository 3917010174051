import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import Geocode from "react-geocode";
import { useDispatch } from 'react-redux';
import {
    useLoadScript
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

import "@reach/combobox/styles.css";
import { postLocalidades } from 'app/features/Localidades/localidadesAPI';

export const Buscador = ({ setLocation, titulosSelect, setTitulosSelect, setMarcadores, setDatosDocimilio, TpoDomicilio, datosDocimilio, Partidos,setListaLocalidades, datosCargados }) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => 43.6532, lng: () => -79.3832 },
            radius: 10,
        },
    });

    const dispatch = useDispatch();

    const handleInput = (e) => {

        setValue(e.target.value);
    };

    let calle = "";
    let numero = "";
    let localidad = "";
    let provincia = "";
    let latitud = "";
    let longitud = "";
    let esLaPlata = false;
    const handleSelect = async (event, direccion) => {

        // console.log(status,value)
        if (direccion !== null) {

            clearSuggestions();
            //const results = await getGeocode({ address });

            // console.log(direccion);

            let address = direccion.label;
            // if (address === 'La Plata, Provincia de Buenos Aires, Argentina') {
            //     esLaPlata = true;
            // } 

            setTitulosSelect({
                label: address, value: 0
            })

            //console.log(titulosSelect);
            // setValue(address, false);

            try {

            

                const results = await getGeocode({ address });

                // console.log(results);

                const { lat, lng } = await getLatLng(results[0]);
                results[0].address_components.map((domicilios, index) => {

                    if (domicilios.types[0] === "street_number") {

                        numero = domicilios.short_name;

                    }
                    if (domicilios.types[0] === "locality") {

                        localidad = domicilios.long_name;

                    }

                    if (domicilios.types[0] === "route") {

                        calle = domicilios.long_name;

                    }

                    if (domicilios.types[0] === "administrative_area_level_1") {

                        provincia = domicilios.short_name;

                    }

                })
                    //esta validacion sirve en el caso de que la ciudad no tenga el nombre que corresponde
                if(localidad.length <= 3){

                    results[0].address_components.map((domicilios, index) => {
                        if (domicilios.types[0] ===  "administrative_area_level_2") {
    
                            localidad = domicilios.long_name;
    
                        }
                    })
                }    
               
                Geocode.fromLatLng(lat, lng).then(
                    (response) => {
                        console.log(response)
                        const address = response.results[0].formatted_address;

                        let codigoPostal;
                        for (let i = 0; i < response.results[0].address_components.length; i++) {
                            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                                // console.log(response.results[0].address_components[i].types[j]);
                                switch (response.results[0].address_components[i].types[j]) {
                                    case "postal_code":

                                        codigoPostal = response.results[0].address_components[i].long_name;
                                        codigoPostal = codigoPostal.replace(/[^\d]/, '');
                                        break;
                                }
                            }
                        }
                        if (provincia === "CABA" && (localidad === "" || localidad === undefined)) {
                            localidad = "Buenos Aires";
                        }
                        
                        if(localidad === "La Plata") esLaPlata = true;
 
                        const datos = {
                            nombreLocalidad: localidad === "Buenos Aires" ? "Capital Federal" : localidad,
                            codPostal: '',
                            nombreProvincia: ''
                        }

                        var codLocalidad = "";
                        localidad = "";
                        let delegacionSelect = '';
                        let partidoSelect = '';
                        postLocalidades(datos).then(result => {
                            let localidadesArr = [];
                            let localidadPrimera = "";
                            console.log(result.data)
                            result.data.map((ld, index) => {

                                if (index === 0) {
                                   
                                    codLocalidad = ld.codLocalidad;
                                    localidadPrimera = ld.nombreLocalidad;
                                    Partidos.map((partido, index) => {
                                        if (ld.codPartido === partido.codPartido) {
                                            if (TpoDomicilio === 1) {
                                                delegacionSelect = partido.razonSocial;
                                            }else{
                                                
                                            delegacionSelect =  datosCargados.domicilios[0].delegacion; 
                                            }

                                            partidoSelect = partido.descripcion;
                                        }

                                    })
                                }

                                if(esLaPlata && index === 2) {
                                    codLocalidad = ld.codLocalidad;
                                    localidadPrimera = ld.nombreLocalidad;
                                    console.log(response.results)
                                    Partidos.map((partido, index) => {
                                        if (ld.codPartido === partido.codPartido) {
                                            if (TpoDomicilio === 1) {
                                                delegacionSelect = partido.razonSocial;
                                            }else{
                                                
                                            delegacionSelect =  datosCargados.domicilios[0].delegacion; 
                                            }

                                            partidoSelect = partido.descripcion;
                                        }

                                    })
                                }

                                localidadesArr.push({ label: ld.nombreLocalidad, value: ld.codLocalidad, codPartido: ld.codPartido })
                            })

                           
                            setDatosDocimilio({
                                ...datosDocimilio,
                                provincia: provincia,
                                calle: calle,
                                localidad: localidadPrimera,
                                codLocalidad: String(codLocalidad),
                                numero: numero,
                                partido:partidoSelect,
                                delegacion: delegacionSelect,
                                codPostal: codigoPostal !== undefined ? codigoPostal.replace(/[^0-9]+/g, "") : ''
                            })
                            setListaLocalidades(localidadesArr);
                        })



                    },
                    (error) => {
                        // console.error("hola");
                    }
                );

                // console.log(lat, lng);
                latitud = lat;
                longitud = lng;
                setLocation({
                    lat: lat,
                    lng: lng,
                });
                setMarcadores([{
                    lat: lat,
                    lng: lng,
                    time: new Date()
                }]);

                //console.log(location);
                //    panTo({ lat, lng });
            } catch (error) {
                console.log("😱 Error: ", error);
            }
        }else{
            setListaLocalidades([{ label: '', value: '', codPartido:'' }]);
        }
        
    };
    let ponerLugares = [titulosSelect];

    data.map((universidad, index) =>

        ponerLugares.push({ "label": universidad.description, "value": universidad.place_id })
    )

    return (
        <div className="search">

            <Autocomplete
                name='titulo'
                options={ponerLugares}
                noOptionsText={'No se encontraron Domicilios'}
                onChange={handleSelect}
                value={titulosSelect}
                defaultValue={titulosSelect}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} onChange={handleInput} placeholder="Formato para cargar domicilio: Calle número (sin la
                    abreviatura “N°”), localidad, provincia" required label="Domicilio" />}
            />


        </div>
    );
}