import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCatalogoTipoDocumento } from 'app/features/TipoDocumento/tipoDocumentoAPI';
const initialState = {
	List: [],
	status: 'idle',
  };


export const getTipoDocumentoAsync = createAsyncThunk(
	'TipoDocumento/fetchtipoDocumento',
	async data => {
	  const response = await getCatalogoTipoDocumento(data);
	  return response.data;
	} 
  );

  export const tipoDocumentoSlice = createSlice({
    name: 'tipoDocumento',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
      setSearch: (state, action) => {
        state.search = action.payload;
      },
    },

    extraReducers: (builder) => {
      builder
        .addCase(getTipoDocumentoAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getTipoDocumentoAsync.fulfilled, (state, action) => {
          state.status = 'idle';
         // console.log(action.payload)
          state.List = action.payload;
        });
    },
  });
  


  export const tipoDocumentoList = (state) => state.tipoDocumentoReducer.List;
export const tipoDocumentoStatus = state => state.tipoDocumentoReducer.status;

export default tipoDocumentoSlice.reducer;
