import React, { Fragment, useState, useEffect } from 'react';

import { Card, CardContent, Grid, Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(theme => ({
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    centrarContenido: {
        display: 'flex',
        justifyContent: 'center'
    },
    backGroundBox: {
        backgroundColor: '#00b347!important'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    mapContainer: {
        height: '100vh', width: '100%'
    },
    mapElements: {
        height: '100%'
    },

    "@media (max-width: 910px)": {
        ocultarBotonEditar: {
            display: "none"
        },
        mostrarBotonEditarCelular: {
            display: "",
            marginLeft: "10px!important"
        }
    },
    "@media (min-width: 910px)": {

        mostrarBotonEditarCelular: {
            display: "none"

        },
        ocultarBotonEditar: {
            display: ""
        },
    }
}));


export const DomicilioUsuario = ({ datos, bloquearBtn, setTituloModal, setOpen, setTipoDomicilio, setDatosDocimilio, bloquearBtnEdit, setErrorTelefono, tipoCalleSelect }) => {
    const clases = useStyles();

    //  const [open, setOpen] = React.useState(false);
    const handleOpen = TpoDomicilio => {
        TpoDomicilio = Number(TpoDomicilio);
        setTituloModal(renderSwitch(TpoDomicilio))
        let paso = 0;
        
        datos.domicilios.map((domicilio, index) => {

            if ((domicilio.TpoDomicilio === TpoDomicilio || domicilio.TpoDomicilio === undefined || domicilio.TpoDomicilio === 0) && paso === 0) {
                setDatosDocimilio({
                    TpoDomicilio: TpoDomicilio,
                    localidad: domicilio.localidad !== null ? domicilio.localidad : '',
                    codLocalidad: domicilio.codLocalidad !== undefined ? domicilio.codLocalidad : '',
                    calle: domicilio.calle,
                    numero: domicilio.numero.trim(),
                    piso: domicilio.piso.trim(),
                    depto: domicilio.depto.trim(),
                    tpoCalle: domicilio.tpoCalle !== undefined ? domicilio.tpoCalle : 0,
                    delegacion: domicilio.delegacion !== null ? domicilio.delegacion : '',
                    codPostal: domicilio.codPostal,
                    partido: domicilio.partido !== null ? domicilio.partido : '',
                    provincia: domicilio.provincia !== null ? domicilio.provincia : '',
                    telefono: domicilio.telefono.trim(),
                    caracteristica: domicilio.caracteristica !== null ? domicilio.caracteristica : '',
                    caracteristicaFijo: domicilio.caracteristicaFijo !== null ? domicilio.caracteristicaFijo : '',
                    celular: domicilio.celular.trim(),
                    //tpoCarga: domicilio.tpoCarga === undefined ? 1 : domicilio.tpoCarga
                    tpoCarga: 1 
                })

                paso = 1;
                return setDatosDocimilio;
            }


        });
        setErrorTelefono({ display: "none" })
        setOpen(true);

        setTipoDomicilio(Number(TpoDomicilio));
    }

    if (datos.domicilios[0].TpoDomicilio !== null && datos.domicilios[0].TpoDomicilio !== undefined && datos.domicilios[0].TpoDomicilio !== 0) {

        datos.domicilios.sort(function (a, b) {

            if (a.TpoDomicilio === null) {

            } else {
                if (a.TpoDomicilio > b.TpoDomicilio) {
                    return 1;
                }
                if (a.TpoDomicilio < b.TpoDomicilio) {
                    return -1;
                }
            }

            // a must be equal to b
            return 0;
        });
    }


    let estilo = {};
    const renderSwitch = param => {

        switch (param) {
            case 1:
                estilo = { marginRight: '-30%', marginLeft: "22%" };
                return 'Particular';

            case 2:
                estilo = { marginRight: '-22%', marginLeft: "22%" };
                return 'Profesional';


            case 3:
                estilo = { marginRight: '-20%', marginLeft: "25%" };
                return 'Constituido';
            case 4:
                estilo = { marginRight: '-25%', marginLeft: "12%" };
                return 'Correspondencia';
            case 5:
                estilo = { marginRight: '-25%', marginLeft: "25%" };
                return 'Real Caja';

        }
    }



    return (
        <Fragment>
            {datos.domicilios.map((domicilio, index) =>

                <Grid item md={6} xs={12} key={index}>
                    <Card variant='custom' sx={{ boxShadow: 5 }}>
                        <CardContent>

                            <Grid container >

                                <Grid item xs={12} className={clases.centrarContenido}>

                                    <Grid  >
                                        <h3 style={{

                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>  <HomeIcon /> {
                                                (domicilio.TpoDomicilio !== undefined && domicilio.TpoDomicilio !== 0) &&
                                                renderSwitch(domicilio.TpoDomicilio)
                                            }
                                            {

                                                (domicilio.TpoDomicilio === undefined || domicilio.TpoDomicilio === 0) &&
                                                renderSwitch(Number(index + 1))
                                            }
                                        </h3>
                                    </Grid>
                                    {(localStorage.userType !== "1" && (bloquearBtn !== true)) &&

                                        <Grid className={clases.ocultarBotonEditar} style={estilo}>
                                            {
                                                Number(index + 1) === 1 &&
                                                <Fab color="secondary" aria-label="edit" onClick={() =>
                                                    handleOpen(Number(index + 1))
                                                }>
                                                    <EditIcon />
                                                </Fab>
                                            }
                                            {
                                                Number(index + 1) !== 1 &&
                                                <Fab disabled={bloquearBtnEdit} color="secondary" aria-label="edit" onClick={() =>
                                                    handleOpen(String(index + 1))
                                                }>
                                                    <EditIcon />
                                                </Fab>
                                            }
                                        </Grid>

                                    }

                                    {/* BOTON PARA EL CELULAR*/}
                                    {(localStorage.userType !== 1 && (bloquearBtn !== true)) &&
                                        <Grid item md={12} className={`${clases.floatRight} ${clases.mostrarBotonEditarCelular}`}>
                                            {
                                                Number(index + 1) === 1 &&
                                                <Fab color="secondary" aria-label="edit" onClick={() =>
                                                    handleOpen(Number(index + 1))
                                                }>
                                                    <EditIcon />
                                                </Fab>
                                            }
                                            {
                                                Number(index + 1) !== 1 &&
                                                <Fab disabled={bloquearBtnEdit} color="secondary" aria-label="edit" onClick={() =>
                                                    handleOpen(Number(index + 1))
                                                }>
                                                    <EditIcon />
                                                </Fab>
                                            }
                                        </Grid>

                                    }
                                </Grid>

                                <Grid item md={6} sx={{ mb: 2 }} >
                                    Provincia: <b> {domicilio.provincia}</b>
                                </Grid>
                                <Grid item md={6} sx={{ mb: 2 }} >
                                    Localidad: <b> {domicilio.localidad}</b>
                                </Grid>

                                <Grid item md={4} sx={{ mb: 2 }} >
                                    Calle : <b> {domicilio.calle}  {domicilio.numero}</b>
                                </Grid>

                                <Grid item md={4} sx={{ mb: 2 }} >

                                    Tipo: <b> {domicilio.tpoCalle !== undefined && domicilio.tpoCalle !== 0 ? tipoCalleSelect.filter(e => e.value === domicilio.tpoCalle)[0].label : ""}</b>
                                </Grid>
                                <Grid item md={2} sx={{ mb: 2 }} >
                                    Piso:<b> {domicilio.piso}</b>
                                </Grid>

                                <Grid item md={2} sx={{ mb: 2 }} >
                                    Dpto.:<b> {domicilio.depto}</b>
                                </Grid>

                                <Grid item md={4} sx={{ mb: 2 }} >
                                    Delegación: <b> {domicilio.delegacion}</b>
                                </Grid>

                                <Grid item md={4} sx={{ mb: 2 }} >
                                    Cod. Postal: <b> {domicilio.codPostal}</b>
                                </Grid>

                                <Grid item md={4} sx={{ mb: 2 }} >
                                    Partido: <b> {domicilio.partido}</b>
                                </Grid>

                                <Grid item md={6}  >
                                    Fijo:  <b> {(domicilio.caracteristicaFijo !== null && domicilio.caracteristicaFijo !== "" && domicilio.caracteristicaFijo !== undefined) && "(0" + domicilio.caracteristicaFijo + ")"}{(domicilio.telefono !== null && domicilio.telefono !== "") && domicilio.telefono}</b>

                                </Grid>

                                <Grid item md={6}  >
                                    Celular:  <b> {(domicilio.caracteristica !== null && domicilio.caracteristica !== "") && "(0" + domicilio.caracteristica + ")"}{(domicilio.celular !== null && domicilio.celular !== "") && "15-" + domicilio.celular}</b>
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Fragment>
    );
}
