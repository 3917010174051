import React, { useState, Fragment, useEffect } from 'react';
import { Grid, Card, CardContent, Link, AlertTitle, Alert, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {
    confirmarEmailUsuario
} from 'app/features/Usuarios/usuariosAPI';
// Styles
import 'scss/components/header.scss';


const ConfirmacionCorreo = () => {


    const params = useParams();
    const [mensajeResultado, setMensajeResultado] = useState();
    const [iconoResultado, setIconoResultado] = useState();
    localStorage.setItem('token', "");
    const [mostrarContenido, setMostrarContenido] = useState(false);

    useEffect(() => {
        const result = confirmarEmailUsuario({ email: params.email, token: params.token, tpoTramite: 1 });
        result.then((value) => {
            setMostrarContenido(true);
            if (!value.datas.resultado) {

                if (value.datas.codigoError === 0) {
                    setMensajeResultado("Intentelo nuevamente mas tarde.");
                }
                if (value.datas.codigoError === 1) {
                    setMensajeResultado("El email ya se encuentra registrado");
                }
                if (value.datas.codigoError === 2) {

                    setMensajeResultado("El email aún no se encuentra verificado");
                }
                if (value.datas.codigoError === 3) {

                    setMensajeResultado("El email no se encuentra registrado");
                }
                if (value.datas.codigoError === 4) {

                    setMensajeResultado("La validación a expirado. Por favor solicite uno nueva.");

                }

                setIconoResultado("error");

            } else {
                setIconoResultado("success");
                setMensajeResultado("El  correo   fue verificado correctamente: " + params.email);
            }
        });

    }, [])

    return (

        <Fragment>
            <Grid item xs={12} style={{ display: mostrarContenido && 'none' }}>
                <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <CircularProgress />
                </Grid>
            </Grid>
            <Grid
                md={10}
                container direction="column"
                className="centrar_contenido"
                style={{ display: !mostrarContenido && 'none' }}
            >

                <Card>
                    <CardContent align='center'>

                        <Alert severity={iconoResultado} align='center'>
                            <AlertTitle> {mensajeResultado} </AlertTitle>

                        </Alert>

                        {iconoResultado !== "error" ?

                            <Grid item sx={{ mt: 2 }} md={12} xs={12} className="centrar_contenido"  >

                                Para realizar la solicitud de inscripción haga
                                <Link sx={{ ml: 1, mr: 1 }} href="/Login">
                                    Click Aqui
                                </Link>
                                e ingrese nuevamente su correo electrónico y contraseña.

                            </Grid> :
                            <Grid item sx={{ mt: 2 }} md={12} xs={12} className="centrar_contenido"  >

                                Para solicitar una nueva validación haga
                                <Link sx={{ ml: 1 }} href="/GenerarToken/1">
                                    Click Aqui
                                </Link>

                            </Grid>}
                    </CardContent>
                </Card>

            </Grid>
        </Fragment>

    );

};

export default ConfirmacionCorreo;
