import { createTheme } from '@mui/material/styles';

const theme = createTheme({
   typography: {
      useNextVariants: true,
   },
   palette: {
      primary: {
         light: '#7986cb',
         main: '#133714',
         dark: '#303f9f',
         contrastText: '#fff',
      },
      secondary: {
         light: '#ff4081',
         main: '#228b22',
         dark: '#4c9141',
         contrastText: '#fff',
      },
      text: {
         primary: 'rgba(0, 0, 0, 0.87)',
         secondary: 'rgba(0, 0, 0, 0.54)',
         disabled: 'rgba(0, 0, 0, 0.38)',
         hint: 'rgba(0, 0, 0, 0.38)',
      },
      background: {
         paper: '#fff',
         default: '#e4f0ec'
      },
      success: {
         light: '#7986cb',
         main: '#133714',
         dark: '#303f9f',
         contrastText: '#fff',
      },
   },
   components: {
      MuiCard: {
         variants: [
            {
               props: { variant: 'custom' },
               style: {
                  backgroundColor: '#b8daba'
               }
            }
         ]
      },
      MuiInputBase: {
         styleOverrides: {
            input: {
               fontWeight: 'bold',
               color: 'black',
            }
         }
      },
      MuiInputLabel: {
         styleOverrides: {
            root: {
               color: 'black',
               fontWeight: 'bold',
            }
         }
      },

   },
   zIndex: {
      appBar: 1200,
      drawer: 1100,
   },
});

export default theme;
