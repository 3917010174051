import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMatricula } from 'app/features/Matriculas/matriculasApi';
const initialState = {
  List: [],
  status: 'idle',
};


export const getMatriculasAsync = createAsyncThunk(
  'Matriculas/fetchMatriculas',
  async data => {
    const response = await getMatricula(data);

    return response.data;
  }
);

export const matriculasSlice = createSlice({
  name: 'matriculas',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMatriculasAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMatriculasAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        // console.log(action.payload)
        state.List = action.payload;
      });
  },
});



export const matriculasList = (state) => state.matriculasReducer.List;
export const matriculasStatus = state => state.matriculasReducer.status;

export default matriculasSlice.reducer;
