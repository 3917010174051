import { EMPTY_FILE } from 'constants/icons';

export const ESTADOS_TRAMITE = {
    DRAFT: 1,
    PENDIENTE: 2,
    EN_REVISION: 3,
    VALIDADO: 4,
    CANCELADA: 5,
    PENDIENTE_EN_REVISION: 6,
    FINALIZADA: 7,
    PRE_CARGA_OBSERVACION: 8,
    PRE_CARGA_VALIDADA: 9,
    PRE_CARGA_FINALIZADA: 10,
    PENDIENTE_REVISION_FINAL_TTEE: 11
};

export const TIPOS_TRAMITE = {
    INSCRIPCION: 1,
    REINSCRIPCION_REHABILITACION: 2,
    CANCELACION: 3,
    BAJA_FALLECIMIENTO: 4,
    SOCIEDADES: 5,
    MODIFICACION: 6,
    REINSCRIPCION: 7,
    ALTA_TITULO: 8,
    INSCRIPCION_TITULO_ESPECIAL: 9,
    ALTA_TITULO_ESPECIAL: 10
};

export const TIPOS_USUARIO = {
    PROFESIONAL: 0,
    SECRETARIO_TECNICO: 1,
    PROFESIONAL_EDITAR_DATOS: 3,
    SECRETARIO_ACTUALIZACION_DATOS: 6,
    PROFESIONAL_ALTA_TITULO: 10,
    USUARIO_EXTERNO: 11,
    USUARIO_INTERNO: 12,
};

export const MAX_TITULOS_ALTA = 1;

const MODIFICACION_DATOS = {
    id: TIPOS_TRAMITE.MODIFICACION,
    loginURL: 'http://localhost/apps/Matriculas/EditarDatosPerfil/EditarDatosDelPerfil'
};

const INSCRIPCION = {
    id: TIPOS_TRAMITE.INSCRIPCION,
    loginURL: '/login'
};

const INSCRIPCION_ESPECIAL = {
    id: TIPOS_TRAMITE.INSCRIPCION_TITULO_ESPECIAL,
    loginURL: '/login'
};

const ALTA_TITULO = {
    id: TIPOS_TRAMITE.ALTA_TITULO,
    loginURL: '/login'
};

const ALTA_TITULO_ESPECIAL = {
    id: TIPOS_TRAMITE.ALTA_TITULO_ESPECIAL,
    loginURL: '/login'
};

const BAJA_FALLECIMIENTO = {
    id: TIPOS_TRAMITE.BAJA_FALLECIMIENTO,
    loginURL: '/loginBajaFallecimiento'
};

// AGREGO ESTE TIPO SOLAMENTE PARA TENER EN CUENTA LOGINEXTERNO QUE NO ESTA CLARO PARA QUE SE UTILIZA
const EXTERNO = {
    id: -99,
    loginURL: '/login'
};

export const TRAMITES = [
    MODIFICACION_DATOS,
    INSCRIPCION,
    INSCRIPCION_ESPECIAL,
    ALTA_TITULO,
    ALTA_TITULO_ESPECIAL,
    EXTERNO,
    BAJA_FALLECIMIENTO
];

export const ADJUNTOS_BASE = {
    DNI: {
        id: "DNI",
        fileType: 'DNI',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    RES_MINISTERIO_EDUCACION: {
        id: "RES_MINISTERIO_EDUCACION",
        fileType: 'Resolución del Ministerio de Educación de la Nación y detalle de incumbencias profesionales',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    PLAN_ESTUDIOS: {
        id: "PLAN_ESTUDIOS",
        fileType: 'Plan de estudios',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    CERT_LIBRE_SANCION: {
        id: "CERT_LIBRE_SANCION",
        fileType: 'Certificado de libre sanción disciplinaria de otros consejos profesionales',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    ANALITICO: {
        id: "ANALITICO",
        fileType: 'Analítico',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    DETALLE_INCUMBENCIAS: {
        id: "DETALLE_INCUMBENCIAS",
        fileType: 'Detalle de incumbencias profesionales',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    DECLARACION_JURADA: {
        id: "DECLARACION_JURADA",
        fileType: 'Declaracion Jurada',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    DECLARACION_JURADA_ENFERMEDADES: {
        id: "DECLARACION_JURADA_ENFERMEDADES",
        fileType: 'Declaración jurada de enfermedades y patologías preexistentes',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    SOLICITUD_REGISTRO: {
        id: "SOLICITUD_REGISTRO",
        fileType: 'SOLICITUD DE REGISTRO EN EL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    FOTO: {
        id: "FOTO",
        fileType: 'Foto',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    },
    TITULO: {
        id: "TITULO",
        fileType: 'Titulo - ',
        fileName: '',
        extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true
    }
      
}
