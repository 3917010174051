
export const cambiarAlturaBox = valor => {

    const root = document.documentElement;


    root?.style.setProperty(
        '--header-height', valor
    );

};