import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Box } from "@mui/material";
import { TextInput } from "components/chat/TextInput.js";
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import ChatIcon from '@mui/icons-material/ChatBubbleOutline';
import { Button, Badge, Typography, Tab, Grid, IconButton } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from "components/chat/Message";

import { comunicacionList, getComunicacionAsync } from 'app/features/comunicaciones/comunicacionesSlice';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { TextInputBajaFallecimiento } from "./TextInputBajaFallecimiento";

const useStyles = makeStyles(theme => ({
  paper: {
    width: "62vh!important",
    height: "50%",
    maxWidth: "500px",
    maxHeight: "700px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative"
  },
  paper2: {
    width: "80vw",
    maxWidth: "500px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative"
  },
  contenidoCerrarChat: {
    cursor: "pointer",
    marginLeft: 'auto',
    marginRight: 10,
    marginTop: "5px!important"
  },
  container: {
    zIndex: 1251,
    border: "1px solid transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    top: 'auto',
    right: 10,
    bottom: 20,
    ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
      width: "80vw",
    },


    position: 'fixed',
  },
  button: {
    float: "none",
    content: '',
    position: "relative",
    float: "right",
  },
  messagesBody: {
    ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
      width: "80vw!important",
    },
    ['@media (min-width:1400px)']: { // eslint-disable-line no-useless-computed-key
      width: "23vw!important",
    },
    customBadge: {
      backgroundColor: "#00AFD7",
      color: "white"
    },
    width: "calc( 27vw - 20px )",
    overflowY: "scroll",
    height: "calc( 40vh - 70px )",
  }
})
);

export const ChatBajaFallecimiento = ({ datos, setDatos, setIdEstado }) => {
  const [clase_cerrar_chat, setCerrarChat] = useState({ display: "none" });
  const [clase_ocultar_boton, setCerrarBoton] = useState({});
  const [ComunicacionList, setComunicacionList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (ComunicacionList.length === 0) {
      dispatch(getComunicacionAsync(localStorage.idLegajoTemporal)).then(valor => {
        setComunicacionList(valor.payload);
      });
    }

  }, []);


  const abrir_chat = () => {
    setCerrarChat({})
    setvalorTabChat(ComunicacionList.length > 0 ? '2' : '1')
    setCerrarBoton({
      display: "none"
    })
  }
  const cerrar_chat = () => {
    setCerrarChat({
      display: "none"

    })
    setCerrarBoton({

    })
  }
  const [valorTabChat, setvalorTabChat] = useState('1');
  const handleChange = (event, newValue) => {

    setvalorTabChat(newValue);


  };
  const classes = useStyles();
  return (
    <div className={classes.container} >

      <Paper className={classes.paper} style={clase_cerrar_chat}>
        <div className={classes.contenidoCerrarChat} onClick={cerrar_chat} >  <RemoveIcon /></div>
        <TabContext value={valorTabChat}>

          <Box>
            <Grid container>

              <Grid item md={12} xs={9}>
                <TabList onChange={handleChange} >

                  <Tab style={{ fontSize: 11 }} label="Escribir Observación" value="1" />
                  <Tab style={{ fontSize: 11 }} label="Historial" value="2" />

                </TabList>
              </Grid>

            </Grid>
          </Box>

          <TabPanel value="1">
            <TextInputBajaFallecimiento datos={datos} setDatos={setDatos} setComunicacionList={setComunicacionList} setIdEstado={setIdEstado} />
          </TabPanel>
          <TabPanel value="2"  >
            <Grid className={classes.messagesBody}>

              {ComunicacionList && ComunicacionList.map((com, index) =>
                <Message
                  message={com.Message}
                  timestamp={com.TimeStamp}
                  userType={com.UsrType}
                  key={index}
                />
              )}
            </Grid>
          </TabPanel>


        </TabContext>


      </Paper>


      <Button style={clase_ocultar_boton} onClick={abrir_chat} variant="contained" color="warning" >

        Observaciones
        {
          ComunicacionList && ComunicacionList.length > 0 &&
          <Badge badgeContent={ComunicacionList.length} color="error" >
            <NotificationsIcon />
          </Badge>


        }
      </Button>

    </div>
  );
}