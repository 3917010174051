import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Autocomplete, Button, Select, Grid, TextField, MenuItem, InputLabel, Typography, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { useSelector, useDispatch } from 'react-redux';
import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { getProfesionalValidarCuit } from 'app/features/Profesionales/profesionalesAPI';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { nacionalidadesList, getNacionalidadesAsync } from 'app/features/Nacionalidades/nacionalidadesSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { crearNuevoTramite, obtenerListaTramites, obtenerTokenBajaFallecimiento } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';
import { TIPOS_USUARIO } from 'constants/constants';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },
    button: {
        display: "flex!important",
        justifyContent: "flex-end!important"
    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    color_rojo: {
        color: 'red'
    },
    option: {
        fontWeight: 'bold',
        color: 'black'
    },
}));

export const DatosPersonales = ({ datos, bloquearBtn, setDatos, setValue, ObrasSociales, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, idEstado, setMatriculas, setCantidadTramites, tpoUser }) => {

    const { handleSubmit } = useForm();
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const clases = useStyles();
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const EstadoCivilList = useSelector(estadoCivilList);
    const NacionalidadesList = useSelector(nacionalidadesList);
    const [labelObraSocial, setLabelObraSocial] = useState('');
    const [errorCuit, setErrorCuit] = useState({ display: "none" });
    const [deshabilitarCampo, setDeshabilitarCampo] = useState(false);
    const tipoUsuario = Number(localStorage.getItem('userType'));
    // const {deshabilitarCampo} = useSelector(state => state.codigoQrReducer);
    // const deshabilitarCampo = JSON.parse(localStorage.getItem('deshabilitarCampo'));
    const ingresaNacionalidad = (event, value) => {

        if (value !== null) {
            setDatos({
                ...datos,
                Nacionalidad: value.value
            });
        } else {
            setDatos({
                ...datos,
                Nacionalidad: ''
            });
        }
    }

    let valueObraSocial = 0;

    const obtenerTramites = async() => {
        const resp = await obtenerListaTramites();

        if (resp !== false) {
            setCantidadTramites(resp.length);
            if (resp.length === 1 && resp[0].idEstado !== 1) {
                setDeshabilitarCampo(true);
            }
            if(resp.length > 1) {
                setDeshabilitarCampo(true);
            }
        }
        
    }

    const ingresaObraSocial = (event, value) => {

        if (value !== null) {
            setLabelObraSocial(value.label)

            setDatos({
                ...datos,
                ObraSocial: value.label,
                TpoOsocial: Number(value.value)
            });
        } else {
            setLabelObraSocial('')
            setDatos({
                ...datos,
                ObraSocial: '',
                TpoOsocial: ''
            });
        }
    }

    const mascaraCuit = (evt) => {
        var codigo = evt.which || evt.keyCode;

        if (codigo !== 8) {
            var cuitNuevo = "";
            let cuit = datos.CuitCuil;

            cuit = cuit.replace(/\-/g, '').replace(/[a-z ]/g, "").split('');

            for (var i = 0; i < cuit.length; i++) {

                if (i === 1 || i === 9) {
                    cuitNuevo += cuit[i] + "-";
                } else {
                    cuitNuevo += cuit[i];
                }

            }
            setDatos({
                ...datos,
                CuitCuil: cuitNuevo
            });
        }
    }

    const mascaraDocumento = (evt) => {
        var codigo = evt.which || evt.keyCode;

        if (codigo !== 8) {
            var documentoNuevo = "";
            let documento = datos.NroDocumento;

            documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "").split('');

            for (var i = 0; i < documento.length; i++) {

                if (i === 1 || i === 4) {
                    documentoNuevo += documento[i] + ".";
                } else {
                    documentoNuevo += documento[i];
                }
            }
            setDatos({
                ...datos,
                NroDocumento: documentoNuevo
            });
        }

    }
    const inputHandleChange = (evt) => {
        if (evt.target.name === "Nacimiento") {
            var hoy = new Date();
            var fechaFormulario = new Date(evt.target.value);
            hoy.setHours(0, 0, 0, 0); // Lo iniciamos a 00:00 horas
    
            if (hoy <= fechaFormulario) {
                Swal.fire({
                    title: 'Atención',
                    html: 'La Fecha de Nacimiento no debe ser posterior a la fecha actual',
                    icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                });
                setDatos({
                    ...datos,
                    [evt.target.name]: ''
                });
                return false;
            }
        } else if (evt.target.name === "RelacionParentesco") {
            // Actualiza específicamente datos.bajaFallecimiento[0].codParentesco
            const newData = {...datos};
            newData.bajaFallecimiento[0].codParentesco = evt.target.value;
            setDatos(newData);
        } else if (evt.target.name === "sexo") {
            setDatos({
                ...datos,
                [evt.target.name]: Number(evt.target.value)
            });
        } else {
            setDatos({
                ...datos,
                [evt.target.name]: evt.target.value
            });
        }
    }

    
    const onSubmit = data => {
        if (datos.Celular.length < 10) {
            Swal.fire({
                title: 'Atención',
                html: 'El número de celular debe tener al menos 10 dígitos',
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return false;
        }
        if (datos.NroDocumento.length !== 10) {
            Swal.fire({
                title: 'Atención',
                html: 'El Numero de Documento ingresado no es valido',
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return false;
        }
        let documento = datos.NroDocumento;

        documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "");

        let cuit = datos.CuitCuil;

        cuit = cuit.substring(3);
        cuit = cuit.substring(0, 8);

        if (datos.CuitCuil.length !== 13 || cuit !== documento) {
            Swal.fire({
                title: 'Atención', html: 'El CUIT-CUIL ingresado no es valido',
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return false;
        }

        if (datos.sexo === null) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe seleccionar el Sexo',
                icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return false;
        }

        getProfesionalValidarCuit(datos.CuitCuil.replace(/-/gi, '')).then((value) => {
            if (!value.data.response) {
                setErrorCuit({ display: '' })

                return false;
            } else {
                setLoaderBtnGuardar(true)
                setBloquearBtn(true);
                guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 99 }).then(async(value) => {
                    setMatriculas(value.data);
                    setLoaderBtnGuardar(false);
                    setBloquearBtn(false);
                    if (value.data.resultado) {
                        if (pathname === '/perfilUsuario') {
                            const resp = await obtenerListaTramites();
                            if(resp.resultado === false) {
                                const response = await crearNuevoTramite({ email: localStorage.email, password: '', tpoTramite: 4, dni:'', tpo_usuario: 11 });
                                const data = await obtenerTokenBajaFallecimiento(response);
                                
                                if(response !== false){
                                    const jsonDatos = JSON.parse(value.data.result.jsonDatos);
                                    localStorage.setItem('usuario', `${jsonDatos.Apellido} ${jsonDatos.Nombre}`);
                                    localStorage.setItem('idLegajoTemporal', response);
                                    localStorage.setItem('token', data.token);
                                    // localStorage.setItem('usuario', `${value.data.apellido} ${value.data.nombre}`);
                                    window.location.href = '/bajaFallecimiento';
                                }
                            }else {
                                if (resp.length === 1 && resp[0].idEstado === 1) {
                                    const response = await obtenerTokenBajaFallecimiento(resp[0].idLegajoTemporal)
                                    const jsonDatos = JSON.parse(value.data.result.jsonDatos);
                                    localStorage.setItem('usuario', `${jsonDatos.Apellido} ${jsonDatos.Nombre}`);
                                    if(response !== false){
                                        localStorage.setItem('idLegajoTemporal', resp[0].idLegajoTemporal);
                                        localStorage.setItem('token', response.token);
                                        window.location.href = '/bajaFallecimiento';
                                    }
                                } else {
                                    const jsonDatos = JSON.parse(value.data.result.jsonDatos);
                                    localStorage.setItem('usuario', `${jsonDatos.Apellido} ${jsonDatos.Nombre}`);
                                    window.location.href = `/menuBajaFallecimiento`;
                                }
                            } 
                        }else {
                            setValue("2");
                        }
                        Swal.fire({
                            title: 'Datos personales guardados correctamente',
                            icon: 'success',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                        })
                    } else {
                        Swal.fire({
                            title: 'Atención',
                            html: value.data.mensaje !== '' && value.data.mensaje !== null ? value.data.mensaje : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                            icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                        })
                        return false;
                    }
                });
            }
        });
    }

    let nacionalidadObjeto = [];

    NacionalidadesList.map((nacionalidad, index) => {

        nacionalidadObjeto.push({ label: nacionalidad.descNacionalidad.trim(), value: nacionalidad.descNacionalidad.trim() })
    })
    
    let obraSocialObjeto = [];
    let obraSocialNombre = "";

    ObrasSociales.map((obraSocial, index) => {
        if (obraSocialNombre.toUpperCase() !== obraSocial.sigla.trim().toUpperCase())
            obraSocialObjeto.push({ label: obraSocial.sigla.trim(), value: obraSocial.tpoOSocial })

        obraSocialNombre = obraSocial.sigla.trim();
    })
    
    let ObrasSocialesOrdenadas = [];

    if (obraSocialObjeto.length > 0) {
        obraSocialNombre = "";
        obraSocialObjeto.map((obraSocial, index) => {
            if (obraSocialNombre.toUpperCase() !== obraSocial.label.trim().toUpperCase()) {
                let agregarObraSocial = 0;
                ObrasSocialesOrdenadas.map(obraSocialRepetida => {
                    if (obraSocialRepetida.label.trim().toUpperCase() === obraSocial.label.trim().toUpperCase())
                        agregarObraSocial = 1;
                })
                if (agregarObraSocial === 0)
                    ObrasSocialesOrdenadas.push({ key: index, label: obraSocial.label.trim().toUpperCase(), value: obraSocial.value })
            }
            obraSocialNombre = obraSocial.label.trim();
        })
    }

    useEffect(() => {
        if (TipoDocumentos.length === 0) {
            dispatch(getTipoDocumentoAsync());
        }
        if (NacionalidadesList.length === 0) {
            dispatch(getNacionalidadesAsync());
        }
        ObrasSociales.map((obraSocial, index) => {
            if (datos.TpoOsocial !== "" && datos.TpoOsocial !== null && obraSocial.tpoOSocial === Number(datos.TpoOsocial)) {
                setLabelObraSocial(obraSocial.sigla.trim());
            }
        })
    }, [])

    useEffect(() => {
        if( Number(localStorage.userType !== 1)){
            obtenerTramites();
        }
    }, [])


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            { tpoUser !== TIPOS_USUARIO.USUARIO_INTERNO ?
                <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >* <b>Completar todos los campos.</b></Typography>
                :
                <Typography variant='h6' className={clases.color_texto_aclaracion} sx={{ mb: 1, ml: 1, color: "black" }} ><b>{datos.CodEnt === 25 ? "Consejo Profesional de Ciencias Económicas." : "Caja de Seguridad Social."}</b></Typography>
            }
            <span sx={{ mb: 2 }}></span>
            <Grid container spacing={2}>
            { tpoUser !== TIPOS_USUARIO.USUARIO_INTERNO &&
                <>
                    <Grid item md={6} xs={6}>
                        <TextField label={<b>Nombre</b>}
                            name='Nombre'
                            onChange={inputHandleChange}
                            value={datos.Nombre}
                            variant={(bloquearBtn === true ) ? "filled" : "outlined"}
                            required
                            disabled={bloquearBtn}
                            fullWidth
                            type="text"
                            inputProps={{ style: {fontWeight: 'bold'}}}
                            // InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                        />
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <TextField label="Apellidos"
                            name='Apellido'
                            onChange={inputHandleChange}
                            value={datos.Apellido}
                            variant={(bloquearBtn === true ) ? "filled" : "outlined"}
                            required
                            disabled={bloquearBtn}
                            fullWidth
                            type="text"
                            inputProps={{ style: {fontWeight: 'bold'}}}
                            InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                        />
                    </Grid>
                
                    <Grid item md={6} xs={6}>
                        <FormControl fullWidth variant={(bloquearBtn === true || deshabilitarCampo === true ) ? "filled" : "outlined"}>
                            <InputLabel id="labelTipoDocumento"
                                sx={{ color: bloquearBtn || deshabilitarCampo ? 'gray' : 'black' }}
                            >Tipo de documento *</InputLabel>
                            <Select
                                labelId="labelTipoDocumento"
                                name="TipoDocumento"
                                onChange={inputHandleChange}
                                required
                                disabled={bloquearBtn || deshabilitarCampo}
                                value={datos.TipoDocumento}
                                label="Tipo de documento"
                                // inputProps={{ style: {fontWeight: 'bold'}}}
                            >
                                {
                                    TipoDocumentos.map((documento, index) =>
                                        <MenuItem key={index} value={documento.tipoDocumento}><b>{documento.descripcion}</b></MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField label="Número"
                            name='NroDocumento'
                            onChange={inputHandleChange}
                            onKeyUp={mascaraDocumento}
                            value={datos.NroDocumento}
                            inputProps={{ maxLength: 10, style: {fontWeight: 'bold'}}}
                            required
                            disabled={bloquearBtn || deshabilitarCampo}
                            variant={(bloquearBtn === true || deshabilitarCampo === true ) ? "filled" : "outlined"}
                            fullWidth
                            type="text"
                            InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField label="CUIT - CUIL"
                            name='CuitCuil'
                            onChange={inputHandleChange}
                            onKeyUp={mascaraCuit}
                            inputProps={{ maxLength: 13, style: {fontWeight: 'bold'}}}
                            value={datos.CuitCuil}
                            required
                            disabled={bloquearBtn || deshabilitarCampo}
                            variant={(bloquearBtn === true || deshabilitarCampo === true ) ? "filled" : "outlined"}
                            fullWidth
                            type="text"
                            InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                        />
                        <Typography className={clases.color_rojo} style={errorCuit} >El CUIT-CUIL ingresado es invalido</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            name='Nacionalidad'
                            onChange={ingresaNacionalidad}
                            options={nacionalidadObjeto}
                            disabled={bloquearBtn}
                            noOptionsText={'No se encontraron Nacionalidades'}
                            value={{ label: datos.Nacionalidad !== undefined ? datos.Nacionalidad : '', value: datos.Nacionalidad !== undefined ? datos.Nacionalidad : '' }}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderInput={(params) => 
                                <TextField {...params} variant={(bloquearBtn === true ) ? "filled" : "outlined"} required label="Nacionalidad"  />}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField 
                            label="Celular (con código de área)"
                            name='Celular'
                            onChange={inputHandleChange}
                            value={datos.Celular}
                            variant={bloquearBtn === true ? "filled" : "outlined"}
                            fullWidth
                            required
                            type="text"
                            disabled={bloquearBtn}
                            inputProps={{ style: {fontWeight: 'bold'}}}
                            error={datos.Celular && (!/^\d+$/.test(datos.Celular))}
                            helperText={
                                datos.Celular && (!/^\d+$/.test(datos.Celular) ? "Solo se permiten números" : "")
                            }
                        />
                    </Grid>
                </>
                }
                <Grid item md={6} xs={12}>
                    <TextField label="Email"
                        name='Email'
                        onChange={inputHandleChange}
                        disabled
                        variant="filled"
                        value={datos.Email}
                        required
                        fullWidth
                        type="email"
                        inputProps={{ style: {fontWeight: 'bold'}}}
                    />
                </Grid>
                <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                    <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary" >{loaderBtnGuardar === true ? <CircularProgress /> : ''}Continuar</Button>
                </Grid>

            </Grid>
        </form >
    )
}