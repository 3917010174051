import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCatalogoTitulosProfesional } from 'app/features/TitulosProfesional/titulosProfesionalAPI';
const initialState = {
	List: [],
	status: 'idle',
  };

export const getTitulosProfesionalesAsync = createAsyncThunk(
	'TitulosProfesional/fetchTitulosProfesionales',
	async data => {
	  const response = await getCatalogoTitulosProfesional(data);
	 
	  return response.data;
	} 
  );


  export const titulosProfesionalesSlice = createSlice({
    name: 'titulosProfesionales',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
      setSearch: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.search = action.payload;
      },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(getTitulosProfesionalesAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getTitulosProfesionalesAsync.fulfilled, (state, action) => {
          state.status = 'idle';
         // console.log(action.payload)
          state.List = action.payload;
        });
    },
  });
  


  export const titulosProfesioaList = (state) => state.titulosProfesionalesReducer.List;
export const universidadesStatus = state => state.titulosProfesionalesReducer.status;

export default titulosProfesionalesSlice.reducer;
