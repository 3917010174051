import axios from 'axios';

import { 
	HEADERS_TOKEN,
	PARTIDOS
} from 'constants/routes/endpoints';
import { getRefreshSessionURL } from 'utils/sessionManager';



export const getCatalogoPartidos = () => {

	return new Promise((resolve, reject) => {
		return axios({
			url: PARTIDOS,
			method: 'GET',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
			if (err.response.status === 401 || err.response.status === 500){
				const redirectURL = getRefreshSessionURL();
				window.location.href = redirectURL;
			}
			});
	});
};

