import React from 'react';
import { makeStyles } from '@mui/styles';
import {  AppBar, Toolbar } from '@mui/material';
import BotonLogin from 'components/header/boton_login'
import { useLocation } from "react-router-dom"

// Styles
import 'scss/components/header.scss';

const useStyles = makeStyles((theme) => ({
   title: {
      flexGrow: 1,
   },
   inputRoot: {
      color: 'inherit',
   },
   toolbar: {
      justifyContent: 'space-between'
   }

}));

const Header = () => {
   const classes = useStyles();
   const sampleLocation = useLocation();


   return (
      <AppBar position="static">
         <Toolbar className={classes.toolbar}>
            <img src="https://www.cpba.com.ar/templates/gk_news/images/style1/xlogo.png.pagespeed.ic.HxJRRfhz9_.webp" height="60" alt='CPBA' />
            {(() => {
          
              
         //  if (localStorage.token !== "" && sampleLocation.pathname !== "/login" && sampleLocation.pathname !== '/registroaltatitulosespeciales'  && sampleLocation.pathname !== "/logininscripciontitulosespeciales" && sampleLocation.pathname !== "/"  && sampleLocation.pathname !== "/Registro"  && sampleLocation.pathname !== "/recuperarpassword") {
         //     return <BotonLogin/>;
         //  }

                        
          if (localStorage.token !== "" && 
            sampleLocation.pathname !== "/login" && 
            sampleLocation.pathname !== '/registroaltatitulosespeciales' && 
            sampleLocation.pathname !== "/logininscripciontitulosespeciales" && 
            sampleLocation.pathname !== "/" && 
            sampleLocation.pathname !== "/Registro" && 
            sampleLocation.pathname !== "/recuperarpassword" && 
            sampleLocation.pathname !== "/loginBajaFallecimiento" && 
            sampleLocation.pathname !== "/registroBajaFallecimiento") {
            return <BotonLogin/>;
         }

        })()}
           
            {/* <Box>
               <Button color="inherit">Inscripcion</Button>
            </Box> */}
            {/* <Button variant="outlined" color="inherit" startIcon={<DeleteIcon />}>
               Login
            </Button> */}
         </Toolbar>
      </AppBar>
   );
};

export default Header;
