import React, { useState } from 'react';
import { Grid, IconButton, Button, TextField, InputAdornment, Card, CardContent, CardActions, Link, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import {
    postAgregarUsuario
} from 'app/features/Usuarios/usuariosAPI';


const RegistroBajaFallecimiento = () => {
    localStorage.setItem('token', "");
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        if (password !== passwordRepetido) {
            return false;
        }


        const lastAtPos = email.lastIndexOf('@');
        const lastDotPos = email.lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            setErrorEmail("error");
            setTextoErrorEmail("Ingrese un email valido");
            return false;
         }else{
            setErrorEmail("");
            setTextoErrorEmail("");
         }
         setMostrarLoader({ display: ''});
        setMostrarContenido({ display: 'none'});
        const result = postAgregarUsuario({ email: email, password: password, tpoTramite: 99,dni:'' });
        result.then((value) => {
           
            if (!value.data.resultado) {

                if (value.data.codigoError === 0) {

                    setTextoErrorEmail("Intentelo nuevamente mas tarde.");
                }
                if (value.data.codigoError === 1) {

                    setTextoErrorEmail("El email ya se encuentra registrado");
                }
                if (value.data.codigoError === 2) {
                    setTextoErrorEmail("El email aún no se encuentra verificado");
                }
                if (value.data.codigoError === 3) {

                    setTextoErrorEmail("El email no se encuentra registrado");
                }
                if (value.data.codigoError === 4) {
                    setTextoErrorEmail("El token es invalido. Por favor solicite uno nuevo.");
                }
                setErrorEmail("error");
            } else {
                Swal.fire({
                    // title: 'Estimado/a',
                    html: '<span style="font-size: 25px;">Por favor verifique su correo:<b>' + email + '</b> <br> para poder validarlo.</span>',
                    icon: 'success',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        window.location.href = `/loginBajaFallecimiento`;
                    }
                });
            }

            setMostrarLoader({ display: 'none'});
            setMostrarContenido({ display: ''});
        });
    };

    // const PeliculasList = useSelector(selectPeliculas);
    const [email, setEmail] = useState();
    const [errorEmail, setErrorEmail] = useState();
    const [textoErrorEmail, setTextoErrorEmail] = useState();
    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState();
    const [showPasswordRepeat, setShowPasswordRepeat] = useState();
    const [passwordRepetido, setPasswordRepetido] = useState('');
    const [errorPassword, setErrorPassword] = useState();
    const [mostrarLoader, setMostrarLoader] = useState({ display: 'none' });
    const [mostrarContenido, setMostrarContenido] = useState({ display: '' });
    const [errorTextoPassword, setErrorTextoPassword] = useState();


    const InputValidatePass = (e) => {
        setPasswordRepetido(e.target.value);

        if (password !== e.target.value) {
            setErrorPassword("error");
            setErrorTextoPassword("Las contraseñas no coinciden");
        } else {

            setErrorPassword("");
            setErrorTextoPassword("");
        }
    }
    const handleClickShowPasswordRepeat = () => {

        setShowPasswordRepeat(!showPasswordRepeat);
    };
    const handleClickShowPassword = () => {

        setShowPassword(!showPassword);
    };

    const onchangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const onchangePassword = (e) => {
   
        
        setPassword(e.target.value);

        if  (passwordRepetido !== "")
        {
         
            if (e.target.value !== passwordRepetido) {
                setErrorPassword("error");
                setErrorTextoPassword("Las contraseñas no coinciden");
            } else {
    
                setErrorPassword("");
                setErrorTextoPassword("");
            }
        }
    }

    return (
        <Grid
        
        align='center'
         direction="column"
    >
        <form onSubmit={handleSubmit(onSubmit)}>
                <Grid md={6} item>
                    <Card>
                        <CardContent >
                            <Typography align='center' variant="h6">Registro</Typography>
                            <Grid sx={{ mb: 3, mt: 2 }} item md={12} xs={12} >
                                <TextField label="Email"
                                    error={errorEmail}
                                    helperText={textoErrorEmail}
                                    value={email}
                                    {...register("email")}
                                    onChange={(value) => onchangeEmail(value)}
                                    required
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    inputProps={{ sx: {fontWeight: 'light'}}}
                                    InputLabelProps={{ sx: { fontWeight: 'normal', color: 'gray' }}}
                                />

                            </Grid>

                            <Grid item sx={{ mb: 3 }} md={12} xs={12}  >
                                <TextField label="Contraseña"
                                    value={password}
                                    type={showPassword ? "text" : "password"}
                                    {...register("password")}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}
                                    onChange={(value) => onchangePassword(value)}
                                    required
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ sx: { fontWeight: 'normal', color: 'gray' }}}
                                    inputProps={{ sx: { fontWeight: 'light' }}}
                                />
                            </Grid>
<Grid
                             sx={{ mb: 3 }} item md={12} xs={12} >
                                <TextField
                                    type={showPasswordRepeat ? "text" : "password"}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPasswordRepeat}
                                                >
                                                    {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}
                                    value={passwordRepetido}
                                    label=" Repetir Contraseña"
                                    onChange={(value) => InputValidatePass(value)}
                                    error={errorPassword}
                                    helperText={errorTextoPassword}
                                    required variant="outlined"
                                    fullWidth
                                    inputProps={{ sx: { fontWeight: 'light' }}}
                                    InputLabelProps={{ sx: { fontWeight: 'normal', color: 'gray' }}}
                                />

                            </Grid>

                            <CardActions
                                sx={{
                                    justifyContent: "center",
                                }}>

                                <Button startIcon={<AddIcon />}  style={mostrarContenido} variant="contained" type="submit" color="primary" >
                                    Registrarse
                                </Button>
                                <Button style={mostrarLoader}  variant="contained"  color="primary" >
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "white"
                                        }}
                                    />
                                </Button>

                            </CardActions>
                            <Grid item sx={{ mt: 2 }} md={12} xs={12} className="centrar_contenido"  >
                                <Grid className="centrar_contenido">
                                    Si ya tienes una cuenta.
                                    <Link href="/loginBajaFallecimiento">
                                        Inicia Sesión
                                    </Link>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
        </form>
        </Grid>
    )
}

export default RegistroBajaFallecimiento;
