import { TRAMITES } from "constants/constants";

export function isSessionExpired() {
    const session = getSession();
    const expirationDate = new Date(session.expirationDate);
    const now = new Date();
    return now > expirationDate;
}

export function getRefreshSessionURL(){
    const session = getSession();
    const tramite = TRAMITES.find((tramite) => tramite.id === session.tpoTramite);
    return tramite.loginURL;
}

export function createSession(session){
    localStorage.setItem('token', session.token);
    localStorage.setItem('idLegajoTemporal',  session.idLegajoTemporal);
    localStorage.setItem('usuarioValidarDatos', session.user);
    localStorage.setItem('passValidarDatos', session.pass);
    localStorage.setItem('idLegajo', session.legajoProfesional);
    localStorage.setItem('email', session.email);
    localStorage.setItem('userType', session.userType);
    localStorage.setItem('usuario', session.usuarioInterno);
    localStorage.setItem('tpoTramite', session.tpoTramite);

    if(session.validacionTitulo)
        localStorage.setItem('validacionTitulo', 1);
    // 4 hs de expiracion hardcodeado 
    localStorage.setItem('expirationDate', new Date(new Date().getTime() + 14400000));
}

export function destroySession(){
    localStorage.clear();
}

export function getSession(){
    return {
        token: localStorage.getItem("token"),
        idLegajoTemporal: localStorage.getItem("idLegajoTemporal"),
        legajoProfesional: localStorage.getItem("idLegajo"),
        email: localStorage.getItem("email"),
        userType: localStorage.getItem("userType"),
        usuarioInterno: localStorage.getItem("usuario"),
        tpoTramite: Number(localStorage.getItem("tpoTramite")),
        expirationDate: localStorage.getItem("expirationDate"),
    }
}

