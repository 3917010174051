import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tab, Grid, Box, Paper, Typography, CircularProgress } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { eliminarCodigoQR, generarCodigoQR, getOperacion, obtenerEstadoOperacionQr, obtenerVerificacionPagoQr } from 'app/features/Profesionales/profesionalesAPI';
import { clearCodigoQr, setCodigoQr } from 'app/features/CodigoQr/codigoQrSlice';

export const PagoQR = ({setMedioDePago, setResult}) => {

  const [numeroOperacion, setNumeroOperacion] = useState(null);
  const [operacionQr, setOperacionQr] = useState(null);
  const [bloquearBtn, setBloquearBtn] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [notificacionPago, setNotificacionPago] = useState(false);

  const dispatch = useDispatch();
  const {qr, tiempoVerificacion, vencimiento} = useSelector(state => state.codigoQrReducer.codigoQr);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const crearCodigoQR = async() => {
    const {idOperacion} = await getOperacion();
    setNumeroOperacion(idOperacion);

    if(qr === null) {
      const resp = await generarCodigoQR(idOperacion)
      if (resp) {
        dispatch(setCodigoQr(resp));
      }
    }
  }

  const estadoOperacionQr = async() => {
   const data = await obtenerEstadoOperacionQr(numeroOperacion);
   setOperacionQr(data)
   if(data) {
    //* TODO: Agregar un SweetAlert que indique que el pago fue aprobado
    setResult(true)
    }
  }

  const verificacionPago = async() => {
    setNotificacionPago(false)
    setDisabledBtn(true)
    //* TODO: Manejar si se verifica el pago o no
    const data = await obtenerVerificacionPagoQr(numeroOperacion);
    if(data) {
     //* Si data es true, se verifica el pago, setResult(true)
      setResult(true)
    }
    setDisabledBtn(false);
    setNotificacionPago(true);
  }

  const goBack = async () => {
    setBloquearBtn(true);
    const data = await eliminarCodigoQR(numeroOperacion);
    setBloquearBtn(false);
    if (data) {
      dispatch(clearCodigoQr());
      setMedioDePago("0")
    } 
  }

  useEffect(() => {
    crearCodigoQR()
  }, [])

  useEffect(() => {
    if (numeroOperacion !== null && qr !== null) {
      const intervalId = setInterval(() => {
        estadoOperacionQr();
      }, 5000); // 3 segundos

      const timeoutId = setTimeout(() => {
          clearInterval(intervalId);
          // Agrego un estado para manejar el spiner de carga y la leyenda "Esperando notificacion de pago" 
          // para cambiarla por "No se recibió notificación de pago"
          if(!operacionQr) {
            setNotificacionPago(true)
          }
          // setDisabledBtn(false);
      }, (tiempoVerificacion*1000)); // 5 minutos 

      return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
      };
    }
  }, [tiempoVerificacion, numeroOperacion]);

  return (
    <Grid>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Grid xs={12}>
                <Typography gutterBottom display='flex' justifyContent="center" alignItems="center" fontSize={'0.85rem'}>
                    <b>Numero de operación: {numeroOperacion}</b>
                </Typography>

               <Box display="flex" justifyContent="center" alignItems="center">
                  {
                    (qr === null) ? <CircularProgress /> : <img src={"data:image/png;base64," + qr} style={{width: '150px', height:'150px' }} />    
                  } 
                </Box>
                {(qr !== null) &&
                  <Typography gutterBottom display='flex' justifyContent="center" alignItems="center" fontSize={'0.85rem'}>
                    
                      <b>Vencimiento: </b>{vencimiento}
                  </Typography>
                }
                {
                  (qr !== null) && (
                  <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                    { !notificacionPago ? <CircularProgress /> : <CloseIcon color='error' fontSize='large'/> }
                  
                      <Typography gutterBottom display='flex' justifyContent="center" alignItems="center" fontSize={'0.85rem'} ml={2} mt={1} >
                        { !notificacionPago ? "Esperando notificacion de pago" : "No se recibió notificación de pago" } 
                      </Typography>
                  </Box>
                 )
                }

              <Typography gutterBottom display='flex' justifyContent="center" alignItems="center" fontSize={'0.85rem'} mt={2}>
                <b>Si ya efectuó el pago y aún no visualiza la imputacion del mismo, presione "Verificar Pago"</b>
              </Typography>

              <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <Button variant="contained" color="secondary" disabled={ disabledBtn || bloquearBtn } onClick={verificacionPago}>
                  Verificar Pago
                </Button>
              </Box>

            </Grid>
        </Box>

          <Grid item xs={12} display='flex' mt={4} >
            <Grid xs={12} md={6} display={isXsScreen ? 'flex' : ''} justifyContent={isXsScreen ? "center" : ""}>
              <Button startIcon={<ArrowBackIosIcon />} onClick={() => goBack()} variant="contained" color="error"  fullWidth={isXsScreen} disabled={bloquearBtn } >
                { bloquearBtn ? <CircularProgress /> : "Atras" } 
              </Button>
            </Grid>
          </Grid>
    </Grid>
  )
}
