import React from "react";
import {makeStyles } from "@material-ui/core/styles";

import { deepOrange } from "@material-ui/core/colors";
// import moment from 'moment';

const useStyles = makeStyles(theme => ({
  messageRowLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
  messageRowRight: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
  }
  // messageContent: {
  //   padding: 0,
  //   color: "#fff",
  //   margin: 0
  // },
  // messageTimeStampRight: {
  //   marginTop: "3px",
  //   marginButtom: "0",
  //   fontSize: ".85em",
  //   fontWeight: "300",
  //   textAlign: "right"
  // },
  // messageTimeStamp: {
  //   marginTop: "3px",
  //   marginButtom: "0",
  //   fontSize: ".85em",
  //   fontWeight: "300",
  // },

 
  // messageWhite: {
  //   position: "relative",
  //   marginLeft: "20px",
  //   marginBottom: "10px",
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2),
  //   backgroundColor: "#fff",
  //   maxWidth: "90%",
  //   textAlign: "left",
  //   font: "400 .9em 'Open Sans', sans-serif",
  //   borderRadius: "20px 20px 20px 0"
  // }

})
);

export const Message = (props) => {

  const convertDateFormat = (string) => {

    var info = string.split('-');
   // console.log(info)
    return info[2].slice(0,2) + '/' + info[1] + '/' + info[0];
}
 // const timestamp = moment(props.timestamp).format('dd-MM-yyyy') === moment(new Date()).format('dd-MM-yyyy') ? moment(props.timestamp).format('HH:mm') : moment(props.timestamp).format('dddd HH:mm');
  const classes = useStyles();
  return (
    <div  className={props.userType === Number(localStorage.userType) ?classes.messageRowRight : classes.messageRowLeft }>
      <div >
    
        <p>{props.message}</p>
        <div >{convertDateFormat(props.timestamp)}</div>
      </div>
    </div>
  );
};
