import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCatalogoEstadoCivil } from 'app/features/EstadoCivil/estadoCilvilAPI';
const initialState = {
  List: [],
  status: 'idle',
};


export const getEstadoCivilAsync = createAsyncThunk(
  'EstadoCivil/fetchEstadoCivil',
  // async (amount, { getState }) => {
  //   const state = getState();
  //   const response = await fetchFirmantes(state.firmantesReducer.search);
  //   // The value we return becomes the `fulfilled` action payload
  //   return response.data;
  // }

  async data => {
    const response = await getCatalogoEstadoCivil(data);
    // console.log({response});
    return response.data;
  }
);

export const estadoCilvilSlice = createSlice({
  name: 'estadoCivil',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSearch: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.search = action.payload;
    },

    // saveFamiliar: (state, action) => {
    // 	const familiarToSave = {
    // 		empleado_id: state.EmpleadoSelected.id,
    // 		nombre: action.payload.nombre,
    // 		parentezco: action.payload.parentezco,
    // 		id: action.payload.id,
    // 	};
    // 	if (action.payload?.id !== '') {
    // 		putFamiliarAsync(familiarToSave);
    // 	} else {
    // 		postFamiliarAsync(familiarToSave);
    // 	}
    // 	state.EmpleadoSelected.familiares[action.payload.index].saved = true;
    // 	// return a
    // },

    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getEstadoCivilAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getEstadoCivilAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        // console.log(action.payload)
        state.List = action.payload;
      });
  },
});



export const estadoCivilList = (state) => state.estadoCivilReducer.List;
export const estadoCivilStatus = state => state.estadoCivilReducer.status;

export default estadoCilvilSlice.reducer;
