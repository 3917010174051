
import { Box, Button, Modal, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { getProfesionalValidarMailActualizacion } from "app/features/Profesionales/profesionalesAPI";
import { makeStyles } from "@mui/styles";

const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
};

const useStyles = makeStyles(_ => ({
    color_rojo: {
        color: 'red'
    },
}));

function ModalEditarCuit({ open, onClose, cuit, onCuitChange }) {

    const [errorCuit, setErrorCuit] = useState({ display: "none" });
    const [errorCuitLabel, setErrorCuitLabel] = useState(false);
    const [datosModalCuit, setDatosModalCuit] = useState({
        CuitCuil: cuit
    });
    const clases = useStyles();

    useEffect(() => {
        if(open){
            setErrorCuitLabel(false);
            setErrorCuit({ display: "none" });
            setDatosModalCuit({
                CuitCuil: cuit
            });
        }
    }, [open]);

    function handleAceptar() {
        if (datosModalCuit.CuitCuil === '') {

            setErrorCuitLabel(true)
            setErrorCuit({ display: "" })
            return false;
        }
        onCuitChange(datosModalCuit.CuitCuil);
        onClose();
        
    }



    const mascaraCuit = (evt) => {
        var codigo = evt.which || evt.keyCode;

        if (codigo !== 8) {
            var cuitNuevo = "";
            let cuit = datosModalCuit.CuitCuil;

            cuit = cuit.replace(/\-/g, '').replace(/[a-z ]/g, "").split('');

            for (var i = 0; i < cuit.length; i++) {

                if (i === 1 || i === 9) {
                    cuitNuevo += cuit[i] + "-";
                } else {
                    cuitNuevo += cuit[i];
                }

            }
            console.log(cuitNuevo);
            setDatosModalCuit({
                ...datosModalCuit,
                CuitCuil: cuitNuevo
            });
        }

    }

    const inputHandleChange = (evt, val) => {
        console.log(val);
        console.log(evt.target.value);
        if (evt.target.name === "CuitCuil" && evt.target.value === "") {
            setErrorCuitLabel(true);
            setErrorCuit({ display: "" });
        }

        

        setDatosModalCuit({
            CuitCuil: evt.target.value
        });
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            onClose={onClose}
            style={modalStyle}
            open={open}
            closeAfterTransition>
            <Paper sx={{ minWidth: "350px", width: "30vw", padding: "20px" }}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"} variant='subtitle1'>Modificar CUIT - CUIL</Typography>
                    <Button onClick={onClose}>
                        <CloseIcon />
                    </Button>
                </Box>
                <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box>
                        <TextField label=""
                            name='CuitCuil'
                            error={errorCuitLabel}
                            onChange={inputHandleChange}
                            onKeyUp={mascaraCuit}

                            inputProps={{ maxLength: 13 }}
                            value={datosModalCuit.CuitCuil}

                            InputProps={{ style: { fontSize: 20 } }}
                            InputLabelProps={{ style: { fontSize: 23 } }}
                            required
                            variant={"outlined"}
                            fullWidth
                            type="text"
                        />
                        <Typography className={clases.color_rojo} style={errorCuit} >El CUIT-CUIL no es valido</Typography>
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "2vh" }}>
                    <Button onClick={handleAceptar} variant='contained' color={'secondary'}>Confirmar</Button>
                </Box>
            </Paper>
        </Modal>);
}

export default ModalEditarCuit;