import { useState } from 'react';
import { Button, Tab, Grid, Box, Paper, Typography } from '@mui/material';
import { PagoBrick } from "components/matriculas/pagoBrick"
import { PagoPendiente } from "components/matriculas/pagoPendiente"
import { PagoQR } from "components/matriculas/pagoQR"
import { SeleccionDeMedioDePago } from "components/matriculas/seleccionDeMedioDePago"
import { PagoCompletadoAltaTitulosEspeciales } from "./pagoCompletadoAltaTitulosEspeciales"


export const DerechoDeInscripcionAltaTituloEspecial = ({ datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, ocultarContenido, setOcultarContenido, bloquearTabs, aprobacionDocumentacion, setAprobacionDocumentacion, resultadoPagoTasa, estadoOperacion, obtenerResultadoDePago, setEstadoOperacion, setResult, montoDeTasa, idLegajoTemporal, medioDePago, setMedioDePago }) => {
  return (
    <Grid>
    {
        estadoOperacion === 1 ? 
         <PagoPendiente obtenerResultadoDePago={obtenerResultadoDePago} setEstadoOperacion={setEstadoOperacion}/>
        :
        (!resultadoPagoTasa ? 
            (() => {
                switch(medioDePago) {
                    case "0":
                        return <SeleccionDeMedioDePago setMedioDePago={setMedioDePago} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} bloquearBtn={bloquearBtn} setValue={setValue}/>
                    case "1":
                        return <PagoBrick setResult={setResult} montoDeTasa={montoDeTasa} setMedioDePago={setMedioDePago}/>
                    case "2":
                        return <PagoQR setMedioDePago={setMedioDePago} setResult={setResult}/>
                }
            })()
            : 
            <PagoCompletadoAltaTitulosEspeciales datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setValue} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} resultadoPagoTasa={resultadoPagoTasa}  estadoOperacion={estadoOperacion} aprobacionDocumentacion={aprobacionDocumentacion} setAprobacionDocumentacion={setAprobacionDocumentacion} />
        )
    }
    </Grid>
  )
}
