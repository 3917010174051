import React, { useState, useEffect } from 'react';
import { Domicilios } from 'components/InscripcionTitulosEspeciales/domicilios';
import { Titulos } from 'components/InscripcionTitulosEspeciales/titulos'
import { GrupoFamiliar } from 'components/InscripcionTitulosEspeciales/grupoFamiliar';
import { Chat } from 'components/chatInscripcionEspecial/chat';
import { ArchivosAdjuntos } from 'components/InscripcionTitulosEspeciales/archivosAdjuntos';
import DatosPersonales from 'components/InscripcionTitulosEspeciales/datosPersonales';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tab, Grid, Box, Paper } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { matriculasList, getMatriculasAsync } from 'app/features/Matriculas/matriculasSlice';
import { TIPOS_USUARIO } from 'constants/constants';



import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { EMPTY_FILE } from 'constants/icons';

// Styles
import 'scss/components/header.scss';
import { getTitulosEspecialesAsync, titulosEspecialesList } from 'app/features/Titulos/titulosEspecialesSlice';
import moment from 'moment';
import { ESTADOS_TRAMITE } from 'constants/constants';
import { PagoBrick } from 'components/matriculas/pagoBrick';
import { getDerechoInscripcion, getMontoDeTasa, getOperacion, getResultadoPagoDeTasa } from 'app/features/Profesionales/profesionalesAPI';

import { PagoCompletadoTitEspecial } from 'components/InscripcionTitulosEspeciales/pagoCompletadoTitEspecial';
import { ArchivosAdjuntosPostCarga } from 'components/InscripcionTitulosEspeciales/archivosAdjuntosPostCarga';
import { PagoPendiente } from 'components/matriculas/pagoPendiente';
import { DerechoDeInscripcionTituloEspecial } from 'components/InscripcionTitulosEspeciales/derechoDeInscripcionTituloEspecial';
import { obtenerQrAsync, setCodigoQr } from 'app/features/CodigoQr/codigoQrSlice';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },

    ocultarBotonesContinuar: {

        '@media (min-width:780px)': {
            display: 'none'
        }
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }

}));

const libraries = ['places'];

const InscipcionTitulosEspeciales = () => {

    

    const dispatch = useDispatch();
    const clases = useStyles();
    const [selectedTab, setSelectedTab] = useState('1');
    const [Matriculas, setMatriculas] = useState([]);
    const [bloquearBtn, setBloquearBtn] = useState(false);
    const [mostrarDatos, setMostrarDatos] = useState(false);
    const ObrasSociales = useSelector(obrasSocialesList);
    const [idLegajoTemporal, setIdLegajoTemporal] = useState(null);
    const [aprobacionDocumentacion, setAprobacionDocumentacion] = useState(null);

    const [imprimirResultado, setImprimirResultado] = useState(0);
    const [bloquearTabs, setBloquearTabs] = useState(1);
    const [resultadoPagoTasa, setResult] = useState();
    const [ocultarContenido, setOcultarContenido] = useState(false);
    // const [resultadoDerechoInscripcion, setResultadoDerechoInscripcion] = useState();
    const [montoDeTasa, setMontoDeTasa] = useState();
    const [estadoOperacion, setEstadoOperacion] = useState();
    const [medioDePago, setMedioDePago] = useState("0");

    const ListadoTitulos = useSelector(titulosEspecialesList);

    const [datos, setDatos] = useState({
        Nombre: '',
        Apellido: '',
        Nacimiento: '',
        NroDocumento: '',
        EstadoCivil: null,
        TipoDocumento: 0,
        CuitCuil: '',
        sexo: null,
        Observado: false,
        TpoOsocial: 0,
        PerteneceOtroConsejo: null,
        Nacionalidad: '',
        Email: localStorage.email,
        titulos: [],
        domicilios: [
            { TpoDomicilio: 1, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 },
            { TpoDomicilio: 2, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 },
            { TpoDomicilio: 4, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 },
            { TpoDomicilio: 3, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 },
            { TpoDomicilio: 5, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 }
        ],
        adjuntos: [
            { fileType: 'DNI', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Resolución Ministerio de Educación', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Plan de estudios', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Detalle de incumbencias profesionales', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Certificado de libre sanción disciplinaria de otros consejos profesionales', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Declaración jurada de enfermedades y patologías preexistentes', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Analítico', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true }
        ]
    });

    const [idEstado, setIdEstado] = useState(1);
    const ordenarAdjuntos = (adjuntos) => {
        const adjuntosDNIs =  adjuntos.filter(a => a.fileType.indexOf('DNI') >= 0);
        const adjuntosFotos =  adjuntos.filter(a => a.fileType.indexOf('Foto') >= 0);
        const adjuntosMinisterio =  adjuntos.filter(a => a.fileType.indexOf('Ministerio') >= 0);
        const adjuntosTitulo = adjuntos.filter(a => a.fileType.search('-') >= 0);
        const adjuntosPlan = adjuntos.filter(a => a.fileType.indexOf('Plan') >= 0);
        const adjuntosDetalle = adjuntos.filter(a => a.fileType.indexOf('Detalle') >= 0);
        const adjuntosAnalitico = adjuntos.filter(a => a.fileType.indexOf('Analítico') >= 0);
        const adjuntosLibreSancion = adjuntos.filter(a => a.fileType.indexOf('sanción') >= 0);
        const adjuntosDDJJ = adjuntos.filter(a => a.fileType === "Declaracion Jurada");
        const adjuntosDDJJSalud = adjuntos.filter(a => a.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes"); 

        return [
            ...adjuntosDNIs,
            ...adjuntosFotos,
            ...adjuntosMinisterio,
            ...adjuntosTitulo,
            ...adjuntosPlan,
            ...adjuntosDetalle,
            ...adjuntosAnalitico,
            ...adjuntosLibreSancion,
            ...adjuntosDDJJSalud,
            ...adjuntosDDJJ
        ];
    }

    if (imprimirResultado === 0 && Matriculas.codigoError === 0 && ListadoTitulos.length > 0) {


        if (Matriculas.mensaje !== "Legajo no encontrado!" && Matriculas.result.jsonDatos !== null) {

            const datosProfesional = JSON.parse(Matriculas.result.jsonDatos);

            setIdEstado(Matriculas.result.idEstado);
            setIdLegajoTemporal(Matriculas.result.idLegajoTemporal);
            setAprobacionDocumentacion({
                documentacionEnviadaParaRevision: Matriculas.result.documentacionEnviadaParaRevision,
                fechaEnvioDocumentacion: Matriculas.result.fechaEnvioDocumentacion,
                fechaAprobacionDocumentacion: Matriculas.result.fechaAprobacionDocumentacion !== null ? moment(Matriculas.result.fechaAprobacionDocumentacion).format("yyyy-MM-DD"): null,
                nroResolucionAprobacionDocumentacion: Matriculas.result.nroResolucionAprobacionDocumentacion 
            });

            setBloquearTabs(Matriculas.result.idEstado);
            if (Matriculas.result.idEstado === ESTADOS_TRAMITE.DRAFT) {
                Swal.fire({
                    title: '',
                    html: 'Se recuerda que antes de comenzar a cargar la solicitud  <u><b>deberá poseer en formato JPG, PDF, JPEG o PNG</b></u> los documentos indicados en los requisitos de inscripción detallados en el siguiente link:<a href="https://www.cpba.com.ar/component/k2/item/13822" target="_blank">clic aquí</a>',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
            } else if ((Matriculas.result.idEstado === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION || 
                Matriculas.result.idEstado === ESTADOS_TRAMITE.PRE_CARGA_VALIDADA || Matriculas.result.idEstado === ESTADOS_TRAMITE.VALIDADO) && (localStorage.userType != TIPOS_USUARIO.SECRETARIO_TECNICO)) {
                    Swal.fire({
                        title: '',
                        html: 'Se recuerda que antes de comenzar a cargar la solicitud deberá poseer en formato JPG, PDF, JPEG o PNG la siguiente documentación: <ul><li> Foto tipo carnet color, de frente y fondo claro </li><li> Sólo para mayores de 50 años: declaración jurada de enfermedades y patologías preexistentes completo en todos sus campos y firmado. <a target="_blank" href="https://www.cpba.com.ar/images/F-PE.02.08_DDJJ_Enfermedades_y_Patologias_Preexistentes_Ley_12724.pdf">[DESCARGAR AQUÍ]</a> </li><li> Sólo para profesionales matriculados en otras jurisdicciones: certificado de libre sanción disciplinaria emitido por los consejos profesionales respectivos.</li> <br/>Ante cualquier duda o consulta, comunicarse al (0221) 441 4100 –Sector Matrículas-.',
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonText:
                            'Aceptar',
                        cancelButtonText:
                            'Cancelar',
                        allowOutsideClick: true
                    });
            }
            if (Matriculas.result.idEstado === ESTADOS_TRAMITE.PENDIENTE || Matriculas.result.idEstado === ESTADOS_TRAMITE.EN_REVISION 
                || Matriculas.result.idEstado === ESTADOS_TRAMITE.PRE_CARGA_FINALIZADA || Matriculas.result.idEstado === ESTADOS_TRAMITE.FINALIZADA 
                || (Matriculas.result.idEstado === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && datosProfesional.Observado === false) || (Matriculas.result.idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION && datosProfesional.Observado === false) || localStorage.userType === "1") {
                setBloquearBtn(true);
            }
            let arrFiles = [];
            if (Array.isArray(datosProfesional.adjuntos)) {
                if(datosProfesional.adjuntos.filter(c => c.fileType === 'Foto').length < 1){
                    arrFiles.push({ fileType: 'Foto', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true });
                }

                datosProfesional.adjuntos.forEach((adj) => {
                        arrFiles.push({ fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file !== null ? adj.file : '', size: adj.size, url: adj.url === "" ? EMPTY_FILE : adj.url, saved: true });
                
                })

            } else {
                arrFiles = datos.adjuntos;
            }

            if (Array.isArray(datosProfesional.titulos)) {
            datosProfesional.titulos
            .forEach((tit) => {
                let nombreTituloMatr = tit.nombreTitulo.trim();
                if(tit.codTitulo){
                    nombreTituloMatr = ListadoTitulos.find(t => t.codTitulo === tit.codTitulo).tituloNombre.trim();
                }

                if(arrFiles.filter(c => c.fileType === 'Título - ' + nombreTituloMatr).length < 1){
                    arrFiles.push({ fileType: 'Título - ' + nombreTituloMatr, fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true });
                }
            });
            }else{
                datosProfesional.titulos = [];
            }


            setDatos({
                ...datos,
                Nombre: datosProfesional.Nombre,
                Apellido: datosProfesional.Apellido,
                PerteneceOtroConsejo: datosProfesional.PerteneceOtroConsejo === undefined ? null : datosProfesional.PerteneceOtroConsejo,
                Nacimiento: datosProfesional.Nacimiento,
                NroDocumento: datosProfesional.NroDocumento,
                ObraSocial: datosProfesional.ObraSocial !== undefined && datosProfesional.ObraSocial !== null ? datosProfesional.ObraSocial : '',
                sexo: datosProfesional.sexo ? datosProfesional.sexo : 0,
                EstadoCivil: datosProfesional.EstadoCivil ? datosProfesional.EstadoCivil : null,
                TpoOsocial: datosProfesional.TpoOsocial,
                TipoDocumento: datosProfesional.TipoDocumento,
                CuitCuil: datosProfesional.CuitCuil,
                Observado: datosProfesional.Observado !== undefined && datosProfesional.Observado !== null ? datosProfesional.Observado : false,
                Nacionalidad: datosProfesional.Nacionalidad ? datosProfesional.Nacionalidad : '',
                Email: datosProfesional.Email ? datosProfesional.Email : localStorage.email,
                titulos: datosProfesional.titulos,
                familiares: datosProfesional.familiares,
                domicilios: Array.isArray(datosProfesional.domicilios) ? datosProfesional.domicilios : datos.domicilios,
                adjuntos: ordenarAdjuntos(arrFiles),
                exentoTasa: datosProfesional.exentoTasa !== undefined ? datosProfesional.exentoTasa : true
            })

            setTimeout(() => setMostrarDatos(true), 1000);

            //   setMostrarDatos(true);
            setImprimirResultado(1);
        } else if (Matriculas.codigoError !== null && imprimirResultado === 0) {
            // alert(Matriculas.codigoError);
            Swal.fire({
                title: '',
                html: 'Se recuerda que antes de comenzar a cargar la solicitud de inscripción  <u><b>deberá poseer en formato deberá poseer en formato JPG, PDF, JPEG o PNG</b></u> los documentos indicados en los requisitos de inscripción detallados en el siguiente link:<a href="https://www.cpba.com.ar/component/k2/item/13822" target="_blank">clic aquí</a>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            setImprimirResultado(1);
            setTimeout(() => setMostrarDatos(true), 1000);
        }

    }

    const obtenerResultadoDePago = async() => {

       const data = await getResultadoPagoDeTasa();
        setResult(data.resultado);   
        
    }

    
    const obtenerOperacion = async() => {
        
        const data = await getOperacion();
        if (data) {
            setEstadoOperacion(data.estado)
            setMontoDeTasa(data.monto)
        }
        
        
    }

    
    const obtenerQR = async() => {
        
        dispatch(obtenerQrAsync()).then((data) => {
            if(data.payload.qr === null || !data.payload.qr) {
                setMedioDePago("0")
            }else{
                dispatch(setCodigoQr(data.payload))
                setMedioDePago("2")
           }
        
        }).catch((error) => {
            //* TODO: Si hay error debo hacer algo como esto
            dispatch(setCodigoQr({qr: null, tiempoVerificacion: null, vencimiento: null}))
            setMedioDePago("0")
        })

    }

    // const obtenerMontoDeTasa = async() => {
    //     const resp = await getMontoDeTasa();
    //     setMontoDeTasa(resp.data)
    // }

    const [location, setLocation] = useState({
        lat: -62.7113387,
        lng: -95.00657030000001,
    });

    const handleChange = (event, newValue) => {

        setSelectedTab(newValue);


    };
    const [loaderBtnGuardar, setLoaderBtnGuardar] = useState(false);

    useEffect(() => {
        if (Matriculas.length === 0) {
            let Null = null;


            //llamo a la funcion del Slice
            dispatch(getMatriculasAsync({ Null, Null })).then(valor => setMatriculas(valor.payload));
        }
        if (ObrasSociales.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getObrasSocialesAsync());
        }

        dispatch(getTitulosEspecialesAsync());

        navigator.geolocation.getCurrentPosition(position => {
            setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
    }, [])



    if (localStorage.token === "") {

        window.location.href = `/login`;

    }

    const cambiarTab = (tabNuevo) => {
        if (tabNuevo === 1 && selectedTab !== "1") {
            let nuevoValor = Number(selectedTab) - 1;

            if ((idEstado === 1 || idEstado === 3 || idEstado === 8) && nuevoValor === 2)
                nuevoValor -= 1;

            setSelectedTab(String(nuevoValor));


        } else if (tabNuevo === 2 && selectedTab !== "6") {

            let nuevoValor = Number(selectedTab) + 1;

            if ((idEstado === 1 || idEstado === 3 || idEstado === 8) && nuevoValor === 2)
                nuevoValor += 1;

            setSelectedTab(String(nuevoValor));
        }

    }

    const [ocultarTabs, setOcultarTabs] = useState({ display: '' });

    useEffect(() => {
        obtenerResultadoDePago();   
    }, [])

    
    // useEffect(() => {
    //     obtenerResultadoDerechoInscripcion();   
    // }, [])

    useEffect(() => {
        obtenerOperacion();   
    }, [])

    useEffect(() => {
        obtenerQR();   
    }, [])

    return (

        <Grid container >


            {mostrarDatos ?
                <Grid item xs={12} md={11}>

                    <TabContext value={selectedTab}>

                        <Box>
                            <Grid container style={ocultarTabs}>
                                <Grid item xs={2} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(1)}><ArrowBackIosIcon /></Button>
                                </Grid>
                                <Grid item md={12} xs={9}>
                                    <TabList onChange={handleChange} >
                                        <Tab label={<b>Datos Personales</b>} value="1" style={ocultarTabs} />
                                        <Tab label={<b>Grupo familiar primario</b>} value="2" style={{ ocultarTabs, display: idEstado === 1 || idEstado === 3 || idEstado === 8 ? 'none' : ocultarTabs }} />
                                        <Tab label={<b>Domicilios</b>} value="3" style={ocultarTabs} />
                                        <Tab label={<b>Título a inscribir</b>} value="4" style={ocultarTabs} />
                                        <Tab label={<b>Adjuntos</b>} value="5" style={ocultarTabs} />
                                        {
                                            (idEstado !== ESTADOS_TRAMITE.DRAFT && idEstado !== ESTADOS_TRAMITE.EN_REVISION && idEstado !== ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION) &&
                                            (!(Matriculas.result.exentoTasa == null? true : Matriculas.result.exentoTasa) || resultadoPagoTasa) && 
                                            <Tab label ={<b>Derecho de Inscripción</b>} value="6" style={ocultarTabs} />
                                        }

                                    </TabList>
                                </Grid>
                                <Grid item xs={1} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(2)}><ArrowForwardIosIcon /></Button>
                                </Grid>
                            </Grid>
                        </Box>

                        <Paper>
                            <TabPanel value="1">
                                <DatosPersonales datos={datos} bloquearBtn={bloquearBtn} bloquearTabs={bloquearTabs} setBloquearBtn={setBloquearBtn} setDatos={setDatos} setValue={setSelectedTab} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} />
                            </TabPanel>
                            <TabPanel value="2"  >
                                <GrupoFamiliar datos={datos} setDatos={setDatos} bloquearBtn={bloquearBtn} bloquearTabs={bloquearTabs} setBloquearBtn={setBloquearBtn} setValue={setSelectedTab} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} />
                            </TabPanel>
                            <TabPanel value="3" >
                                <Domicilios datos={datos} libraries={libraries} setDatos={setDatos} bloquearTabs={bloquearTabs} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} location={location} setLocation={setLocation} ValorTab={setSelectedTab} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} editarDatos={0} setMatriculas={setMatriculas} />
                            </TabPanel>
                            <TabPanel value="4"  >
                                <Titulos datos={datos} setDatos={setDatos} bloquearBtn={bloquearBtn} bloquearTabs={bloquearTabs} setBloquearBtn={setBloquearBtn} setValue={setSelectedTab} loaderBtnGuardar={loaderBtnGuardar} idEstado={idEstado} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} />
                            </TabPanel>
                            <TabPanel value="5" >
                                {(idEstado === ESTADOS_TRAMITE.DRAFT || idEstado === ESTADOS_TRAMITE.EN_REVISION || idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION) ?
                                    <ArchivosAdjuntos idLegajoTemporal={idLegajoTemporal} datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearTabs={bloquearTabs} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setSelectedTab} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} aprobacionDocumentacion={aprobacionDocumentacion} setAprobacionDocumentacion={setAprobacionDocumentacion}/> 
                                :
                                    <ArchivosAdjuntosPostCarga idLegajoTemporal={idLegajoTemporal} datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearTabs={bloquearTabs} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setSelectedTab} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} aprobacionDocumentacion={aprobacionDocumentacion} setAprobacionDocumentacion={setAprobacionDocumentacion} setMatriculas={setMatriculas} resultadoPagoTasa={resultadoPagoTasa}/>
                                }
                            </TabPanel>
                             
                            { 
                             (idEstado !== ESTADOS_TRAMITE.DRAFT && idEstado !== ESTADOS_TRAMITE.EN_REVISION && idEstado !== ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION) &&
                             (!(Matriculas.result.exentoTasa == null? true : Matriculas.result.exentoTasa) || resultadoPagoTasa) && 
                                <TabPanel value="6" >   
                                    <DerechoDeInscripcionTituloEspecial datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setSelectedTab} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} resultadoPagoTasa={resultadoPagoTasa}  estadoOperacion={estadoOperacion} obtenerResultadoDePago={obtenerResultadoDePago} setEstadoOperacion={setEstadoOperacion} setResult={setResult} montoDeTasa={montoDeTasa} aprobacionDocumentacion={aprobacionDocumentacion} setAprobacionDocumentacion={setAprobacionDocumentacion} idLegajoTemporal={idLegajoTemporal} bloquearTabs={bloquearTabs} setMedioDePago={setMedioDePago} medioDePago={medioDePago}/>
                                </TabPanel>
                            }
                        </Paper>

                    </TabContext>

                </Grid>
                :
                <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </div>
                </Grid>
            }
            {(idEstado !== ESTADOS_TRAMITE.DRAFT && idEstado !== ESTADOS_TRAMITE.VALIDADO) 
                && 
                <Chat 
                datos={datos} 
                setDatos={setDatos} 
                userType={localStorage.userType}
                idEstado={idEstado}  
                setIdEstado={setIdEstado} />}
             {/* {((idEstado !== 1 && idEstado !== 7 && localStorage.userType !== "0") 
             || (localStorage.userType === "0" && datos.Observado === true) 
             || localStorage.userType === "1") */}
        </Grid>


    );
};

export default InscipcionTitulosEspeciales;
