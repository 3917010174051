import React, { useState } from 'react';
import { Button, Grid, TextField, IconButton, InputAdornment, Card, CardContent, CardActions, Link, Typography, ThemeProvider } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useForm } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import {
    postLoginUsuario
} from 'app/features/Usuarios/usuariosAPI';
import { TIPOS_TRAMITE, TIPOS_USUARIO }  from 'constants/constants';

import LoginIcon from '@mui/icons-material/Login';
// Styles
import 'scss/components/header.scss';
import { createSession } from 'utils/sessionManager';
import { obtenerListaTramites, obtenerTokenBajaFallecimiento, verificarPerfilUsuario } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';


const LoginBajaFallecimiento = () => {
    const { handleSubmit, formState: { errors } } = useForm();
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState();
    const [textoErrorEmail, setTextoErrorEmail] = useState();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState();
   // localStorage.setItem('token', "");
   

    const InputChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const InputChangePass = (e) => {

        setPassword(e.target.value);
    }

    const handleClickShowPassword = () => {

        setShowPassword(!showPassword);
    };
    const [mostrarLoader, setMostrarLoader] = useState({ display: 'none' });
    const [mostrarContenido, setMostrarContenido] = useState({ display: '' });
    const onSubmit = data => {
        localStorage.clear();
        setMostrarLoader({ display: ''});
        setMostrarContenido({ display: 'none'});
        const result = postLoginUsuario({ email: email, password: password, tpoTramite : 99 });
      
        result.then(async(value) => {
            // console.log(value);
              setMostrarLoader({ display: 'none'});
            setMostrarContenido({ display: ''});
          
            if (!value.data.resultado) {
                if (value.data === "") {
                    setTextoErrorEmail("Email y/o contraseña invalidos");
                }
                if (value.data.codigoError === 0) {

                    setTextoErrorEmail("Intentelo nuevamente mas tarde.");
                }
                if (value.data.codigoError === 1) {

                    setTextoErrorEmail("El email ya se encuentra registrado");
                }
                if (value.data.codigoError === 2) {
                    setTextoErrorEmail("El email aún no se encuentra verificado");
                }
                if (value.data.codigoError === 3) {

                    setTextoErrorEmail("El email no se encuentra registrado");
                }
                if (value.data.codigoError === 4) {
                    setTextoErrorEmail("El token es invalido. Por favor solicite uno nuevo.");
                }
                setErrorEmail("error");
            } else {

                const session = {
                    ...value.data,
                    userType: TIPOS_USUARIO.USUARIO_EXTERNO,
                    tpoTramite: TIPOS_TRAMITE.BAJA_FALLECIMIENTO,
                }
                createSession(session);

                const resp = await verificarPerfilUsuario(value.data.email);

                if (resp !== true) {
                    window.location.href = `/perfilUsuario`;
                    return;
                }
                localStorage.setItem('usuario', value.data.usuario);
                obtenerListaTramites().then(async(resp) => {
                    if (resp !== false) {
                        const jsonDatos = JSON.parse(resp[0].jsonDatos);
                        if (resp.length === 1 && resp[0].idEstado === 1) {
                            const response = await obtenerTokenBajaFallecimiento(resp[0].idLegajoTemporal)
                            if(response !== false){
                                localStorage.setItem('idLegajoTemporal', resp[0].idLegajoTemporal);
                                localStorage.setItem('token', response.token);
                                window.location.href = '/bajaFallecimiento';
                            }
                        } else {
                            window.location.href = `/menuBajaFallecimiento`;
                        }
                        
                    }
                }); 
            }
           
        });
    };

    return (
        <Grid
            
            align='center'
            direction="column"
        >

            <form onSubmit={handleSubmit(onSubmit)} >


                <Grid item md={6} >
                    <Card >


                        <CardContent >

                            <Typography align='center' variant="h6">Inicio de Sesión</Typography>


                            <Grid item sx={{ mb: 3, mt: 2 }} md={12} xs={12} >
                                <TextField label="Email"
                                    value={email}
                                    error={errorEmail}
                                    helperText={textoErrorEmail}
                                    onChange={(value) => InputChangeEmail(value)}
                                    required
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    inputProps={{ sx: {fontWeight: 'light'}}}
                                    InputLabelProps={{ sx: { fontWeight: 'light', color: 'grey' }}}
                                />
                            </Grid>

                            <Grid sx={{ mb: 3 }} item md={12} xs={12}  >
                                <TextField label="Contraseña"
                                    value={password}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClickShowPassword}>
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}
                                    onChange={(value) => InputChangePass(value)}
                                    required
                                    variant="outlined"
                                    InputLabelProps={{ sx: { fontWeight: 'light', color: 'grey' }}}
                                    inputProps={{ sx: {fontWeight: 'light'}}}
                                    fullWidth />
                            </Grid>
                            <CardActions
                                sx={{
                                    justifyContent: "center",
                                }}>

                                <Button startIcon={<LoginIcon />} style={mostrarContenido} variant="contained" type="submit" color="primary" >

                                    Iniciar Sesión
                                </Button>
                             
                                <Button style={mostrarLoader}  variant="contained"  color="primary" >
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "white"
                                        }}
                                    />
                                </Button>

                            </CardActions>
                            <Grid item sx={{ mt: 1 }} md={12} xs={12} className="centrar_contenido"  >
                                <Grid className="centrar_contenido">
                                    Si no tienes una cuenta.
                                    <Link href="/registroBajaFallecimiento">
                                        Registrate
                                    </Link>
                                    <br />

                                </Grid>
                            </Grid>
                            <Grid item sx={{ mt: 1 }} md={12} xs={12} className="centrar_contenido"  >
                                <Grid className="centrar_contenido">
                                    <Link href="/recuperarpassword">
                                        Recuperar contraseña
                                    </Link>
                                    <br />

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

            </form>
        </Grid>
    );

};

export default LoginBajaFallecimiento;
