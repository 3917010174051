import React, { useState, Fragment } from 'react';
import Swal from 'sweetalert2'
import { Button, Grid, TextField, IconButton, InputAdornment, Card, CardContent, CardActions, Link, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm } from "react-hook-form";
import {
    recuperacionPasswordUsuario
} from 'app/features/Usuarios/usuariosAPI';
// Styles
import 'scss/components/header.scss';


const RecuperacionPasswordTituloEspecial = () => {
    localStorage.setItem('token', "");
    const { handleSubmit } = useForm();
    const params = useParams();

    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState();
    const [showPasswordRepeat, setShowPasswordRepeat] = useState();
    const [passwordRepetido, setPasswordRepetido] = useState('');
    const [errorPassword, setErrorPassword] = useState();

    const [errorTextoPassword, setErrorTextoPassword] = useState();

    const [errorPasswordSubmit, setErrorPasswordSubmit] = useState();

    const [errorTextoPasswordSubmit, setErrorTextoPasswordSubmit] = useState();

    const InputValidatePass = (e) => {
        setPasswordRepetido(e.target.value);

        if (password !== e.target.value) {
            setErrorPassword("error");
            setErrorTextoPassword("Las contraseñas no coinciden");
        } else {

            setErrorPassword("");
            setErrorTextoPassword("");
        }
    }
    const handleClickShowPasswordRepeat = () => {

        setShowPasswordRepeat(!showPasswordRepeat);
    };
    const handleClickShowPassword = () => {

        setShowPassword(!showPassword);
    };


    const onchangePassword = (e) => {

        setPassword(e.target.value);

        if (passwordRepetido !== "") {

            if (e.target.value !== passwordRepetido) {
                setErrorPassword("error");
                setErrorTextoPassword("Las contraseñas no coinciden");
            } else {

                setErrorPassword("");
                setErrorTextoPassword("");
            }
        }
    }

    const onSubmit = data => {


        if (password !== passwordRepetido) {
            setErrorPassword("error");
            setErrorTextoPassword("Las contraseñas no coinciden");
            return false;

        }


        const result = recuperacionPasswordUsuario({ email: params.email, token: params.token, password: password, tpoTramite: 9 });
        result.then((value) => {

            if (!value.data.resultado) {

                if (value.data.codigoError === 0) {

                    setErrorTextoPasswordSubmit("Intentelo nuevamente mas tarde.");
                }
                if (value.data.codigoError === 1) {

                    setErrorTextoPasswordSubmit("El email ya se encuentra registrado");
                }
                if (value.data.codigoError === 2) {
                    setErrorTextoPasswordSubmit("El email aún no se encuentra verificado");
                }
                if (value.data.codigoError === 3) {

                    setErrorTextoPasswordSubmit("El email no se encuentra registrado");
                }
                if (value.data.codigoError === 4) {
                    setErrorPasswordSubmit("El token es invalido. Por favor solicite uno nuevo.");
                }
                setErrorPasswordSubmit("error");
            } else {
                Swal.fire({
                    title: 'Correcto',
                    html: 'Se ha generado una nueva contraseña.',
                    icon: 'success',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        window.location.href = `/logininscripciontitulosespeciales`
                    }
                });
            }

        });


    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    md={6}
                    direction="column"
                    className="centrar_contenido"
                >

                    <Card>
                        <CardContent >
                            <Typography align='center' sx={{ mb: 1 }} variant="h6">Recuperacion de Contraseña</Typography>

                            <Typography variant="display1">A continuación ingrese la nueva contraseña</Typography>
                            <Grid sx={{ mt: 2, mb: 3 }} item md={12} xs={12}  >
                                <TextField label="Nueva Contraseña"
                                    value={password}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}
                                    error={errorPasswordSubmit}
                                    helperText={errorTextoPasswordSubmit}
                                    onChange={(value) => onchangePassword(value)}
                                    required
                                    variant="outlined"
                                    InputLabelProps={{ sx: { fontWeight: 'light', color: 'gray' }}}
                                    inputProps={{ sx: { fontWeight: 'light' }}}
                                    fullWidth />
                            </Grid>
                            <Grid sx={{ mb: 3 }} item md={12} xs={12} >
                                <TextField
                                    type={showPasswordRepeat ? "text" : "password"}
                                    InputProps={{
                                        
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPasswordRepeat}
                                                >
                                                    {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}
                                    value={passwordRepetido}
                                    label="Repetir Contraseña"
                                    helperText={errorTextoPassword}
                                    onChange={(value) => InputValidatePass(value)}
                                    error={errorPassword}
                                    required variant="outlined"
                                    InputLabelProps={{ sx: { fontWeight: 'light', color: 'gray' }}}
                                    inputProps={{ sx: { fontWeight: 'light' }}}
                                    fullWidth />

                            </Grid>


                            <CardActions
                                sx={{
                                    justifyContent: "center",
                                }}>

                                <Button variant="contained" type="submit" color="primary" >
                                    Recuperar
                                </Button>


                            </CardActions>
                            <Grid item sx={{ mt: 1 }} md={12} xs={12} className="centrar_contenido"  >
                                <Grid className="centrar_contenido">
                                    Para iniciar sesión hacer
                                    <Link sx={{ ml: 1 }} href="/Login">
                                        Click Aqui
                                    </Link>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
            </form>
        </Fragment>

    );

};

export default RecuperacionPasswordTituloEspecial;
