import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCatalogoTitulosTitulosEspeciales } from 'app/features/Titulos/titulosAPI';
const initialState = {
  List: [],
  status: 'loading',
};

export const getTitulosEspecialesAsync = createAsyncThunk(
  'TitulosEspeciales/fetchTitulosEspeciales',
  async data => {
    const response = await getCatalogoTitulosTitulosEspeciales(data);
    // console.log({response});
    return response.data;
  }
);


export const titulosEspecialesSlice = createSlice({
  name: 'titulosEspeciales',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTitulosEspecialesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTitulosEspecialesAsync.fulfilled, (state, action) => {
        state.status = 'correcto';
        state.List = action.payload;
      });
  },
});



export const titulosEspecialesList = (state) => state.titulosEspecialesReducer.List;
export const titulosEspecialesStatus = state => state.titulosEspecialesReducer.status;

export default titulosEspecialesSlice.reducer;
