import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getComunicaciones, guardarComunicacion } from 'app/features/comunicaciones/comunicacionesApi';


const initialState = {
    List: [],
    status: 'idle',
};


export const getComunicacionAsync = createAsyncThunk(
    'Comunicacion/fetchComunicacion',
    async data => {
        const response = await getComunicaciones(data);
        if (response.data.resultado) {
            if (response.data.result.datosComunicacion) {
                return JSON.parse(response.data.result.datosComunicacion);
            }
        }
        return [];
    }
);

export const postComunicacionAsync = createAsyncThunk(
    'Comunicacion/postComunicacion',
    async data => {
        const response = await guardarComunicacion(data);
        return response.data;
    }
);

export const comunicacionesSlice = createSlice({
    name: 'comunicaciones',
    initialState,
    reducers: {
        updateComunicationList: (state, action) => {
            state.List = [...state.List, action.payload];
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getComunicacionAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getComunicacionAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.List = action.payload
            });
    },
});


export const { updateComunicationList } = comunicacionesSlice.actions;

export const comunicacionList = (state) => state.comunicacionReducer.List;
export const comunicacionStatus = state => state.comunicacionReducer.status;

export default comunicacionesSlice.reducer;
