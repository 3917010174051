import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { postLoginExterno } from 'app/features/Usuarios/usuariosAPI';
import { getLegajosAsync } from 'app/features/Profesionales/profesionalesSlice';
import { useDispatch } from 'react-redux';
import { TIPOS_TRAMITE, TIPOS_USUARIO } from 'constants/constants';
import useQuery from 'customHooks/useQuery';
import { createSession } from 'utils/sessionManager';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const LoginEditarDatos = () => {
    localStorage.setItem('token', "");
    const queryParams = useQuery();
    const dispatch = useDispatch();
    const classes = useStyles();
    //cuando se le carga una observacion 6
    localStorage.removeItem('usuario');

    const [mensajePantalla, setMensajePantalla] = useState("cargando...");
    useEffect(() => {
        const usrData = { 
            usuario: encodeURIComponent(queryParams.get("u")), 
            password: encodeURIComponent(queryParams.get("p")),
            tpoTramite: TIPOS_TRAMITE.MODIFICACION
        };
        
        //LOGIN_ACTUALIZACION(endpoint)

        postLoginExterno(usrData)
            .then((value) => {

                localStorage.clear();
                if (value.data.resultado) {

                    const session = {
                        ...value.data,
                        userType: TIPOS_USUARIO.PROFESIONAL_EDITAR_DATOS,
                        tpoTramite: TIPOS_TRAMITE.MODIFICACION,
                        user: queryParams.get("u"),
                        pass: queryParams.get("p")
                    }
                    createSession(session);

                    dispatch(getLegajosAsync({idLegajo: value.data.legajoProfesional, token:value.data.token}))
                    .then(() => window.location.href = `/editardatospersonales`);

                    
                } else {
                    setMensajePantalla("Usuario y/o contraseña incorrectos");
                }
            });
    }, []);

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle1">
                {mensajePantalla}
            </Typography>
        </Paper>
    )
}

export default LoginEditarDatos;
