import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { obtenerCodigoQR } from './codigoQrApi';
const initialState = {
    codigoQr: {
        qr: null,
        tiempoVerificacion: null,
        vencimiento: null
    },
    loading: false,
    error: null,
};

export const obtenerQrAsync = createAsyncThunk('CodigoQr/fetchcodigoQrAsync',
    async data => {
      const response = await obtenerCodigoQR();
      return response;
    } 
);

export const codigoQrSlice = createSlice({
    name: 'codigoQr',
    initialState,
    reducers: {
        setCodigoQr: (state, action) => {
            state.codigoQr = action.payload;
        },
        clearCodigoQr: (state) => {
            state.codigoQr = {qr: null, tiempoVerificacion: null, vencimiento: null};
        }

    },
    extraReducers: (builder) => {
        return builder
            .addCase(obtenerQrAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(obtenerQrAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.codigoQr = action.payload
            })
            .addCase(obtenerQrAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            });
    }
});

// Selector para obtener el estado 'codigoQr' del estado global
export const selectCodigoQr = (state) => state.codigoQrReducer.codigoQr;

export default codigoQrSlice.reducer;

// Exportar las acciones
export const { setCodigoQr, clearCodigoQr } = codigoQrSlice.actions;

