export function nuevoAdjunto(nombre, extension, file) {
    return {
        fileType: nombre,
        fileName: nombre,
        extension: extension,
        file: file,
        size: 0.0308,
        url: "",
        saved: true
    }
}