import React, { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import InboxIcon from '@mui/icons-material/Inbox';

import 'scss/pages/tablas.scss';

const useStyles = makeStyles(theme => ({
    header_tabla: {
        backgroundColor: "black",
        color: 'white!important'
    },
    descartarTablet: {
        "@media (max-width: 1280px)": {
          display:"none !important"
        }
      },
    

}));
const GenerarToken = () => {

    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
       
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    const columns = [
        { id: 'fechaCarga', label: 'Fecha de carga' },
        { id: 'tipoTramite', label: 'Tipo de Tramite'},
        {  id: 'legajo', label: 'Legajo N°' },
        {  id: 'nombre', label: 'Apellido y Nombre' },
        {  id: 'celular', label: 'Celular' }
    ];

    function createData(id,fechaCarga, tipoTramite, legajo, nombre,celular) {
        return { id,fechaCarga, tipoTramite, legajo, nombre,celular };
    }
 
    const rows = [
        createData(1,'29/09/2021 12:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>INSCRIPCIÓN</b></span>', "INSC1234","PEREZ JUAN", 3287263),
        createData(2,'28/09/2021 11:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>MODIFICACION DE DATOS</b></span>',14035,"BARRIO VALLS PAULINA",  9596961),
        createData(3,'27/09/2021 10:22 hs', '<span  style="text-decoration: none;cursor:pointer;"><b>INSCRIPCIÓN</b></span>', "INSC1504","GÁRATE SANZ TEODOSIO", 301340),
        createData(4,'26/09/2021 09:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>INSCRIPCIÓN</b></span>', "INSC15034","MENÉNDEZ CEPEDA ARTURO", 9833520),
        createData(5,'25/09/2021 13:22 hs', 'CANCELACIONES', 37602103,"CANALS RODRIGO",  9984670),
        createData(6,'24/09/2021 15:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>MODIFICACION DE DATOS</b></span>', 25470,"VILLALOBOS RITA",  7692024),
        createData(7,'23/09/2021 16:22 hs', 'REINSCRIPCIÓN/HABILITACION', 83019200, "TEODOSIO GÁRATE SANZ", 357578),
        createData(8,'22/09/2021 18:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>INSCRIPCIÓN</b></span>', "INSC1234","INFANTE SOCORRO VIVIANA QUEROL",  70273),
        createData(9,'21/09/2021 17:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>MODIFICACION DE DATOS</b></span>', 12791, "SERRANO MOLES LEIRE", 1972550),
        createData(10,'20/09/2021 16:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>INSCRIPCIÓN</span>', "INSC1234", "SUAREZ MONTALBÁN CHARO", 377973),
        createData(11,'19/09/2021 10:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>MODIFICACION DE DATOS</b></span>', 67000, "BECERRA MENENDEZ AGUSTÍN ABEL", 640679),
        createData(12,'18/09/2021 09:22 hs', 'REINSCRIPCIÓN/HABILITACION', 67545757,"SUREDA MONREAL TRISTÁN JUAN JOSÉ",  242495),
        createData(13,'17/09/2021 08:22 hs', 'CANCELACIONES', 146793744,"VILANOVA VALENCIA ÁNGELES",  17098246),
        createData(14,'16/09/2021 13:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>MODIFICACION DE DATOS</b></span>', 262417,"CARRASCO-RAYA SOLEDAD",  923768),
        createData(16,'15/09/2021 21:22 hs', '<span style="text-decoration: none;cursor:pointer;"><b>INSCRIPCIÓN</b></span>', "INSC1234","HERVÁS ARIAS JOSE MIGUEL",  8515767),
    ];
    return (
        <Fragment>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                  
                    <Typography  variant="h5" style={{
                        justifyContent:'center',
                        display: 'flex',
                        alignItems: 'center',
                    }}><InboxIcon /> Tramites pendientes de Inicio</Typography>
                </CardContent>
            </Card>
            <Paper >


                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow >
                                <TableCell className= {`${classes.header_tabla} ${classes.descartarTablet}`}>Fecha de Carga</TableCell>
                                <TableCell className={classes.header_tabla}>Tipo de Tramite</TableCell>
                                <TableCell className= {`${classes.header_tabla} ${classes.descartarTablet}`}>Legajo N°</TableCell>
                                <TableCell className={classes.header_tabla}>Apellido y Nombre</TableCell>
                                <TableCell className= {`${classes.header_tabla} ${classes.descartarTablet}`}>Celular</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    console.log(row.id);
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                              
                                                const value = row[column.id];
                                                return (


                                                    <TableCell key={column.id}
                                                   
                                                    className={column.id === 'legajo' ||  column.id === 'fechaCarga' || column.id === 'celular'
                                                    ? classes.descartarTablet
                                                    : ''}
                                                    
                                                    align={column.align} dangerouslySetInnerHTML={{__html: value}}>
                                                       
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                       rowsPerPageOptions={[]}
                       component="div"
                       count={rows.length}
                       rowsPerPage={rowsPerPage}
                       page={page}
                       onPageChange={handleChangePage}
                       onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>

            </Paper>
        </Fragment>
    );

};

export default GenerarToken;
