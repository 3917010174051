import React from 'react';
import ReactDOM from 'react-dom';
import Routes from 'routes';
import reportWebVitals from './reportWebVitals';

// Theme
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import muiTheme from 'theme/muiTheme';

//Redux
import {store} from 'app/store'
import { Provider } from 'react-redux'

// Styles
import CssBaseline from '@mui/material/CssBaseline';
import 'scss/index.scss';

//Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


ReactDOM.render(
   <React.StrictMode>
         <Provider store={store}>
            <MuiThemeProvider theme={muiTheme}>
               <CssBaseline />
               <Routes />
            </MuiThemeProvider> 
            <ToastContainer />
         </Provider>
   </React.StrictMode>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
