import React, { useState, useEffect, Fragment } from 'react';
import logo from './../../Logos/Imagen_logo_cpba.jpg';
import { Page, Image, Text, Svg, View, Document, StyleSheet, Font } from '@react-pdf/renderer';



const PdfPreCarga = ({ datos, EstadoCivilList, TipoDocumentos, titulosProfesional, ObrasSociales, leyendas,  muestraDireccion ,  muestraDatosPersonales, esAlta }) => {


    Font.register({
        family: "Oswald",
        src: `https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf`,
        fontWeight: 600
    });
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            fontSize: 11,
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
            lineHeight: 1.5,
            flexDirection: 'column',
        },
        section_center: {
            backgroundColor: "#D9D8D8",
            marginLeft: 0,
            fontSize: 15,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 5,
            height: 48,
            width: '58%',
            border: '1'
        },
        section_center_text: {
            marginBottom: 0,
            justifyContent: "center",
            alignItems: "center",
            fontSize: 10,
        },
        section_right: {
            marginLeft: 0,
            fontSize: 15,
            paddingTop: 5,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 5,
            height: 48,
            width: '18%',
            border: '1'
        },
        section_left: {
            marginTop: 5,
            height: 48,
            width: '32%',
            border: 1
        },
        profile_container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '20',
            marginBottom: '20px',
            height: '150',
            fontFamily: 'Helvetica-Bold',
        },
        name_text: {
            paddingTop: '10px',
            paddingBottom: '5px',
            fontSize: '14px',
            fontWeight: '900',
            color: 'white',
        },
        profession_text: {
            color: '#d1d5db',
            fontSize: '11px',
        },

        profile_img: {
            width: '60px',
            height: '60px',
            borderRadius: '90',
        },
        profile_line: {
            marginTop: '10px',
            width: '10%',
            height: '1px',
            backgroundColor: '#FFF',
            textAlign: 'center',
        },
        row: {
            flexDirection: 'row',
        },

        rowTable: {
            flexDirection: 'row',
            borderBottomColor: '#3778C2',
            borderBottomWidth: 1,
            alignItems: 'center',
            fontStyle: 'bold',
        },
        viewer: {
            width: 800, //the pdf viewer will take up all of the width and height
            height: 800,
        },
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderWidth: 1,
            borderColor: '#3778C2',
        },
        container: {
            flexDirection: 'row',
            borderBottomColor: '#3778C2',
            backgroundColor: '#3778C2',
            color: '#fff',
            borderBottomWidth: 1,
            alignItems: 'center',
            textAlign: 'center',
            fontStyle: 'bold',
            flexGrow: 5,
        },

        nombres: {
            width: '18%',
            fontSize: 9,
            marginTop: 5,
            height: 20,
            borderRightWidth: 1,
        },
        parentesco: {
            width: '12%',//mostrar el parentesco con un filter
            fontSize: 9,
            borderRightWidth: 1,
        },
        fecNacimiento: {
            width: '12%',
            fontSize: 9,
            borderRightWidth: 1,
        },
        documento: {
            width: '15%',
            fontSize: 9,
            borderRightWidth: 1,
        },
        cargo: {
            width: '10%',
            fontSize: 9,
            borderRightWidth: 1,
        },
        edad: {
            width: '10%',
            fontSize: 9,
            borderRightWidth: 1,
        },
        incapacitado: {
            width: '10%',
            fontSize: 8,
            borderRightWidth: 1,
        },
        obraSocial: {
            fontSize: 9,
            width: '18%'
        },
        nombresContenidoTabla: {
            width: '18%',
            fontSize: 9,
            height: 18,
            textAlign: 'center',
            borderRightWidth: 1,
        },
        parentescoContenidoTabla: {
            width: '12%',//mostrar el parentesco con un filter
            textAlign: 'center',
            fontSize: 9,
            height: 18,
            borderRightWidth: 1,
        },
        fecNacimientoContenidoTabla: {
            width: '12%',
            fontSize: 9,
            height: 18,
            textAlign: 'center',
            borderRightWidth: 1,
        },
        documentoContenidoTabla: {
            width: '15%',
            fontSize: 8,
            height: 18,
            textAlign: 'center',
            borderRightWidth: 1,
        },
        cargoContenidoTabla: {
            width: '10%',
            fontSize: 9,
            height: 18,
            textAlign: 'center',
            borderRightWidth: 1,
        },
        edadContenidoTabla: {
            width: '10%',
            fontSize: 9,
            height: 18,
            textAlign: 'center',
            borderRightWidth: 1,
        },
        firmaProfesional: {
            position: 'absolute',
            fontSize: 11,
            bottom: 20,
            left: 380,
            right: 0
        },
        incapacitadoContenidoTabla: {
            width: '10%',
            fontSize: 9,
            height: 18,
            textAlign: 'center',
            borderRightWidth: 1,
        },
        obraSocialContenidoTabla: {
            textAlign: 'center',
            fontSize: 9,
            height: 18,
            width: '18%'
        }
    });

    const renderSwitch = param => {

        switch (param) {
            case 1:
                return 'Particular';
            case 2:
                return 'Profesional';
            case 3:
                return 'Constituido';
            case 4:
                return 'Correspondencia';
            case 5:
                return 'Real Caja';

        }
    }

    const convertDateFormat = (string) => {

        var info = string.split('-');
        return info[2] + '/' + info[1] + '/' + info[0];
    }

    const formatDate = (date) => {
        let formatted_date = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "/" + date.getFullYear()

        return formatted_date;
    }

    return (

        <Document>
            <Page size="A4" style={styles.page}>


                {/* </Svg> */}
                <Fragment>
                    <View style={styles.row}>
                        <View style={styles.section_left}>
                            <Image src={logo} style={{ width: 130, height: 41, marginLeft: 18 }} />
                            {/* <Svg viewBox="0 0 240 40"> */}

                        </View>

                        {esAlta ? (
                            <View style={styles.section_center}>
                                <Text style={styles.section_center_text}>SOLICITUD DE REGISTRO EN EL   </Text>
                                <Text style={styles.section_center_text}>LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS  </Text>
                            </View>
                        ) :
                            <View style={styles.section_center}>
                                <Text style={styles.section_center_text}>SOLICITUD INCORPORACIÓN DE TÍTULO   </Text>
                                <Text style={styles.section_center_text}>AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS  </Text>
                            </View>
                        }


                        <View style={styles.section_right}>
                            <Text style={{ fontSize: 9, padding: 0, marginBottom: 10, marginTop: 0 }}>F-PE.02.32  {"\n"} Rev.01</Text>
                            <Text style={{ borderTop: 1, width: '90px', marginTop: 3, marginBottom: 3 }}></Text>
                            <Text style={{ fontSize: 9, marginBottom: 8 }}>Página 1 de 1</Text>
                        </View>
                    </View>
                </Fragment>

                <Fragment>
                    <View style={styles.row}>
                        <Text style={{ marginTop: 5/* , fontFamily: 'Oswald' */ }}>Sres. C.P.C.E.P.B.A <text style={{ color: "white" }}>{"".padEnd(61, "_")}</text><text >{formatDate(new Date())}</text></Text>
                        <Text> </Text>
                    </View>

                </Fragment>
                <Fragment>

                    <View style={styles.row}>

                        <Text style={{ marginTop: 3 }}>Quién subscribe, <text style={{ fontFamily: 'Helvetica-Bold' }}>{datos.Nombre + " " + datos.Apellido}</text>,  solicita se proceda a registrar el Título de
                        {' '} <Text style={{ marginTop: 10, fontFamily: 'Helvetica-Bold' }}>{datos.titulos.map((titulo, index) => {

                            let coma = "";
                            if (index > 0)
                                coma = ", ";

                            return (
                                coma + titulo.nombreTitulo
                            )


                        })}</Text> 

                            {esAlta ? (
                                <Text>{' '} en el Libro Especial de Títulos Homologados. A tales fines, se adjunta la documentación requerida que permita determinar si se ajusta a la normativa vigente.
                                    {'\n'}
                                    A continuación se detallan los datos de contacto: </Text>
                            ) :
                                <Text>{' '} en el Libro Especial de títulos Homologados, es por ello que se adjunta la documentación requerida para que se determine si se cumplen los requisitos establecidos en la normativa vigente.
                                    Siendo los datos personales y de contacto los que se detallan a continuación. </Text>
                            }


                        {muestraDatosPersonales && (
                            <Text>
                            {'\n'}
                            <text style={{ marginTop: 10, marginBottom: 3, fontFamily: 'Helvetica-Bold' }}>DATOS PERSONALES:</text>
                            {'\n'}

                            Fecha de nacimiento: {
                                convertDateFormat(datos.Nacimiento)}  <text style={{ color: "white" }}>s </text>
                            Tipo de documento: {TipoDocumentos.filter(Documento => Documento.tipoDocumento === datos.TipoDocumento)[0].descripcion.trim()} <text style={{ color: "white" }}> </text>
                            Nro.: {datos.NroDocumento + " "}

                            CUIT/CUIL: {datos.CuitCuil} <text style={{ color: "white" }}>s </text> 
                            </Text>
                            )}
                        </Text>

                    </View>


                </Fragment>
                <Fragment>
                    <View style={[styles.row, { marginTop: 3, fontFamily: 'Helvetica-Bold' } ]}>
                        <Text>DATOS DE CONTACTO: </Text>
                    </View>

                    <View style={styles.row}>
                        <Text>
                            E-mail: <text style={{ color: "white" }}>a </text> <text style={{ fontFamily: 'Helvetica-Bold' }}>{datos.Email}</text> <text style={{ color: "white" }}>a </text> 
                            N° de teléfono móvil: ({datos.domicilios[0].caracteristica}) {datos.domicilios[0].celular} <text style={{ color: "white" }}>a </text>
                            

                        </Text>

                    </View>
                    <View style={styles.row}>
                        {esAlta ? (
                            <Text> Presto expresa conformidad para que todas las notificaciones / comunicaciones que el
                            CPCEPBA deba realizar serán efectuadas a través de la casilla de correo electrónico y/o
                            teléfono celular denunciado. </Text>
                        ) :
                            <Text> Presto expresa conformidad para que todas las notificaciones o comunicaciones que el
                                CPCEPBA deba cursarme -referidas a la presente solicitud-
                                se dirijan a la casilla de correo electrónico y/o teléfono celular denunciado en el
                                presente formulario. </Text>
                        }

                    </View>
                </Fragment>


                {muestraDireccion && (
                    <Fragment>
                        <View style={styles.row}>
                            <Text style={{ marginTop: 5, fontFamily: 'Helvetica-Bold' }}>DOMICILIO PARTICULAR</Text>
                        </View>

                        {datos.domicilios.filter(d => d.TpoDomicilio === 1).map((domicilio, index) =>
                            <Fragment>
                                <Text style={{ /* fontSize: 9 */ }}>
                                    Calle:  <text style={{ fontFamily: 'Helvetica-Bold' }}>{domicilio.calle.replace("Calle", "") + " "}  </text><text style={{ color: "white" }}>s</text>
                                    N°:<text style={{ fontFamily: 'Helvetica-Bold' }}>{domicilio.numero + " "}</text><text style={{ color: "white" }}>s</text>
                                    Piso: <text style={{ fontFamily: 'Helvetica-Bold' }}>{domicilio.piso + " "}</text><text style={{ color: "white" }}></text>
                                    Dpto.:<text style={{ fontFamily: 'Helvetica-Bold' }}>{domicilio.depto + " "}</text><text style={{ color: "white" }}>s</text>
                                    Código postal: <text style={{ fontFamily: 'Helvetica-Bold' }}>{domicilio.codPostal + " "}   </text><text style={{ color: "white" }}></text>
                                    Localidad:<text style={{ fontFamily: 'Helvetica-Bold' }}>{domicilio.localidad + " "}</text><text style={{ color: "white" }}>eo</text>
                                    Celular:<text style={{ fontFamily: 'Helvetica-Bold' }}>{(domicilio.caracteristica !== null && domicilio.caracteristica !== "") && "0" + domicilio.caracteristica + "-"}{(domicilio.celular !== null && domicilio.celular !== "") && "15" + domicilio.celular + " "}</text><text style={{ color: "white" }}></text>
                                    Teléfono Fijo:<text style={{ fontFamily: 'Helvetica-Bold' }}>{(domicilio.caracteristicaFijo !== null && domicilio.caracteristicaFijo !== "" && domicilio.caracteristicaFijo !== undefined) && "0" + domicilio.caracteristicaFijo + "-"}{(domicilio.telefono !== null && domicilio.telefono !== "") && domicilio.telefono}</text><text style={{ color: "white" }}>s</text>
                                    Provincia:  <text style={{ fontFamily: 'Helvetica-Bold' }}>{domicilio.provincia === "Provincia de Buenos Aires" ? "Buenos Aires" : domicilio.provincia}</text>
                                </Text>

                            </Fragment>
                        )}

                    </Fragment>
                )}

                <Fragment>
                    <View style={styles.row}>
                        <Text style={{ marginTop: 6, marginBottom: 5, fontFamily: 'Helvetica-Bold' }}>DATOS DEL TÍTULO</Text>
                    </View>

                    {datos.titulos.map((titulo, index) =>
                        <View style={[styles.row, { marginBottom: 15 }]}>

                            <Text style={{ /* fontSize: 10 */ }}>
                                Título: <text style={{ fontFamily: 'Helvetica-Bold' }}>{titulo.nombreTitulo + " "}</text>
                               <text style={{ color: "white" }} >{
                               
                               titulo.nombreTitulo.length >= 20 ? titulo.nombreTitulo.slice(0, 25) : titulo.nombreTitulo.padEnd(42, '-')
                             }</text> 

                                Expedido por: <text style={{ fontFamily: 'Helvetica-Bold' }}>{titulo.nombreUniversidad + " "}</text>

                                {'\n'}
                                Fecha de Título: <text style={{ fontFamily: 'Helvetica-Bold' }}>{convertDateFormat(titulo.fechaEmisionTitulo) + "  "}</text><text style={{ color: "white" }}>espacioss ssssssiisssssssss</text>
                                Fecha de la Última Materia: <text style={{ fontFamily: 'Helvetica-Bold' }}>{convertDateFormat(titulo.fechaUltimaMateria) + " "}</text>
                                {'\n'}
                                Promedio: <text style={{ fontFamily: 'Helvetica-Bold' }}>{titulo.promedio + " "}</text>{'\n'}
                                Resolución del Ministerio de Educación N°: <text style={{ fontFamily: 'Helvetica-Bold' }}>{titulo.resolucion + '/' + titulo.anioResolucion}</text>

                            </Text>
                        </View>

                    )}

                </Fragment>

                <Fragment>
                    <View style={{ fontFamily: 'Helvetica-Bold' , marginBottom: 1 }}>
                        <Text> DECLARO BAJO MI RESPONSABILIDAD</Text>
                    </View>
                    <View>
                        <Text style={{ /* fontSize: 8 */ }}>
                            -- Manifiesto con carácter de declaración jurada, que la información consignada en este formulario es correcta y completa sin omitir ni falsear dato alguno que deba contener, siendo fiel expresión de la verdad.
                        </Text>
                    </View>


                </Fragment>
                <Fragment>
                    <View fixed style={styles.firmaProfesional} >
                        <Text>
                            {esAlta && <text>{datos.sexo == "0" ? "Dra. " : "Dr. "}</text>}
                            {datos.Nombre + " " + datos.Apellido}
                        </Text>
                        <Text> {TipoDocumentos.filter(Documento => Documento.tipoDocumento === datos.TipoDocumento)[0].descripcion.trim() + " " + datos.NroDocumento + " "} </Text>
                    </View>

                </Fragment>

            </Page>


        </Document>
    )
};

export default PdfPreCarga;