import { configureStore } from '@reduxjs/toolkit';
import {combineReducers} from "redux";
import peliculasReducer from './features/TipoCalles/tipoCallesSilice.js';
import estadoCivilReducer from './features/EstadoCivil/estadoCilvilSlice.js';
import tipoDocumentoReducer from './features/TipoDocumento/tipoDocumentoSlice.js';
import matriculasReducer from './features/Matriculas/matriculasSlice';
import tipoDomicilioReducer from './features/TipoDomicilio/tipoDomicilioSlice';
import titulosReducer from './features/Titulos/titulosSlice';
import titulosEspecialesReducer from './features/Titulos/titulosEspecialesSlice';
import universidadesReducer from './features/Universidades/universidadesSlice';
import parentescosReducer from './features/Parentescos/parentescosSlice';
import comunicacionReducer from './features/comunicaciones/comunicacionesSlice';
import nacionalidadesReducer from './features/Nacionalidades/nacionalidadesSlice';
import profesionalesReducer from './features/Profesionales/profesionalesSlice';
import partidosReducer from './features/Partidos/partidosSlice';
import obrasSocialesReducer from './features/TiposObrasSociales/tiposObrasSocialesSlice';
import leyendasReducer from './features/Leyendas/leyendasSlice';
import tipoCalleReducer from './features/TipoCalles/tipoCallesSilice';
import titulosProfesionalesReducer from './features/TitulosProfesional/titulosProfesionalSlice';
import editarDatosProfesionalReducer from './features/FotoProfesional/editarDatosProfesionalSlice.js'
import codigoQrReducer from './features/CodigoQr/codigoQrSlice.js';
import bajaFallecimientoReducer  from './features/BajaPorFallecimiento/bajaFallecimientoSlice.js';

const reducers = combineReducers({
  peliculasReducer,  
  estadoCivilReducer,
  tipoDocumentoReducer,
  matriculasReducer,
  tipoDomicilioReducer,
  titulosReducer,
  titulosEspecialesReducer,
  universidadesReducer,
  parentescosReducer,
  comunicacionReducer,
  nacionalidadesReducer,
  profesionalesReducer,
  partidosReducer,
  obrasSocialesReducer,
  leyendasReducer,
  titulosProfesionalesReducer,
  tipoCalleReducer,
  editarDatosProfesionalReducer,
  codigoQrReducer,
  bajaFallecimientoReducer
});


export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production'
});

