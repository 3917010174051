import { useEffect, useState } from 'react';
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';
import { Grid, Typography, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import { generarOperacion, grabarOperacionAltaTitulo, pagoMatricula } from 'app/features/Profesionales/profesionalesAPI';
import { MP_VARIABLE } from 'constants/routes/endpoints';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const customization = {
  paymentMethods: { 
    maxInstallments: 1,
  }
};

export const PagoBrick = ({setResult, montoDeTasa, setMedioDePago}) => {

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const initialization = {
    amount: montoDeTasa,
  };
  
  const [ready, setReady] = useState(false);
  const [shouldRenderCardPayment, setShouldRenderCardPayment] = useState(true);

  const loadSdk = async() => {
    await initMercadoPago(MP_VARIABLE);
  }
   
   
  const onSubmit = async (formData) => {
    formData = {
      ...formData,
      IdLegajoTemporal: Number(localStorage.idLegajoTemporal)
    }

    // callback llamado al hacer clic en el botón enviar datos
    try {
      const response = await pagoMatricula(formData);
        if (response.data.resultado === true) {
            Swal.fire({
              title: 'Pago Aprobado',
              html: 'Su pago fue aprobado con <b>éxito</b>',
              icon: 'success',
              showCloseButton: true,
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
              cancelButtonText:'Cancelar',
              allowOutsideClick: true
            }).then(() => {
              setResult(true)
            })
        } else {
            setShouldRenderCardPayment(false)
            window.cardPaymentBrickController.unmount()
            Swal.fire({
              title: 'Pago Rechazado',
              html:  `Motivo: <b>${response.data.mensaje}</b>`,
              icon: 'warning',
              showCloseButton: true,
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
              cancelButtonText: 'Cancelar',
              allowOutsideClick: true
            }).then(() => {
              loadSdk();
              setShouldRenderCardPayment(true)
              llamarGenOp()
            });

        }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        html: '<b>Ocurrió un error al obtener la respuesta del servidor.<br />Se va a refrescar la página para volver a consultar el estado</b>',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText:'Aceptar',
        cancelButtonText:'Cancelar',
        allowOutsideClick: true
      }).then(()=>{
        window.location.href = `/matriculas`;
      })
    }
  }
 
  const onError = async (error) => {
  console.log('onError');
  console.log(error);
  };
   

  const llamarGenOp = async() => {

    if(localStorage.idLegajo === ""){
      
      const {data} = await generarOperacion();
    }else{

      const data = await grabarOperacionAltaTitulo();
    }
  }

    const goBack = () => {
      setMedioDePago("0")
      setShouldRenderCardPayment(false)
      window.cardPaymentBrickController.unmount()
    }

    useEffect(() => {
        loadSdk()
    }, [])

  return (
    <>
    { localStorage.userType !== "1" ?
    <Grid sx={{ minHeight: !isXsScreen ? '10em' : ''}}>
      { ready === true && ( <Typography style={{marginLeft: '16px'}} > Monto a pagar: <b>${initialization.amount}</b>  </Typography> )} 
      
      { !ready && ( 
        <Grid display='flex' justifyContent='center' alignItems='center'
          sx={isXsScreen ? { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } :
           { position: 'absolute', top: '50%', left: '47%', transform: 'translate(-50%, -50%)' }}
        >
          <CircularProgress /> 
        </Grid>
      )}
      {/* <Typography style={{marginLeft: '16px'}} > Monto a pagar: <b>${initialization.amount}</b>  </Typography>  */}

      <Grid sx={{minHeight: '540px'}}>
        { shouldRenderCardPayment && (
          <CardPayment
            initialization={initialization}
            onSubmit={onSubmit}
            onReady={() => setReady(true)}
            onError={onError}
            customization={customization}
            locale='es-AR'
          />
        )}
      </Grid>

      { ready && (
        <Grid item xs={12} display='flex' mt={4} >
          <Grid xs={12} md={6} display={isXsScreen ? 'flex' : ''} justifyContent={isXsScreen ? "center" : ""} ml={isXsScreen ? 0 : 2}>
            <Button startIcon={<ArrowBackIosIcon />} onClick={() => goBack()} variant="contained" color="error" fullWidth={isXsScreen} >
              Atras
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
      :
    <Grid>
        <Typography> El pago aún no fue realizado. </Typography>
    </Grid>
    }
    </>
  )
}
