import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { postLoginExterno } from 'app/features/Usuarios/usuariosAPI';
import { TIPOS_TRAMITE, TIPOS_USUARIO } from 'constants/constants';
import useQuery from 'customHooks/useQuery';
import { createSession } from 'utils/sessionManager';
const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const LoginValidacionAltaTituloEspecial = () => {
    localStorage.setItem('token', "");
    localStorage.removeItem('usuario');
    const classes = useStyles();
   

    const query = useQuery();
    const [mensajePantalla, setMensajePantalla] = useState("cargando...");

    useEffect(() => {

        console.log(query.get("id"))
        const usrData = { usuario: encodeURIComponent(query.get("usuario")), password: encodeURIComponent(query.get("pass")), idLegajoTemporal: query.get("id"), tpoTramite:10 };
        postLoginExterno(usrData)
            .then((value) => {

                
                localStorage.clear();
             
                if (value.data.resultado) {


                    const session = {
                        ...value.data,
                        userType: TIPOS_USUARIO.SECRETARIO_TECNICO,
                        tpoTramite: TIPOS_TRAMITE.ALTA_TITULO_ESPECIAL,
                        user: query.get("usuario"),
                        pass: query.get("pass")
                    }
                    createSession(session);

                    // localStorage.setItem('token', value.data.token);
                    // localStorage.setItem('idLegajoTemporal', value.data.idLegajoTemporal);
                    // localStorage.setItem('usuario', '');
                    // localStorage.setItem('usuarioValidarDatos', query.get("usuario"));
                    // localStorage.setItem('passValidarDatos', query.get("pass"));
                    // localStorage.setItem('email', value.data.email);
                    // localStorage.setItem('userType', TIPOS_USUARIO.SECRETARIO_TECNICO);
                    // localStorage.setItem('usuario', value.data.usuarioInterno);

                    window.location.href = `/altatituloespecial`;
                    // dispatch(getMatriculasAsync({ idLegajoTemporal: value.data.idLegajoTemporal, token: value.data.token })).then((valor) => {

                    //     if (valor.payload.mensaje === "Legajo no encontrado!") {
                    //         setMensajePantalla("El legajo no existe");
                    //         return false;
                    //     }

                    //     const datosProfesional = JSON.parse(valor.payload.result.jsonDatos);

                       
                    // });

                } else {
                    setMensajePantalla("Usuario y/o contraseña incorrectos");
                }
            });
    }, []);

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle1">
                {mensajePantalla}
            </Typography>
        </Paper>
    )
}

export default LoginValidacionAltaTituloEspecial;
