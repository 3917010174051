import { useState } from "react";
import { CircularProgress, Grid, Typography, Button } from "@mui/material"


export const PagoPendiente = ({obtenerResultadoDePago, setEstadoOperacion}) => {

  const [bloquearBtn, setBloquearBtn] = useState(false);

  const consultarPago = async() => {
    setBloquearBtn(true);
    await obtenerResultadoDePago();
    // setEstadoOperacion()  
    setBloquearBtn(false);
    
  }

  return (
    <Grid container sx={{ height: { xs: '50vh', md: '40vh' } }} alignItems={'center'} justifyContent={'center'} >
                        
        <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Typography style={{ fontSize: 18 }} > <b>No se pudo obtener el resultado de la operación</b> </Typography>
                    
        </Grid>
                    
        <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}  ml={2} mt={-10}>
            <Typography style={{ fontSize: 18 }} > Consulte en unos instantes </Typography>
                    
        </Grid>

        <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}  ml={2} mt={-15}>
            <Button variant="contained" onClick={consultarPago} disabled={bloquearBtn} >
              {
                bloquearBtn ? <CircularProgress /> : <Typography>Refrescar</Typography>
              }
            </Button>
                    
        </Grid>
                                        
    </Grid>
  )
}
