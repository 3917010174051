import axios from 'axios';
import https from 'https'

import {
	AGREGAR_USUARIOS,
	HEADERS,
	LOGIN_USUARIO,
	RECUPERAR_PASSWORD,
	CONFIRMAR_EMAIL,
	GENERAR_TOKEN,
	CAMBIAR_PASSWORD,
	LOGIN_EXTERNO,
	LOGIN_ACTUALIZACION,
	PAGO_TASA,
	DERECHO_INSCRIPCION,
	MONTO_TASA,
	HEADERS_TOKEN
} from 'constants/routes/endpoints';

export const postAgregarUsuario = ({ email, password, tpoTramite,dni }) => {

	return new Promise((resolve, reject) => {
		return axios({
			url: AGREGAR_USUARIOS,
			method: 'POST',
			headers: HEADERS,
			data: {
				email: email,
				password: password,
				tpoTramite: tpoTramite,
				dni
			},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				return resolve({ data: err });
			});
	});
};




export const postLoginUsuario = ({ email, password, tpoTramite }) => {

	const agent = new https.Agent({  
		rejectUnauthorized: true
	  });


	return new Promise((resolve, reject) => {
		return axios({
			url: LOGIN_USUARIO,
			method: 'POST',
			headers: HEADERS,
			httpsAgent: agent,
			data: {
				email: email,
				password: password,
				tpoTramite
			},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response === undefined) {
					window.location.href = `/login`;
				}	

				
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};

export const postLoginExterno = ({ usuario, password, idLegajoTemporal,email, tpoTramite }) => {
	return new Promise((resolve, reject) => {
		return axios({
			url: LOGIN_EXTERNO,
			method: 'POST',
			headers: HEADERS,
			data: {
				usuario: usuario,
				password: password,
				idLegajoTemporal: idLegajoTemporal,
				email: email,
				tpoTramite:tpoTramite,
			},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};

export const postLoginEditarDatos = ({ idLegajo }) => {


	return new Promise((resolve, reject) => {
		return axios({
			url: LOGIN_ACTUALIZACION +"/" +idLegajo,
			method: 'POST',
			headers: HEADERS,
			data: {},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};
export const postLoginActualizacionDatos = ({ usuario, password, id, tpoTramite }) => {


	return new Promise((resolve, reject) => {
		return axios({
			url: LOGIN_EXTERNO,
			method: 'POST',
			headers: HEADERS,
			data: {
				Usuario: usuario,
				idLegajoTemporal: id,
				Password: password,
				email:'',
				tpoTramite
			},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};
export const recuperarPasswordUsuario = ({ email, tpoTramite }) => {

	return new Promise((resolve, reject) => {
		return axios({
			url: RECUPERAR_PASSWORD,
			method: 'POST',
			headers: HEADERS,
			data: {
				email: email,
				tpoTramite
			},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};


export const confirmarEmailUsuario = ({ email, token, tpoTramite }) => {

	return new Promise((resolve, reject) => {
		return axios({
			url: CONFIRMAR_EMAIL,
			method: 'POST',
			headers: HEADERS,
			data: {
				email: email,
				token: token,
				tpoTramite
			},
		})
			.then(response => response)
			.then(json =>
				resolve({ datas: json.data })
			)
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};

export const generarTokenUsuario = ({ email, tpoTramite }) => {

	return new Promise((resolve, reject) => {
		return axios({
			url: GENERAR_TOKEN,
			method: 'POST',
			headers: HEADERS,
			data: {
				email: email,
				tpoTramite
			},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401) {
					window.location.href = `/login`;
				}
			});
	});
};
export const recuperacionPasswordUsuario = ({ email, token, password, tpoTramite }) => {

	return new Promise((resolve, reject) => {
		return axios({
			url: CAMBIAR_PASSWORD,
			method: 'POST',
			headers: HEADERS,
			data: {
				email: email,
				token: token,
				password: password,
				tpoTramite
			},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};



// export const getResultadoPagoDeTasa =  async() =>{
// 	const idLegajoTemporal = localStorage.idLegajoTemporal;

// 	try {

// 		const resp = await axios.get(`${PAGO_TASA}/${idLegajoTemporal}`, { headers: HEADERS_TOKEN });

// 		return resp;
// 	} catch (error) {
// 		console.log(error);
// 	}
	
// }


// export const getDerechoInscripcion =  async() =>{
// 	const idLegajoTemporal = localStorage.idLegajoTemporal;

// 	try {

// 		const resp = await axios.get(`${DERECHO_INSCRIPCION}/${idLegajoTemporal}`, { headers: HEADERS_TOKEN });
		
// 		return resp;
// 	} catch (error) {
// 		console.log(error);
// 	}
	
// }

// export const getMontoDeTasa = async() => {
// 	try {
// 		const resp = await axios.get(`${MONTO_TASA}`, { headers: HEADERS_TOKEN });

// 		return resp;
// 	} catch (error) {
// 		console.log(error)
// 	}


// }


