import React, { useState, Fragment, useEffect } from 'react';
import { Grid, Card, CardContent, Button, AlertTitle, Alert, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {
    confirmarEmailUsuario
} from 'app/features/Usuarios/usuariosAPI';
// Styles
import 'scss/components/header.scss';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ConfirmacionCorreoBajaFallecimiento = () => {

    const params = useParams();
    const [mensajeResultado, setMensajeResultado] = useState();
    const [iconoResultado, setIconoResultado] = useState();
    localStorage.setItem('token', "");
    const [mostrarContenido, setMostrarContenido] = useState(false);

    useEffect(() => {
        const result = confirmarEmailUsuario({ email: params.email, token: params.token, tpoTramite: 99 });
        result.then((value) => {
            setMostrarContenido(true);
            if (!value.datas.resultado) {

                if (value.datas.codigoError === 0) {
                    setMensajeResultado("Intentelo nuevamente mas tarde.");
                }
                if (value.datas.codigoError === 1) {
                    setMensajeResultado("El email ya se encuentra registrado");
                }
                if (value.datas.codigoError === 2) {

                    setMensajeResultado("El email aún no se encuentra verificado");
                }
                if (value.datas.codigoError === 3) {

                    setMensajeResultado("El email no se encuentra registrado");
                }
                if (value.datas.codigoError === 4) {

                    setMensajeResultado("La validación a expirado. Por favor solicite uno nueva.");

                }

                setIconoResultado("error");

            } else {
                setIconoResultado("success");
                setMensajeResultado("El  correo   fue verificado correctamente: " + params.email);
            }
        });

    }, [])

    return (

<Box>
            <Grid item xs={12} style={{ display: mostrarContenido && 'none' }}>
                <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <CircularProgress />
                </Grid>
            </Grid>
            <Grid
                md={10}
                container direction="column"
                className="centrar_contenido"
                style={{ display: !mostrarContenido && 'none' }}
            >

                <Card>
                    <CardContent align='center' sx={{
                        height: '30vh',
                    }}

                    >
                        <Box
                            sx={{
                                mt: 4,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                backgroundColor: iconoResultado === "error" ? 'rgb(253, 237, 237)' : 'rgb(241, 242, 249)',
                                color: 'white',
                                p: 2,
                                borderRadius: 1
                            }}
                            >
                            {iconoResultado === "error" ? (
                                <ErrorOutlineIcon sx={{ mr: 1, color: 'rgb(95, 33, 32)' }} />
                            ) : (
                                <CheckCircleOutlineIcon sx={{ mr: 1, color: 'rgb(48, 53, 81)' }} />
                            )}
                            <Typography variant='h6' color={iconoResultado === "error" ? 'rgb(95, 33, 32)' : 'rgb(48, 53, 81)'}>
                                {mensajeResultado}
                            </Typography>
                        </Box>

                        {iconoResultado !== "error" ?

                            <Grid item sx={{ mt: 4 }} md={12} xs={12} className="centrar_contenido"  >

                                <Typography variant='h6'>
                                    <b>
                                        Para realizar la solicitud de baja por fallecimiento haga
                                    </b>
                                    <Button sx={{ ml: 1, mr: 1 }} variant="contained" color="primary" href="/loginBajaFallecimiento">
                                        Click Aqui
                                    </Button>
                                    <b>
                                        e ingrese nuevamente su correo electrónico y contraseña.
                                    </b>
                                </Typography>

                            </Grid> :
                            <Grid item sx={{ mt: 4}} md={12} xs={12} className="centrar_contenido" display='flex' justifyContent='center' >
                                
                                <Typography variant='h6'>
                                    <b>
                                        Para solicitar una nueva validación haga
                                    </b>
                                </Typography>
                                <Button sx={{ ml: 1, mr: 1 }} variant="contained" color="primary" href="/GenerarToken/99">
                                    Click Aqui
                                </Button>
                            </Grid>}
                    </CardContent>
                </Card>

            </Grid>
        </Box>
    );

};

export default ConfirmacionCorreoBajaFallecimiento;