import axios from 'axios';

import { 
	HEADERS_TOKEN,
	TITULOS,
	TITULOS_COMPLETOS,
	TITULOS_ESPECIALES
} from 'constants/routes/endpoints';



export const getCatalogoTitulos = () => {

	return new Promise((resolve, reject) => {
		return axios({
			url: localStorage.tpoTramite === '9' ? TITULOS_COMPLETOS : TITULOS,
			method: 'GET',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
			if (err.response.status === 401 || err.response.status === 500){
				window.location.href = `/login`;
			}
			});
	});
};

export const getCatalogoTitulosTitulosEspeciales = () => {

	return new Promise((resolve, reject) => {
		return axios({
			url: TITULOS_ESPECIALES,
			method: 'GET',
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
			if (err.response.status === 401 || err.response.status === 500){
				window.location.href = `/login`;
			}
			});
	});
};

