import React, { Fragment, useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2'
import Geocode from "react-geocode";

import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";

import { postLocalidades } from 'app/features/Localidades/localidadesAPI';
import "@reach/combobox/styles.css";


const useStyles = makeStyles(theme => ({
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    centrarContenido: {
        display: 'flex',
        justifyContent: 'center'
    },
    backGroundBox: {
        backgroundColor: '#00b347!important'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        bomdhadow: 24,
        p: 4
    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    mapContainer: {
        height: '100vh', width: '100%'
    },
    mapElements: {
        height: '100%'
    }
}));

const AnyReactComponent = ({ text }) => <div>{text}</div>;



const defaultProps = {
    center: {
        lat: -38.416096,
        lng: -63.616673
    },
    zoom: 7
};


export const Mapa = ({ setDatosDocimilio, titulosSelect, setTitulosSelect, marcadores, setMarcadores, datosDocimilio, location, setLocation, libraries, setListaLocalidades, Partidos, TpoDomicilio, datosCargados }) => {

    //  console.log(domiciliosProfesionales);

    useEffect(() => {


        if (datosDocimilio.calle !== "") {
            setTitulosSelect({
                label: datosDocimilio.calle + " " + datosDocimilio.numero + ", " + datosDocimilio.localidad + ", " + datosDocimilio.provincia, value: 0
            })
        }
        Geocode.setApiKey("AIzaSyBb5RiBio4TouN2IjNLz-LVW4BbIZH0eE8");

        // set response language. Defaults to english.
        Geocode.setLanguage("en");

        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion("es");
        if (datosDocimilio.calle !== "") {
            Geocode.fromAddress(datosDocimilio.calle + " " + datosDocimilio.numero + ", " + datosDocimilio.localidad + ", " + datosDocimilio.provincia).then(
                (response) => {

                    const { lat, lng } = response.results[0].geometry.location;


                    setMarcadores([{
                        lat: lat,
                        lng: lng,
                        time: new Date()
                    }]);
                    setLocation({
                        lat: lat,
                        lng: lng
                    });
                },
                (error) => {
                    // console.error(error);
                }
            );
        }

        // console.log(marcadores);
        //navigator.geolocation.getCurrentPosition(success);
    }, [])
    //console.log(domiciliosProfesionales);
    const [center, setCenter] = useState({
        lat: -38.416096,
        lng: -67.616673
    })

    //  console.log(navigator.geolocation);
    //Creamos la funcion para el botón 'Buscar mi ubicación'


    const mapContainerStylePhone = {

        width: '72vw',
        height: '50vh',
    }
    const mapContainerStyle = {

        width: '45vw',
        height: '60vh',

    }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyBb5RiBio4TouN2IjNLz-LVW4BbIZH0eE8",
        libraries
    })
    const [propiedadesDefecto, setPropiedadesDefecto] = useState({
        center: {
            lat: -38.416096,
            lng: -67.616673
        },
        zoom: 7
    })

    const seleccionarLocacion = (event) => {
        setMarcadores([{
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            time: new Date()
        }]);


        Geocode.setApiKey("AIzaSyBb5RiBio4TouN2IjNLz-LVW4BbIZH0eE8");

        // set response language. Defaults to english.
        Geocode.setLanguage("en");

        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion("es");

        //console.log(latitud, longitud);
        Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
            (response) => {
                const address = response.results[0].formatted_address;
                let calle, numero, localidad, provincia, country, codigoPostal;

                for (let i = 0; i < response.results[0].address_components.length; i++) {
                    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "locality":
                                localidad = response.results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                provincia = response.results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country = response.results[0].address_components[i].long_name;
                                break;
                            case "street_number":
                                numero = response.results[0].address_components[i].long_name;
                                break;
                            case "route":
                                calle = response.results[0].address_components[i].long_name;
                                break;
                            case "postal_code":

                                codigoPostal = response.results[0].address_components[i].long_name;
                                codigoPostal = codigoPostal.replace(/[^\d]/, '')
                                break;
                        }
                    }
                }
                //  console.log(codigoPostal);
                if (calle === undefined) {
                    calle = "calle";
                    numero = "S/N";
                }
                if (numero === undefined) {
                    numero = "S/N";
                }
                if (codigoPostal === undefined) {

                    codigoPostal = "";
                }
                if (localidad !== undefined && provincia !== undefined) {

                    setTitulosSelect({
                        label: calle + " " + numero + ", " + localidad + ", " + provincia, value: 0
                    })

                    if (localidad === "BZI" || localidad === "BZK" || localidad === "BZJ") {
                        localidad = "La Plata";
                    }

                    const datos = {
                        nombreLocalidad: localidad === "Buenos Aires" ? "Capital Federal" : localidad,
                        codPostal: '',
                        nombreProvincia: ''
                    }

                    let codLocalidad = "";
                    localidad = "";
                    let delegacionSelect = '';
                    let partidoSelect = '';
                    postLocalidades(datos).then(result => {
                        let localidadesArr = [];
                        result.data.map((ld, index) => {

                            if (index === 0) {

                                codLocalidad = ld.codLocalidad;
                                localidad = ld.nombreLocalidad;
                                Partidos.map((partido, index) => {
                                    if (ld.codPartido === partido.codPartido) {
                                        if (TpoDomicilio === 1) {
                                            delegacionSelect = partido.razonSocial;
                                        } else {

                                            delegacionSelect = datosCargados.domicilios[0].delegacion;
                                        }

                                        partidoSelect = partido.descripcion;
                                    }

                                })
                            }

                            localidadesArr.push({ label: ld.nombreLocalidad, value: ld.codLocalidad, codPartido: ld.codPartido })
                        })
                        setDatosDocimilio({
                            ...datosDocimilio,
                            provincia: provincia,
                            calle: calle,
                            localidad: localidad,
                            codLocalidad: String(codLocalidad),
                            numero: numero,
                            partido: partidoSelect,
                            delegacion: delegacionSelect,
                            codPostal: codigoPostal.replace(/[^0-9]+/g, "")
                        })
                        setListaLocalidades(localidadesArr);
                    })



                }
                // console.log(address);
            },
            (error) => {
                // console.error("hola");
            }
        );
    }

    let mapRef;
    let esLaPlata = false;
    function handleChange() {
        // Here, we invoke the callback with the new value
        //   console.log(mapRef.state.map.center.lat());
        const newCenter = mapRef;

        setMarcadores([{
            lat: mapRef.state.map.center.lat(),
            lng: mapRef.state.map.center.lng(),
            time: new Date()
        }]);



        Geocode.setApiKey("AIzaSyBb5RiBio4TouN2IjNLz-LVW4BbIZH0eE8");

        // set response language. Defaults to english.
        Geocode.setLanguage("en");

        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion("es");

        //console.log(latitud, longitud);
        Geocode.fromLatLng(newCenter.state.map.center.lat(), newCenter.state.map.center.lng()).then(
            (response) => {
                const address = response.results[0].formatted_address;
                let calle, numero, localidad, provincia, country, codigoPostal;
                for (let i = 0; i < response.results[0].address_components.length; i++) {
                    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "locality":
                                localidad = response.results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                provincia = response.results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country = response.results[0].address_components[i].long_name;
                                break;
                            case "street_number":
                                numero = response.results[0].address_components[i].long_name;
                                break;
                            case "route":
                                calle = response.results[0].address_components[i].long_name;
                                break;
                            case "postal_code":

                                codigoPostal = response.results[0].address_components[i].long_name;
                                codigoPostal = codigoPostal.replace(/[^\d]/, '')
                                break;
                        }
                    }
                }

                //esta validacion sirve en el caso de que la ciudad no tenga el nombre que corresponde
                if (localidad.length <= 3) {

                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "administrative_area_level_2":
                                    localidad = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }
                }
                
                if (calle === undefined) {
                    calle = "calle";
                    numero = "S/N";
                }
                if (numero === undefined) {
                    numero = "S/N";
                }
                if (codigoPostal === undefined) {

                    codigoPostal = "";
                }
                setTitulosSelect({
                    label: calle + " " + numero + ", " + localidad + ", " + provincia, value: 0
                })

                if (provincia === "CABA" && (localidad === "" || localidad === undefined)) {
                    localidad = "Buenos Aires";
                }



                if (localidad === "BZI" || localidad === "BZK" || localidad === "BZJ") {
                    localidad = "La Plata";
                }
                
                if(localidad === "La Plata") esLaPlata = true;

                const datos = {
                    nombreLocalidad: localidad === "Buenos Aires" ? "Capital Federal" : localidad,
                    codPostal: '',
                    nombreProvincia: ''
                }

                let codLocalidad = "";
                localidad = "";

                let delegacionSelect = '';
                let partidoSelect = '';
                postLocalidades(datos).then(result => {
                    let localidadesArr = [];
                    result.data.map((ld, index) => {

                        if (index === 0) {

                            codLocalidad = ld.codLocalidad;
                            localidad = ld.nombreLocalidad;
                            Partidos.map((partido, index) => {
                                if (ld.codPartido === partido.codPartido) {
                                    if (TpoDomicilio === 1) {
                                        delegacionSelect = partido.razonSocial;
                                    } else {

                                        delegacionSelect = datosCargados.domicilios[0].delegacion;
                                    }

                                    partidoSelect = partido.descripcion;
                                }

                            })
                        }   
                        if(esLaPlata && index === 2 ){
                            codLocalidad = ld.codLocalidad;
                            localidad = ld.nombreLocalidad;
                            Partidos.map((partido, index) => {
                                if (ld.codPartido === partido.codPartido) {
                                    if (TpoDomicilio === 1) {
                                        delegacionSelect = partido.razonSocial;
                                    }else{
                                        
                                    delegacionSelect =  datosCargados.domicilios[0].delegacion; 
                                    }

                                    partidoSelect = partido.descripcion;
                                }

                            })
                        }


                        localidadesArr.push({ label: ld.nombreLocalidad, value: ld.codLocalidad })
                    })
                    codigoPostal = codigoPostal.replace(/[^0-9]+/g, "");
                    setDatosDocimilio({
                        ...datosDocimilio,
                        provincia: provincia,
                        calle: calle,
                        localidad: localidad,
                        codLocalidad: String(codLocalidad),
                        numero: numero,
                        partido: partidoSelect,
                        delegacion: delegacionSelect,
                        codPostal: codigoPostal
                    })
                    setListaLocalidades(localidadesArr);
                })



                // console.log(address);
            },
            (error) => {
                // console.error("hola");
            }
        );
        //   props.onChange(newCenter);
    }
    const clases = useStyles();
    return (
        <Fragment>

            {isLoaded ?
                <GoogleMap
                    mapContainerStyle={window.screen.width > 1024 ? mapContainerStyle : mapContainerStylePhone}
                    ref={(ref) => (mapRef = ref)}
                    center={location}
                    zoom={16}
                    onDragEnd={handleChange}
                    onClick={(event) => {
                        seleccionarLocacion(event);
                        //console.log(marcadores);
                    }}
                >

                    {marcadores.map(marcador => <Marker key={marcador.time.toISOString()} position={{
                        lat: marcador.lat,
                        lng: marcador.lng
                    }} />)}
                </GoogleMap>
                : ''}

        </Fragment>
    );
}
