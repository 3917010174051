import { ESTADOS_TRAMITE } from "constants/constants";

export function isNullOrEmpty(value) {
  return value === null || value === undefined || value === ''
}


export function calcularEdad(fecha) {
  let hoy = new Date();
  let cumpleanos = new Date(fecha);
  let edad = hoy.getFullYear() - cumpleanos.getFullYear();
  let m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
  }

  return edad;
}

export const devolverNombreEstadoTramite = (id, observado) => {
    if (id === ESTADOS_TRAMITE.DRAFT) {
      return 'Iniciada';
    } else if (id === ESTADOS_TRAMITE.PENDIENTE || (id === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && !observado)) {
      return 'Pendiente';
    } else if (id === ESTADOS_TRAMITE.EN_REVISION) {
      return 'En revisión';
    } else if (id === ESTADOS_TRAMITE.VALIDADO) {
      return 'Validado';
    } else if (id === ESTADOS_TRAMITE.CANCELADA) {
      return 'Cancelada';
    } else if (id === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && observado) {
      return 'Observada';
    } else if (id === ESTADOS_TRAMITE.FINALIZADA) {
      return 'Finalizada';
    } else if (id === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION) {
      return 'Pre carga observación';
    } else if (id === ESTADOS_TRAMITE.PRE_CARGA_VALIDADA) {
      return 'Pre carga validada';
    } else if (id === ESTADOS_TRAMITE.PRE_CARGA_FINALIZADA) {
      return 'Pre carga finalizada';
    } else if (id === ESTADOS_TRAMITE.PENDIENTE_REVISION_FINAL_TTEE) {
      return 'Pendiente revisión final TTEE';
    } else {
      return 'No definido';
    }
}

export const devolverFraseEstadoTramite = (id, observado) => {
  if(id === ESTADOS_TRAMITE.DRAFT){
      return 'Completar Carga'
  } else if (id === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && observado){
      return 'Modificar'
  } else {
      return 'Ver'
  }
}


export const formatDNI = (dni) => {
  if (typeof dni !== 'string') {
    dni = dni.toString();
  }
  return dni.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};