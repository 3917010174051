import { Fragment } from 'react';
import logo from './../../Logos/Imagen_logo_cpba.jpg';
import { Page, Image, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { Box } from '@mui/material';

export const Pdf = ({ datos, TipoDocumentos, leyendas, nombreGerencia }) => {

    Font.register({
        family: "Oswald",
        src: `https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf`,
        fontWeight: 600
    });
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            fontSize: 11,
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
            lineHeight: 1.5,
            flexDirection: 'column',
        },
        section_center: {
            backgroundColor: "#D9D8D8",
            marginLeft: 0,
            fontSize: 15,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 5,
            height: 48,
            width: '50%',
            border: '1'
        },
        section_center_text: {
            marginTop: 12,
            fontSize: 11,
            marginBottom: 1,
            justifyContent: "center",
            alignItems: "center",
            width: '90%'
        },
        section_right: {
            marginLeft: 0,
            fontSize: 15,
            paddingTop: 5,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 5,
            height: 48,
            width: '22%',
            border: '1'
        },
        section_left: {
            marginTop: 5,
            height: 48,
            width: '35%',
            border: 1
        },
        row: {
            flexDirection: 'row',
        },
        rowDos: {
            flexDirection: 'row',
            marginTop: 2
        },
        firmaProfesional: {
            position: 'absolute',
            fontSize: 11,
            bottom: 20,
            left: 380,
            right: 0
        },
        nuevaFirmaProfesional: {
            position: 'absolute',
            fontSize: 11,
            bottom: 20,
            left: 370,
            right: 0,
            top: 360
        },
    });

    const formatDate = (date) => {
        let formatted_date = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "/" + date.getFullYear()
        return formatted_date;
    }
    
    const nombreGerenciaSinSector = (nombre) => {
        if (nombre.includes("-")) {
            return nombre.split("-")[1].trim();
        }
        return nombre;
    }

    const filteredLeyendas = leyendas.filter(leyenda => leyenda.descripcionLeyendaDdjj.startsWith("Asimismo, manifiesto"));

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Fragment>
                    <View style={styles.row}>
                        <View style={styles.section_left}>
                            <Image src={logo} style={{ width: 130, height: 41, marginLeft: 18 }} />
                        </View>
                        <View style={styles.section_center}>
                            <Text style={styles.section_center_text}>Solicitud de Baja por Fallecimiento</Text>
                            {localStorage.userType === "10" && <Text style={{ fontSize: 11 }}>  - Alta nuevo título-</Text>}
                        </View>
                        <View style={styles.section_right}>
                            <Text style={{ fontSize: 9, padding: 0, marginBottom: 10, marginTop: 0 }}> F-PG.05.04 {"\n"} Rev. 1</Text>
                            <Text style={{ borderTop: 1, width: '110px', marginTop: 3, marginBottom: 3 }}></Text>
                            <Text style={{ fontSize: 9, marginBottom: 8 }}>Página 1 de 1</Text>
                        </View>
                    </View>
                </Fragment>
                <Fragment>
                    <View style={styles.rowDos}>
                        <Text style={{ marginTop: 5, fontFamily: 'Helvetica-Bold' }}>Sres. C.P.C.E.P.B.A <text style={{ color: "white" }}>{"".padEnd(108, "_")}</text></Text>
                    </View>
                    <View>
                    <Text style={{ fontFamily: 'Helvetica-Bold', position:'absolute', bottom: 15, left: 450 }}>{formatDate(new Date())}</Text>
                    </View>
                </Fragment>
                <Fragment>
                    <View style={styles.rowDos}>
                    { localStorage.userType === "11" ?
                        <Text style={{ fontSize: 10 }}>
                            Quien suscribe, {localStorage.usuario} D.N.I {datos.NroDocumento} comunica el fallecimiento {datos.bajaFallecimiento[0].Sexo === "M" ? "del Dr" : "de la Dra"} 
                            {datos.bajaFallecimiento.map((fallecimiento, index) => {
                                let coma = "";
                                if (index > 0) coma = ", ";
                                return " " + fallecimiento.Nombre.trim() +  " " + fallecimiento.Apellido.trim() + " ";
                            })}Legajo N° {datos.bajaFallecimiento[0].LegProf},{datos.bajaFallecimiento[0].TitulosFallecido.map(prof => (
                                <text>
                                    {" "}matrícula: {prof.descrip.trim()}, T: {prof.tomo}, F: {prof.folio},
                                </text>
                            ))} acompañando las constancias respectivas. 
                            {/* ocurrido con fecha {formatDate(new Date())} */}
                            {'\n'} 
                            {'\n'} 
                            <text style={{ marginTop: 10, marginBottom: 3, fontFamily: 'Helvetica-Bold' }}>DATOS PERSONALES Y DE CONTACTO DEL SOLICITANTE:</text>
                            {'\n'}
                            
                            Tipo de documento: {TipoDocumentos.filter(Documento => Documento.tipoDocumento === datos.TipoDocumento)[0].descripcion.trim()} <text style={{ color: "white" }}> </text>
                    
                            N°: {datos.NroDocumento + " "}

                            CUIT/CUIL: {datos.CuitCuil} <text style={{ color: "white" }}>s </text>{'\n'}
                            
                            Nacionalidad: {datos.Nacionalidad.trim()} <text style={{ color: "white" }}>s</text> 
                            Carácter Invocado : {datos.bajaFallecimiento[0].codParentesco}
                        </Text>
                        :
                        <Text style={{ fontSize: 10 }}>
                            { datos.CodEnt === 25 ? "El CPCEPBA " : "La CSSPPCEPBA "}comunica el fallecimiento {datos.bajaFallecimiento[0].Sexo === "M" ? "del Dr" : "de la Dra"}
                            {datos.bajaFallecimiento.map((fallecimiento, index) => {
                                let coma = "";
                                if (index > 0) coma = ", ";
                                return " " + fallecimiento.Nombre.trim() +  " " + fallecimiento.Apellido.trim() + " ";
                            })} Legajo N° {datos.bajaFallecimiento[0].LegProf},{datos.bajaFallecimiento[0].TitulosFallecido.map(prof => (
                                <text>
                                    {" "}matrícula: {prof.descrip.trim()}, T: {prof.tomo}, F: {prof.folio},
                                </text>
                            ))} acompañando las constancias respectivas. 
                            {/* ocurrido con fecha {formatDate(new Date())} */}
                            {'\n'} 
                            {'\n'} 
                            <text style={{ marginTop: 10, marginBottom: 3, fontFamily: 'Helvetica-Bold' }}>DATOS DEL SOLICITANTE:</text>
                            {'\n'}

                            CUIT/CUIL: {datos.CuitCuil} <text style={{ color: "white" }}>s </text>
                            E-mail:<text style={{ color: "white" }}>a </text><text style={{ fontFamily: 'Helvetica-Bold' }}>{datos.Email}</text> <text style={{ color: "white" }}>a </text>
                            {'\n'}
                        </Text>
                        }
                    </View>
                    
                    {
                        localStorage.userType === "11" &&
                        <View style={styles.rowDos}>
                        <Text>E-mail:<text style={{ color: "white" }}>a </text><text style={{ fontFamily: 'Helvetica-Bold' }}>{datos.Email}</text> <text style={{ color: "white" }}>a </text>
                            <Text>
                                Celular:({datos?.domicilios[0]?.caracteristica}) {datos.Celular}<text style={{ color: "white" }}>a </text>
                            </Text>
                        </Text>
                    </View>
                    }
                    <View style={styles.rowDos}></View>
                </Fragment>
                <Fragment>
                    <View style={styles.rowDos}>
                    { localStorage.userType === "11" ?
                        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}>Presta expresa conformidad para que todas las notificaciones y/o comunicaciones que el CPCEPBA deba cursar -referidas a la presente solicitud- se dirijan a la casilla de correo electrónico y/o teléfono celular denunciado en el presente formulario, donde serán consideradas válidas todas las notificaciones que se remitan.</Text>
                        :
                        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}>Presta expresa conformidad para que todas las notificaciones y/o comunicaciones que el CPCEPBA deba cursar -referidas a la presente solicitud- se dirijan a la casilla de correo electrónico denunciado en el presente formulario, donde serán consideradas válidas todas las notificaciones que se remitan.</Text>
                    }
                    </View>
                </Fragment>
                <Fragment>
                    <View style={{ fontFamily: 'Helvetica-Bold', marginBottom: 1, marginTop: 15 }}>
                        <Text> DECLARA BAJO SU RESPONSABILIDAD</Text>
                    </View>
                    <View>
                        {/* {filteredLeyendas.map((leyenda, index) => */}
                            <Text style={{ fontFamily: 'Helvetica', fontSize: 10 }}>
                                -- Se manifiesta en carácter de declaración jurada, que la información consignada en este formulario es correcta y completa sin omitir ni falsear dato alguno que deba contener, siendo fiel expresión de la verdad.
                            </Text>
                        {/* )} */}
                    </View>
                </Fragment>
                <Fragment>
                    <View fixed style={styles.nuevaFirmaProfesional}>
                        <Box>
                            <Text><text>{datos.Nombre + " " + datos.Apellido}  </text></Text>
                            {
                                localStorage.userType === "12" && 
                                <Text>{nombreGerenciaSinSector(nombreGerencia)}{datos.CodEnt === 25 ? " - Consejo Profesional de Ciencias Económicas" : " - Caja de Seguridad Social" }</Text>
                            }
                            { localStorage.userType === "11" && 
                                <Text> {TipoDocumentos.filter(Documento => Documento.tipoDocumento === datos.TipoDocumento)[0].descripcion.trim() + " " + datos.NroDocumento + " "} </Text>
                            }
                        </Box>
                    </View>
                </Fragment>
            </Page>
        </Document>
    )
};

