import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    deshabilitarCampo: false,
    // fecDefuncion: '',
    // NroActa: 0,
    // NroTomo: 0,
    // Anio: 0,
    // NroFolio: 0,
};

export const bajaFallecimientoSlice = createSlice({
    name: 'bajaFallecimiento',
    initialState,
    reducers: {
        setDeshabilitarCampo: (state) => {
            state.deshabilitarCampo = true;
        },
        setHabilitarCampo : (state) => {
            state.deshabilitarCampo = false;
        },
    },
});

// Selector para obtener el estado 'deshabilitarCampo' del estado global
export const selectDeshabilitarCampo = (state) => state.bajaFallecimiento.deshabilitarCampo;

export default bajaFallecimientoSlice.reducer;

// Exportar las acciones
export const { setDeshabilitarCampo, setHabilitarCampo } = bajaFallecimientoSlice.actions;