import { useEffect, useState, useRef } from 'react';
import { Button, Modal, Paper, Typography, Divider, DialogTitle, DialogContent, DialogActions, TextField, Box } from "@mui/material";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useForm } from 'react-hook-form';
import { TIPOS_USUARIO } from 'constants/constants';
import { formatDNI } from 'utils/utils';

export const ModalAceptarSeleccion = ({datos, setDatos, showConfirmModal, selectedProf, handleConfirmClose, handleConfirmAccept, tpoUser}) => {
        
    const parentescoValidations = {
        required: { value: true, message: 'Parentesco requerido' },
        maxLength: { value: 10, message: 'Parentesco no puede superar los 10 caracteres' },
        pattern: { value: /^[a-zA-Z]+$/, message: 'Parentesco solo puede contener letras' }
    };

    const formRef = useRef();
    const {register, handleSubmit, formState: { errors }} = useForm({
        defaultValues: {
            parentesco:  datos.bajaFallecimiento !== null ? datos.bajaFallecimiento[0].codParentesco : ''
        }
    });    
    
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [disableBtn, setDisableBtn] = useState(true);
    const [parentescoValue, setParentescoValue] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        setParentescoValue(value);
        setDisableBtn(value.length < 4);
        const newData = { ...datos };
        newData.bajaFallecimiento[0].codParentesco = value;
        setDatos(newData);
    }

    const onSubmit = (data) => {
        if (data.parentesco) {
            handleConfirmAccept();
        } else {
            const newData = { ...datos };
            newData.bajaFallecimiento[0].codParentesco = "Empleado";
            setDatos(newData);
            handleConfirmAccept();
        }
        
    }
    
    useEffect(() => {
        if (datos.bajaFallecimiento[0].codParentesco !== '') {
            setParentescoValue(datos.bajaFallecimiento[0].codParentesco);
            setDisableBtn(false);
        }
    }, [datos]);

    useEffect(() => {
        if ( Number(localStorage.userType) === TIPOS_USUARIO.USUARIO_INTERNO ) {
            setDisableBtn(false);
        }
    } ,[]);

    return (
        <Modal open={showConfirmModal} onClose={handleConfirmClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Paper style={{ padding: '16px', width: isXs ? '300px' : '500px' }}>
                <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        tpoUser === TIPOS_USUARIO.USUARIO_INTERNO ? 'Por favor confirme la selección' : 'Indique parentesco/ relación'
                    }
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography variant="body1" style={{ marginBottom: '5px' }}><b>Apellido: </b><b>{selectedProf?.apellido}</b></Typography>
                    <Typography variant="body1" style={{ marginBottom: '5px' }}><b>Nombre: </b><b>{selectedProf?.nombre}</b></Typography>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}><b>Doc: </b><b>{formatDNI(selectedProf?.nroDocumento)}</b></Typography>
                </DialogContent>
                <Divider />
                {
                    Number(localStorage.userType) === TIPOS_USUARIO.USUARIO_EXTERNO &&
                
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ p: 2 }}>
                            <TextField 
                                type='text' 
                                label='Vinculo del solicitante con el profesional' 
                                variant='outlined' 
                                fullWidth 
                                margin='normal'
                                {...register('parentesco', 
                                    {
                                        required: parentescoValidations.required,
                                        maxLength: parentescoValidations.maxLength,
                                        pattern: parentescoValidations.pattern
                                    }
                                )}
                                error={!!errors.parentesco}
                                helperText={errors.parentesco?.message}
                                onChange={handleInputChange}
                                value={datos.bajaFallecimiento[0].codParentesco}
                            />
                        </Box>
                    </form>
                }
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button variant="contained" color="error" onClick={handleConfirmClose}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => handleSubmit(onSubmit)()} disabled={disableBtn}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Paper>
        </Modal>
    )
}