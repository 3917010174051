import React, { useEffect, useState } from 'react';
import { Button, Grid, Card, CardContent, Typography, Fab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Domicilios } from 'components/matriculas/domicilios';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getProfesionalValidarCuit, getProfesionalValidarMailActualizacion } from 'app/features/Profesionales/profesionalesAPI';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ModalEditarDatosContacto from './ModalEditarDatosContacto';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },

    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },

    button: {
        display: "flex!important",
        justifyContent: "flex-end!important"
    },
    color_texto_aclaracion: {
        //  color: 'gray',
        fontSize: 1
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        bomdhadow: 24,
        p: 4,
        '@media (max-width:900px)': {
            top: '92%',
        }
    },
    modales: {
        overflow: 'scroll',

    },
    ocultarBtnModal: {
        '@media (max-width:900px)': {
            display: 'none',
        },
        '@media (min-width:900px)': {
            display: '',
        }
    },
    ocultarBtnModalCelular: {
        '@media (max-width:900px)': {
            display: '',
        },
        '@media (min-width:900px)': {
            display: 'none',
        }
    },
    color_rojo: {
        color: 'red'
    }
}));

function DatosContacto({ datos, bloquearBtn, ObrasSociales, setDatos, setValue, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, idEstado, setLegajoTemporal, libraries }) {

    const { handleSubmit } = useForm();

    let obraSocialObjeto = [];
    let obraSocialNombre = "";
    ObrasSociales.map((obraSocial, index) => {
        if (obraSocialNombre.toUpperCase() !== obraSocial.sigla.trim().toUpperCase())
            obraSocialObjeto.push({ label: obraSocial.sigla.trim(), value: obraSocial.tpoOSocial })

        obraSocialNombre = obraSocial.sigla.trim();
    })

    
    const EstadoCivilList = useSelector(estadoCivilList);
    const validarDomicilios = () => {
        let errorDomicilio = 0;
        datos.domicilios.map((domicilio, index) => {

            if (domicilio.calle === "" || domicilio.provincia === "" || domicilio.provincia === null || domicilio.localidad === "" || domicilio.localidad === null) {
                errorDomicilio = 1;

            }

        })

        if (errorDomicilio === 1) {
            Swal.fire({
                title: 'Atención',
                html: 'Los domicilios tienen campos sin completar',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        } else {
            return true;
        }

    }
    
    let ObrasSocialesOrdenadas = [];

    if (obraSocialObjeto.length > 0) {

        obraSocialNombre = "";

        obraSocialObjeto.map((obraSocial, index) => {
            if (obraSocialNombre.toUpperCase() !== obraSocial.label.trim().toUpperCase()) {

                let agregarObraSocial = 0;
                ObrasSocialesOrdenadas.map(obraSocialRepetida => {
                    if (obraSocialRepetida.label.trim().toUpperCase() === obraSocial.label.trim().toUpperCase())
                        agregarObraSocial = 1;

                })
                if (agregarObraSocial === 0)
                    ObrasSocialesOrdenadas.push({ key: index, label: obraSocial.label.trim().toUpperCase(), value: obraSocial.value })
            }


            obraSocialNombre = obraSocial.label.trim();


        })

    }
    const onSubmit = accion => {
        if (datos.CuitCuil === '' || datos.Email === '') {
            Swal.fire({
                title: 'Atención',
                html: 'El <b>Email</b>  y <b> CUIT-CUIL</b> son obligatorios',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }


        if (datos.EmailRepetido === '') {
            Swal.fire({
                title: 'Atención',
                html: 'Debe Repetir el Email',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }

        if (datos.EmailRepetido !== datos.Email) {
            Swal.fire({
                title: 'Atención',
                html: 'Los Emails ingresados no coinciden',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }

        let erroresMostrar = "";
        let mostrarErrorDomicilio = 0;
        datos.domicilios.map((domicilio, index) => {
            // console.log(domicilio);
            if ((domicilio.calle === "" || domicilio.caracteristica === null || domicilio.caracteristica === "" || domicilio.celular === "" || (isNaN(domicilio.caracteristica) || isNaN(domicilio.celular))) && mostrarErrorDomicilio === 0) {
                if (erroresMostrar !== "") {
                    erroresMostrar += ", ";
                }
                
                erroresMostrar += "<b>Domicilios y/o Teléfono</b>";
                mostrarErrorDomicilio = 1;
            }

        })


        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }


        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);
            return false;

        }
        getProfesionalValidarCuit(datos.CuitCuil.replace(/-/gi, '')).then((value) => {
            
            if (!value.data.response) {

                return false;
            } else {

                getProfesionalValidarMailActualizacion(datos.Email, localStorage.idLegajo).then((value) => {

                    if (!value.data.response) {

                        Swal.fire({
                            title: 'Atención',
                            html: 'El <b>Email</b> ingresado ya se encuentra registrado',
                            icon: 'warning',
                            showCloseButton: true,
                            showCancelButton: false,
                            confirmButtonText:
                                'Aceptar',
                            cancelButtonText:
                                'Cancelar',
                            allowOutsideClick: true
                        });
                        setLoaderBtnGuardar(false)
                        setBloquearBtn(false);
                        return false;
                    } else {

                        if (validarDomicilios()) {
                            setLoaderBtnGuardar(true)
                            setBloquearBtn(true);


                            guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 6 }).then(value => {
                                setLoaderBtnGuardar(false);
                                setBloquearBtn(false);
                                if (value.data.resultado) {
                                    setValue("2");
                                    setLegajoTemporal(value.data.result.idLegajoTemporal)
                                    localStorage.setItem('idLegajoTemporal', value.data.result.idLegajoTemporal);
                                } else {
                                    Swal.fire({
                                        title: 'Atención',
                                        html: 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                                        icon: 'warning',
                                        showCloseButton: true,
                                        showCancelButton: false,
                                        confirmButtonText:
                                            'Aceptar',
                                        cancelButtonText:
                                            'Cancelar',
                                        allowOutsideClick: true
                                    })
                                    return false;
                                }


                            });
                        }
                        // });
                    }
                })

            }
        });

    }

    


    const [guardarDatosTemporales, setGuardarDatosTemporales] = useState(0);
    const [modalEditarDatosPersonales, setModalEditarDatosPersonales] = useState(false);
    const handleClose = () => setModalEditarDatosPersonales(false);
    const dispatch = useDispatch();
    const clases = useStyles();
    const [location, setLocation] = useState({
        lat: -62.7113387,
        lng: -95.00657030000001,
    });



    useEffect(() => {
        if (EstadoCivilList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getEstadoCivilAsync());
        }

        navigator.geolocation.getCurrentPosition(position => {
            // console.log(position);
            setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
    }, [])


    return (
        <Grid>
            <Typography sx={{ mb: 1 }} >* <b>Datos registrados. Para realizar la modificación de los mismos deberá hacer click en  <EditIcon />.</b></Typography>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12} >
                    <Card variant='custom' sx={{ boxShadow: 5 }}>
                        <CardContent>
                            <Grid container >

                                <Grid item md={5} xs={12} sx={{ mb: 2 }} >
                                    Email: <b> {datos.Email}</b>
                                </Grid>
                                <Grid item md={5} xs={12} sx={{ mb: 2 }} >
                                    Repetir Email: <b> {datos.Email}</b>
                                </Grid>

                                {bloquearBtn === false &&
                                    <Grid item className={clases.ocultarBtnModal} md={2}>
                                        <Fab color="secondary" aria-label="edit" onClick={() => {
                                            setModalEditarDatosPersonales(true)
                                        }} >
                                            <EditIcon />
                                        </Fab>
                                    </Grid>
                                }
                                {/* <Grid item md={3} xs={12} sx={{ mb: 2 }} >
                                    CUIT - CUIL: <b> {datos.CuitCuil}</b>
                                </Grid> */}
                                <Grid item md={4} xs={12} sx={{ mb: 2 }} >
                                    Estado Civil: <b> {EstadoCivilList.filter(i => i.estadoCivil === datos.EstadoCivil)[0] !== undefined && EstadoCivilList.filter(i => i.estadoCivil === datos.EstadoCivil)[0].descripcion}</b>
                                </Grid>
                                <Grid item md={4} xs={12} sx={{ mb: 2 }} >
                                    Obra Social: <b> {datos.ObraSocial}</b>
                                </Grid>
                                {bloquearBtn === false &&
                                    <Grid item className={`${clases.floatRight} ${clases.ocultarBtnModalCelular}`} xs={12}>
                                        <Fab color="secondary" aria-label="edit" onClick={() => {
                                            setModalEditarDatosPersonales(true)
                                        }} >
                                            <EditIcon />
                                        </Fab>
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>






                <Grid item md={12} xs={12} style={{ color: "red" }}>
                    (*)Campos obligatorios
                </Grid>
                <Grid item md={12} xs={12}>
                    <Domicilios datos={datos} setDatos={setDatos} bloquearBtn={bloquearBtn} libraries={libraries} location={location} setLocation={setLocation} ValorTab={setValue} setGuardarDatosTemporales={setGuardarDatosTemporales} editarDatos={1} />
                </Grid>

                <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                    <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="button" onClick={onSubmit} color="secondary" >{loaderBtnGuardar === true ? <CircularProgress /> : ''}Continuar</Button>
                    {/* <Button endIcon={<ArrowForwardIosIcon />} variant="contained" onClick={() => setValue("2")} className={clases.button} type="submit" color="info" >Continuar</Button> */}
                </Grid>

            </Grid>

            <ModalEditarDatosContacto 
            bloquearBtn={bloquearBtn} 
            obrasSociales={ObrasSociales} 
            onClose={handleClose}
            onDatosChange={(nuevosDatos) => setDatos({...datos, ...nuevosDatos})}
            open={modalEditarDatosPersonales}
            email={datos.Email}
            obraSocial={datos.ObraSocial}
            tpoObraSocial={datos.TpoOsocial}
            estadoCivil={datos.EstadoCivil}
              />
        </Grid >


    )
}

export default DatosContacto;