import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Button, Card, CardContent, Fab, Grid, Modal, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import SchoolIcon from '@mui/icons-material/School';
import { useForm } from "react-hook-form";
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector, useDispatch } from 'react-redux';
import { titulosList, getTitulosAsync } from 'app/features/Titulos/titulosSlice';
import { universidadesList, getUniversidadesAsync } from 'app/features/Universidades/universidadesSlice';
import { EMPTY_FILE } from 'constants/icons';
const useStyles = makeStyles(theme => ({
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    centrarContenido: {
        display: 'flex',
        justifyContent: 'center'
    },
    backGroundBox: {
        backgroundColor: '#00b347!important'
    },
    deleteFab: {
        backgroundColor: 'red!important'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    },
    modales: {
        overflow: 'scroll',

    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    color_rojo: {
        color: 'red'
    },
    mapContainer: {
        height: '100vh'
    },
    mapElements: {
        height: '100%'
    }
}));

export const TitulosRegistrados = ({ bloquearBtn, setValue, loaderBtnGuardar, titulosProfesional }) => {
    const clases = useStyles();
    const dispatch = useDispatch();

 
    const convertirFechaTituloProfesional = string => {

        string = string.slice(0, string.search('T'));
        var info = string.split('-');

        if(info[2] + '/' + info[1] + '/' + info[0] === "01/01/0001"){
            return "";
        }

            return info[2] + '/' + info[1] + '/' + info[0];
    }


    return (
        <Fragment>
            <Typography sx={{ mb: 1 }} >* <b>Título/s con los que se encuentra matriculado.</b></Typography>
            <Grid container spacing={2} >
              
                {titulosProfesional.map((tituloAgregado, index) =>
                    <Grid item md={6} key={index}>
                        <Card variant='custom' sx={{ boxShadow: 5 }}>
                            <CardContent>
                                <Grid container>
                                    <Grid item md={12} className={clases.centrarContenido}>
                                        <h3 style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}><SchoolIcon />  {tituloAgregado.descripcion.trim()}</h3>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        Universidad: <b>{tituloAgregado.nombreUniversidad}</b>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        Promedio: <b>{tituloAgregado.promedio}</b>
                                    </Grid>

                                    <Grid item md={8} sx={{ mb: 2 }}>
                                        Fec. Última Materia : <b>{tituloAgregado.fecUltMateria !== null && convertirFechaTituloProfesional(tituloAgregado.fecUltMateria)}</b>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        Fec. Emisión de Título: <b>{tituloAgregado.fecTitulo !== null && convertirFechaTituloProfesional(tituloAgregado.fecTitulo)}</b>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
              
            </Grid>

            <Grid container>
                <Grid item md={12}> </Grid>
              
                <Grid item md={12}>
                    <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                        <Button disabled={bloquearBtn} endIcon={<ArrowForwardIosIcon />} onClick={() => setValue("2")} variant="contained" type="submit" color="secondary" >

                            {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}
