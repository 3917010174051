import axios from "axios";
import { HEADERS_TOKEN, OBTENER_QR } from "constants/routes/endpoints";

export const obtenerCodigoQR = async() => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.get(`${OBTENER_QR}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		// console.log(resp)
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

