import { useEffect, useState, Fragment } from 'react';
import { Grid, Button, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, TableFooter, TablePagination, TextField, Box, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { crearNuevoTramite, obtenerListaTramites, obtenerTokenBajaFallecimiento } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';
import { devolverFraseEstadoTramite, devolverNombreEstadoTramite, formatDate, formatDNI } from 'utils/utils';
import { usePaginate } from 'hooks/usePaginate';
import SearchIcon from '@mui/icons-material/Search';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useMediaQuery, useTheme } from '@material-ui/core';



export const MenuBajaFallecimiento = () => {

    const [listaTramites, setListaTramites] = useState('');
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [valorBusqueda, setValorBusqueda] = useState('');
    const { currentList, setPage, currentPage, nextPage, prevPage, searchList, clearList} = usePaginate(listaTramites, rowsPerPage);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, listaTramites.length - currentPage * rowsPerPage);

    const handleInputChange = (e) => {
        setValorBusqueda(e.target.value);
    };

    const onSearch = (e) => {
        e.preventDefault();
        searchList(valorBusqueda);
    };

    const onClear = () => {
        clearList();
        setValorBusqueda('')
    };

    const handleChangePage = (event, newPage) => {
        if (newPage > currentPage) {
            nextPage();
        } else {
            prevPage();
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const onClick = async () => {
        setLoading(true);
        const resp = await crearNuevoTramite({ email: localStorage.email, password: '', tpoTramite: 4, dni: '', tpo_usuario: Number(localStorage.userType) });
        const data = await obtenerTokenBajaFallecimiento(resp);
        if (resp !== false) {
            localStorage.setItem('idLegajoTemporal', resp);
            localStorage.setItem('token', data.token);
            window.location.href = '/bajaFallecimiento';
            setLoading(false);
        }
    };

    const cargarTramites = async () => {
        setLoading(true);
        const resp = await obtenerListaTramites();
        if (resp !== false) {
            setListaTramites(resp);
            setLoading(false);
        }
    };

    const selectTramite = async (idLegajoTemporal) => {
        const resp = await obtenerTokenBajaFallecimiento(idLegajoTemporal);
        if (resp !== false) {
            localStorage.setItem('idLegajoTemporal', idLegajoTemporal);
            localStorage.setItem('token', resp.token);
            window.location.href = '/bajaFallecimiento';
        }
    };

    useEffect(() => {
        cargarTramites();
    }, []);

  return (
    <Grid container>
    <Grid item xs={12} md={11}>
        <Paper elevation={3} style={{ padding: '10px' }}>
            <Box display={!isXs && 'flex'} justifyContent={!isXs && 'space-between'}>
                <Typography variant="h6" style={{ marginBottom: '10px' }}>Solicitudes </Typography>
                <form onSubmit={onSearch} style={{ display: 'flex', flexDirection: isXs ? 'column' : 'row', justifyContent: 'center'}}>
                    <Grid xs={12} md={5} mt={!isXs && 1} mb={isXs && 2}>
                        <Button variant="contained" color="primary" fullWidth={isXs} onClick={onClick} disabled={loading} sx={{minWidth: '133px', mt: !isXs && 1}}> {loading ? <CircularProgress size={20} /> : <Typography fontSize={'0.8em'}>Nueva Solicitud</Typography> }</Button>
                    </Grid>
                    <Grid xs={12} md={9} display='flex' justifyContent='center'>
                        <TextField value={valorBusqueda} onChange={handleInputChange} id="outlined-basic" label="Buscar trámite" variant="outlined" style={{ marginBottom: '10px', marginRight: '10px' }} />
                        <IconButton aria-label="search" color="primary" type='submit'>
                            <SearchIcon />
                        </IconButton>
                        <IconButton aria-label="search" color="primary" onClick={onClear}>
                            <CleaningServicesIcon />
                        </IconButton>
                    </Grid>
                </form>
            </Box>
            <Box ml={1}>
                <Typography >
                    Datos de la solicitud
                </Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Trámite</TableCell> */}
                            <TableCell sx={{ textAlign: 'center' }}><b>Fecha</b></TableCell>
                            <TableCell sx={{ textAlign: 'center' }}><b>Nombre y Apellido</b></TableCell>
                            <TableCell sx={{ textAlign: 'center' }}><b>Documento</b></TableCell>
                            <TableCell sx={{ textAlign: 'center' }}><b>Legajo</b></TableCell>
                            <TableCell sx={{ textAlign: 'center' }}><b>Estado</b></TableCell>
                            <TableCell sx={{ textAlign: 'center' }}><b>Solicitud</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listaTramites !== '' && currentList.map((tramite, index) => {
                            // <TablaTramites key={index} tramite={tramite} selectTramite={selectTramite} index={index} />
                            let datos = {Nombre: "-", Apellido: "", NroDocumento: "-", LegProf: "-"};
                            if (tramite.jsonDatos !== null) datos = JSON.parse(tramite.jsonDatos);
                            const estado = devolverNombreEstadoTramite(tramite.idEstado, datos.Observado);
                            // const documento = datos.bajaFallecimiento && datos.bajaFallecimiento[0] ? formatDNI(datos.bajaFallecimiento[0].NroDocumento) : "-";
                            const frase = devolverFraseEstadoTramite(tramite.idEstado, datos.Observado);
                            return (
                                <TableRow key={index}>
                                    {/* <TableCell>{tramite.idLegajoTemporal}</TableCell> */}
                                    <TableCell sx={{ textAlign: 'center' }}><b>{tramite.fehaInscripcion ? formatDate(tramite.fehaInscripcion) : "-" }</b></TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}><b>
                                        {datos.bajaFallecimiento && datos.bajaFallecimiento[0] ?
                                         `${datos.bajaFallecimiento[0].Nombre} ${datos.bajaFallecimiento[0].Apellido}` 
                                         : "-"}
                                         </b>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}><b>{datos.bajaFallecimiento && datos.bajaFallecimiento[0] ? datos.bajaFallecimiento[0].NroDocumento : "-"}</b></TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}><b>{datos.bajaFallecimiento && datos.bajaFallecimiento[0] ? datos.bajaFallecimiento[0].LegProf : "-"}</b></TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}><b>{estado}</b></TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        <Link component="button" variant="body2" onClick={() => selectTramite(tramite.idLegajoTemporal)}>
                                            <b>{frase}</b>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={6}
                                count={listaTramites.length}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage="Filas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`} 
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Paper>
    </Grid>
</Grid>
  )
}
