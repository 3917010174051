import React, { useState, useEffect, Fragment } from 'react';
import { TitulosRegistrados } from 'components/altaTitulos/titulosRegistrados';
import { TitulosEspecialAlta } from 'components/altaTitulosEspeciales/titulosEspeciales'
import { Chat } from 'components/chatInscripcionEspecial/chat';
import { ArchivosAdjuntosTituloEspecial } from 'components/altaTitulosEspeciales/archivosAdjuntosTituloEspecial';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tab, Grid, Box, Paper } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { getMatriculasAsync } from 'app/features/Matriculas/matriculasSlice';
import { getTitulosProfesionalesAsync } from 'app/features/TitulosProfesional/titulosProfesionalSlice';
import { getLegajosAsync } from 'app/features/Profesionales/profesionalesSlice';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { parentescosList, getParentescosAsync } from 'app/features/Parentescos/parentescosSlice';
import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';
import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment';

import { EMPTY_FILE } from 'constants/icons';
import { ADJUNTOS_BASE, ESTADOS_TRAMITE } from 'constants/constants';

// Styles
import 'scss/components/header.scss';
import { getDerechoInscripcion, getEstadoOperacion, getOperacion, getResultadoPagoDeTasa, grabarProfIntermediaTasa } from 'app/features/Profesionales/profesionalesAPI';
import { PagoPendiente } from 'components/matriculas/pagoPendiente';
import { PagoBrick } from 'components/matriculas/pagoBrick';
import { PagoCompletado } from 'components/matriculas/pagoCompletado';
import { PagoCompletadoTitEspecial } from 'components/InscripcionTitulosEspeciales/pagoCompletadoTitEspecial';
import { PagoCompletadoAltaTitulosEspeciales } from 'components/altaTitulosEspeciales/pagoCompletadoAltaTitulosEspeciales';
import { DerechoDeInscripcionAltaTituloEspecial } from 'components/altaTitulosEspeciales/derechoDeInscripcionAltaTituloEspecial';
import { obtenerQrAsync, setCodigoQr } from 'app/features/CodigoQr/codigoQrSlice';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },

    ocultarBotonesContinuar: {

        '@media (min-width:780px)': {
            display: 'none'
        }
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4' 
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }

}));

const libraries = ['places'];

const AltaTituloEspecial = () => {
    const dispatch = useDispatch();
    const clases = useStyles();
    const [value, setValue] = useState('1');
    const [Matriculas, setMatriculas] = useState([]);
    const [bloquearBtn, setBloquearBtn] = useState(false);
    const [mostrarDatos, setMostrarDatos] = useState(false);
    const [titulosProfesional, setTitulosProfesional] = useState([]);
    const [guardarDatosTemporales, setGuardarDatosTemporales] = useState(0);
    const ObrasSociales = useSelector(obrasSocialesList);
    const [aprobacionDocumentacion, setAprobacionDocumentacion] = useState(null);
    const [idLegajoTemporal, setIdLegajoTemporal] = useState(null);

    const [imprimirResultado, setImprimirResultado] = useState(0);

    const [datos, setDatos] = useState({
        titulos: [],
        adjuntos: [
            ADJUNTOS_BASE.DNI,
            ADJUNTOS_BASE.RES_MINISTERIO_EDUCACION,
            ADJUNTOS_BASE.PLAN_ESTUDIOS,
            ADJUNTOS_BASE.DECLARACION_JURADA_ENFERMEDADES,
            ADJUNTOS_BASE.CERT_LIBRE_SANCION,
            ADJUNTOS_BASE.ANALITICO
        ]
    });

    const [idEstado, setIdEstado] = useState(1);

    
    const [resultadoPagoTasa, setResult] = useState();
    // const [resultadoDerechoInscripcion, setResultadoDerechoInscripcion] = useState();
    const [montoDeTasa, setMontoDeTasa] = useState();
    const [estadoOperacion, setEstadoOperacion] = useState();
    const [ocultarContenido, setOcultarContenido] = useState(false);
    const [medioDePago, setMedioDePago] = useState("0");

    const obtenerResultadoDePago = async() => {

       const data = await getResultadoPagoDeTasa();
        // console.log(resp)
        setResult(data.resultado);   
        
    }
    
    const obtenerEstadoOperacion = async() => {
        const data = await getEstadoOperacion();
        // console.log(data)
        setEstadoOperacion(data)
    }

    const obtenerOperacion = async() => {
        
        const data = await getOperacion();
        if (data) {
            setEstadoOperacion(data.estado)
            setMontoDeTasa(data.monto)
        }
    }

    const obtenerQR = async() => {
        
        dispatch(obtenerQrAsync()).then((data) => {
            if(data.payload.qr === null || !data.payload.qr) {
                setMedioDePago("0")
            }else{
                dispatch(setCodigoQr(data.payload))
                setMedioDePago("2")
           }
        
        })

    }

    const mascaraCuitCargarDatos = (cuitIngresado) => {

        var cuitNuevo = "";
        let cuit = cuitIngresado;

        cuit = cuit.replace(/\-/g, '').replace(/[a-z ]/g, "").split('');

        for (var i = 0; i < cuit.length; i++) {

            if (i === 1 || i === 9) {
                cuitNuevo += cuit[i] + "-";
            } else {
                cuitNuevo += cuit[i];
            }

        }

        return cuitNuevo;

    }

    const mascaraDocumento = (documento) => {

        var documentoNuevo = "";
        documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "").split('');

        for (var i = 0; i < documento.length; i++) {

            if (i === 1 || i === 4) {
                documentoNuevo += documento[i] + ".";
            } else {
                documentoNuevo += documento[i];
            }

        }

        return documentoNuevo;
    }
    const convertirFecha = string => {
        if (string.search('/') === -1) {
            return string;
        }


        string = string.slice(0, string.search(' '));
        var info = string.split('/');


        if (info[1].length === 1)
            info[1] = "0" + info[1];

        if (info[0].length === 1)
            info[0] = "0" + info[0];

        return info[2] + '-' + info[1] + '-' + info[0];
    }
    const Parentescos = useSelector(parentescosList);
    const EstadoCivilList = useSelector(estadoCivilList);
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const calcularEdad = (fecha) => {
        let hoy = new Date();
        let cumpleanos = new Date(fecha);
        let edad = hoy.getFullYear() - cumpleanos.getFullYear();
        let m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }

    const ordenarAdjuntos = (adjuntos) => {

        const adjuntosDNIs =  adjuntos.filter(a => a.id === ADJUNTOS_BASE.DNI.id );
        const adjuntosMinisterio =  adjuntos.filter(a => a.id === ADJUNTOS_BASE.RES_MINISTERIO_EDUCACION.id);
        const adjuntosTitulo = adjuntos.filter(a => a.id === ADJUNTOS_BASE.TITULO.id);
        const adjuntosPlan = adjuntos.filter(a => a.id === ADJUNTOS_BASE.PLAN_ESTUDIOS.id);
        const adjuntosDetalle = adjuntos.filter(a => a.id ===  ADJUNTOS_BASE.DETALLE_INCUMBENCIAS.id);
        const adjuntosAnalitico = adjuntos.filter(a => a.id === ADJUNTOS_BASE.ANALITICO.id);
        const adjuntosLibreSancion = adjuntos.filter(a => a.id === ADJUNTOS_BASE.CERT_LIBRE_SANCION.id);
        const adjuntosDDJJ = adjuntos.filter(a => a.id === ADJUNTOS_BASE.DECLARACION_JURADA.id);
        const adjuntosSolicitud = adjuntos.filter(a => a.id ===  ADJUNTOS_BASE.SOLICITUD_REGISTRO.id);
        const adjuntosDDJJSalud = adjuntos.filter(a => a.id === ADJUNTOS_BASE.DECLARACION_JURADA_ENFERMEDADES.id);


        return [
            ...adjuntosDNIs,
            ...adjuntosMinisterio,
            ...adjuntosTitulo,
            ...adjuntosPlan,
            ...adjuntosDetalle,
            ...adjuntosAnalitico,
            ...adjuntosLibreSancion,
            ...adjuntosDDJJSalud,
            ...adjuntosDDJJ,
            ...adjuntosSolicitud
        ];
    }

    if (imprimirResultado === 0 && Matriculas.codigoError === 0 && TipoDocumentos.length > 0 && Parentescos.length > 0) {
        

        const root = document.documentElement;
        root?.style.setProperty(
            "--distanciaAlto", 100
        );

        //Sacar boton revisar inscripcion y agregar campo Observado cambiar nombre observaciones por revisar inscripcion lado matriculas
        //observado en true puede editar siempre y cuando este en estado 6
        //observado en false no puede editar

        if (Matriculas.mensaje !== "Legajo no encontrado!" && Matriculas.result.jsonDatos !== null) {
            let datosProfesional = [];
            let estadoCargaMatricula = 0;

            setIdLegajoTemporal(Matriculas.result.idLegajoTemporal);
            if (Matriculas.result.jsonDatos !== undefined) {
                datosProfesional = JSON.parse(Matriculas.result.jsonDatos);
                estadoCargaMatricula = Matriculas.result.idEstado;
                setIdEstado(Matriculas.result.idEstado);
                setAprobacionDocumentacion({
                    documentacionEnviadaParaRevision: Matriculas.result.documentacionEnviadaParaRevision,
                    fechaEnvioDocumentacion: Matriculas.result.fechaEnvioDocumentacion,
                    fechaAprobacionDocumentacion: Matriculas.result.fechaAprobacionDocumentacion !== null ? moment(Matriculas.result.fechaAprobacionDocumentacion).format("yyyy-MM-DD"): null,
                    nroResolucionAprobacionDocumentacion: Matriculas.result.nroResolucionAprobacionDocumentacion 
                });
            } else {
                datosProfesional = Matriculas.result;
                setIdEstado(1);
                estadoCargaMatricula = 1;
            }

            if ((estadoCargaMatricula === 1 || (estadoCargaMatricula === 6 && datosProfesional.Observado === true)) && localStorage.userType === "10") {
                Swal.fire({
                    title: '',
                    html: 'Se recuerda que antes de comenzar a cargar el alta de un título  <u><b>deberá poseer en formato JPG o PDF</b></u> los documentos indicados en los requisitos de inscripción detallados en el siguiente link:<a href="https://www.cpba.com.ar/component/k2/item/13821" target="_blank">clic aquí</a>',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
            }
            if (estadoCargaMatricula === 2 || estadoCargaMatricula === 3 || estadoCargaMatricula === 10 || estadoCargaMatricula === 7 || (estadoCargaMatricula === 6 && datosProfesional.Observado === false) || (Matriculas.result.idEstado === 8 && datosProfesional.Observado === false) || localStorage.userType === "1") {
                setBloquearBtn(true);
            }

            let arrFiles = [];
            if (Array.isArray(datosProfesional.adjuntos)) {
                datosProfesional.adjuntos.map((adj) => {

                    if (adj.id !== ADJUNTOS_BASE.TITULO.id) {
                        arrFiles.push({ id: adj.id, fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file !== null ? adj.file : '', size: adj.size, url: adj.url === "" ? EMPTY_FILE : adj.url, saved: true });
                    } else {

                        datosProfesional.titulos.map((tit) => {

                            let nombreTit = 'Título - ' + tit.nombreTitulo.trim();
                            let mapTit = adj.fileType.replace(/\d/g, "").trim();
                            if (nombreTit === mapTit) {


                                if (adj.url === "") {
                                    adj.url = EMPTY_FILE;
                                }

                                arrFiles.push({id: adj.id,  fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file, size: adj.size, url: adj.url, saved: true });
                            }
                        })
                    }

                })

            } else {
                arrFiles = datos.adjuntos;
            }


            if (datosProfesional.titulos !== null) {
                datosProfesional.titulos.map((tit) => (arrFiles.filter(c => c.fileType === 'Título - ' + tit.nombreTitulo.trim()).length < 1)
                    && arrFiles.push({ id: ADJUNTOS_BASE.TITULO.id,  fileType: 'Título - ' + tit.nombreTitulo.trim(), fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true }));


            }

            let familiaresArr = [];
            if (datosProfesional.familiares !== null) {
                datosProfesional.familiares.map((fam, index) => {

                    familiaresArr.push({
                        nombreParentesco: Parentescos.filter(p => p.codParentesco === fam.codParentesco)[0].descripcion.trim(),
                        nombreTipoDocumento: TipoDocumentos.filter(t => t.tipoDocumento === fam.tipoDocumento)[0].descripcion.trim(),
                        apellido: fam.apellido.trim(),
                        cargo: fam.cargo,
                        codParentesco: fam.codParentesco,
                        edad: fam.edad === 0 ? Number(calcularEdad(convertirFecha(fam.fechaNacimiento))) : Number(fam.edad),
                        fechaNacimiento: convertirFecha(fam.fechaNacimiento),
                        id: fam.id,
                        incapacitado: fam.incapacitado,
                        nombre: fam.nombre.trim(),
                        numeroDocumento: fam.numeroDocumento,
                        obraSocial: fam.obraSocial.trim(),
                        sexo: fam.sexo,
                        tipoDocumento: fam.tipoDocumento,
                        tpoOsocial: fam.tpoOsocial
                    })
                })
            }

            if (datosProfesional.LegProf !== undefined) {

                dispatch(getTitulosProfesionalesAsync(datosProfesional.LegProf)).then(result => {
                    setTitulosProfesional(result.payload.results)
                })
            }

            setDatos({
                ...datos,
                Nombre: datosProfesional.nombre !== undefined ? datosProfesional.nombre.trim() : datosProfesional.Nombre.trim(),
                Apellido: datosProfesional.apellido !== undefined ? datosProfesional.apellido.trim() : datosProfesional.Apellido.trim(),
                LegProf: datosProfesional.LegProf !== undefined ? Number(datosProfesional.LegProf) : datosProfesional.legProf,
                CuitCuil: datosProfesional.cuitCuil !== undefined ? mascaraCuitCargarDatos(datosProfesional.cuitCuil) : mascaraCuitCargarDatos(datosProfesional.CuitCuil),
                Email: datosProfesional.email !== undefined ? datosProfesional.email : datosProfesional.Email,
                familiares: familiaresArr,
                Celular: datosProfesional.celular !== undefined ? datosProfesional.celular : datosProfesional.Celular,
                Observado: datosProfesional.Observado !== undefined ? datosProfesional.Observado : false,
                domicilios: datosProfesional.domicilios,
                Nacionalidad: datosProfesional.nacionalidad !== undefined ? datosProfesional.nacionalidad : datosProfesional.Nacionalidad,
                Nacimiento: datosProfesional.nacimiento !== undefined ? convertirFecha(datosProfesional.nacimiento) : datosProfesional.Nacimiento,
                NroDocumento: datosProfesional.nroDocumento !== undefined ? mascaraDocumento(datosProfesional.nroDocumento) : datosProfesional.NroDocumento,
                TipoDocumento: datosProfesional.tipoDocumento !== undefined ? datosProfesional.tipoDocumento : datosProfesional.TipoDocumento,
                EstadoCivil: datosProfesional.descEstadoCivil !== undefined ? EstadoCivilList.filter(e => e.descripcion === datosProfesional.descEstadoCivil)[0].estadoCivil : datosProfesional.EstadoCivil,
                titulos: datosProfesional.titulos !== null ? datosProfesional.titulos : [],
                PerteneceOtroConsejo: datosProfesional.perteneceOtroConsejo !== undefined ? datosProfesional.perteneceOtroConsejo : datosProfesional.PerteneceOtroConsejo,
                adjuntos: ordenarAdjuntos(arrFiles),
                sexo: datosProfesional.sexo,
                exentoTasa: datosProfesional.exentoTasa !== undefined ? datosProfesional.exentoTasa : true
            });

            if ((localStorage.userType !== '1') && (!(estadoCargaMatricula === ESTADOS_TRAMITE.DRAFT || estadoCargaMatricula === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION || estadoCargaMatricula === ESTADOS_TRAMITE.EN_REVISION))) {
                setValue("3");
            }

            setTimeout(() => setMostrarDatos(true), 1000);


            setImprimirResultado(1);
        } else if (Matriculas.codigoError !== null && imprimirResultado === 0) {
            
            Swal.fire({
                title: '',
                html: 'Se recuerda que antes de comenzar a cargar el alta de un título  <u><b>deberá poseer en formato JPG o PDF</b></u> los documentos indicados en los requisitos de inscripción detallados en el siguiente link:<a href="https://www.cpba.com.ar/component/k2/item/13821" target="_blank">clic aquí</a>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            setImprimirResultado(1);
            setTimeout(() => setMostrarDatos(true), 1000);
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [loaderBtnGuardar, setLoaderBtnGuardar] = useState(false);

    useEffect(() => {
        if (TipoDocumentos.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getTipoDocumentoAsync());
        }
        if (EstadoCivilList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getEstadoCivilAsync());
        }
        if (Parentescos.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getParentescosAsync());
        }

        if (titulosProfesional.length === 0 && localStorage.validacionTitulo !== "1") {

            dispatch(getTitulosProfesionalesAsync()).then(result => {

                setTitulosProfesional(result.payload.results)
            })
        }

        if (Matriculas.length === 0) {
            let Null = null;
            //llamo a la funcion del Slice
            dispatch(getMatriculasAsync({ Null, Null })).then(valor => {

                if (valor.payload.mensaje !== "Legajo no encontrado!") {
                    setMatriculas(valor.payload)
                } else {
                    dispatch(getLegajosAsync(({ idLegajo: null, token: null }))).then(response => {

                        setMatriculas(response.payload);
                    })
                }

            });
        }

    }, [])



    if (localStorage.token === "") {
        window.location.href = `/login`;

    }

    const cambiarTab = (tabNuevo) => {
        if (tabNuevo === 1 && value !== "1") {

            let nuevoValor = String(Number(value) - 1);
            setValue(nuevoValor);
        } else if (tabNuevo === 2 && value !== "4") {
            let nuevoValor = String(Number(value) + 1);
            setValue(nuevoValor);
        }

    }
    const [ocultarTabs, setOcultarTabs] = useState({ display: '' });

    useEffect(() => {
        if (localStorage.idLegajoTemporal !== '0' && localStorage.idLegajoTemporal !== '') {
            obtenerResultadoDePago();
        } 
    }, [])

    useEffect(() => {
        if (localStorage.idLegajoTemporal !== '0' && localStorage.idLegajoTemporal !== '') {
            obtenerEstadoOperacion();
        }
    }, [])

    useEffect(() => {
        if (localStorage.idLegajoTemporal !== '0' && localStorage.idLegajoTemporal !== '') {
            obtenerOperacion();
        }
    }, [])

    useEffect(() => {
        if (localStorage.idLegajoTemporal !== '0' && localStorage.idLegajoTemporal !== '') {
            obtenerQR();
        }
    }, [])


    return (

        <Grid container >


            {mostrarDatos ?
                <Grid item xs={12} md={11}>

                    <TabContext value={value}>

                        <Box>
                            <Grid container style={ocultarTabs} >
                                <Grid item xs={2} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(1)}><ArrowBackIosIcon /></Button>
                                </Grid>
                                <Grid item md={12} xs={9}>
                                    <TabList onChange={handleChange} style={ocultarTabs}>
                                        <Tab label={<b>Título/s registrado/s</b>} value="1" style={{ fontWeight: 'bold', background: value === "1" ? '#E1E1E1' : '', borderRadius: '5px' }} />
                                        <Tab label={<b>Título a inscribir</b>} value="2" style={{ fontWeight: 'bold', background: value === "2" ? '#E1E1E1' : '', borderRadius: '5px' }} />
                                        <Tab label={<b>Adjuntos</b>}  value="3" style={{ fontWeight: 'bold', background: value === "3" ? '#E1E1E1' : '', borderRadius: '5px' }} />
                                        { (idEstado !== ESTADOS_TRAMITE.DRAFT && idEstado !== ESTADOS_TRAMITE.EN_REVISION && idEstado !== ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION) &&
                                             (!(Matriculas.result.exentoTasa == null? true : Matriculas.result.exentoTasa) || resultadoPagoTasa) && <Tab label ={<b>Derecho de Inscripción</b>} value="4" style={{fontWeight: 'bold', background: value === "4" ? '#E1E1E1': '', borderRadius: '5px'}}  /> }

                                    </TabList>
                                </Grid>
                                <Grid item xs={1} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(2)}><ArrowForwardIosIcon /></Button>
                                </Grid>
                            </Grid>
                        </Box>

                        <Paper>
                            <TabPanel value="1"  >
                                <TitulosRegistrados
                                    bloquearBtn={bloquearBtn}
                                    setBloquearBtn={setBloquearBtn}
                                    setValue={setValue}
                                    loaderBtnGuardar={loaderBtnGuardar}
                                    titulosProfesional={titulosProfesional} 
                                    />
                            </TabPanel>

                            <TabPanel value="2" >
                                <TitulosEspecialAlta datos={datos}
                                    setDatos={setDatos}
                                    bloquearBtn={bloquearBtn}
                                    setBloquearBtn={setBloquearBtn}
                                    setValue={setValue}
                                    loaderBtnGuardar={loaderBtnGuardar}
                                    idEstado={idEstado}
                                    titulosProfesional={titulosProfesional}
                                    setLoaderBtnGuardar={setLoaderBtnGuardar} 
                                    obtenerEstadoOperacion={obtenerEstadoOperacion}
                                    setMatriculas={setMatriculas}
                                    />
                            </TabPanel>
                            <TabPanel value="3" >
                                <ArchivosAdjuntosTituloEspecial idLegajoTemporal={idLegajoTemporal} datos={datos} setDatos={setDatos} titulosProfesional={titulosProfesional} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setValue} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} aprobacionDocumentacion={aprobacionDocumentacion} setAprobacionDocumentacion={setAprobacionDocumentacion}  resultadoPagoTasa={resultadoPagoTasa} setMatriculas={setMatriculas} />
                            </TabPanel>

                            {
                                (idEstado !== ESTADOS_TRAMITE.DRAFT && idEstado !== ESTADOS_TRAMITE.EN_REVISION && idEstado !== ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION) &&
                                (!(Matriculas.result.exentoTasa == null? true : Matriculas.result.exentoTasa) || resultadoPagoTasa) &&
                                <TabPanel value="4" >
                                    
                                    <DerechoDeInscripcionAltaTituloEspecial  datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setValue} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} resultadoPagoTasa={resultadoPagoTasa}  estadoOperacion={estadoOperacion} aprobacionDocumentacion={aprobacionDocumentacion} setAprobacionDocumentacion={setAprobacionDocumentacion} obtenerResultadoDePago={obtenerResultadoDePago} setEstadoOperacion={setEstadoOperacion} setResult={setResult} montoDeTasa={montoDeTasa} setMedioDePago={setMedioDePago} medioDePago={medioDePago}/>
                                    {/* {
                                       estadoOperacion === 1 ? 
                                        <PagoPendiente obtenerResultadoDePago={obtenerResultadoDePago} setEstadoOperacion={setEstadoOperacion}/>
                                       :
                                       (!resultadoPagoTasa ? 
                                           <PagoBrick setResult={setResult} montoDeTasa={montoDeTasa}/> 
                                           : 
                                           <PagoCompletadoAltaTitulosEspeciales datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setValue} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} resultadoPagoTasa={resultadoPagoTasa}  estadoOperacion={estadoOperacion} aprobacionDocumentacion={aprobacionDocumentacion} setAprobacionDocumentacion={setAprobacionDocumentacion} />
                                        )
                                    } */}
                                </TabPanel>
                            } 
                        </Paper>

                    </TabContext>

                </Grid>
                :
                <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </div>
                </Grid>
            }

            {((idEstado !== 1 && idEstado !== 7 && localStorage.userType !== "10") || (localStorage.userType === "10" && datos.Observado === true) || localStorage.userType === "1")
                && <Chat datos={datos} setDatos={setDatos} setIdEstado={setIdEstado} />}

        </Grid>


    );
};

export default AltaTituloEspecial;
