import React, { useEffect, useState } from 'react';

import { Autocomplete, Button, Select, Grid, TextField, MenuItem, InputLabel, Typography, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { useSelector, useDispatch } from 'react-redux';
import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { getProfesionalValidarCuit } from 'app/features/Profesionales/profesionalesAPI';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { nacionalidadesList, getNacionalidadesAsync } from 'app/features/Nacionalidades/nacionalidadesSlice';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },
    button: {
        display: "flex!important",
        justifyContent: "flex-end!important"
    },
    color_texto_aclaracion: {
        //  color: 'gray',
        fontSize: 1
    },
    color_rojo: {
        color: 'red'
    },
    option: {
        fontWeight: 'bold',
        color: 'black'
    },
}));

function DatosPersonales({ datos, bloquearBtn, setDatos, setValue, ObrasSociales, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, idEstado, setMatriculas }) {

    // const [datos, setDatos] = useState({
    //     localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', telefono: '', fax: ''
    // });


    const { handleSubmit } = useForm();

    const ingresaNacionalidad = (event, value) => {

        if (value !== null) {
            setDatos({
                ...datos,
                Nacionalidad: value.value
            });
        } else {
            setDatos({
                ...datos,
                Nacionalidad: ''
            });
        }
    }

    let valueObraSocial = 0;
    const [labelObraSocial, setLabelObraSocial] = useState('');

    const ingresaObraSocial = (event, value) => {

        if (value !== null) {

            setLabelObraSocial(value.label)

            setDatos({
                ...datos,
                ObraSocial: value.label,
                TpoOsocial: Number(value.value)
            });
        } else {
            setLabelObraSocial('')
            setDatos({
                ...datos,
                ObraSocial: '',
                TpoOsocial: ''
            });
        }
    }

    const mascaraCuit = (evt) => {
        var codigo = evt.which || evt.keyCode;

        if (codigo !== 8) {
            var cuitNuevo = "";
            let cuit = datos.CuitCuil;

            cuit = cuit.replace(/\-/g, '').replace(/[a-z ]/g, "").split('');

            for (var i = 0; i < cuit.length; i++) {

                if (i === 1 || i === 9) {
                    cuitNuevo += cuit[i] + "-";
                } else {
                    cuitNuevo += cuit[i];
                }

            }

            setDatos({
                ...datos,
                CuitCuil: cuitNuevo
            });
        }

    }

    const mascaraDocumento = (evt) => {
        var codigo = evt.which || evt.keyCode;

        if (codigo !== 8) {
            var documentoNuevo = "";
            let documento = datos.NroDocumento;

            documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "").split('');

            for (var i = 0; i < documento.length; i++) {

                if (i === 1 || i === 4) {
                    documentoNuevo += documento[i] + ".";
                } else {
                    documentoNuevo += documento[i];
                }

            }

            setDatos({
                ...datos,
                NroDocumento: documentoNuevo
            });
        }

    }
    const inputHandleChange = (evt) => {

        if (evt.target.name === "Nacimiento") {

            var hoy = new Date();
            var fechaFormulario = new Date(evt.target.value);

            // Comparamos solo las fechas => no las horas!!
            hoy.setHours(0, 0, 0, 0);  // Lo iniciamos a 00:00 horas

            if (hoy <= fechaFormulario) {

                Swal.fire({
                    title: 'Atención',
                    html: 'La Fecha de Nacimiento no debe ser posterior a la fecha actual',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                setDatos({
                    ...datos,
                    [evt.target.name]: ''
                });

                return false;
            }


        }

        if (evt.target.name === "sexo") {

            setDatos({
                ...datos,
                [evt.target.name]: Number(evt.target.value)
            });

        } else {

            setDatos({
                ...datos,
                [evt.target.name]: evt.target.value
            });
        }


    }

    const [errorCuit, setErrorCuit] = useState({ display: "none" });
    const onSubmit = data => {

        //console.log(datos);
        if (datos.NroDocumento.length !== 10) {
            Swal.fire({
                title: 'Atención',
                html: 'El Numero de Documento ingresado no es valido',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }
        let documento = datos.NroDocumento;

        documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "");

        let cuit = datos.CuitCuil;

        cuit = cuit.substring(3);
        cuit = cuit.substring(0, 8);

        if (datos.CuitCuil.length !== 13 || cuit !== documento) {
            Swal.fire({
                title: 'Atención',
                html: 'El CUIT-CUIL ingresado no es valido',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        if (datos.sexo === null) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe seleccionar el Sexo',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        getProfesionalValidarCuit(datos.CuitCuil.replace(/-/gi, '')).then((value) => {


            if (!value.data.response) {
                setErrorCuit({ display: '' })

                return false;
            } else {
                setLoaderBtnGuardar(true)
                setBloquearBtn(true);

                // let adjuntosTemporales = [];
                // datos.adjuntos.map((adj, i) => {
                //     adjuntosTemporales.push({
                //         url: adj.url,
                //         file: adj.file,
                //         fileType: adj.fileType,
                //         fileName: adj.fileName
                //     })
                // })

                // datos.adjuntos.map((adj, i) => {
                //     datos.adjuntos.filter(c => c.fileType === adj.fileType)[0].file = '';
                //     datos.adjuntos.filter(c => c.fileType === adj.fileType)[0].url = '';
                // })
                
                guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 1 }).then((value) => {
                    setMatriculas(value.data);
                    setLoaderBtnGuardar(false);
                    setBloquearBtn(false);
                    console.dir(value);
                    if (value.data.resultado) {
                        setValue("2");
                    } else {
                        Swal.fire({
                            title: 'Atención',
                            html: value.data.mensaje !== '' && value.data.mensaje !== null ? value.data.mensaje : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                            icon: 'warning',
                            showCloseButton: true,
                            showCancelButton: false,
                            confirmButtonText:
                                'Aceptar',
                            cancelButtonText:
                                'Cancelar',
                            allowOutsideClick: true
                        })
                        return false;
                    }
                });
            }
        });
    }

    const dispatch = useDispatch();
    const clases = useStyles();
    const TipoDocumentos = useSelector(tipoDocumentoList);

    const EstadoCivilList = useSelector(estadoCivilList);
    const NacionalidadesList = useSelector(nacionalidadesList);
    //console.log(NacionalidadesList)
    useEffect(() => {
        if (EstadoCivilList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getEstadoCivilAsync());
        }

        if (TipoDocumentos.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getTipoDocumentoAsync());
        }

        if (NacionalidadesList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getNacionalidadesAsync());
        }
        // console.log(ObrasSociales)
        ObrasSociales.map((obraSocial, index) => {
            //console.log(datos.TpoOsocial);
            //console.log(Number(datos.TpoOsocial))
            if (datos.TpoOsocial !== "" && datos.TpoOsocial !== null && obraSocial.tpoOSocial === Number(datos.TpoOsocial)) {
                setLabelObraSocial(obraSocial.sigla.trim());

            }

        })

    }, [])

    let nacionalidadObjeto = [];

    NacionalidadesList.map((nacionalidad, index) => {

        nacionalidadObjeto.push({ label: nacionalidad.descNacionalidad.trim(), value: nacionalidad.descNacionalidad.trim() })
    })

    let obraSocialObjeto = [];
    let obraSocialNombre = "";

    function SortArray(x, y) {
        if (x.label < y.label) { return -1; }
        if (x.label > y.label) { return 1; }
        return 0;
    }

    ObrasSociales.map((obraSocial, index) => {
        if (obraSocialNombre.toUpperCase() !== obraSocial.sigla.trim().toUpperCase())
            obraSocialObjeto.push({ label: obraSocial.sigla.trim(), value: obraSocial.tpoOSocial })

        obraSocialNombre = obraSocial.sigla.trim();
    })
    let ObrasSocialesOrdenadas = [];

    if (obraSocialObjeto.length > 0) {

        obraSocialNombre = "";

        obraSocialObjeto.map((obraSocial, index) => {
            if (obraSocialNombre.toUpperCase() !== obraSocial.label.trim().toUpperCase()) {

                let agregarObraSocial = 0;
                ObrasSocialesOrdenadas.map(obraSocialRepetida => {
                    if (obraSocialRepetida.label.trim().toUpperCase() === obraSocial.label.trim().toUpperCase())
                        agregarObraSocial = 1;

                })
                if (agregarObraSocial === 0)
                    ObrasSocialesOrdenadas.push({ key: index, label: obraSocial.label.trim().toUpperCase(), value: obraSocial.value })
            }


            obraSocialNombre = obraSocial.label.trim();


        })

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >* <b>Completar todos los campos.</b></Typography>
            <span sx={{ mb: 2 }}></span>
            <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                    <TextField label={<b>Nombre</b>}
                        name='Nombre'
                        onChange={inputHandleChange}
                        value={datos.Nombre}
                        variant={bloquearBtn === true ? "filled" : "outlined"}
                        required
                        disabled={bloquearBtn}
                        fullWidth
                        type="text"
                        inputProps={{ style: {fontWeight: 'bold'}}}
                        // InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                    />
                </Grid>
                <Grid item md={6} xs={6}>
                    <TextField label="Apellidos"
                        name='Apellido'
                        onChange={inputHandleChange}
                        value={datos.Apellido}
                        variant={bloquearBtn === true ? "filled" : "outlined"}
                        required
                        disabled={bloquearBtn}
                        fullWidth
                        type="text"
                        inputProps={{ style: {fontWeight: 'bold'}}}
                        InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                sx: { fontWeight: 'bold', color: 'black' }
                            }}
                            label="Fecha de Nacimiento"
                            variant={bloquearBtn === true ? "filled" : "outlined"}
                            fullWidth
                            name='Nacimiento'
                            disabled={bloquearBtn}
                            required
                            onChange={inputHandleChange}
                            value={datos.Nacimiento}
                            type="date"
                            inputProps={{ style: {fontWeight: 'bold'}}}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={6}>
                    <FormControl fullWidth variant={bloquearBtn === true ? "filled" : "outlined"}>
                        <InputLabel id="labelEstadoCivil" 
                            sx={{ fontWeight: 'bold', color: 'black' }}
                        >Estado Civil *</InputLabel>
                        <Select
                            labelId="labelEstadoCivil"
                            name='EstadoCivil'
                            onChange={inputHandleChange}
                            required
                            value={datos.EstadoCivil}
                            disabled={bloquearBtn}
                            label="Estado Civil"
                            
                        >
                            {

                                EstadoCivilList.map((eC, index) =>

                                    <MenuItem key={index} value={eC.estadoCivil}><b>{eC.descripcion}</b></MenuItem>
                                )


                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={6}>
                    <FormControl fullWidth variant={bloquearBtn === true ? "filled" : "outlined"}>
                        <InputLabel id="labelTipoDocumento"
                            sx={{ fontWeight: 'bold', color: 'black' }}
                        >Tipo de documento *</InputLabel>
                        <Select
                            labelId="labelTipoDocumento"
                            name="TipoDocumento"
                            onChange={inputHandleChange}
                            required
                            disabled={bloquearBtn}
                            value={datos.TipoDocumento}
                            label="Tipo de documento"
                            inputProps={{ style: {fontWeight: 'bold'}}}
                        >
                            {

                                TipoDocumentos.map((documento, index) =>

                                    <MenuItem key={index} value={documento.tipoDocumento}><b>{documento.descripcion}</b></MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField label="Número"
                        name='NroDocumento'
                        onChange={inputHandleChange}
                        onKeyUp={mascaraDocumento}
                        value={datos.NroDocumento}
                        inputProps={{ maxLength: 10, style: {fontWeight: 'bold'}}}
                        required
                        disabled={bloquearBtn}
                        variant={bloquearBtn === true ? "filled" : "outlined"}
                        fullWidth
                        type="text"
                        InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField label="CUIT - CUIL"
                        name='CuitCuil'
                        onChange={inputHandleChange}
                        onKeyUp={mascaraCuit}
                        inputProps={{ maxLength: 13, style: {fontWeight: 'bold'}}}
                        value={datos.CuitCuil}
                        required
                        disabled={bloquearBtn}
                        variant={bloquearBtn === true ? "filled" : "outlined"}
                        fullWidth
                        type="text"
                        InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                    />
                    <Typography className={clases.color_rojo} style={errorCuit} >El CUIT-CUIL ingresado es invalido</Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Autocomplete
                        name='Nacionalidad'
                        onChange={ingresaNacionalidad}
                        options={nacionalidadObjeto}
                        disabled={bloquearBtn}
                        noOptionsText={'No se encontraron Nacionalidades'}
                        value={{ label: datos.Nacionalidad !== undefined ? datos.Nacionalidad : '', value: datos.Nacionalidad !== undefined ? datos.Nacionalidad : '' }}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => 
                            <TextField {...params} variant={bloquearBtn === true ? "filled" : "outlined"} required label="Nacionalidad"  />}
                    />
                </Grid>



                <Grid item md={5} xs={12}>
                    <TextField label="Email"
                        name='Email'
                        onChange={inputHandleChange}
                        disabled
                        variant="filled"
                        value={datos.Email}
                        required
                        fullWidth
                        type="email"
                        inputProps={{ style: {fontWeight: 'bold'}}}
                        // InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                        // InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                    />
                </Grid>


                <Grid item md={4} xs={12}>
                    <Autocomplete
                        name='TpoOsocial'
                        onChange={ingresaObraSocial}
                        options={ObrasSocialesOrdenadas}
                        disabled={bloquearBtn}
                        noOptionsText={'No se encontraron Obras Sociales'}
                        value={{ label: labelObraSocial, value: isNaN(datos.TpoOsocial) === false ? datos.TpoOsocial : 0 }}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => 
                            <TextField {...params} variant={bloquearBtn === true ? "filled" : "outlined"} required label="Obra Social"  />}
                    />
                    {/* <TextField label="Obra Social"
                        name='TpoOsocial'
                        disabled={bloquearBtn}
                        onChange={inputHandleChange}
                        value={datos.TpoOsocial}
                        required
                        variant={bloquearBtn === true ? "filled" : "outlined"}
                        fullWidth
                        type="text"
                        inputProps={{ style: {fontWeight: 'bold'}}}
                        InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                    /> */}
                </Grid>
                <Grid item md={3} xs={12}>
                    <FormControl>
                        <FormLabel id="demo-form-control-label-placement"
                            sx={{ fontWeight: 'bold', color: 'black' }}
                        ><b>Sexo (Según indica el DNI)*</b></FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            required
                            value={datos.sexo}
                        >
                            <FormControlLabel
                                value={0}

                                onChange={inputHandleChange}
                                name="sexo"
                                disabled={bloquearBtn}
                                control={<Radio />}
                                label="F"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={1}
                                onChange={inputHandleChange}
                                name="sexo"
                                disabled={bloquearBtn}
                                control={<Radio />}
                                label="M"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value={2}
                                onChange={inputHandleChange}
                                disabled={bloquearBtn}
                                name="sexo"
                                control={<Radio />}
                                label="X"
                                labelPlacement="start"
                            />

                        </RadioGroup>
                    </FormControl>

                </Grid>
                <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                    <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary" >{loaderBtnGuardar === true ? <CircularProgress /> : ''}Continuar</Button>
                    {/* <Button endIcon={<ArrowForwardIosIcon />} variant="contained" onClick={() => setValue("2")} className={clases.button} type="submit" color="info" >Continuar</Button> */}
                </Grid>

            </Grid>
        </form >
    )
}

export default DatosPersonales;