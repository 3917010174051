import React, { useState, useEffect } from 'react';
import { Chat } from 'components/chat/chat';
import { useSelector, useDispatch } from 'react-redux';
import { guardarDatosMatriculas, validarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { Button, Box, Card, CardContent, Grid, Tab, TextField, Paper } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import DatosContacto from 'components/editarDatosPersonales/DatosContactoEditarDatos';
import { GrupoFamiliar } from 'components/editarDatosPersonales/grupoFamiliarEditarDatos';
import { ArchivosAdjuntos } from 'components/editarDatosPersonales/archivosAdjuntosEditarDatos';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice'
import { getTitulosProfesionalesAsync } from 'app/features/TitulosProfesional/titulosProfesionalSlice';
import { parentescosList, getParentescosAsync } from 'app/features/Parentescos/parentescosSlice';
import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import { legajosList, getLegajosAsync } from 'app/features/Profesionales/profesionalesSlice';
import { matriculasList, getMatriculasAsync } from 'app/features/Matriculas/matriculasSlice';
import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { tipoDomicilioList, getTipoDomicilioAsync } from 'app/features/TipoDomicilio/tipoDomicilioSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { EMPTY_FILE } from 'constants/icons';

// Styles
import 'scss/components/header.scss';
import DatosPersonales from 'components/editarDatosPersonales/DatosPersonalesEditarDatos';
import { setCambiaDNI, setCambiaFoto, setFotoNueva, setFotoNuevaFullSize } from 'app/features/FotoProfesional/editarDatosProfesionalSlice';
import { TIPOS_USUARIO } from 'constants/constants';
import { nuevoAdjunto } from 'utils/adjuntosLogic';
import { calcularEdad, isNullOrEmpty } from 'utils/utils';




const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },
    ocultarBotonesContinuar: {

        '@media (min-width:780px)': {
            display: 'none'
        }
    },
    color_rojo: {
        color: 'red'
    }
}));


const Matriculas = () => {
    const dispatch = useDispatch();
    const clases = useStyles();
    const [solapa, setNumeroSolapa] = useState('0');
    const [Matriculas, setMatriculas] = useState([]);
    const [titulosProfesional, setTitulosProfesional] = useState([]);
    const TipoDomicilios = useSelector(tipoDomicilioList);
    const [bloquearBtn, setBloquearBtn] = useState(false);
    const [mostrarDatos, setMostrarDatos] = useState(false);
    const [legajoTemporal, setLegajoTemporal] = useState(Number(localStorage.idLegajoTemporal))
    const [legajoProfesional, setLegajoProfesional] = useState();
    const ObrasSociales = useSelector(obrasSocialesList);
    const libraries = ['places'];
    const [imprimirResultado, setImprimirResultado] = useState(0);
    const editarFotoSlice = useSelector(state => state.editarDatosProfesionalReducer);

    const cuerpoAdjuntos = [
        { fileType: 'DNI - Anverso', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: false },
        { fileType: 'DNI - Reverso', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: false },
    ];

    const [datos, setDatos] = useState({
        CuitCuil: '',
        Email: '',
        domicilios: [
            { TpoDomicilio: 1, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 },
            { TpoDomicilio: 2, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 },
            { TpoDomicilio: 3, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 },
            { TpoDomicilio: 4, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 },
            { TpoDomicilio: 5, localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', edito: false, tpoCarga: 1 }
        ],
        adjuntos: cuerpoAdjuntos
    });

    const mascaraCuitCargarDatos = (cuitIngresado) => {

        var cuitNuevo = "";
        let cuit = cuitIngresado;

        cuit = cuit.replace(/\-/g, '').replace(/[a-z ]/g, "").split('');

        for (var i = 0; i < cuit.length; i++) {

            if (i === 1 || i === 9) {
                cuitNuevo += cuit[i] + "-";
            } else {
                cuitNuevo += cuit[i];
            }

        }

        return cuitNuevo;

    }
    const Parentescos = useSelector(parentescosList);
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const [idEstado, setIdEstado] = useState(1);
    const [loaderBtnGuardar, setLoaderBtnGuardar] = useState(false);

    const mascaraDocumento = (documento) => {

        var documentoNuevo = "";
        documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "").split('');

        var largoDocumento = documento.length;

        for (var i = 0; i < documento.length; i++) {

            if (largoDocumento === 8) {
                if (i === 1 || i === 4) {
                    documentoNuevo += documento[i] + ".";
                } else {
                    documentoNuevo += documento[i];
                }

            } else if (largoDocumento === 7) {
                if (i === 0 || i === 3) {
                    documentoNuevo += documento[i] + ".";
                } else {
                    documentoNuevo += documento[i];
                }
            } else {
                if (i === 2 || i === 5) {
                    documentoNuevo += documento[i] + ".";
                } else {
                    documentoNuevo += documento[i];
                }
            }
        }

        return documentoNuevo;
    }
    const convertirFecha = string => {
        if (string.search('/') === -1) {
            return string;
        }
        string = string.slice(0, string.search(' '));
        var info = string.split('/');

        if (info[1].length === 1)
            info[1] = "0" + info[1];

        if (info[0].length === 1)
            info[0] = "0" + info[0];

        return info[2] + '-' + info[1] + '-' + info[0];
    }
    if (imprimirResultado === 0 && Matriculas.codigoError === 0 && TipoDocumentos.length > 0 && Parentescos.length > 0) {

        if (Matriculas.mensaje !== "Legajo no encontrado!" && Matriculas.result !== null) {
            let datosProfesional = [];
            let estadoCargaMatricula = 0;


            if (Matriculas.result.jsonDatos !== undefined) {
                datosProfesional = JSON.parse(Matriculas.result.jsonDatos);
                estadoCargaMatricula = Matriculas.result.idEstado;
                setIdEstado(Matriculas.result.idEstado);

                if (Matriculas.result.idEstado === 2 || Matriculas.result.idEstado === 7 || (Matriculas.result.idEstado === 6 && !datosProfesional.Observado ) || localStorage.userType == TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS) {
                    setBloquearBtn(true);
                }

            } else {
                datosProfesional = Matriculas.result;



                if (idEstado == 2 || idEstado == 7 || (idEstado === 6 && !datosProfesional.Observado ) || localStorage.userType == TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS) {
                    setBloquearBtn(true);
                }
                estadoCargaMatricula = 1;
            }

            let familiaresArr = [];

            datosProfesional.familiares.map((fam, index) => {
                let parentescoFam = Parentescos.filter(p => p.codParentesco === fam.codParentesco)[0]?.descripcion.trim();
                let tipoDocFam = TipoDocumentos.filter(t => t.tipoDocumento === fam.tipoDocumento)[0]?.descripcion.trim();
                familiaresArr.push({
                    nombreParentesco: parentescoFam === undefined ? null : parentescoFam,
                    nombreTipoDocumento: tipoDocFam === undefined ? null : tipoDocFam,
                    apellido: fam.apellido,
                    cargo: fam.cargo,
                    codParentesco: fam.codParentesco,
                    edad: Number(calcularEdad(convertirFecha(fam.fechaNacimiento))),
                    fechaNacimiento: convertirFecha(fam.fechaNacimiento),
                    id: 1 + (Math.random() * (999999 - 1)),
                    incapacitado: fam.incapacitado,
                    nombre: fam.nombre.trim(),
                    codNacionalidad: fam.codNacionalidad,
                    ocupacion: fam.ocupacion,
                    lugarNacimiento: fam.lugarNacimiento,
                    numeroDocumento: fam.numeroDocumento,
                    obraSocial: fam.obraSocial.trim(),
                    sexo: fam.sexo,
                    tipoDocumento: fam.tipoDocumento,
                    tpoOsocial: fam.tpoOsocial,
                    idFam: fam.idFam,
                    estado: fam.estado === null || fam.estado === undefined ? 1 : fam.estado,
                })
            })
            let arrFiles = [];
            if (datosProfesional.adjuntos !== null) {
                datosProfesional.adjuntos.map(adj => {
                    arrFiles.push({ fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file !== null ? adj.file : '', size: adj.size, url: adj.url === "" ? EMPTY_FILE : adj.url, saved: true });
                })


            }

            dispatch(getTitulosProfesionalesAsync(localStorage.idLegajo)).then(result => {
                setTitulosProfesional(result.payload.results)
            })


            let domicilios = datosProfesional.domicilios;
            let domiciliosArr = [];
            let domiciliosAgregados = [];
            if (Array.isArray(domicilios)) {

                domicilios.map(dom => {
                    domiciliosArr.push({
                        TpoDomicilio: dom.TpoDomicilio === null || dom.TpoDomicilio === undefined ? dom.tpoDomicilio : dom.TpoDomicilio,
                        localidad: dom.localidad,
                        codLocalidad: dom.codLocalidad !== undefined ? dom.codLocalidad : '',
                        calle: dom.calle,
                        numero: dom.numero,
                        piso: dom.piso,
                        depto: dom.depto,
                        delegacion: dom.delegacion,
                        codPostal: dom.codPostal,
                        partido: dom.partido,
                        provincia: dom.provincia,
                        telefono: dom.telefono,
                        caracteristica: dom.caracteristica,
                        caracteristicaFijo: dom.caracteristicaFijo,
                        celular: dom.celular,
                        tpoCalle: dom.tpoCalle,
                        tpoCarga: dom.tpoCarga
                    })


                    domiciliosAgregados.push(dom.TpoDomicilio === null || dom.TpoDomicilio === undefined ? dom.tpoDomicilio : dom.TpoDomicilio);
                })


                if (domiciliosArr.length < 5) {
                    datos.domicilios.map(domi => {
                        let agregarDomi = 0;
                        domiciliosAgregados.map(da => {
                            if (da === domi.TpoDomicilio)
                                agregarDomi = 1;

                        })

                        domi.delegacion = domiciliosArr.filter(d => d.TpoDomicilio === 1)[0].delegacion !== undefined ? domiciliosArr.filter(d => d.TpoDomicilio === 1)[0].delegacion : '';
                        if (agregarDomi === 0)
                            domiciliosArr.push(domi)

                    })

                }
            }
            setLegajoProfesional(datosProfesional.LegProf);

            setDatos({
                ...datos,
                Nombre: datosProfesional.nombre !== undefined ? datosProfesional.nombre.trim() : datosProfesional.Nombre.trim(),
                Apellido: datosProfesional.apellido !== undefined ? datosProfesional.apellido.trim() : datosProfesional.Apellido.trim(),
                LegProf: datosProfesional.LegProf !== undefined ? Number(datosProfesional.LegProf) : datosProfesional.legProf,
                NroDocumento: datosProfesional.nroDocumento !== undefined ? mascaraDocumento(datosProfesional.nroDocumento) : datosProfesional.NroDocumento,
                TipoDocumento: datosProfesional.tipoDocumento !== undefined ? datosProfesional.tipoDocumento : datosProfesional.TipoDocumento,
                familiares: familiaresArr,
                ObraSocial: datosProfesional.obraSocial !== undefined ? datosProfesional.obraSocial : datosProfesional.ObraSocial,
                TpoOsocial: datosProfesional.tpoOsocial !== undefined ? datosProfesional.tpoOsocial : datosProfesional.TpoOsocial,
                adjuntos: datosProfesional.adjuntos !== null ? arrFiles : datos.adjuntos,
                EstadoCivil: datosProfesional.EstadoCivil !== undefined ? datosProfesional.EstadoCivil : datosProfesional.estadoCivil,
                Observado: datosProfesional.Observado !== null ? datosProfesional.Observado : false,
                EmailRepetido: datosProfesional.EmailRepetido !== undefined ? datosProfesional.EmailRepetido : datosProfesional.Email !== undefined && datosProfesional.Email !== "null" ? datosProfesional.Email : localStorage.email,
                CuitCuil: datosProfesional.cuitCuil !== undefined ? mascaraCuitCargarDatos(datosProfesional.cuitCuil) : mascaraCuitCargarDatos(datosProfesional.CuitCuil),
                Email: datosProfesional.Email !== undefined && datosProfesional.Email !== "null" ? datosProfesional.Email : localStorage.email,
                domicilios: Array.isArray(domicilios) ? domiciliosArr : datos.domicilios,
            })

            let adjuntoDeFoto = datosProfesional.adjuntos?.find(a => a.fileType === "FotoPerfil");
            if (adjuntoDeFoto) {
                dispatch(setFotoNueva(adjuntoDeFoto.url));
            }

            let adjuntoDeFotoFull = datosProfesional.adjuntos?.find(a => a.fileType === "FotoPerfilFull");
            if (adjuntoDeFotoFull) {
                dispatch(setFotoNuevaFullSize(adjuntoDeFotoFull.url));
            }

            dispatch(setCambiaFoto(datosProfesional.cambiaFoto));
            dispatch(setCambiaDNI(datosProfesional.cambiaDNI));


            setTimeout(() => setMostrarDatos(true), 1000);

            setImprimirResultado(1);
        } else if (Matriculas.codigoError !== null && imprimirResultado === 0) {

            setImprimirResultado(1);
            setTimeout(() => setMostrarDatos(true), 1000);
        }
    }

    const [location, setLocation] = useState({
        lat: -62.7113387,
        lng: -95.00657030000001,
    });

    const [anchoPantalla, setAnchoPantalla] = useState(0);

    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }

    window.addEventListener("resize", handleResize);

    //console.log(datos);
    useEffect(() => {

        if (Matriculas.length === 0) {
            let Null = null;

            //llamo a la funcion del Slice
            if (localStorage.userType == TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS || localStorage.idLegajoTemporal !== "0") {
                if (localStorage.userType == TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS)
                    setBloquearBtn(true)


                dispatch(getMatriculasAsync({ Null, Null })).then(valor => {

                    setIdEstado(valor.payload.result.idEstado)
                    setMatriculas({

                        result: JSON.parse(valor.payload.result.jsonDatos),
                        codigoError: 0
                    })
                });


            } else {

                setIdEstado(1)
                dispatch(getLegajosAsync(({ idLegajo: null, token: null }))).then(valor => setMatriculas(valor.payload));
            }


            if (ObrasSociales.length === 0) {
                //llamo a la funcion del Slice
                dispatch(getObrasSocialesAsync());
            }

        }

        if (TipoDocumentos.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getTipoDocumentoAsync());
        }

        if (Parentescos.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getParentescosAsync());
        }

        if (TipoDomicilios.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getTipoDomicilioAsync());
        }

        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);

        navigator.geolocation.getCurrentPosition(position => {
            // console.log(position);
            setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
    }, [])

    useEffect(() => {

        if (localStorage.userType === TIPOS_USUARIO.PROFESIONAL_EDITAR_DATOS.toString()) {
            Swal.fire({
                title: 'Atención',
                html: `
                <div style="text-align: left;">
            A continuación podrá modificar/ actualizar los siguientes datos: 
            <br/><br/> Tipo y n° de DNI
            <br/> CUIL/ CUIT 
            <br/> Foto
            <br/> Email
            <br/> Estado civil
            <br/> Obra social
            <br/> Domicilios
            <br/> Teléfonos
            <br/> Grupo familiar
          </div>
                <div style="text-align: center; margin-top: 15px;" >
            <strong>IMPORTANTE:</strong>
             Deberá adjuntar <u>su</u> DNI.
            </div>
                `,
                showCloseButton: true,
                confirmButtonText: 'Aceptar',
                allowOutsideClick: true
            });
        }

    }, []);

    if (localStorage.token === "") {
        window.location.href = `/login`;

    }

    const [ocultarTabs, setOcultarTabs] = useState({ display: '' });

    const cambiarTab = (tabNuevo) => {
        if (tabNuevo === 1 && solapa !== "1") {

            let idNuevoTab = String(Number(solapa) - 1);
            navigateToTab(idNuevoTab);
        } else if (tabNuevo === 2 && solapa !== "3") {
            let idNuevoTab = String(Number(solapa) + 1);
            navigateToTab(idNuevoTab);
        }

    }
    const navigateToTab = (_, tabId) => {
        if (tabId > 3 || tabId < 0) return false;
        if (solapa == 0) {
            if (isNullOrEmpty(editarFotoSlice.cambiaDNI)) {
                Swal.fire({
                    title: 'Atención',
                    html: 'Debe responder si desea cambiar su DNI',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                return false;
            }

            if (isNullOrEmpty(editarFotoSlice.deseaCambiarFoto)) {
                Swal.fire({
                    title: 'Atención',
                    html: 'Debe responder si desea cambiar su Foto',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                return false;
            }
            if (editarFotoSlice.deseaCambiarFoto && isNullOrEmpty(editarFotoSlice.fotoNueva)) {
                Swal.fire({
                    title: 'Atención',
                    html: 'Debe cargar una nueva foto',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                return false;
            }

            if (editarFotoSlice.deseaCambiarFoto) {
                let adjuntoDeFoto = datos.adjuntos.find(a => a.fileType === "FotoPerfil");
                if (adjuntoDeFoto) {
                    adjuntoDeFoto.file = editarFotoSlice.fotoNueva;
                } else {
                    adjuntoDeFoto = nuevoAdjunto("FotoPerfil", "image/jpeg", editarFotoSlice.fotoNueva);
                    datos.adjuntos.push(adjuntoDeFoto);
                }

                let adjuntoDeFotoFull = datos.adjuntos.find(a => a.fileType === "FotoPerfilFull");
                if (adjuntoDeFotoFull) {
                    adjuntoDeFotoFull.file = editarFotoSlice.fotoNuevaFullSize;
                } else {
                    adjuntoDeFotoFull = nuevoAdjunto("FotoPerfilFull", "image/jpeg", editarFotoSlice.fotoNuevaFullSize);
                    datos.adjuntos.push(adjuntoDeFotoFull);
                }
            }
            datos.cambiaFoto = editarFotoSlice.deseaCambiarFoto;
            datos.cambiaDNI = editarFotoSlice.cambiaDNI;
            setDatos(datos);
        }
        setNumeroSolapa(tabId.toString());
    };


    return (

        <Grid container spacing={2}>
            {mostrarDatos ?
                <Grid item xs={anchoPantalla < 900 ? 11 : 10}>
                    <TabContext value={solapa}>

                        <Box>
                            <Grid container style={ocultarTabs} >
                                <Grid item xs={2} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => navigateToTab(null, Number(solapa) - 1)}><ArrowBackIosIcon /></Button>
                                </Grid>
                                <Grid item md={12} xs={9}>
                                    <TabList onChange={navigateToTab} >

                                        <Tab label="Datos personales" value="0" style={ocultarTabs} />
                                        <Tab label="Datos de contacto" value="1" style={ocultarTabs} />
                                        <Tab label="Grupo familiar primario" value="2" style={ocultarTabs} />
                                        <Tab label="Adjuntos" value="3" style={ocultarTabs} />

                                    </TabList>
                                </Grid>
                                <Grid item xs={1} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => navigateToTab(null, Number(solapa) + 1)}><ArrowForwardIosIcon /></Button>
                                </Grid>
                            </Grid>
                        </Box>

                        <Paper>

                            <TabPanel value="0">
                                <DatosPersonales datos={datos}
                                    bloquearBtn={bloquearBtn}
                                    legajoProfesional={legajoProfesional}
                                    setBloquearBtn={setBloquearBtn}
                                    setDatos={setDatos}
                                    setValue={setNumeroSolapa}
                                    ObrasSociales={ObrasSociales}
                                    idEstado={idEstado}
                                    libraries={libraries}
                                    loaderBtnGuardar={loaderBtnGuardar}
                                    setLoaderBtnGuardar={setLoaderBtnGuardar}
                                    legajoTemporal={legajoTemporal}
                                    setLegajoTemporal={setLegajoTemporal}
                                />
                            </TabPanel>

                            <TabPanel value="1">
                                <DatosContacto datos={datos}
                                    bloquearBtn={bloquearBtn}
                                    setBloquearBtn={setBloquearBtn}
                                    setDatos={setDatos}
                                    setValue={setNumeroSolapa}
                                    ObrasSociales={ObrasSociales}
                                    idEstado={idEstado}
                                    libraries={libraries}
                                    loaderBtnGuardar={loaderBtnGuardar}
                                    setLoaderBtnGuardar={setLoaderBtnGuardar}
                                    legajoTemporal={legajoTemporal}
                                    setLegajoTemporal={setLegajoTemporal}
                                />
                            </TabPanel>
                            <TabPanel value="2"  >
                                <GrupoFamiliar datos={datos}
                                    setDatos={setDatos}
                                    bloquearBtn={bloquearBtn}
                                    setBloquearBtn={setBloquearBtn}
                                    setValue={setNumeroSolapa}
                                    ObrasSociales={ObrasSociales}
                                    idEstado={idEstado}
                                    loaderBtnGuardar={loaderBtnGuardar}
                                    setLegajoTemporal={setLegajoTemporal}
                                    setLoaderBtnGuardar={setLoaderBtnGuardar} />
                            </TabPanel>

                            <TabPanel value="3" >
                                <ArchivosAdjuntos datos={datos}
                                    setDatos={setDatos}
                                    titulosProfesional={titulosProfesional}
                                    bloquearBtn={bloquearBtn}
                                    setBloquearBtn={setBloquearBtn}
                                    setValue={setNumeroSolapa}
                                    setOcultarTabs={setOcultarTabs}
                                    loaderBtnGuardar={loaderBtnGuardar}
                                    setLoaderBtnGuardar={setLoaderBtnGuardar}
                                    idEstado={idEstado}
                                    setLegajoTemporal={setLegajoTemporal}
                                    setIdEstado={setIdEstado} />
                            </TabPanel>
                        </Paper>

                    </TabContext>

                </Grid>
                :
                <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </div>
                </Grid>
            }

            {((idEstado !== 1 && idEstado !== 7 && localStorage.userType != TIPOS_USUARIO.PROFESIONAL_EDITAR_DATOS) || (localStorage.userType == TIPOS_USUARIO.PROFESIONAL_EDITAR_DATOS && datos.Observado ) || localStorage.userType == TIPOS_USUARIO.SECRETARIO_ACTUALIZACION_DATOS)
                && <Chat datos={datos} setDatos={setDatos} setIdEstado={setIdEstado} />}

        </Grid>
    );
};

export default Matriculas;
