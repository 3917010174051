import React, { Fragment, useState, useEffect } from 'react';

import { Autocomplete, Button, Card, CardContent, Fab, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, Modal, MenuItem, Typography, TextField, RadioGroup, Radio, Select } from '@mui/material';
import { Buscador } from 'components/matriculas/buscador';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2'
import {
    useLoadScript
} from "@react-google-maps/api";

import "@reach/combobox/styles.css";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { DomicilioUsuario } from 'components/domiciliosTitulosEspeciales/domiciliosUsuarioTitulosEspeciales';
import { Mapa } from 'components/matriculas/mapa';
import { partidosList, getPartidosAsync } from 'app/features/Partidos/partidosSlice';
import { getTipoCalleAsync } from 'app/features/TipoCalles/tipoCallesSilice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { postLocalidades } from 'app/features/Localidades/localidadesAPI';
const useStyles = makeStyles(theme => ({
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    color_rojo: {
        color: 'red'
    },
    centrarContenido: {
        display: 'flex',
        justifyContent: 'center'
    },
    backGroundBox: {
        backgroundColor: '#00b347!important'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        bomdhadow: 24,
        p: 4,
        '@media (max-width:900px)': {
            top: '93%',
        }
    },
    modales: {
        overflow: 'scroll',

    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    mapElements: {
        height: '100%'
    }
}));

export const Domicilios = ({ datos, bloquearBtn, setDatos, location, setLocation, ValorTab, bloquearTabs, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn,  idEstado, libraries, setMatriculas }) => {
    const clases = useStyles();


    // if (datos.domicilios[0].TpoDomicilio !== null && datos.domicilios[0].TpoDomicilio !== undefined && datos.domicilios[0].TpoDomicilio !== 0) {
    //     datos.domicilios.sort(function (a, b) {

    //         if (a.TpoDomicilio > b.TpoDomicilio) {
    //             return 1;
    //         }
    //         if (a.TpoDomicilio < b.TpoDomicilio) {
    //             return -1;
    //         }

    //         // a must be equal to b

    //         return 0;
    //     });
    // }

    const dispatch = useDispatch();
    const Partidos = useSelector(partidosList);
    //console.log(Partidos);

    const [domiciliosProfesionales, setDomiciliosProfesionales] = useState({
        DomicilioRealCaja: {

            TpoDomicilio: 5,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        },
        DomicilioCorrespondencia: {
            TpoDomicilio: 4,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        },
        DomicilioConstituido: {
            TpoDomicilio: 3,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        },
        DomicilioProfesional: {
            TpoDomicilio: 2,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        },
        DomicilioParticular: {
            TpoDomicilio: 1,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        }
    });


    const [datosDocimilio, setDatosDocimilio] = useState({
        localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristica: '',
        celular: '', codLocalidad: '', edito: true
    });
    const [open, setOpen] = useState(false);
    const [TpoDomicilio, setTipoDomicilio] = useState(0);

    const [tituloModal, setTituloModal] = useState('');

    const { handleSubmit, formState: { errors } } = useForm();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let partidosSelect = [];
    const [tipoCalleSelect, setTipoCalleSelect] = useState([]);
    const inputHandleChange = (evt) => {

        //se limitan los caracteres para que no haga problemas cuando inserta en el SQL

        if (evt.target.name == "caracteristica" || evt.target.name == "caracteristicaFijo" || evt.target.name == "celular" || evt.target.name == "telefono") {
            evt.target.value = evt.target.value.replace(/\-/g, '').replace(/[^0-9]/g, "").replace(/,/g, '');
        }

        if ((evt.target.name == "piso" || evt.target.name == "depto" || evt.target.name == "numero") && evt.target.value.length > 18)
            return false;

        if (evt.target.name == "codPostal" && evt.target.value.length > 10)
            return false;



        if ((evt.target.name == "calle") && evt.target.value.length > 50)
            return false;

        if ((evt.target.name == "caracteristica" || evt.target.name == "caracteristicaFijo") && evt.target.value.length > 5)
            return false;

        setDatosDocimilio({
            ...datosDocimilio,
            [evt.target.name]: evt.target.name === "tpoCarga" ? Number(evt.target.value) : evt.target.value
        })
    }

    let datosDomicilioRealCaja = {};
    let datosDomicilioCorrespondencia = {};
    let datosDomiciliosConstituido = {};
    let datosDomiciliosProfesional = {};
    let datosDomiciliosParticular = {};

    const [listaLocalidades, setListaLocalidades] = useState([]);


    const buscarLocalidades = letras => {
        let datos = {
            nombreLocalidad: letras,
            nombreProvincia: '',
            codPostal: ''
        }
        if (letras.trim().length >= 4) {
            postLocalidades(datos).then(result => {
                let localidadesArr = [];
                let nombreRepetido = "";

                result.data.map((ld, index) => {

                    if (nombreRepetido !== ld.nombreLocalidad) {

                        //    if(index === 0){
                        //     setDatosDocimilio({
                        //         ...datosDocimilio,
                        //         codLocalidad: String(ld.codLocalidad),
                        //         localidad:  ld.nombreLocalidad
                        //     })
                        //    }
                        localidadesArr.push({ key: (Math.random() * (999999 - 1)), label: ld.nombreLocalidad, value: ld.codLocalidad, codPartido: ld.codPartido })
                        nombreRepetido = ld.nombreLocalidad;
                    }

                })

                setListaLocalidades(localidadesArr);
            })
        }

    }

    const [errorTelefono, setErrorTelefono] = useState({ display: "none" });
    const [errorTelefonoTexto, setErrorTelefonoText] = useState('');

    const guardarDatosTemporales = () => {

        if (isNaN(datosDocimilio.caracteristica)) {
            setErrorTelefonoText("La Caracteristica del teléfono Celular debe contener solo números")
            setErrorTelefono({ display: "" })
            return false;
        }

        if (isNaN(datosDocimilio.caracteristicaFijo)) {
            setErrorTelefonoText("La Caracteristica del teléfono Fijo debe contener solo números")
            setErrorTelefono({ display: "" })
            return false;
        }
        if (isNaN(datosDocimilio.celular)) {
            setErrorTelefonoText("El Teléfono Celular debe contener solo números")
            setErrorTelefono({ display: "" })
            return false;
        }
        if (isNaN(datosDocimilio.telefono)) {
            setErrorTelefonoText("El Teléfono fijo debe contener solo números")
            setErrorTelefono({ display: "" })
            return false;
        }

        setOpen(false);

        setBloquearBtnEdit(false);

        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].localidad = datosDocimilio.localidad;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].codLocalidad = datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '';
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].calle = datosDocimilio.calle;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].numero = datosDocimilio.numero;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].piso = datosDocimilio.piso;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].depto = datosDocimilio.depto;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].delegacion = datosDocimilio.delegacion;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].codPostal = datosDocimilio.codPostal;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].provincia = datosDocimilio.provincia;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].partido = datosDocimilio.partido;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].telefono = datosDocimilio.telefono;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].caracteristica = datosDocimilio.caracteristica;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].caracteristicaFijo = datosDocimilio.caracteristicaFijo;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].celular = datosDocimilio.celular;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].tpoCalle = datosDocimilio.tpoCalle;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].edito = true;
        datos.domicilios.filter(d => d.TpoDomicilio === TpoDomicilio)[0].tpoCarga = datosDocimilio.tpoCarga;

        setDatos({
            ...datos,
            domicilios: datos.domicilios
        });

        if (TpoDomicilio === 1 && idEstado !== 1 && idEstado !== 8) {

            Swal.fire({
                title: 'Atención',
                html: localStorage.userType === "3" ? '¿Desea utilizar este domicilio y teléfono para los demas?' : '¿Desea utilizar el domicilio Particular para los domicilios: Profesional, Constituido, Correspondencia y Real Caja?',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText:
                    'Si',
                cancelButtonText:
                    'No',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {


                    datos.domicilios.map(dom => {

                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].localidad = datosDocimilio.localidad;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].codLocalidad = datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '';
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].calle = datosDocimilio.calle;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].numero = datosDocimilio.numero;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].piso = datosDocimilio.piso;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].depto = datosDocimilio.depto;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].delegacion = datosDocimilio.delegacion;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].codPostal = datosDocimilio.codPostal;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].provincia = datosDocimilio.provincia;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].partido = datosDocimilio.partido;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].telefono = datosDocimilio.telefono;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].caracteristica = datosDocimilio.caracteristica;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].caracteristicaFijo = datosDocimilio.caracteristicaFijo;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].celular = datosDocimilio.celular;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].tpoCalle = datosDocimilio.tpoCalle;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].edito = true;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom)[0].tpoCarga = datosDocimilio.tpoCarga;

                    });

                    setDatos({
                        ...datos,
                        domicilios: datos.domicilios
                    });
                }

            });

        }

    }

    const [bloquearBtnEdit, setBloquearBtnEdit] = useState(true);


    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBb5RiBio4TouN2IjNLz-LVW4BbIZH0eE8",
        libraries
    })

    if (datosDocimilio.provincia !== "" || Number(datosDocimilio.tpoCarga) === 1) {


        let provincia = datosDocimilio.provincia;

        if (provincia === "Provincia de Buenos Aires")
            provincia = "Buenos Aires";

        if (provincia === "CABA")
            provincia = "Capital Federal";

        partidosSelect = [];


        Partidos.map((partido, index) => {
            if (provincia.normalize("NFD").replace(/[\u0300-\u036f]/g, "") === partido.provincia.normalize("NFD").replace(/[\u0300-\u036f]/g, "") || datosDocimilio.tpoCarga === 1) {
                partidosSelect.push({ label: partido.descripcion.trim(), value: partido.descripcion.trim() })
            }
        })
    }

    const [anchoPantalla, setAnchoPantalla] = useState(0)

    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }

    window.addEventListener("resize", handleResize);

    useEffect(() => {

        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);
        if (Partidos.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getPartidosAsync());
        }

        dispatch(getTipoCalleAsync()).then(response => {
            let tipoCalle = [];

            response.payload.map(tpoCalle => {
                tipoCalle.push({ value: tpoCalle.tpoCalle, label: tpoCalle.denTpoCalle })
            })

            setTipoCalleSelect(tipoCalle);
        })
        //setDomiciliosProfesionales();
        //  console.log(datos.domicilios);
        datos.domicilios.map((domicilio, index) => {

            if (index + 1 === 1 && domicilio.calle !== "" && domicilio.provincia !== "") {
                setBloquearBtnEdit(false)
            }

        });

        if (idEstado === 4 || idEstado === 9 && localStorage.userType === "0") {
            Swal.fire({
                title: 'Atención',
                html: '¿Desea utilizar el domicilio Particular para los domicilios: Profesional, Constituido, Correspondencia y Real Caja?',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText:
                    'Si',
                cancelButtonText:
                    'No',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {

                    datos.domicilios.map(dom => {
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].localidad = datos.domicilios[0].localidad;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].codLocalidad = datos.domicilios[0].codLocalidad;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].calle = datos.domicilios[0].calle;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].numero = datos.domicilios[0].numero;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].piso = datos.domicilios[0].piso;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].depto = datos.domicilios[0].depto;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].delegacion = datos.domicilios[0].delegacion;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].codPostal = datos.domicilios[0].codPostal;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].provincia = datos.domicilios[0].provincia;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].partido = datos.domicilios[0].partido;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].telefono = datos.domicilios[0].telefono;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].caracteristica = datos.domicilios[0].caracteristica;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].caracteristicaFijo = datos.domicilios[0].caracteristicaFijo;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].celular = datos.domicilios[0].celular;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].tpoCalle = datos.domicilios[0].tpoCalle;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].edito = true;
                        datos.domicilios.filter(d => d.TpoDomicilio === dom.TpoDomicilio)[0].tpoCarga = datos.domicilios[0].tpoCarga;

                    });

                    setDatos({
                        ...datos,
                        domicilios: datos.domicilios
                    });
                }

            });
        }


    }, [])

    const guardarContinuar = () => {

        let erroresMostrar = "";
        let mostrarErrorDomicilio = 0;
        datos.domicilios.map((domicilio, index) => {
            // console.log(domicilio);
            if (idEstado === 1 && domicilio.TpoDomicilio === 1) {
                if ((domicilio.calle === "" || domicilio.caracteristica === null || domicilio.caracteristica === "" || domicilio.celular === "" || (isNaN(domicilio.caracteristica) || isNaN(domicilio.celular))) && mostrarErrorDomicilio === 0) {
                    if (erroresMostrar !== "") {
                        erroresMostrar += ", ";
                    }
                    erroresMostrar += "<b>Domicilios y/o Teléfono</b>";
                    mostrarErrorDomicilio = 1;
                }
            }
        })


        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }

        setLoaderBtnGuardar(true)
        setBloquearBtn(true);
        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 9 }).then((value) => {

            setMatriculas(value.data);

                setLoaderBtnGuardar(false)
                setBloquearBtn(false);
                

                if (value.data.resultado) {
                    ValorTab("4");
                } else {
                    Swal.fire({
                        title: 'Atención',
                        html: value.data.mensaje !== '' && value.data.mensaje !== null ? value.data.mensaje : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonText:
                            'Aceptar',
                        cancelButtonText:
                            'Cancelar',
                        allowOutsideClick: true
                    })
                    return false;
                }
        });

    }

    const [titulosSelect, setTitulosSelect] = useState({ label: '', value: 0 });
    const [inputValue, setInputValue] = useState('');
    const [marcadores, setMarcadores] = useState([{
        lat: location.lat,
        lng: location.lng,
        time: new Date()
    }]);

    const ingresaPartido = (event, value) => {

        if (value !== null) {
            let delegacionSelect = '';
            let provinciaSelect = '';
            Partidos.map((partido, index) => {
                if (value.value === partido.descripcion) {
                    delegacionSelect = partido.razonSocial;
                    provinciaSelect = partido.provincia;
                }

            })

            if (TpoDomicilio === 1) {

                setDatosDocimilio({
                    ...datosDocimilio,
                    partido: value.value,
                    delegacion: delegacionSelect,
                    provincia: provinciaSelect
                })
            } else {
                setDatosDocimilio({
                    ...datosDocimilio,
                    partido: value.value,
                    provincia: provinciaSelect
                })
            }

        } else {
            setDatosDocimilio({
                ...datosDocimilio,
                partido: '',
                provincia: ''
            })
        }
    }

    const ingresaTipoCalle = (event, value) => {

        if (value !== null) {

            setDatosDocimilio({
                ...datosDocimilio,
                tpoCalle: Number(value.value)
            })

        } else {
            setDatosDocimilio({
                ...datosDocimilio,
                tpoCalle: 0
            })
        }
    }
    const ingresaLocalidad = (event, value) => {


        if (value !== null) {

            let delegacionSelect = '';
            let partidoSelect = '';
            let provinciaSelect = '';
            Partidos.map((partido, index) => {
                if (value.codPartido === partido.codPartido) {
                    delegacionSelect = partido.razonSocial;
                    partidoSelect = partido.descripcion;
                    provinciaSelect = partido.provincia;
                }

            })

            if (TpoDomicilio === 1) {

                setDatosDocimilio({
                    ...datosDocimilio,
                    partido: partidoSelect,
                    delegacion: delegacionSelect,
                    codLocalidad: String(value.value),
                    localidad: value.label,
                    provincia: provinciaSelect
                })
            } else {
                setDatosDocimilio({
                    ...datosDocimilio,
                    partido: partidoSelect,
                    codLocalidad: String(value.value),
                    localidad: value.label,
                    provincia: provinciaSelect
                })
            }

        } else {
            setDatosDocimilio({
                ...datosDocimilio,
                codLocalidad: '',
                localidad: '',
                partido: '',
                provincia: ''
            })
        }
    }
    const [mensajeSinOpciones, setMensajeSinOpciones] = useState('Debe ingresar al menos 4 letras y aguarde los resultados')

    return (
        <Fragment>
            
                <Typography sx={{ mb: 1 }} >* <b>  { idEstado === 1 || idEstado === 3 || idEstado  === 8  ? 'Deberá declarar el domicilio particular para ello deberá hacer clic en el botón' : 'Deberá declarar todos los domicilios indicados: particular, profesional,correspondiente, constituido y real caja. Para comenzar a cargar sus domicilios deberá hacer clic en el botón' }  <EditIcon />.</b></Typography>


                    

            <Grid container spacing={2}>
                {tipoCalleSelect.length >= 1 ?

                    <DomicilioUsuario datos={datos}
                        bloquearBtn={bloquearBtn}
                        setTituloModal={setTituloModal}
                        setOpen={setOpen}
                        setErrorTelefono={setErrorTelefono}
                        bloquearTabs={bloquearTabs}
                        setTipoDomicilio={setTipoDomicilio}
                        setDatosDocimilio={setDatosDocimilio}
                        bloquearBtnEdit={bloquearBtnEdit}
                        tipoCalleSelect={tipoCalleSelect}
                    />
                    : <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </div>
                    </Grid>
                }

            </Grid>
            {tipoCalleSelect.length >= 1 &&
                <Modal
                    className={clases.modales}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Card className={clases.modal}>
                        <CardContent>
                            <Grid container>
                                <Grid item md={10} xs={10}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Domicilio: {tituloModal} {(localStorage.userType === "3" || localStorage.userType === "6") && " y teléfono"}
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={2} container justifyContent="flex-end">
                                    <CloseIcon style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                                </Grid>
                                <Grid item md={4}> <Typography sx={{ mt: "9px" }} ><b>Seleccione como desea cargar el domicilio:</b> </Typography></Grid>
                                <Grid item md={3} xs={3}>
                                    <FormControl>

                                        <RadioGroup

                                            row
                                            name="position"
                                            value={datosDocimilio.tpoCarga}
                                        >
                                            <FormControlLabel
                                                value={1}
                                                onChange={inputHandleChange}
                                                name="tpoCarga"
                                                control={<Radio />}
                                                label="Manualmente"
                                                labelPlacement="start"
                                            />

                                            <FormControlLabel
                                                value={0}

                                                onChange={inputHandleChange}
                                                name="tpoCarga"
                                                control={<Radio />}
                                                label="Google"
                                                labelPlacement="start"
                                            />


                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>



                            <form onSubmit={handleSubmit(guardarDatosTemporales)}>
                                <Grid container spacing={2}>
                                    {datosDocimilio.tpoCarga === 0 ?
                                        <Grid item md={12} xs={12} sx={{ mt: 2 }} >
                                            <Grid container spacing={2}>


                                                <Grid item md={7} xs={12}>
                                                    <Buscador setLocation={setLocation}
                                                        location={location}
                                                        datosCargados={datos}
                                                        Partidos={Partidos}
                                                        TpoDomicilio={TpoDomicilio}
                                                        libraries={libraries}
                                                        setListaLocalidades={setListaLocalidades}
                                                        titulosSelect={titulosSelect}
                                                        setTitulosSelect={setTitulosSelect}
                                                        setMarcadores={setMarcadores}
                                                        setDatosDocimilio={setDatosDocimilio}
                                                        datosDocimilio={datosDocimilio}
                                                    />
                                                </Grid>
                                                <Grid item md={2} xs={6}>
                                                    <Autocomplete
                                                        name='tpoCalle'
                                                        onChange={ingresaTipoCalle}
                                                        options={tipoCalleSelect}
                                                        noOptionsText={'No se encontraron Tipos de Calle'}
                                                        value={{ label: datosDocimilio.tpoCalle !== 0 && datosDocimilio.tpoCalle !== undefined && tipoCalleSelect.filter(e => e.value === datosDocimilio.tpoCalle)[0].label !== undefined ? tipoCalleSelect.filter(e => e.value === datosDocimilio.tpoCalle)[0].label : '', value: datosDocimilio.tpoCalle }}
                                                        getOptionLabel={(option) => option.label}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        renderInput={(params) => <TextField {...params} required label="Tipos de Calle" />}
                                                    />
                                                </Grid>
                                                <Grid item md={3} xs={6}>

                                                    <Autocomplete
                                                        name='codLocalidad'
                                                        onChange={ingresaLocalidad}
                                                        options={listaLocalidades}
                                                        noOptionsText={"No se encontraron Localidades"}
                                                        value={{ label: datosDocimilio.localidad, value: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : 0 }}
                                                        getOptionLabel={option => option.label}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        renderInput={(params) => <TextField {...params} required label="Localidad" />}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {anchoPantalla < 900 &&
                                                <Grid item xs={12} sx={{ mt: 2 }}>

                                                    <Mapa setDatosDocimilio={setDatosDocimilio}
                                                        titulosSelect={titulosSelect}
                                                        datosCargados={datos}
                                                        Partidos={Partidos}
                                                        TpoDomicilio={TpoDomicilio}
                                                        marcadores={marcadores}
                                                        setListaLocalidades={setListaLocalidades}
                                                        libraries={libraries}
                                                        setMarcadores={setMarcadores}
                                                        setTitulosSelect={setTitulosSelect}
                                                        datosDocimilio={datosDocimilio}
                                                        location={location}
                                                        setLocation={setLocation}
                                                    />
                                                    <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >Si la ubicación encontrada no es la correcta, por favor seleccioanela en el mapa.</Typography>
                                                </Grid>
                                            }
                                        </Grid>

                                        :
                                        <Grid item md={12} xs={12} sx={{ mt: 2 }}>
                                            <Grid container spacing={2}>
                                                <Grid item md={3} xs={12}>
                                                    <Autocomplete
                                                        name='tpoCalle'
                                                        onChange={ingresaTipoCalle}
                                                        options={tipoCalleSelect}
                                                        noOptionsText={'No se encontraron Tipos de Calle'}
                                                        value={{ label: datosDocimilio.tpoCalle !== 0 && datosDocimilio.tpoCalle !== undefined && tipoCalleSelect.filter(e => e.value === datosDocimilio.tpoCalle)[0].label !== undefined ? tipoCalleSelect.filter(e => e.value === datosDocimilio.tpoCalle)[0].label : '', value: datosDocimilio.tpoCalle }}
                                                        getOptionLabel={(option) => option.label}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        renderInput={(params) => <TextField {...params} required label="Tipos de Calle" />}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <TextField label="Calle"
                                                        name='calle'
                                                        onChange={inputHandleChange}
                                                        value={datosDocimilio.calle}

                                                        variant="outlined"
                                                        fullWidth
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <TextField label="Número"
                                                        name='numero'
                                                        onChange={inputHandleChange}
                                                        value={datosDocimilio.numero}

                                                        variant="outlined"
                                                        fullWidth
                                                        type="number"
                                                    />
                                                </Grid>
                                                {/* <Grid item md={3} xs={12}>
                                                    <TextField label="Localidad"
                                                        name='localidad'
                                                        onChange={inputHandleChange}
                                                        value={datosDocimilio.localidad}
                                                        variant="outlined"
                                                        fullWidth
                                                        type="text"
                                                    />
                                                </Grid> */}
                                                <Grid item md={3} xs={6}>

                                                    <Autocomplete
                                                        name='codLocalidad'
                                                        onChange={ingresaLocalidad}
                                                        options={listaLocalidades}
                                                        inputValue={inputValue}
                                                        onInputChange={(event, newInputValue) => {
                                                            setInputValue(newInputValue)
                                                            if (newInputValue.trim().length >= 4)
                                                                buscarLocalidades(newInputValue)
                                                            else {
                                                                setMensajeSinOpciones("Debe ingresar al menos 4 letras y aguarde los resultados")
                                                                setListaLocalidades([]);

                                                            }
                                                        }}
                                                        noOptionsText={mensajeSinOpciones}

                                                        value={datosDocimilio.localidad !== '' && datosDocimilio.localidad !== undefined ? datosDocimilio.localidad : ''}
                                                        // getOptionLabel={option => option.label}
                                                        // isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        renderInput={(params) => <TextField {...params} required label="Localidad" />}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                    <Grid item md={datosDocimilio.tpoCarga === 1 ? 12 : 5} xs={12}>
                                        <Grid container spacing={2}>

                                            <Grid item md={6} xs={6}>
                                                <TextField label="Piso"
                                                    name='piso'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.piso}

                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                />
                                            </Grid>

                                            <Grid item md={6} xs={6}>
                                                <TextField label="Departamento"
                                                    name='depto'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.depto}
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Autocomplete
                                                    name='partido'
                                                    onChange={ingresaPartido}
                                                    options={partidosSelect}
                                                    noOptionsText={'No se encontraron Partidos'}
                                                    value={{ label: datosDocimilio.partido, value: datosDocimilio.partido }}
                                                    getOptionLabel={(option) => option.label}
                                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                                    renderInput={(params) => <TextField {...params} required label="Partido" />}
                                                />
                                            </Grid>

                                            <Grid item md={6} xs={6}>
                                                <TextField label="Codigo Postal"
                                                    name='codPostal'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.codPostal}
                                                    required
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={6}>
                                                <TextField label="Delegación"
                                                    name='delegacion'
                                                    disabled
                                                    required
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.delegacion}
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                />
                                            </Grid>

                                            <Grid item md={6} xs={12}>
                                                <TextField label="Provincia"
                                                    name='provincia'
                                                    disabled
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.provincia}
                                                    required
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                />
                                            </Grid>

                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 1 : 2} xs={12} className="centrar_contenido"  >
                                                <Grid className="centrar_contenido">
                                                    <Typography  ><b>Teléfono  Celular</b></Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 3 : 4} xs={5}>
                                                <TextField label="Caracteristica"
                                                    name='caracteristica'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.caracteristica}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                0
                                                            </InputAdornment>
                                                        ),
                                                        pattern: "[0-9]+",
                                                        maxLength: 5
                                                    }}
                                                    type="text"
                                                />
                                            </Grid>

                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 8 : 6} xs={7}>
                                                <TextField label="Celular"
                                                    name='celular'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.celular}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ maxLength: 12 }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                15
                                                            </InputAdornment>
                                                        ),
                                                        maxLength: 12
                                                    }}
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 1 : 2} xs={12} className="centrar_contenido"  >
                                                <Grid className="centrar_contenido">
                                                    <Typography  ><b>Teléfono  Fijo</b></Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 3 : 4} xs={5}>
                                                <TextField label="Caracteristica"
                                                    name='caracteristicaFijo'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.caracteristicaFijo}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                0
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 8 : 6} xs={7}>
                                                <TextField label="Fijo"
                                                    name='telefono'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.telefono}
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{ maxLength: 12 }}
                                                    type="text"
                                                />
                                            </Grid>


                                            <Grid item md={12} style={errorTelefono} sx={{ m: 0 }}>
                                                <Typography className={clases.color_rojo}  >{errorTelefonoTexto}</Typography>
                                            </Grid>
                                            <Grid item sx={{ mt: errorTelefono.display === "none" ? 3 : 0 }} md={12}>
                                                <Grid container justifyContent="center">
                                                    <Button startIcon={<CheckIcon />} type="submit" variant="contained" color="info" >

                                                        Confirmar
                                                    </Button>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {datosDocimilio.tpoCarga === 0 && anchoPantalla > 900 &&
                                        <Grid item md={7} xs={12}>

                                            <Mapa setDatosDocimilio={setDatosDocimilio}
                                                titulosSelect={titulosSelect}
                                                marcadores={marcadores}
                                                setListaLocalidades={setListaLocalidades}
                                                setMarcadores={setMarcadores}
                                                Partidos={Partidos}
                                                libraries={libraries}
                                                setTitulosSelect={setTitulosSelect}
                                                datosDocimilio={datosDocimilio}
                                                datosCargados={datos}
                                                TpoDomicilio={TpoDomicilio}
                                                location={location}
                                                setLocation={setLocation}
                                            />
                                            <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >Si la ubicación encontrada no es la correcta, por favor seleccioanela en el mapa.</Typography>
                                        </Grid>
                                    }
                                </Grid>

                            </form>
                        </CardContent>
                    </Card>
                </Modal>
            }
            {localStorage.userType !== "3" && localStorage.userType !== "6" &&
                <Grid container>
                    <Grid item md={12}> </Grid>
                    <Grid item md={6} xs={6} sx={{ mt: 3 }} container>
                        <Button startIcon={<ArrowBackIosIcon />} onClick={() => ValorTab("2")} variant="contained" type="submit" color="error" >
                            Atras
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                            <Button disabled={bloquearBtn} endIcon={<ArrowForwardIosIcon />} onClick={() => guardarContinuar()} variant="contained" type="submit" color="secondary" >

                                {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                            </Button>
                            {/* <Button endIcon={<ArrowForwardIosIcon />} onClick={() => ValorTab("3")} variant="contained" type="submit" color="info" >

                            Continuar
                        </Button> */}
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Fragment>
    );
}
