import axios from 'axios';

import { 
	HEADERS,
	LOCALIDADES
} from 'constants/routes/endpoints';



export const postLocalidades = (datos) => {
    //console.log(datos);
	return new Promise((resolve, reject) => {
		return axios({
			url: LOCALIDADES,
			method: 'POST',
            data:datos,
			headers: HEADERS,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
			
			});
	});
};

